import {v4 as uuidv4} from 'uuid'
import axios from 'axios'
import {ApplicationPath} from "../class/general/ApplicationPath";
import {AuthenticationService} from "./AuthenticationService";
import {AdaUserService} from "./AdaUserService";
//import moment from "moment"
import _ from 'lodash'
import {getMimetypeString, Mimetype} from "../class/bundel/Mimetype";

import {Referentiebestand} from "../class/bundel/Referentiebestand";
import {CarrouselContext} from "../class/carrousel/Carrousel";
import {TeKoopItem} from "../class/bundel/TeKoopItem";
import * as SparkMD5 from 'spark-md5';
import {
    MimetypeIcon_Illustrator,
    MimetypeIcon_Directory,
    MimetypeIcon_Zip,
    MimetypeIcon_TextPlain,
    MimetypeIcon_TextRtf,
    MimetypeIcon_TextCsv,
    MimetypeIcon_Powerpoint,
    MimetypeIcon_Word,
    MimetypeIcon_Numbers,
    MimetypeIcon_Excel,
    MimetypeIcon_Photoshop,
    MimetypeIcon_Pdf,
    MimetypeIcon_Jpeg,
    MimetypeIcon_Svg,
    MimetypeIcon_File
} from "../component/icon/Icons";

const BUNDEL_TABLE: string = 'bundel'
const BUNDEL_REFERENTIEBESTANDEN_TABLE: string = 'bundel_referentiebestand'
const BUNDEL_TEKOOPITEMS_TABLE: string = 'bundel_teKoopItem'
const COLLECTIE_BUNDEL_TABLE: string = 'collectie_bundel'
const moment = require('moment')


export class BundelService {

    applicationPath: ApplicationPath
    authenticationInstance: AuthenticationService
    adaUserService: AdaUserService
    bundelLookup: object = {}
    collectieSettingsLookup: object = {}
    bundelCollectiesLookup: object = {}
    collectieBundelsLookup: object = {}
    bundelReferentiebestantdenLookup: object = {}
    bundelTeKoopItemsLookup: object = {}
    bundelOutlineLookup: object = {}
    bundelPathLookup: object = {}
    bundelSettingsLookup: object = {}

    constructor(authenticationInstance, adaUserService, applicationPath) {
        this.authenticationInstance = authenticationInstance
        this.adaUserService = adaUserService
        this.applicationPath = applicationPath
    }

    generateUuid = () => {
        return uuidv4()
    }

    //==============================================================
    //==============================================================
    //                      BUNDEL
    //==============================================================
    //==============================================================

    getBundel = (uuid: string) => {

        return new Promise((resolve, reject) => {

            if (this.bundelLookup[uuid]) {
                resolve(this.bundelLookup[uuid])
                return
            }
            const url = `${this.applicationPath.crud_api()}${BUNDEL_TABLE}/?filter[]=uuid,eq,${uuid}&transform=1`

            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {
                    const bundelArr = response.data.bundel

                    if (bundelArr.length > 0) {
                        const bundel = bundelArr[0]
                        this.bundelLookup[bundel.uuid] = bundel
                        resolve(bundel)
                    } else {

                        resolve(null)
                    }
                })
                .catch(error => {
                    console.error('ERROR', error)
                    reject(error)
                })


        })

    }//MULTIPLE BUNDELS
    getBundels = (ids: number[]) => {
        return new Promise((resolve, reject) => {

            if (ids.length === 0) {
                resolve([])
                return
            }

            const cachedBundels = ids.reduce((bundels, id) => {
                if (this.bundelLookup[id]) bundels.push(this.bundelLookup[id])
                return bundels
            }, [])

            const bundelsToFetch = ids.filter(id => !this.bundelLookup[id])

            if (bundelsToFetch.length === 0) {
                resolve(cachedBundels)
                return
            }

            axios.get(`${this.applicationPath.crud_api()}${BUNDEL_TABLE}/${bundelsToFetch.join(',')}?transform=1`, this.authenticationInstance.axiosOptions)
                .then(response => {
                    let bundels = response.data
                    if (!Array.isArray(bundels)) bundels = [bundels]
                    for (const bundel of bundels) {
                        this.bundelLookup[bundel.id] = bundel
                    }
                    resolve([...bundels, ...cachedBundels])
                })
                .catch(error => {
                    console.error('GET ERROR', error)
                    reject(error)
                })
        })
    }

    updateBundel = (bundel) => {

        bundel.gewijzigd = moment().format('YYYY-MM-DD HH:mm:ss')
        bundel.isMetadataDirty = 1

        const url = `${this.applicationPath.crud_api()}${BUNDEL_TABLE}/${bundel.id}?transform=1`

        return new Promise((resolve, reject) => {
                axios.put(url, bundel)
                    .then((result) => {
                        this.bundelLookup[bundel.id] = {...bundel}
                        resolve(result)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    createBundel = (bundel) => {

        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api()}${BUNDEL_TABLE}/?transform=1`

            axios.post(url, bundel)
                .then((result) => {
                    bundel.id = result.data
                    this.bundelLookup[bundel.id] = bundel
                    resolve(bundel)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    deleteBundel = (bundel, collectie) => {

        return new Promise((resolve, reject) => {

            //call naar deleteBundel in api (om referentiebeeld en bundelmap te verwijderen)
            const config: any = this.authenticationInstance.axiosOptions


            let deleteBundelUrl = `${this.applicationPath.ada_api()}/deleteBundel/${bundel.uuid}/`
            axios.delete(deleteBundelUrl, config)
                .then((result) => {
                    //console.log('DELETE BUNDEL', result, config)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })

            let deleteRefBeeldUrl = `${this.applicationPath.ada_api()}/deleteReferentiebeeld/${bundel.uuid}/`
            axios.delete(deleteRefBeeldUrl, config)
                .then((result) => {
                    //console.log('DELETE REFERENTIEBEELD', result)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })


            //Delete bundel where id = bundel.id
            const url = `${this.applicationPath.crud_api()}bundel/${bundel.id}?transform=1`

            axios.delete(url)
                .then((result) => {
                    //console.log('DELETE BUNDEL', bundel, collectie, this.collectieBundelsLookup)
                    if (this.collectieBundelsLookup[collectie.id])
                        this.collectieBundelsLookup[collectie.id] = this.collectieBundelsLookup[collectie.id].filter(b => b.id !== bundel.id)
                    delete this.bundelLookup[bundel.id]
                    resolve('Bundel deleted')
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    resolveReferentiebeeld = (bundel) => {
        return bundel.defaultReferentiebeeld !== ''
            ? bundel.defaultReferentiebeeld
            : this.applicationPath.referentiebeelden() + bundel.uuid + '_thumb.jpg?d=' + new Date().getTime()
    }

    //==============================================================
    //==============================================================
    //                      OUTLINE
    //==============================================================
    //==============================================================


    getBundelOutline = (bundel) => {
        return new Promise((resolve, reject) => {

            const uuid = bundel.uuid

            const postObject = {} as any
            postObject.function = 'getBundelOutline'
            postObject.uuid = uuid

            let url = `${this.applicationPath.ada_api()}/getBundelOutline/${uuid}`

            axios.get(url)
                .then(response => {
                    this.bundelOutlineLookup[bundel.id] = response.data.map(item => ({file: item}))
                    if (response.data.error) {
                        console.warn(response.data.error)
                    }

                    this.processOutline(bundel)

                    resolve(this.bundelOutlineLookup[bundel.id])
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    //==============================================================
    //==============================================================
    //                      BUNDEL COLLECTIE
    //==============================================================
    //==============================================================

    getBundelCollecties = (bundel: any) => {
        return new Promise((resolve, reject) => {

            if (this.bundelCollectiesLookup[bundel.id]) {
                resolve(this.bundelCollectiesLookup[bundel.id])
                return
            }

            axios.get(`${this.applicationPath.crud_api()}${COLLECTIE_BUNDEL_TABLE}/?transform=1&filter[]=bundel,eq,${bundel.id}`, this.authenticationInstance.axiosOptions)
                .then(response => {
                    this.bundelCollectiesLookup[bundel.id] = response.data[COLLECTIE_BUNDEL_TABLE]
                    resolve(this.bundelCollectiesLookup[bundel.id])
                    /* const collectieIds = response.data[COLLECTIE_BUNDEL_TABLE].map(cb => cb.collectie).join(',')

                     axios.get(`${this.applicationPath.crud_api()}${BUNDEL_TABLE}/${collectieIds}`, this.authenticationInstance.axiosOptions)
                         .then(bundelResponse => {

                             const bundels = bundelResponse.data
                             this.bundelCollectiesLookup[bundel.id] = bundels
                             resolve(bundels)
                         })*/

                })
                .catch(error => {
                    console.error('GET COLLECTIEBUNDELS ERROR', error)
                    reject(error)
                })


        })

    }

    //==============================================================
    //==============================================================
    //                      COLLECTIE BUNDEL
    //==============================================================
    //==============================================================

    getCollectieBundels = (collectie: any, clearCache: boolean = false) => {
        return new Promise((resolve, reject) => {


            if (clearCache) delete this.collectieBundelsLookup[collectie.id]

            //caching: de bundels die al in de cache zitten moeten uit de cache gehaald worden!

            if (this.collectieBundelsLookup[collectie.id]) {
                //bloody hell...
                resolve(this.collectieBundelsLookup[collectie.id].map(bundel => this.bundelLookup[bundel.id]))
                return
            }

            const url = `${this.applicationPath.crud_api()}${COLLECTIE_BUNDEL_TABLE}/?transform=1&filter[]=collectie,eq,${collectie.id}`

            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {
                    const bundelIds = response.data[COLLECTIE_BUNDEL_TABLE].map(cb => cb.bundel).join(',')

                    if (bundelIds === '') {
                        this.collectieBundelsLookup[collectie.id] = []
                        resolve([])
                        return
                    }

                    axios.get(`${this.applicationPath.crud_api()}${BUNDEL_TABLE}/${bundelIds}/?transform=1`, this.authenticationInstance.axiosOptions)
                        .then(bundelResponse => {

                            let bundels = bundelResponse.data
                            if (!Array.isArray(bundels)) bundels = [bundels]

                            //cache: bundels die al in de cache zitten:
                            bundels = bundels.map(bundel => this.bundelLookup[bundel.id] ? this.bundelLookup[bundel.id] : bundel)

                            //cache: bundels in cache steken
                            for (const bundel of bundels) {
                                if (!this.bundelLookup[bundel.id]) this.bundelLookup[bundel.id] = bundel
                            }

                            this.collectieBundelsLookup[collectie.id] = bundels
                            resolve(bundels)
                        })

                })
                .catch(error => {
                    console.error('GET COLLECTIEBUNDELS ERROR', error)
                    reject(error)
                })


        })

    }

    createCollectieBundel = (collectieBundel, bundel) => {

        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api()}${COLLECTIE_BUNDEL_TABLE}/?transform=1`

            axios.post(url, collectieBundel)
                .then((result) => {
                    collectieBundel.id = result.data

                    if (!this.collectieBundelsLookup[collectieBundel.collectie]) this.collectieBundelsLookup[collectieBundel.collectie] = []
                    //die is eigenlijk niet helemaal correct. In het proces van bundels aanmaken via csv bvb. zijn dit preview bundels!
                    this.collectieBundelsLookup[collectieBundel.collectie].push(bundel)
                    resolve(collectieBundel)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    deleteCollectieBundels = (bundel) => {

        return new Promise((resolve, reject) => {

            const ids = this.bundelCollectiesLookup[bundel.id].map(cb => cb.id).join(',')
            let url = `${this.applicationPath.crud_api()}${COLLECTIE_BUNDEL_TABLE}/${ids}}?transform=1`

            axios.delete(url)
                .then((result) => {
                    delete this.bundelCollectiesLookup[bundel.id]
                    resolve('Collectie bundels deleted')
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    //==============================================================
    //==============================================================
    //                      SETTINGS
    //==============================================================
    //==============================================================

    getBundelSettings = (bundel) => {
        if (!bundel.settings) bundel.settings = '{}'

        if (!this.bundelSettingsLookup[bundel.id]) this.bundelSettingsLookup[bundel.id] = JSON.parse(bundel.settings)

        return this.bundelSettingsLookup[bundel.id]
    }

    getBundelSetting = (bundel, setting) => {
        if (!bundel.settings) bundel.settings = '{}'

        if (!this.bundelSettingsLookup[bundel.id]) this.bundelSettingsLookup[bundel.id] = JSON.parse(bundel.settings)

        return this.bundelSettingsLookup[bundel.id][setting]
    }

    setBundelSetting = (bundel, setting, value) => {
        if (!this.bundelSettingsLookup[bundel.id]) this.bundelSettingsLookup[bundel.id] = {}
        this.bundelSettingsLookup[bundel.id][setting] = value
        bundel.settings = JSON.stringify(this.bundelSettingsLookup[bundel.id])
        return this.bundelSettingsLookup[bundel.id]
    }

    //==============================================================
    //==============================================================
    //                      PRIVATE
    //==============================================================
    //==============================================================

    private processOutline = (bundel) => {

        const items = this.bundelOutlineLookup[bundel.id]

        this.bundelPathLookup[bundel.id] = _.keyBy(items, (item) => {
            const dataIndex = item.file.path.indexOf('/data/')
            item.file.path = item.file.path.substr(dataIndex + 5)
            return item.file.path
        })

        for (const item of items) {
            item.icon = this.getFileIcon(item)
            item.type = getMimetypeString(item.file.mimetype)
            item.canBeReferentiebestand = this.canBeReferentiebestand(item)
            item.canBeReferentiebeeld = this.canBeReferentiebeeld(item)

            //get parent
            const pieces = item.file.path.split('/')
            pieces.pop()
            const parent = pieces.join('/')
            item.parentString = parent
            item.parent = this.bundelPathLookup[bundel.id][parent]

            if (item.parent) {
                if (!item.parent.children) item.parent.children = []
                item.parent.children.push(item)
            }

        }

        this.setIsAndHas(bundel.uuid)
    }

    private canBeReferentiebestand = (item) => {

        //const mime = MIMES[item.file.mimetype]

        switch (item.file.mimetype) {
            case Mimetype.IMAGE_PNG:
            case Mimetype.IMAGE_JPEG:
            case Mimetype.VIDEO_MP4:
            case Mimetype.AUDIO_MPEG:
            case Mimetype.VIDEO_QUICKTIME:
                return true
            case Mimetype.APPLICATION_PDF:
                if (item.file.path.includes('.ai')) return false
                return true
            default:
                return false
        }
    }

    private canBeReferentiebeeld = (item) => {
        return [Mimetype.IMAGE_JPEG, Mimetype.IMAGE_PNG].indexOf(item.file.mimetype) > -1
    }

    private getFileIcon = (item) => {

        switch (item.file.mimetype) {
            case Mimetype.DIRECTORY:
                return MimetypeIcon_Directory
            case Mimetype.APPLICATION_ZIP:
                return MimetypeIcon_Zip
            case Mimetype.TEXT_PLAIN:
                return MimetypeIcon_TextPlain
            case Mimetype.TEXT_RTF:
                return MimetypeIcon_TextRtf
            case Mimetype.TEXT_CSV:
                return MimetypeIcon_TextCsv
            case Mimetype.POWERPOINT:
                return MimetypeIcon_Powerpoint
            case Mimetype.WORD:
                return MimetypeIcon_Word
            case Mimetype.NUMBERS:
                return MimetypeIcon_Numbers
            case Mimetype.EXCEL_X:
            case Mimetype.EXCEL:
                return MimetypeIcon_Excel
            case Mimetype.POSTSCRIPT:
                if (item.file.path.includes('.ai')) return MimetypeIcon_Illustrator
                return null
            case Mimetype.IMAGE_PHOTOSHOP:
            case Mimetype.APPLICATION_PHOTOSHOP:
                return MimetypeIcon_Photoshop
            case Mimetype.APPLICATION_PDF:
                if (item.file.path.includes('.ai')) return MimetypeIcon_Illustrator
                return null
            case Mimetype.URL_ENCODED:
                if (item.file.path.includes('.numbers')) return MimetypeIcon_Numbers
                return null

            //deze negeren want ze hebben een eigen player
            case Mimetype.AUDIO_MPEG:
            case Mimetype.IMAGE_JPEG:
            case Mimetype.IMAGE_PNG:
            case Mimetype.IMAGE_SVG:
            case Mimetype.VIDEO_MP4:
            case Mimetype.VIDEO_QUICKTIME:
            case Mimetype.VIDEO_OGG:
            case Mimetype.APPLICATION_OCTET_STREAM:
                return null
            //notify these ones
            default:
                console.error('GEEN ICOON VOOR', item.file.mimetype, item)
                return MimetypeIcon_File
        }
    }

    //==============================================================
    //==============================================================
    //                      FILES
    //==============================================================
    //==============================================================

    removeFileFromBundel = (bundel, item) => {
        return new Promise((resolve, reject) => {

            const postObject = {item: item.file.path}

            console.group()
            const url = `${this.applicationPath.ada_api()}/removeFileFromBundel/${bundel.uuid}/`
            axios.post(url, postObject)
                .then(response => {
                    resolve(response)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    createBundelDirectory = (bundel, root, name) => {
        return new Promise((resolve, reject) => {

            const postObject = {root, name}

            console.group()
            const url = `${this.applicationPath.ada_api()}/createBundelDirectory/${bundel.uuid}/`
            console.info('CALL createBundelDirectory', url, postObject)
            axios.post(url, postObject)
                .then(response => {
                    console.log('SERVER SESPONSE', response)
                    resolve(response)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    /*computeChecksumMd5(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const chunkSize = 2097152; // Read in chunks of 2MB
            const spark = new SparkMD5.ArrayBuffer();
            const fileReader = new FileReader();

            let cursor = 0; // current cursor in file

            fileReader.onerror = function (): void {
                reject('MD5 computation failed - error reading the file');
            };

            // read chunk starting at `cursor` into memory
            function processChunk(chunk_start: number): void {
                const chunk_end = Math.min(file.size, chunk_start + chunkSize);
                fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
            }

            // when it's available in memory, process it
            // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
            // of `any` for `e` variable, otherwise stick with `any`
            // See https://github.com/Microsoft/TypeScript/issues/25510
            fileReader.onload = function (e: any): void {
                spark.append(e.target.result); // Accumulate chunk to md5 computation
                cursor += chunkSize; // Move past this chunk

                if (cursor < file.size) {
                    // Enqueue next chunk to be accumulated
                    processChunk(cursor);
                } else {
                    // Computation ended, last chunk has been processed. Return as Promise value.
                    // This returns the base64 encoded md5 hash, which is what
                    // Rails ActiveStorage or cloud services expect

                    resolve(btoa(spark.end(true)));

                    // If you prefer the hexdigest form (looking like
                    // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
                    // resolve(spark.end());
                }
            };

            processChunk(0);
        });
    }*/

    setIsAndHas = (bundelUuid) => {
        const bundel = this.bundelLookup[bundelUuid]
        if (!bundel) return
        if (!this.bundelOutlineLookup[bundel.id]) return

        const referentieBestanden = this.bundelReferentiebestantdenLookup[bundel.id] || []
        const teKoopItems = this.bundelTeKoopItemsLookup[bundel.id] || []
        this.setIsAndHasReferentiebestand(bundel, referentieBestanden)
        this.setIsAndHasTeKoopItem(bundel, teKoopItems)

    }

    //==============================================================
    //==============================================================
    //                      REFERENTIEBESTAND
    //==============================================================
    //==============================================================

    getBundelReferentiebestanden = (bundel: any) => {
        return new Promise((resolve, reject) => {

            if (this.bundelReferentiebestantdenLookup[bundel.id]) {
                resolve(this.bundelReferentiebestantdenLookup[bundel.id])
                return
            }

            //https://www.arteveldehogeschool.be/digitaalarchief-dev/ada-server/ada-api/getBundelReferentieBestanden/4cc6ba5a-b30d-489e-aadb-cff9e1f7deb5

            //const url = `${this.applicationPath.crud_api()}${BUNDEL_REFERENTIEBESTANDEN_TABLE}/?transform=1&filter[]=bundel,eq,${bundel.id}`
            const url = `${this.applicationPath.ada_api()}/getBundelReferentiebestanden/${bundel.uuid}`
            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {
            //console.log('GBRB', url, response)
                    this.bundelReferentiebestantdenLookup[bundel.id] = response.data //[BUNDEL_REFERENTIEBESTANDEN_TABLE]
                    this.setIsAndHas(bundel.uuid)

                    resolve(this.bundelReferentiebestantdenLookup[bundel.id])
                })
                .catch(error => {
                    console.error('GET COLLECTIEBUNDELS ERROR', error)
                    reject(error)
                })


        })

    }

    deleteBundelReferentiebestanden = (bundel) => {

        return new Promise((resolve, reject) => {

            const ids = this.bundelReferentiebestantdenLookup[bundel.id].map(rb => rb.id).join(',')
            let url = `${this.applicationPath.crud_api()}${BUNDEL_REFERENTIEBESTANDEN_TABLE}/${ids}}?transform=1`

            axios.delete(url)
                .then((result) => {
                    delete this.bundelReferentiebestantdenLookup[bundel.id]
                    this.setIsAndHas(bundel.uuid)
                    resolve('Bundel referentiebestanden deleted')
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    toggleReferentiebestand = (bundel, item, checked, referentiebestand) => {
        return new Promise((resolve, reject) => {

            if (checked && !referentiebestand) {
                //CREATE
                const referentiebestand = new Referentiebestand()
                referentiebestand.bundel = bundel.id
                referentiebestand.referentiebestand = item.file.path
                referentiebestand.mimetype = item.file.mimetype

                axios.post(`${this.applicationPath.crud_api()}${BUNDEL_REFERENTIEBESTANDEN_TABLE}/`, referentiebestand)
                    .then(response => {
                        if (!this.bundelReferentiebestantdenLookup[bundel.id]) this.bundelReferentiebestantdenLookup[bundel.id] = []
                        referentiebestand.id = response.data
                        this.bundelReferentiebestantdenLookup[bundel.id] = [...this.bundelReferentiebestantdenLookup[bundel.id], referentiebestand]
                        this.setIsAndHas(bundel.uuid)

                        resolve(this.bundelReferentiebestantdenLookup[bundel.id])
                    })
                    .catch(error => {
                        console.error('GET COLLECTIEBUNDELS ERROR', error)
                        reject(error)
                    })

            } else if (!checked && referentiebestand) {
                axios.delete(`${this.applicationPath.crud_api()}${BUNDEL_REFERENTIEBESTANDEN_TABLE}/${referentiebestand.id}`)
                    .then(response => {
                        this.bundelReferentiebestantdenLookup[bundel.id] = this.bundelReferentiebestantdenLookup[bundel.id].filter(rb => rb.id !== referentiebestand.id)
                        this.setIsAndHas(bundel.uuid)

                        resolve(this.bundelReferentiebestantdenLookup[bundel.id])
                    })
                    .catch(error => {
                        console.error('GET COLLECTIEBUNDELS ERROR', error)
                        reject(error)
                    })
            }

        })

    }

    setIsAndHasReferentiebestand = (bundel, referentiebestanden) => {


        for (const item of this.bundelOutlineLookup[bundel.id]) {
            item.isReferentiebestand = false
            item.hasReferentiebestand = false
        }
        for (const item of this.bundelOutlineLookup[bundel.id]) {
            for (const referentiebestand of referentiebestanden) {

                if (item.file.path === referentiebestand.referentiebestand) {
                    item.isReferentiebestand = true
                    let parent = item.parent
                    while (parent) {
                        parent.hasReferentiebestand = true
                        parent = parent.parent
                    }
                }
            }
        }

    }

    updateReferentiebestand = (referentiebestand: any) => {
        return new Promise((resolve, reject) => {

            const bundel = this.bundelLookup[referentiebestand.bundel]

            axios.put(`${this.applicationPath.crud_api()}${BUNDEL_REFERENTIEBESTANDEN_TABLE}/${referentiebestand.id}?transform=1`, referentiebestand)
                .then(response => {
                    this.bundelReferentiebestantdenLookup[referentiebestand.bundel] = [...this.bundelReferentiebestantdenLookup[referentiebestand.bundel]]
                    this.setIsAndHas(referentiebestand.bundel)

                    resolve(response)
                })
                .catch(error => {
                    console.error('UPDATE REFERENTIEBESTAND ERROR', error)
                    reject(error)
                })


        })

    }

    //==============================================================
    //==============================================================
    //                      TE KOOP
    //==============================================================
    //==============================================================


    getBundelTeKoopItems = (bundel: any) => {
        return new Promise((resolve, reject) => {

            if (this.bundelTeKoopItemsLookup[bundel.id]) {
                resolve(this.bundelTeKoopItemsLookup[bundel.id])
                return
            }

            axios.get(`${this.applicationPath.crud_api()}${BUNDEL_TEKOOPITEMS_TABLE}/?transform=1&filter[]=bundel,eq,${bundel.id}`, this.authenticationInstance.axiosOptions)
                .then(response => {
                    this.bundelTeKoopItemsLookup[bundel.id] = response.data[BUNDEL_TEKOOPITEMS_TABLE]
                    this.setIsAndHas(bundel.uuid)
                    resolve(this.bundelTeKoopItemsLookup[bundel.id])
                })
                .catch(error => {
                    console.error('GET BUNDEL_TEKOOPITEMS ERROR', error)
                    reject(error)
                })


        })

    }

    deleteBundelTeKoopItems = (bundel) => {

        return new Promise((resolve, reject) => {

            if (this.bundelTeKoopItemsLookup[bundel.id] === undefined) {
                resolve('Geen bundel teKoopItems')
            }
            
            const ids = this.bundelTeKoopItemsLookup[bundel.id].map(rb => rb.id).join(',')
            let url = `${this.applicationPath.crud_api()}${BUNDEL_TEKOOPITEMS_TABLE}/${ids}}?transform=1`

            axios.delete(url)
                .then((result) => {
                    delete this.bundelTeKoopItemsLookup[bundel.id]
                    this.setIsAndHas(bundel.uuid)
                    resolve('Bundel teKoopItems deleted')
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    toggleTeKoopItem = (bundel, item, checked, teKoopItem) => {
        return new Promise((resolve, reject) => {

            if (checked && !teKoopItem) {
                //CREATE
                const teKoopItem = new TeKoopItem()
                teKoopItem.bundel = bundel.id
                teKoopItem.bestand = item.file.path
                teKoopItem.mimetype = item.file.mimetype

                axios.post(`${this.applicationPath.crud_api()}${BUNDEL_TEKOOPITEMS_TABLE}/`, teKoopItem)
                    .then(response => {
                        if (!this.bundelTeKoopItemsLookup[bundel.id]) this.bundelTeKoopItemsLookup[bundel.id] = []
                        teKoopItem.id = response.data
                        this.bundelTeKoopItemsLookup[bundel.id] = [...this.bundelTeKoopItemsLookup[bundel.id], teKoopItem]
                        this.setIsAndHas(bundel.uuid)
                        resolve(this.bundelTeKoopItemsLookup[bundel.id])
                    })
                    .catch(error => {
                        console.error('GET TEKOOPITEMS ERROR', error)
                        reject(error)
                    })

            } else if (!checked && teKoopItem) {
                axios.delete(`${this.applicationPath.crud_api()}${BUNDEL_TEKOOPITEMS_TABLE}/${teKoopItem.id}`)
                    .then(response => {
                        this.bundelTeKoopItemsLookup[bundel.id] = this.bundelTeKoopItemsLookup[bundel.id].filter(rb => rb.id !== teKoopItem.id)
                        this.setIsAndHas(bundel.uuid)
                        resolve(this.bundelTeKoopItemsLookup[bundel.id])
                    })
                    .catch(error => {
                        console.error('GET TEKOOPITEMS ERROR', error)
                        reject(error)
                    })
            }

        })

    }

    setIsAndHasTeKoopItem = (bundel, teKoopItems) => {


        for (const item of this.bundelOutlineLookup[bundel.id]) {
            item.isTeKoopItem = false
            item.hasTeKoopItem = false
        }
        for (const item of this.bundelOutlineLookup[bundel.id]) {
            for (const teKoopItem of teKoopItems) {

                if (item.file.path === teKoopItem.bestand) {
                    item.isTeKoopItem = true
                    let parent = item.parent
                    while (parent) {
                        parent.hasTeKoopItem = true
                        parent = parent.parent
                    }
                }
            }
        }

    }

    updateTeKoopItem = (teKoopItem: any) => {
        return new Promise((resolve, reject) => {

            axios.put(`${this.applicationPath.crud_api()}${BUNDEL_TEKOOPITEMS_TABLE}/${teKoopItem.id}?transform=1`, teKoopItem)
                .then(response => {
                    this.bundelTeKoopItemsLookup[teKoopItem.bundel] = [...this.bundelTeKoopItemsLookup[teKoopItem.bundel]]
                    this.setIsAndHas(teKoopItem.bundel)
                    resolve(response)
                })
                .catch(error => {
                    console.error('UPDATE TE KOOP ITEM ERROR', error)
                    reject(error)
                })


        })

    }

    //==============================================================
    //==============================================================
    //                      CARROUSSEL
    //==============================================================
    //==============================================================

    getCarrouselContent = (context) => {
        return new Promise((resolve, reject) => {

            let url

            switch (context) {
                case CarrouselContext.RECENT:
                    url = `${this.applicationPath.crud_api()}${BUNDEL_TABLE}/?transform=1&order=aangemaakt,desc&size=10`
                    break
                default:
                    resolve([])
                    return
            }

            url += `&filter[]=zichtbaarInCarrousel,eq,1`

            axios.get(url)
                .then((result) => {
                    const bundels = result.data[BUNDEL_TABLE].map(bundel => {
                        if (!this.bundelLookup[bundel.id]) this.bundelLookup[bundel.id] = bundel
                        return this.bundelLookup[bundel.id]
                    })
                    resolve(bundels)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }
}
