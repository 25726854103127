import React, {useState, useEffect, useContext} from 'react'
import ServiceContext from '../../context/ServiceContext'
import {InfoViewer, CollectieSettings} from "@ahsmediatheek/ada-lib";
import {Checkbox, Form, Header, Input, Divider} from "semantic-ui-react";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const CollectieSettingsTrefwoorden = (props) => {

    const [VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN, SET_VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN] = useState(false)
    const [MINIMUUM_AANTAL_TREFWOORDEN, SET_MINIMUUM_AANTAL_TREFWOORDEN] = useState(1)

    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        SET_VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN) === 1)
        SET_MINIMUUM_AANTAL_TREFWOORDEN(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.MINIMUUM_AANTAL_TREFWOORDEN))

    }, [serviceContext.collectieSettings, serviceContext.collectie, serviceContext.collectieService])

    const onMinimuumAantalTrefwoordenChange = (event, {value}) => {
        const newSettings = serviceContext.collectieService.setCollectieSetting(serviceContext.collectie, CollectieSettings.MINIMUUM_AANTAL_TREFWOORDEN, parseInt(value))
        serviceContext.setCollectieSettings({...newSettings})
        saveSettings()
    }

    const onCheckboxChange = (event, {name, checked}) => {
        const newSettings = serviceContext.collectieService.setCollectieSetting(serviceContext.collectie, name, checked ? 1 : 0)
        serviceContext.setCollectieSettings({...newSettings})
        saveSettings()
    }

    const saveSettings = () => {
        //SAVE
        serviceContext.collectieService.updateCollectie(serviceContext.collectie).then(res => {
            //console.log('COLLECTIE UPDATED', serviceContext.collectie)
        })
    }

    return (
        <Form>
            <ComponentIdentifier displayName='CollectieSettingsTrefwoorden'/>

            <Header dividing size='huge'>{serviceContext.localeManager.getString('TREFWOORDEN')}</Header>

            <Divider />

            <Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN)}</label>

                <InfoViewer refId='INFO_VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN'
                            editable={serviceContext.infoEditable}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN)}
                          name={CollectieSettings.VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN}
                          checked={VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN}
                          onChange={onCheckboxChange}/>
            </Form.Field>

            {VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN && <Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.MINIMUUM_AANTAL_TREFWOORDEN)}</label>

                <Input style={{width: '150px'}} type='number' min={1} max={5} value={MINIMUUM_AANTAL_TREFWOORDEN} onChange={onMinimuumAantalTrefwoordenChange}/>
            </Form.Field>}


        </Form>
    )
}

export default CollectieSettingsTrefwoorden
