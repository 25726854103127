import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Grid, Menu, Label} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'
import {AdaUserLink, AdaUserLinkRef, AdminIcon, DangerIcon, EmbargoIcon, ToegangsticketStatus, ToegangsticketTargetType, CollectieStatus} from '@ahsmediatheek/ada-lib'
import CollectieSettingsGegevens from "./settingsCollectie/CollectieSettingsGegevens";
import CollectieSettingsInstellingen from "./settingsCollectie/CollectieSettingsInstellingen";
import CollectieSettingsGebruikers from "./settingsCollectie/CollectieSettingsGebruikers";
import CollectieSettingsTrefwoorden from "./settingsCollectie/CollectieSettingsTrefwoorden";
import AdaUserSearch from "../component/AdaUserSearch";
import CollectieSettingsEmbargo from "./settingsCollectie/CollectieSettingsEmbargo";
import CollectieSettingsAdmin from "./settingsCollectie/CollectieSettingsAdmin";
import CollectieSettingsReferentiebeeld from "./settingsCollectie/CollectieSettingsReferentiebeeld";
import CollectieActies from "../component/CollectieActies";
import CollectieSettingsToegangstickets from "./settingsCollectie/CollectieSettingsToegangstickets";
import {ComponentIdentifier} from "../component/ComponentIdentifier";
import CollectieSettingsNotificatie from "./settingsCollectie/CollectieSettingsNotificatie";

const VIEW_GEGEVENS = 'gegevens'
const VIEW_INSTELLINGEN = 'instellingen'
const VIEW_GEBRUIKERS = 'gebruikers'
//const VIEW_VALIDATIEMODULES = 'validatiemodules'
//const VIEW_BUNDELCONTROLE = 'bundelcontrole'
const VIEW_EMBARGO = 'embargo'
//const VIEW_OPEN_ACCESS = 'openAccess'
const VIEW_TOEGANGSTICKETS = 'toegangstickets'
const VIEW_TREFWOORDEN = 'trefwoorden'
const VIEW_ADMIN = 'admin'
const VIEW_REFERENTIEBEELD = 'referentiebeeld'
const VIEW_NOTIFICATIE = 'notificatie'

const MENU_GROUPS = [
    {label: 'algemeen', items: [VIEW_GEGEVENS, VIEW_TREFWOORDEN, VIEW_INSTELLINGEN, VIEW_REFERENTIEBEELD, VIEW_NOTIFICATIE]},
    {label: 'gebruikers', items: [VIEW_GEBRUIKERS]},
    /*{label: 'validatie', items: [VIEW_VALIDATIEMODULES, VIEW_BUNDELCONTROLE]},*/
    {label: 'toegang', items: [VIEW_EMBARGO, VIEW_TOEGANGSTICKETS/*, VIEW_OPEN_ACCESS, */]},
    {label: 'Admin', items: [VIEW_ADMIN]}
]

const CollectieDetailSettingsView = () => {

    const [view, setView] = useState(VIEW_GEGEVENS)
    const [isDirty, setIsDirty] = useState(false)
    const [collectieSettings, setCollectieSettings] = useState()
    const [adaUserSearchModalOpen, setAdaUserSearchModalOpen] = useState(false)
    const [rol, setRol] = useState('')
    const [adaUserSearchSelectedUsers, setAdaUserSearchSelectedUsers] = useState([])
    const [toegangstickets, setToegangstickets] = useState([])
    const [toegangsticketGeblokkeerd, setToegangsticketGeblokkeerd] = useState(false)


    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        const settings = serviceContext.collectieService.getCollectieSettings(serviceContext.collectie)
        setCollectieSettings(settings)
    }, [serviceContext.collectie, serviceContext.collectieService])

    useEffect(() => {
        setToegangsticketGeblokkeerd(toegangstickets.reduce((geblokkeerd, tt) => {
            if(tt.status === ToegangsticketStatus.GEBLOKKEERD) return true
            return geblokkeerd
        }, false))
    }, [toegangstickets])

    useEffect(() => {
        serviceContext.toegangsticketService.getToegangstickets(serviceContext.collectie.uuid).then(res => {
            setToegangstickets(res)
        })
    }, [serviceContext.collectie])

    const handleMenuClick = (event, {name}) => {
        if (isDirty) {
            const ok = window.confirm(serviceContext.localeManager.getString('GEGEVENS_NOG_NIET_BEWAARD'))
            if (ok) {
                setView(name)
                setIsDirty(false)
            }
        } else setView(name)

    }

    const onAddAdaUsers = (rol) => {
        setAdaUserSearchSelectedUsers(serviceContext.collectieAdaUserLinks
            .filter(adaUserLink => adaUserLink.rol === rol)
            .map(adaUserLink => serviceContext.collectieService.adaUserService.adaUserLookup[adaUserLink.adaUser]))
        setRol(rol)
        setAdaUserSearchModalOpen(true)
    }

    const onAdaUsersSelect = (adaUsers) => {

        const promises = []
        for (const adaUser of adaUsers) {

            const adaUserLink = new AdaUserLink()
            adaUserLink.rol = rol
            adaUserLink.ref = AdaUserLinkRef.COLLECTIE
            adaUserLink.refId = serviceContext.collectie.id
            adaUserLink.adaUser = adaUser.id

            promises.push(serviceContext.adaUserService.createAdaUserLink(adaUserLink))
        }

        Promise.all(promises).then(res => {
            setAdaUserSearchModalOpen(false)
            serviceContext.updateAdaUserLinks()
        })

    }

    const getMenuIcon = (item) => {
        switch (item) {
            case VIEW_EMBARGO:
                return serviceContext.collectie.embargo && serviceContext.collectie.embargo !== '' && <EmbargoIcon/>
            case VIEW_TOEGANGSTICKETS:
                return toegangstickets && toegangstickets.length > 0 && <Label circular size='tiny'>{toegangstickets.length}{toegangsticketGeblokkeerd && <Fragment>&nbsp;&nbsp;<DangerIcon/></Fragment>}</Label>
            default:
                return null
        }
    }

    const deleteTicket = (tt) => {
        serviceContext.toegangsticketService.deleteToegangsticket(tt).then(res => {
            setToegangstickets(toegangstickets.filter(toeti => toeti.id !== tt.id))
        })
    }

    const maakTicket = (tt) => {
        tt.consumer_type = ToegangsticketTargetType.COLLECTIE
        tt.consumer_uuid = serviceContext.collectie.uuid

        serviceContext.toegangsticketService.createToegangsticket(tt).then(res => {
            setToegangstickets([res, ...toegangstickets])
        })
    }

    const heropenTicket = (ticket) => {
        ticket.status = ToegangsticketStatus.OPEN

        serviceContext.toegangsticketService.updateToegangsticket(ticket).then(res => {
            setToegangstickets([...toegangstickets])
        })
    }

    return (
        <Fragment>
            <ComponentIdentifier displayName='CollectieDetailSettingsView'/>

            <CollectieActies collectie={serviceContext.collectie}/>

                <Grid columns={2}>
                    <Grid.Column width={4}>

                        <div>
                            <Menu fluid vertical pointing>
                                {MENU_GROUPS.map((group, index) => {

                                    if (group.access && group.access === 'admin' && !serviceContext.privilegeManager.isAdmin()) return null

                                    return (
                                        <Fragment key={index}>
                                            <Menu.Item>
                                                <Menu.Header style={{padding: '5px', backgroundColor: '#eff6fa'}}>
                                                    {serviceContext.localeManager.getString(group.label)}
                                                </Menu.Header>
                                                <Menu.Menu>
                                                    {group.items.map((item, index) => {
                                                        return <Menu.Item key={index} name={item} active={view === item} onClick={handleMenuClick}>

                                                            {serviceContext.localeManager.getString(item)}
                                                            {getMenuIcon(item)}

                                                        </Menu.Item>
                                                    })}
                                                </Menu.Menu>
                                            </Menu.Item>
                                        </Fragment>
                                    )
                                })}
                            </Menu>
                        </div>
                    </Grid.Column>

                    <ServiceContext.Provider value={{...serviceContext, setIsDirty, collectieSettings, setCollectieSettings}}>
                        <Grid.Column style={{backgroundColor: isDirty ? 'linen' : ''}} width={12}>

                            {view === VIEW_GEGEVENS && <CollectieSettingsGegevens/>}
                            {view === VIEW_TREFWOORDEN && <CollectieSettingsTrefwoorden/>}
                            {view === VIEW_INSTELLINGEN && <CollectieSettingsInstellingen/>}
                            {view === VIEW_REFERENTIEBEELD && <CollectieSettingsReferentiebeeld/>}
                            {view === VIEW_NOTIFICATIE && <CollectieSettingsNotificatie/>}

                            {view === VIEW_GEBRUIKERS && <CollectieSettingsGebruikers onAddAdaUsers={onAddAdaUsers}/>}
                            {view === VIEW_EMBARGO && <CollectieSettingsEmbargo/>}
                            {view === VIEW_TOEGANGSTICKETS && <CollectieSettingsToegangstickets
                                toegangstickets={toegangstickets}
                                deleteTicket={deleteTicket}
                                heropenTicket={heropenTicket}
                                maakTicket={maakTicket}/>}

                            {view === VIEW_ADMIN && <CollectieSettingsAdmin/>}

                        </Grid.Column>
                    </ServiceContext.Provider>
                </Grid>

            {adaUserSearchModalOpen &&
            <AdaUserSearch modalOpen={adaUserSearchModalOpen} rol={rol}
                           adaUsers={adaUserSearchSelectedUsers}
                           onUsersSelect={onAdaUsersSelect}
                           academiejaar={serviceContext.collectie.academiejaar}
                           opleiding={serviceContext.collectie.opleiding}/>}
        </Fragment>
    )
}

export default CollectieDetailSettingsView
