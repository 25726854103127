import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {formatDistance, formatRelative, format} from 'date-fns'
import {Button, Icon, Segment, label, Divider} from "semantic-ui-react";
import ServiceContext from "../../context/ServiceContext";
import {DateUtil, ToegangsticketStatus, ApplicationPath} from "@ahsmediatheek/ada-lib";
import ToegangsticketHistoriekRenderer from "./ToegangsticketHistoriekRenderer";
import {ComponentIdentifier} from "../ComponentIdentifier";

const ToegangsticketRenderer = ({toegangsticket, deleteTicket, showButtons, heropenTicket}) => {

    const [owner, setOwner] = useState()
    const [historiekVisible, setHistoriekVisible] = useState(false)
    const [historiek, setHistoriek] = useState()
    const [testUrl, setTestUrl] = useState()
    const [aangemaaktString, setAangemaaktString] = useState('')
    const serviceContext = useContext(ServiceContext)
    const [geldig_van_string, setGeldig_van_string] = useState('')
    const [geldig_tot_string, setGeldig_tot_string] = useState('')

    useEffect(() => {
        serviceContext.adaUserService.getAdaUser(toegangsticket.aangemaakt_door).then(user => {
            setOwner(user)
        })
    }, [toegangsticket])

    useEffect(() => {
        const url = serviceContext.applicationPath.toegangsticket_path() + `/${toegangsticket.uuid}`
        setTestUrl(url)
    }, [toegangsticket])

    useEffect(() => {
        if (historiekVisible) {
            serviceContext.toegangsticketService.getToegangsticketHistoriek(toegangsticket).then(res => setHistoriek(res))
        }
    }, [historiekVisible])

    useEffect(() => {
        setAangemaaktString(formatRelative(
            DateUtil.dateFromMysql(toegangsticket.datum_aanmaak),
            new Date(),
            {addSuffix: true, locale: serviceContext.localeManager.dateFnsLocaleLookup[serviceContext.localeManager.locale]}))

        setGeldig_tot_string(format(DateUtil.dateFromMysql(toegangsticket.geldig_tot), 'PPPPp', {locale: serviceContext.localeManager.dateFnsLocaleLookup[serviceContext.localeManager.locale]}))
        setGeldig_van_string(format(DateUtil.dateFromMysql(toegangsticket.geldig_van), 'PPPPp', {locale: serviceContext.localeManager.dateFnsLocaleLookup[serviceContext.localeManager.locale]}))

    }, [toegangsticket, serviceContext.localeManager.locale])

    const deleteTicketButtonClickHandler = () => {
        const ok = confirm(serviceContext.localeManager.getString('VERWIJDER_DIT_TICKET_CONFIRM'))
        if (ok) deleteTicket && deleteTicket(toegangsticket)
    }

    const onVerstuurMeldingNaarBegunstigde_clickHandler = () => {
        serviceContext.toegangsticketService.stuurToegangsTicketNaarBegunstigde(toegangsticket, serviceContext.localeManager.locale).then(res => {
            console.log('mail', res)
            alert(serviceContext.localeManager.getString('MAIL_VERSTUURD'))
        })
    }

    const toonHistoriekButton_clickHandler = () => {
        setHistoriekVisible(!historiekVisible)

    }

    if (!owner) return null

    return (
        <Segment style={{display: 'flex'}} secondary>
            <ComponentIdentifier displayName='ToegangsticketRenderer'/>

            <Icon name='ticket' size='huge' color='brown'/>
            <div style={{marginLeft: '20px', width: '100%'}}>
                {serviceContext.localeManager.getString('AANGEMAAKT_DOOR')} <strong>{owner.voornaam} {owner.familienaam}</strong> - <strong>{aangemaaktString}</strong>

                <p>{serviceContext.localeManager.getString('BEGUNSTIGDE')}: <strong>{toegangsticket.begunstigde}</strong></p>
                <p>{serviceContext.localeManager.getString('GELDIG_VAN')} <strong>{geldig_van_string}</strong> {serviceContext.localeManager.getString('TOT')} <strong>{geldig_tot_string}</strong></p>
                <p>Status: <strong>{serviceContext.localeManager.getString(toegangsticket.status)}</strong></p>

                {serviceContext.privilegeManager.isAdmin() && <p>Link: (enkel zichtbaar voor admin) <a target='_blank' href={testUrl}>{testUrl}</a></p>}

                {showButtons && <div style={{display: 'flex'}}>
                    {toegangsticket.status === ToegangsticketStatus.OPEN &&
                    <Button size='mini' color='blue' onClick={onVerstuurMeldingNaarBegunstigde_clickHandler}>{serviceContext.localeManager.getString('VERSTUUR_MELDING_NAAR_BEGUNSTIGDE')}</Button>}
                    {toegangsticket.status === ToegangsticketStatus.GEBLOKKEERD &&
                    <Button size='mini' color='blue' onClick={() => heropenTicket(toegangsticket)}>{serviceContext.localeManager.getString('TT_DEBLOKKEER')}</Button>}

                    <Button size='mini' color='red' onClick={deleteTicketButtonClickHandler}>{serviceContext.localeManager.getString('VERWIJDER_DIT_TICKET')}...</Button>


                    <Button style={{marginLeft: 'auto'}} size='mini' onClick={toonHistoriekButton_clickHandler}>{serviceContext.localeManager.getString('TT_TOON_HISTORIEK')}</Button>

                </div>}


                {historiekVisible && historiek && <Fragment>

                    <Divider/>

                    {historiek.map(historiekItem => {
                        return <ToegangsticketHistoriekRenderer key={historiekItem.id} historiekItem={historiekItem}/>
                    })}
                </Fragment>}
            </div>
        </Segment>
    )
}

export default ToegangsticketRenderer

ToegangsticketRenderer.propTypes = {
    toegangsticket: PropTypes.object.isRequired,
    deleteTicket: PropTypes.func,
    showButtons: PropTypes.bool,
    heropenTicket: PropTypes.func,
}

ToegangsticketRenderer.defaultProps = {
    showButtons: true
}
