import React, {useState, useEffect} from 'react'

const LSC = 33
const MSC = 122
const ENC_STR = '065073082069032097110100032065068065032099111110099101112116032109097100101032098121032068097110121032068104111110100116032100117114105110103032050048048051045050048050049046010067108105101110116032109097100101032098121032068097110121032068104111110100116044032066097099107101110100032109097100101032098121032076101110110101114116032072111108118111101116'

const CwCopyright = (props) => {

    const [displayString, setDisplayString] = useState('')
    const [displayArr, setDisplayArr] = useState()

    const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

    useEffect(() => {
        const codeArr = ENC_STR.split(/(.{3})/).filter(x => x.length === 3).map(chunk => {
            const charCode = parseInt(chunk)
            return {chunk, charCode, char: String.fromCharCode(charCode), match: false}
        });
        setDisplayArr(codeArr)
    }, [])

    useEffect(() => {
        if (!displayArr) return
        let match = true
        const displayString = displayArr.reduce((str, codeObj) => {
            if (codeObj.match || codeObj.charCode < LSC) {
                str += codeObj.char
                return str
            }

            match = false

            const r = random(LSC, MSC)
            let char = String.fromCharCode(r)
            if (char === codeObj.char) {
                codeObj.match = true
            }
            str += char
            return str
        }, '')

        if (!match) {
            setTimeout(() => {
                setDisplayString(displayString)
                setDisplayArr([...displayArr])
            }, 20)
        }

    }, [displayArr])


    return (
        <div style={{fontFamily: 'courier new'}}>{displayString}</div>
    )
}

export default CwCopyright

CwCopyright.propTypes = {}

CwCopyright.defaultProps = {}
