"use strict";
exports.__esModule = true;
exports.CarrouselContext = void 0;
var CarrouselContext = /** @class */ (function () {
    function CarrouselContext() {
    }
    CarrouselContext.STAFF_PICK = 'staffPick';
    CarrouselContext.RECENT = 'recent';
    return CarrouselContext;
}());
exports.CarrouselContext = CarrouselContext;
