import React, {useState, useEffect, useContext, Fragment} from 'react'
import {useParams, useHistory} from "react-router-dom"
import {Divider, Label, Icon} from "semantic-ui-react";
import {
    CompilatieIcon,
    SettingsIcon,
    PreviousIcon,
    AdaUserLinkRef,
    InfoViewer,
    MetadataUtil
} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../context/ServiceContext'
import CompilatieDetailMainView from "./CompilatieDetailMainView";
import CompilatieDetailSettingsView from "./CompilatieDetailSettingsView";
import ProviderStatus from "../component/ProviderStatus";
import {ComponentIdentifier} from "../component/ComponentIdentifier";

//const IDLE = 'idle'
//const COMPILATIE_LOADING = 'compilatieLoading'
const COMPILATIE_DETAIL_MAIN_VIEW = 'compilatieDetailMainView'
const COMPILATIE_DETAIL_SETTINGS_VIEW = 'compilatieDetailSettingView'

const CompilatieView = () => {
    const [compilatieAdaUserLinks, setCompilatieAdaUserLinks] = useState()
    const [view, setView] = useState(COMPILATIE_DETAIL_MAIN_VIEW)
    const [localeContent, setLocaleContent] = useState()
    const [compilatie, setCompilatie] = useState()
    const [uuid, setUuid] = useState()

    const [referentieBeeld, setReferentieBeeld] = useState()
    const [compilatieTitel, setCompilatieTitel] = useState('')

    const [toegangsticketEigenaar, setToegangsticketEigenaar] = useState()
    const serviceContext = useContext(ServiceContext)

    const params = useParams()
    const history = useHistory()

    //COMPILATIE
    useEffect(() => {
        if (!params.uuid) return
        if (uuid === params.uuid) return

        //uuid kan zowel een compilatie uuid zijn of een toegangsticket uuid

        serviceContext.compilatieService.getCompilatie(params.uuid).then(res => {
            if (!res) {
                history.push('/home')
                return
            }
            if (res !== compilatie) setCompilatie(res)
            setUuid(res.uuid)
        })
    }, [params.uuid, compilatie, history, serviceContext.compilatieService, uuid])

    //TICKET
    useEffect(() => {
        if (!serviceContext.toegangsticket) return
        serviceContext.adaUserService.getAdaUser(serviceContext.toegangsticket.aangemaakt_door).then(au => {
            console.log('TT EIG', au)
            setToegangsticketEigenaar(au)
        })
    }, [serviceContext.toegangsticket])

    useEffect(() => {
        if (!compilatie) return

        const resolveReferentiebeeld = () => {
            return compilatie.defaultReferentiebeeld !== ''
                ? compilatie.defaultReferentiebeeld
                : serviceContext.applicationPath.referentiebeelden() + compilatie.uuid + '.jpg?d=' + new Date().getTime()
        }

        setReferentieBeeld(resolveReferentiebeeld())

        //async
        Promise.all([
            serviceContext.adaUserService.getAdaUserLinks(AdaUserLinkRef.COMPILATIE, compilatie.id),
            serviceContext.compilatieLocaleDataService.getLocaleData(compilatie.id),
            serviceContext.arteveldeApiService.getOpleidingen(compilatie.academiejaar),
        ])
            .then(([compilatieAdaUserLinks, localeContentResponse, opleidingen]) => {
                if (compilatie.newlyCreated) {
                    compilatie.newlyCreated = false
                    setView(COMPILATIE_DETAIL_SETTINGS_VIEW)
                }

                setCompilatieAdaUserLinks(compilatieAdaUserLinks)
                setLocaleContent(localeContentResponse.localeContent)
            }).catch(error => console.log('PA ERROR', error))


    }, [compilatie, serviceContext.adaUserService, serviceContext.arteveldeApiService, serviceContext.compilatieLocaleDataService, serviceContext.localeManager.locale])

    //OPLEIDING
    useEffect(() => {
        if (!compilatie) return

        setCompilatieTitel(MetadataUtil.resolveTitel(compilatie, serviceContext.localeManager.locale))
    }, [compilatie, serviceContext.localeManager.locale, serviceContext.arteveldeApiService])

    const onSettingsIconClick = () => {
        setView(COMPILATIE_DETAIL_SETTINGS_VIEW)
    }

    const onDeleteAdaUserLink = (adaUserLink) => {

        serviceContext.adaUserService.deleteAdaUserLink(adaUserLink).then(res => {
            setCompilatieAdaUserLinks(res)
        })
    }

    const updateAdaUserLinks = () => {
        serviceContext.adaUserService.getAdaUserLinks(AdaUserLinkRef.COMPILATIE, compilatie.id).then(res => {
            setCompilatieAdaUserLinks([...res])
        })
    }

    const onSaveCompilatie = (compilatie, changedLocales) => {
        if (changedLocales && changedLocales.length > 0) {
            for (let localeContentObject of changedLocales) {
                if (localeContentObject.field === 'titel' && localeContentObject.locale === 'nl') compilatie.titel_nl = localeContentObject.content
                if (localeContentObject.field === 'titel' && localeContentObject.locale === 'en') compilatie.titel_en = localeContentObject.content
                if (localeContentObject.field === 'subtitel' && localeContentObject.locale === 'nl') compilatie.subtitel_nl = localeContentObject.content
                if (localeContentObject.field === 'subtitel' && localeContentObject.locale === 'en') compilatie.subtitel_en = localeContentObject.content
                if (localeContentObject.field === 'beschrijving' && localeContentObject.locale === 'nl') compilatie.beschrijving_nl = localeContentObject.content
                if (localeContentObject.field === 'beschrijving' && localeContentObject.locale === 'en') compilatie.beschrijving_en = localeContentObject.content
            }
        }

        serviceContext.compilatieLocaleDataService.saveLocaleData(changedLocales).then(result => {
            serviceContext.compilatieService.updateCompilatie(compilatie).then(res => {
                setCompilatie({...compilatie})
            })
        })
    }

    const onReferentiebeeldUploaded = (provider) => {
        provider.defaultReferentiebeeld = ''
        serviceContext.compilatieService.updateCompilatie(provider).then(res => {
            setCompilatie({...compilatie})
        })
    }

    if (!compilatie || !serviceContext.privilegeManager) return null

    return (
        <ServiceContext.Provider value={{
            ...serviceContext, compilatie, onSaveCompilatie, compilatieAdaUserLinks,
            localeContent, onDeleteAdaUserLink,
            updateAdaUserLinks, onReferentiebeeldUploaded
        }}><Fragment>

            <ComponentIdentifier displayName='CompilatieView' info={compilatie && `uuid: ${compilatie.uuid} | id: ${compilatie.id}`}/>


            <div className='detail-view-header'
                 style={{
                     backgroundImage: `url(${referentieBeeld})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center center',
                     backgroundRepeat: 'no-repeat',
                 }}>
                <div className='detail-view-titel'>
                    <CompilatieIcon style={{flexShrink: 0}}/>
                    <div style={{marginLeft: '10px'}}>{compilatieTitel}</div>
                </div>

                {compilatieAdaUserLinks && serviceContext.privilegeManager.canEditCompilatieSettings(compilatie, compilatieAdaUserLinks) &&
                <div className='detail-view-icons'>
                    <SettingsIcon onClick={onSettingsIconClick}/>
                </div>}

                <div className='detail-view-message'>
                    <ProviderStatus provider={compilatie}/>
                </div>

            </div>


            <div className='main-content'>

                {serviceContext.toegangsticket && toegangsticketEigenaar &&
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}} secondary>
                    <Icon name='ticket' size='huge' color='orange'/>
                    <InfoViewer editable={serviceContext.privilegeManager.canViewInfoButtons()}
                                refId={'TT_HEADER_IN_MAIN_VIEW'}
                                showIcons={false}
                                substitutions={{
                                    '[[CONSUMER_TYPE]]': serviceContext.localeManager.getString(serviceContext.toegangsticket.consumer_type),
                                    '[[AANGEMAAKT_DOOR]]': toegangsticketEigenaar.email}}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>
                </div>}

                {view !== COMPILATIE_DETAIL_MAIN_VIEW &&
                <Fragment>
                    <Label as='a' onClick={() => setView(COMPILATIE_DETAIL_MAIN_VIEW)}><PreviousIcon/> {serviceContext.localeManager.getString('TERUG_NAAR_COMPILATIE')}</Label>
                    <Divider/>
                </Fragment>
                }

                {view === COMPILATIE_DETAIL_MAIN_VIEW && compilatieAdaUserLinks &&
                <CompilatieDetailMainView/>}

                {view === COMPILATIE_DETAIL_SETTINGS_VIEW && compilatieAdaUserLinks &&
                <CompilatieDetailSettingsView/>}

            </div>
        </Fragment>
        </ServiceContext.Provider>
    )
}

export default CompilatieView

CompilatieView.propTypes = {}

CompilatieView.defaultProps = {}
