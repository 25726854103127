import React, {useState, useEffect, Fragment, useContext} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import Parser from 'html-react-parser'
import ServiceContext from '../context/ServiceContext'

import {Card, Image, Dimmer, Loader, Label, Popup, Divider} from "semantic-ui-react";
import {BundelIcon, EmbargoIcon, Spacer, StatusManager, MetadataUtil} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "./ComponentIdentifier";
import AdaUserDisplay from "./AdaUserDisplay";

const VIEW_LOADING = 'loading'
const VIEW_BUNDEL = 'bundel'
const VIEW_BLOCKED = 'blocked'

const DISPLAY_LIST = "list"
const DISPLAY_TILE = "tile"

const BundelCard = ({bundel, showStatus, fromCollectie, display, onClick}) => {


    //fromCollectie wil zeggen dat de bundel card getoond wordt BINNEN een collectie. Enkel dan mag de knop 'terug naar collectie' werken!

    const [referentieBeeld, setReferentieBeeld] = useState()
    const [hasEmbargo, setHasEmbargo] = useState(false)
    const [view, setView] = useState(VIEW_LOADING)
    const [resolvedBundelEmbargo, setResolvedBundelEmbargo] = useState()
    const [opleidingString, setOpleidingString] = useState('')
    const [labelColor, setLabelColor] = useState()
    const [bundelAdaUserLinks, setBundelAdaUserLinks] = useState()
    const [bundelViewable, setBundelViewable] = useState(true)
    const [bundelViewableReason, setBundelViewableReason] = useState('')
    const [bundelContributors, setBundelContributors] = useState()


    const serviceContext = useContext(ServiceContext)

    //VIEWABLE?
    useEffect(() => {
        if (!bundelAdaUserLinks) return
        const bundelViewable = serviceContext.privilegeManager.canViewBundelCard(bundel, bundelAdaUserLinks, serviceContext.collectieAdaUserLinks)
        setBundelViewable(bundelViewable.allow)
        const rs = `${serviceContext.localeManager.getString('REDEN_DAT_JE_DEZE_BUNDEL_KAN_ZIEN')}: ${bundelViewable.reason}`
        setBundelViewableReason(rs)
    }, [bundel, bundelAdaUserLinks])

    useEffect(() => {
        setLabelColor(StatusManager.getStatusColor(bundel.status))
    }, [bundel])

    useEffect(() => {
        if (!bundel) return


        const resolveReferentiebeeld = () => {
            return bundel.defaultReferentiebeeld !== ''
                ? bundel.defaultReferentiebeeld
                : serviceContext.applicationPath.referentiebeelden() + bundel.uuid + '_thumb.jpg?d=' + new Date().getTime()
        }

        setReferentieBeeld(resolveReferentiebeeld())

        const he = bundel.embargo && bundel.embargo !== ''
        setHasEmbargo(he)

        serviceContext.adaUserService.getAdaUserLinks('bundel', bundel.id).then(adaUserLinks => {
            setBundelAdaUserLinks(adaUserLinks)
            if (serviceContext.collectieAdaUserLinks) {
                adaUserLinks = adaUserLinks.concat(serviceContext.collectieAdaUserLinks)
            }

            //TODO: rekening houden met het collectie embargo!!

            //Wanneer ook de metadata onder embargo zitten zal er niets getoond worden!
            serviceContext.privilegeManager.resolveEmbargo(bundel, adaUserLinks, 'metadata').then(resp => {


                if (resp.allow) {
                    setView(VIEW_BUNDEL)
                    setBundelViewable(true)
                } else {
                    setResolvedBundelEmbargo(resp)
                    setView(VIEW_BLOCKED)
                }
            })
        })


    }, [bundel, serviceContext.adaUserService, serviceContext.collectieAdaUserLinks, serviceContext.privilegeManager])

    //OPLEIDING
    useEffect(() => {
        serviceContext.arteveldeApiService.getOpleidingen(bundel.academiejaar).then(() => {
            setOpleidingString(serviceContext.arteveldeApiService.getOpleidingString(bundel.academiejaar, bundel.opleiding, serviceContext.localeManager.locale))
        })
    }, [bundel, serviceContext.localeManager.locale, serviceContext.arteveldeApiService])


    const imageErrorHandler = (el) => {
        setReferentieBeeld(serviceContext.applicationPath.geen_referentiebeeld())
    }

    const testBlock = (event) => {
        event.preventDefault()
    }

    if (!bundel || !bundelViewable) return null

    const card = (
        display === DISPLAY_TILE ? (
            <Card color='teal' className='result-card'>
                <Image src={referentieBeeld} wrapped ui={false} onError={imageErrorHandler}  loading="lazy"/>

                <div className='icon-group-top-left'>
                    <BundelIcon/>
                    {hasEmbargo && <EmbargoIcon/>}
                </div>

                {showStatus && <div className='icon-group-top-right'>
                    <Label size='tiny' color={labelColor}>{serviceContext.localeManager.getString(bundel.status)}</Label>
                </div>}

                <Card.Content>
                    <p className='academiejaar'>{bundel.academiejaar}</p>

                    <Card.Header>{MetadataUtil.resolveTitel(bundel, serviceContext.localeManager.locale)}</Card.Header>
                    <Card.Meta>
                        {opleidingString}
                    </Card.Meta>
                    <Card.Description>
                        {bundel[`subtitel_${serviceContext.localeManager.locale}`] || bundel.subtitel_nl}
                    </Card.Description>
                </Card.Content>
            </Card>
        ) : display === DISPLAY_LIST && (
            <Card fluid color='teal' className='result-card-list'>
                <div className='icon-group-top-right'>
                    <BundelIcon onClick={testBlock}/>
                    {hasEmbargo && <EmbargoIcon/>}
                </div>

                {showStatus && <div className='icon-group-bottom-right'>
                    <Label size='tiny' color={labelColor}>{serviceContext.localeManager.getString(bundel.status)}</Label>
                </div>}

                <Card.Content>
                    <Image src={referentieBeeld} size='small' wrapped ui={true} floated='left' onError={imageErrorHandler}/>

                    <p className='academiejaar'>{bundel.academiejaar}</p>
                    <Card.Header>{MetadataUtil.resolveTitel(bundel, serviceContext.localeManager.locale)}</Card.Header>
                    <Card.Meta>
                        {opleidingString}
                        <Divider/>
                        {bundelAdaUserLinks && <AdaUserDisplay adaUserLinks={bundelAdaUserLinks}/>}
                    </Card.Meta>
                    <Card.Description>
                        {bundel[`subtitel_${serviceContext.localeManager.locale}`] || bundel.subtitel_nl}
                    </Card.Description>
                </Card.Content>
            </Card>
        )
    );

    return (
        <Fragment>
            <ComponentIdentifier displayName='BundelCard' info={bundelViewableReason}/>

            {view === VIEW_BUNDEL && (
                onClick ? (
                    <div onClick={event => onClick(event, bundel)}>
                        {card}
                    </div>
                ) : (
                    <Link to={`/bundel/${bundel.uuid}`}>
                        {card}
                    </Link>
                )
            )}

            {view === VIEW_LOADING &&
            <Card fluid={display === DISPLAY_LIST} color='teal' style={{height: '180px'}}>
                <Card.Content>
                    <Dimmer active inverted>
                        <Loader inverted>{serviceContext.localeManager.getString('EMBARGO_RESOLVING')}</Loader>
                    </Dimmer>
                </Card.Content>
            </Card>
            }

            {/*{view === VIEW_BLOCKED && resolvedBundelEmbargo &&
            <Card fluid={display === DISPLAY_LIST} color='teal' style={{height: '180px'}}>
                <Card.Content>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.2em'}}>
                        <EmbargoIcon/>
                        <Spacer vertical distance={20}/>
                        <p style={{fontSize: '0.8em'}}>
                            {Parser(serviceContext.localeManager.getString(resolvedBundelEmbargo.reason).replace('[[opleiding]]', '<strong>' + opleidingString + '</strong>'))}
                        </p>
                    </div>
                </Card.Content>
            </Card>
            }*/}

        </Fragment>
    )
}

export {BundelCard}

BundelCard.propTypes = {
    bundel: PropTypes.object.isRequired,
    showStatus: PropTypes.bool,
    fromCollectie: PropTypes.bool,
    display: PropTypes.string,
}

BundelCard.defaultProps = {
    showStatus: false,
    fromCollectie: false,
    display: DISPLAY_TILE
}
