import {BundelStatus} from "../bundel/Bundel";
import {CollectieStatus} from "../collectie/Collectie";


export class StatusManager {

    static getStatusColor(status) {
        switch (status) {
            case BundelStatus.DRAFT:
                return 'yellow'
            case BundelStatus.VALIDATIE_AFGEWEZEN:
                return 'red'
            case BundelStatus.IN_VALIDATIE:
                return 'blue'
            case BundelStatus.GEVALIDEERD:
                return 'green'

            case CollectieStatus.DRAFT:
                return 'red'
            case CollectieStatus.OPEN_VOOR_INZENDEN:
                return 'blue'
            case CollectieStatus.GESLOTEN_VOOR_INZENDEN:
                return 'red'
            case CollectieStatus.GEPUBLICEERD:
                return 'green'

            default:
                return 'grey'

        }
    }


}
