import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Segment, Breadcrumb, Header, Icon, Dimmer, Loader, Divider} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'
import {DownloadIcon, EmbargoIcon, FolderIcon, InfoViewer, Mimetype, Spacer} from "@ahsmediatheek/ada-lib";
import 'dropzone/dist/min/dropzone.min.css'
import 'react-dropzone-js/styles/filepicker.css'


import DropzoneItem from "../component/dropzone/DropzoneItem";
import ItemView from "./ItemView";
import Parser from 'html-react-parser'
import DropzoneBundel from "../component/dropzone/DropzoneBundel";
import {ComponentIdentifier} from "../component/ComponentIdentifier";

const VIEW_LOADING = 'loading'
const VIEW_DROPZONE = 'dropzone'
const VIEW_DIRECTORY_CHANGE = 'directoryChange'
const VIEW_ITEM = 'item'
const DELIMITER = '/'

const BundelContentView = ({bundel}) => {

    const [outline, setOutline] = useState()
    const [teKoopItems, setTeKoopItems] = useState()

    const [view, setView] = useState(VIEW_LOADING)
    const [root, setRoot] = useState('')
    const [directoryContent, setDirectoryContent] = useState()
    const [breadcrumbOptions, setBreadcrumbOptions] = useState()
    const [selectedItem, setSelectedItem] = useState()
    const [uploading, setUploading] = useState(false)
    const [totalProgressPercentage, setTotalProgressPercentage] = useState(0)
    const [errorData] = useState('')

    const serviceContext = useContext(ServiceContext)


    useEffect(() => {
        initOutline()
    }, [bundel])

    useEffect(() => {

        if (!outline) return

        const directoryContent = outline.filter(item => {
            return item.parentString === root
        })

        const rootArray = root.split(DELIMITER).filter(el => el !== '')

        const breadcrumbOptions = rootArray.map((element, index, elements) => {
            const myRoot = '/' + elements.slice(0, index + 1).join('/')
            const option = {key: index + 1, content: element, link: true, onClick: onBreadcrumbClick, root: myRoot}
            return option
        })

        breadcrumbOptions.unshift({key: 0, content: serviceContext.localeManager.getString('BUNDEL'), link: true, onClick: onBreadcrumbClick, root: ''})

        delete breadcrumbOptions[breadcrumbOptions.length - 1].link
        delete breadcrumbOptions[breadcrumbOptions.length - 1].onClick
        breadcrumbOptions[breadcrumbOptions.length - 1].active = true

        setBreadcrumbOptions(breadcrumbOptions)
        setDirectoryContent(directoryContent)
    }, [outline, root, serviceContext.localeManager])

    const initOutline = () => {
        Promise.all([
            serviceContext.bundelService.getBundelOutline(bundel),
            serviceContext.bundelService.getBundelTeKoopItems(bundel)
        ]).then(([outline, teKoopItems]) => {
            //serviceContext.bundelService.setIsAndHasTeKoopItem(bundel, teKoopItems)
            setTeKoopItems(teKoopItems)
            setOutline(outline)
            //serviceContext.bundelService.setIsAndHasReferentiebestand(bundel, serviceContext.referentiebestanden)

            setView(VIEW_DROPZONE)
        }).catch(error => console.log('PA2 ERROR', error))
    }
    /*const onDrop = (addedFiles, rFiles) => {
        console.log('FILES', addedFiles)

        setTotalProgressPercentage(0)
        setUploading(true)

        serviceContext.bundelService.addFilesToBundel(bundel, root, addedFiles, progressCallback, sanitize).then(res => {
            setUploading(false)
            onDropComplete && onDropComplete(res)
        })
    }*/

    /*const progressCallback = (progressEvent) => {
        setTotalProgressPercentage(Math.round(progressEvent.loaded / progressEvent.total * 100))
    }*/

    const onDropzoneItemClick = (event, {type, item}) => {
        event.stopPropagation()
        setSelectedItem(item)
    }

    const onDropzoneItemDoubleClick = (event, {type, item}) => {
        event.stopPropagation()

        if (type === Mimetype.DIRECTORY) {
            setView(VIEW_DIRECTORY_CHANGE)
            setTimeout(() => {
                setView(VIEW_DROPZONE)
            }, 50)

            setRoot(item.file.path)
            setSelectedItem(null)
        } else {
            if (!serviceContext.privilegeManager.canUploadBundelItems(serviceContext.bundelCollectieCollectie, bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks)) return
            setView(VIEW_ITEM)
        }
    }

    const onBreadcrumbClick = (event, data) => {
        setView(VIEW_DIRECTORY_CHANGE)
        setTimeout(() => {
            setView(VIEW_DROPZONE)
        }, 50)
        setRoot(data.root)
    }

    const onDownload = (event, {item, type}) => {
        //cBijzettafeltjes%202.jpg
        event.stopPropagation()
        //tweak: als er een / aan het begin van path staat, dit verwijderen
        let path = item.file.path.replace(/^\//g, '');

        const link = serviceContext.applicationPath.fileservice() + path
        console.log('DOWNLOAD', item, type, link, outline)
    }

    const onChangeTeKoopItem = (bundel, item, checked, teKoopItem) => {
        console.log('CHANGE TE KOOP ITEM', item, teKoopItem)
        serviceContext.bundelService.toggleTeKoopItem(bundel, item, checked, teKoopItem).then(teKoopItems => {
            console.log('TOGGLE', teKoopItems)
            //serviceContext.bundelService.setIsAndHasTeKoopItem(bundel, teKoopItems)
            //serviceContext.bundelService.setIsAndHasReferentiebestand(bundel, serviceContext.referentiebestanden)
            setTeKoopItems(teKoopItems)
        })
    }

    const onDeleteItem = (event, {item, type}) => {
        event.stopPropagation()

        if (item.hasReferentiebestand || item.isReferentiebestand) {
            //window.alert(serviceContext.localeManager.getString('KAN_NIET_VERWIJDEREN_REFERENTIEBESTAND'))
            return
        }

        const itemString = type === Mimetype.DIRECTORY ? serviceContext.localeManager.getString('DEZE_MAP') : serviceContext.localeManager.getString('DIT_ITEM').toLowerCase()
        const msg = serviceContext.localeManager.getString('BEN_JE_ZEKER_VERWIJDEREN').replace('[[item]]', itemString)
        const ok = window.confirm(msg)

        if (ok) {
            setView(VIEW_DIRECTORY_CHANGE)
            serviceContext.bundelService.removeFileFromBundel(bundel, item).then(res => {
                /*serviceContext.bundelService.getBundelOutline(bundel).then(res => {
                    setView(VIEW_DROPZONE)
                    setOutline(res)
                })*/
                initOutline()
            })
        }
    }

    const onDropComplete = () => {
        setView(VIEW_LOADING)
        serviceContext.bundelService.getBundelOutline(bundel).then(res => {
            setView(VIEW_DROPZONE)
            setOutline(res)
        })
    }


    const onAddFolder = (root) => {
        const name = window.prompt(serviceContext.localeManager.getString('GEEF_DIRECTORY_NAAM'))
        if (name) {
            setView(VIEW_LOADING)
            serviceContext.bundelService.createBundelDirectory(bundel, root, name).then(res1 => {
                /*serviceContext.bundelService.getBundelOutline(bundel).then(res => {
                    console.log('MAP GEMAAKT', res)
                    setView(VIEW_DROPZONE)
                    setOutline(res)
                })*/
                initOutline()
            })
        }
    }

    return (<ServiceContext.Provider value={{
            ...serviceContext, onChangeTeKoopItem, teKoopItems
        }}>
            <div>
                <ComponentIdentifier displayName='BundelContentView'/>

                {view === VIEW_LOADING &&
                <Segment padded='very'>
                    {<Dimmer active inverted>
                        <Loader inverted>{serviceContext.localeManager.getString('INHOUD_AAN_HET_LADEN')}</Loader>
                    </Dimmer>}
                </Segment>
                }

                {(view === VIEW_DROPZONE || view === VIEW_DIRECTORY_CHANGE) && <Fragment>
                    <InfoViewer editable={serviceContext.infoEditable}
                                refId={'INFO_BUNDEL_CONTENT'}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>

                    <Segment>
                        <Header>{serviceContext.localeManager.getString('BUNDEL_INHOUD')}</Header>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            {breadcrumbOptions && <Breadcrumb icon='right angle' sections={breadcrumbOptions}/>}

                            <div style={{fontSize: '1.3em', display: 'flex', alignItems: 'center'}}>

                                {serviceContext.privilegeManager.canUploadBundelItems(serviceContext.bundelCollectieCollectie, bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks) &&
                                <Icon.Group className='icon-hoverable' onClick={(event) => onAddFolder(root)}>
                                    <FolderIcon/>
                                    <Icon corner='top right' name='add'/>
                                </Icon.Group>}
                                <Spacer/>
                                {/*<DownloadIcon className='icon-hoverable' onClick={(event) => onDownload(event, {item: root, type: 'bundel'})}/>*/}
                            </div>
                        </div>
                    </Segment>

                    {view === VIEW_DIRECTORY_CHANGE &&
                    <Segment padded='very'>
                        {<Dimmer active inverted>
                            <Loader inverted>{serviceContext.localeManager.getString('UPDATING')}</Loader>
                        </Dimmer>}
                    </Segment>
                    }


                    {view === VIEW_DROPZONE && <Fragment>

                        {directoryContent && <DropzoneBundel bundelService={serviceContext.bundelService}
                                                             bundel={bundel} root={root}
                                                             uploadEnabled={serviceContext.privilegeManager.canUploadBundelItems(serviceContext.bundelCollectieCollectie, bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks)}
                                                             onUploadComplete={onDropComplete}
                                                             uploadConfig={serviceContext.applicationService.config.upload}>

                            {directoryContent.map((item, index) => {
                                return <DropzoneItem key={index}
                                                     item={item}
                                                     bundel={bundel}
                                                     onClick={onDropzoneItemClick}
                                                     onDoubleClick={onDropzoneItemDoubleClick}
                                                     selected={item === selectedItem}
                                                     onDelete={onDeleteItem}
                                                     onView={onDropzoneItemDoubleClick}
                                                     onDownload={onDownload}/>
                            })}

                            {directoryContent.length === 0 && <div style={{alignSelf: 'center'}}>{serviceContext.localeManager.getString('DEZE_MAP_IS_LEEG')}</div>}

                        </DropzoneBundel>}
                    </Fragment>}




                    {/*{directoryContent && <Dropzone onDrop={onDrop}>
                        {({getRootProps, getInputProps}) => (
                            <div className='dropzone' {...getRootProps()}>

                                {serviceContext.privilegeManager.canUploadBundelItems(serviceContext.bundelCollectieCollectie, bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks) &&
                                <input {...getInputProps()} />}

                                {uploading && <Progress style={{width: '100%'}} autoSuccess progress size='small' percent={totalProgressPercentage}>

                                </Progress>}

                                {directoryContent.map((item, index) => {
                                    return <DropzoneItem key={index}
                                                         item={item}
                                                         bundel={bundel}
                                                         onClick={onDropzoneItemClick}
                                                         onDoubleClick={onDropzoneItemDoubleClick}
                                                         selected={item === selectedItem}
                                                         onDelete={onDeleteItem}
                                                         onView={onDropzoneItemDoubleClick}
                                                         onDownload={onDownload}/>
                                })}

                                {directoryContent.length === 0 && <div style={{alignSelf: 'center'}}>{serviceContext.localeManager.getString('DEZE_MAP_IS_LEEG')}</div>}
                            </div>
                        )}
                    </Dropzone>}*/}

                    {serviceContext.privilegeManager.isAdmin() && <div>
                        {Parser(errorData)}
                    </div>}
                </Fragment>}

                {view === VIEW_ITEM && <ItemView item={selectedItem}
                                                 bundel={bundel}
                                                 onBackClick={() => setView(VIEW_DROPZONE)}/>}


            </div>
        </ServiceContext.Provider>
    )
}

export default BundelContentView

BundelContentView.propTypes = {
    bundel: PropTypes.object.isRequired,
}

BundelContentView.defaultProps = {}
