import {CollectieService} from "../../service/CollectieService";
import {BundelService} from "../../service/BundelService";
import {AdaUser, AdaUserRol} from "../user/AdaUser";
import {AdaUserService} from "../../service/AdaUserService";
import {LocaleManager} from "../../service/LocaleManager";
import {ArteveldeApiService} from "../../service/ArteveldeApiService";
import {Embargo} from "../embargo/Embargo";
import {BundelStatus} from "../bundel/Bundel";
import {CollectieStatus} from "../collectie/Collectie";
import {CollectieSettings} from "../collectie/CollectieSettings";
import {CompilatieStatus} from "../compilatie/Compilatie";

const moment = require('moment')

export class PrivilegeManager {

    adaUser: AdaUser
    collectieService: CollectieService
    bundelService: BundelService
    adaUserService: AdaUserService
    arteveldeApiService: ArteveldeApiService
    localeManager: LocaleManager
    today: string
    adminEnabled: boolean = true
    

    constructor(collectieService, bundelService, adaUserService, arteveldeApiService, localeManager) {
        this.collectieService = collectieService
        this.bundelService = bundelService
        this.adaUserService = adaUserService
        this.arteveldeApiService = arteveldeApiService
        this.localeManager = localeManager
        this.today = moment().format('YYYY-MM-DD')
    }

    setAdaUser = (adaUser) => {
        this.adaUser = adaUser
    }


    isSuperAdmin = (adaUser = null, evaluateAdminEnabled = true) => {
        if (evaluateAdminEnabled && !this.adminEnabled) return false
        if (adaUser) return adaUser.isSuperAdmin
        if (this.adaUser) return this.adaUser.isSuperAdmin
        return false
    }

    isAdmin = (adaUser = null, evaluateAdminEnabled = true) => {
        if (evaluateAdminEnabled && !this.adminEnabled) return false
        if (this.isSuperAdmin(adaUser, evaluateAdminEnabled)) return true
        if (this.adaUser) return this.adaUser.roles.includes('admin')
        return false
    }

    adminRole = () => {
        if (this.isSuperAdmin(null, false)) return 'Super admin'
        if (this.isAdmin(null, false)) return 'Admin'
        return null
    }

    //===================================================================================
    //===================================================================================
    //===================================================================================
    //===================================================================================

    loggedIn = () => {
        return !!this.adaUser
    }

    canEditInfo = () => {
        return this.isAdmin()
    }

    hasPilotAccess = () => {
        if (this.isAdmin()) return true

        if (!this.adaUser) return false
        if (!this.adaUserService.pilotAccess) return false
        return this.adaUserService.pilotAccess.map(pa => pa.adaUser).indexOf(this.adaUser.id) > -1
    }

    resolve = (allow, reason?) => {
        return {allow, reason}
    }

    hasRol = (adaUserLinks, rol) => {
        //if (this.isAdmin()) return true
        if (!this.adaUser || !adaUserLinks) return false
        return adaUserLinks.reduce((hasrol, aul) => {
            if (aul.adaUser === this.adaUser.id && aul.rol === rol) return true
            return hasrol
        }, false)
    }

    settingActive = (provider, setting) => {
        console.log(provider)
        console.log(setting)
        let settings
        //return settings[setting] === 1

        console.log(this.collectieService)
        console.log(this.bundelService)
        
        if (provider.class === 'collectie'){
            settings = this.collectieService.getCollectieSettings(provider)
        } else if (provider.class === 'bundel'){

            settings = this.bundelService.getBundelSettings(provider)
            console.log(settings)
        }

        if (!settings) return false
        if (settings[setting] === null) return false
        return settings[setting] === 1
        
    }

    //==============================================================
    //==============================================================
    //                      COMPILATIE
    //==============================================================
    //==============================================================

    canAddCompilatie = () => {
        if (this.isAdmin()) return true
        return this.isArteveldeMedewerker(this.adaUser)
    }


    canEditCompilatieSettings = (compilatie, adaUserLinks) => {
        if (this.isSuperAdmin()) return true
        if (compilatie.status === CompilatieStatus.GEARCHIVEERD) return false

        return this.hasRol(adaUserLinks, AdaUserRol.EIGENAAR)
    }

    canDeleteCompilatieAdaUserLink = (compilatie, adaUser) => {
        return this.loggedIn()
    }

    canAddCompilatieAdaUserLink = (compilatie, rol) => {
        if (rol === AdaUserRol.EIGENAAR){
            //return this.settingActive(compilatie, CompilatieSettings.BUNDEL_VALIDATIE_ACTIEF)
            return true;
        }
        return this.loggedIn()
    }

    canDeleteCompilatie = (compilatie, compilatieAdaUserLinks) => {
        return this.hasRol(compilatieAdaUserLinks, AdaUserRol.EIGENAAR)
    }
    //==============================================================
    //==============================================================
    //                      COLLECTIE
    //==============================================================
    //==============================================================

    canAddCollectie = () => {
        if (this.isAdmin()) return true
        return this.isArteveldeMedewerker(this.adaUser)
    }

    canAccessCollectie = (collectie, collectieAdaUserLinks) => {
        /*
        * Wanneer een collectie link ergens getoond wordt, moet gechecked worden of de gebruiker die collectie mag zien!*/
        if (collectie.status === CollectieStatus.GEPUBLICEERD) return true
        return this.hasRol(collectieAdaUserLinks, AdaUserRol.VALIDATOR) || this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canEditCollectieSettings = (collectie, adaUserLinks) => {
        if (this.isSuperAdmin()) return true
        if (collectie.status === CollectieStatus.GEARCHIVEERD) return false
        return this.hasRol(adaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canAddBundel = (collectie, adaUserLinks) => {
        if (collectie.status === CollectieStatus.GEARCHIVEERD) return false
        return this.hasRol(adaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canExportExcel = (collectie, adaUserLinks) => {
        return this.hasRol(adaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canDeleteCollectieAdaUserLink = (collectie, adaUser) => {
        return this.loggedIn()
    }

    canAddCollectieAdaUserLink = (collectie, rol) => {
        if (rol === AdaUserRol.VALIDATOR){
            return this.settingActive(collectie, CollectieSettings.BUNDEL_VALIDATIE_ACTIEF)
        }
        return this.loggedIn()
    }

    canDeleteCollectie = (collectie, collectieAdaUserLinks) => {
        return this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canPublishCollectie = (collectie, collectieAdaUserLinks) => {
        /*
        * Een collectie kan gepubliceerd worden indien:
        * De collectie status niet gepubliceerd is
        * Je collectie beheerder bent
        * */

        if (collectie.status === CollectieStatus.GEPUBLICEERD) return false

        return this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canOpenCollectieVoorInzenden = (collectie, collectieAdaUserLinks) => {
        /*
        * Een collectie kan gepubliceerd worden indien:
        * De collectie status niet gepubliceerd is
        * Je collectie beheerder bent
        * */

        if (collectie.status === CollectieStatus.OPEN_VOOR_INZENDEN) return false

        return this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canSluitCollectieVoorInzenden = (collectie, collectieAdaUserLinks) => {
        /*
        * Een collectie kan heropend worden indien:
        * De collectie status op OPEN_VOOR_INZENDEN staat
        * Je collectie beheerder bent
        * */

        if (collectie.status !== CollectieStatus.OPEN_VOOR_INZENDEN) return false

        return this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canArchiveerCollectie = (collectie, collectieAdaUserLinks) => {
        /*
        * Een collectie kan gearchiveerd worden indien:
        * De collectie status op GEPUBLICEERD staat
        * Je collectie beheerder bent
        * */

        if (collectie.status !== CollectieStatus.GEPUBLICEERD) return false

        return this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    //==============================================================
    //==============================================================
    //                      BUNDEL
    //==============================================================
    //==============================================================

    canViewBundelCard = (bundel, bundelAdaUserLinks, collectieAdaUserLinks) => {
        /*
       * Je kan een bundel card zien als:
       * De bundel status niet op DRAFT of VALIDATIE_AFGEWEZEN staat
       * De bundel een geldig referentiebeeld heeft
       * Je bundel eigenaar of collectie beheerder bent
       * */

        if (this.isAdmin()) return this.resolve(true, 'SUPER_ADMIN')

        //Als bundel eigenaar of collectie beheerder kan je de bundel altijd zien
        if (this.hasRol(bundelAdaUserLinks, AdaUserRol.EIGENAAR))
            return this.resolve(true, 'BUNDEL_EIGENAAR')
        if (this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER))
            return this.resolve(true, 'COLLECTIE_BEHEERDER')

        //Als promotor kan je de bundel zien!
        if (this.hasRol(bundelAdaUserLinks, AdaUserRol.PROMOTOR))
            return this.resolve(true, 'BUNDEL_PROMOTOR')

        //Als er geen referentiebeeld is, kan je de bundel niet zien
        if (bundel.defaultReferentiebeeld !== '')
            return this.resolve(false, 'GEEN_REFERENTIEBEELD')


        return this.resolve(bundel.status !== BundelStatus.DRAFT && bundel.status !== BundelStatus.VALIDATIE_AFGEWEZEN, 'STATUS_NIET_DRAFT_OF_VALIDATIE_AFGEWEZEN')
    }

    canUploadBundelItems = (collectie, bundel, bundelAdaUserLinks, collectieAdaUserLinks) => {
        /*
       * Je kan item uploaden indien:
       * De bundel status op DRAFT of VALIDATIE_AFGEWEZEN staat
       * Je bundel eigenaar of collectie beheerder bent
       * */
        if (collectie.status === CollectieStatus.GEARCHIVEERD) return false
        if (bundel.status !== BundelStatus.DRAFT && bundel.status !== BundelStatus.VALIDATIE_AFGEWEZEN) return false
        return this.hasRol(bundelAdaUserLinks, AdaUserRol.EIGENAAR) || this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canEditBundelSettings = (collectie, bundel, adaUserLinks, collectieAdaUserLinks) => {

        if (this.isSuperAdmin()) return this.resolve(true, 'SUPER_ADMIN')

        //Je kan geen setting editen als de collectie gearchiveerd is
        if (collectie.status === CollectieStatus.GEARCHIVEERD) return this.resolve(false, 'COLLECTIE_GEARCHIVEERD')

        //Je kan de bundel settings wijzigen als je collectie beheerder bent
        if (this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)) return  this.resolve(true, 'COLLECTIE_BEHEERDER')

        //Je kan als eigenaar van de bundel de settings enkel wijzigen als de status van
        if (this.hasRol(adaUserLinks, AdaUserRol.EIGENAAR)){
            return  this.resolve(bundel.status === BundelStatus.DRAFT || bundel.status === BundelStatus.VALIDATIE_AFGEWEZEN, 'BUNDEL_STATTUS_DRAFT_OF_VALIDATIE_AFGEWEZEN')
        }

        return this.resolve(false, 'GEEN_RECHTEN_OM_SETTINGS_TE_WIJZIGEN')
    }

    canViewBundelItems = (bundel, adaUserLinks, collectieAdaUserLinks) => {
        return this.hasRol(adaUserLinks, AdaUserRol.EIGENAAR) || this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canDeleteBundelAdaUserLink = (bundel, adaUser) => {
        return this.loggedIn()
    }

    canAddBundelAdaUserLink = (bundel) => {
        return this.loggedIn()
    }

    canCommitBundel = (collectie, bundel, bundelAdaUserLinks, collectieAdaUserLinks) => {
        /*
        * Een bundel kan gecommit worden indien:
        * De bundel status op DRAFT staat
        * Je bundel eigenaar of collectie beheerder bent
        * De collectie status open voor inzenden staat
        * */
        if (collectie.status !== CollectieStatus.OPEN_VOOR_INZENDEN) return false
        if (bundel.status !== BundelStatus.DRAFT && bundel.status !== BundelStatus.VALIDATIE_AFGEWEZEN) return false

        return this.hasRol(bundelAdaUserLinks, AdaUserRol.EIGENAAR) || this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canValidateBundel = (bundelCollectie, bundel, bundelAdaUserLinks, collectieAdaUserLinks) => {
        console.log(bundelCollectie, bundel, bundelAdaUserLinks, collectieAdaUserLinks)
        console.log(CollectieSettings.BUNDEL_VALIDATIE_ACTIEF)
        /*
        * Een bundel kan gevalideerd worden indien:
        * De collectie settings dit toelaat
        * De bundel status op IN_VALIDATIE staat
        * Je collectie validator of collectie beheerder bent
        * */

        if (!this.settingActive(bundelCollectie, CollectieSettings.BUNDEL_VALIDATIE_ACTIEF)) return false

        if (bundel.status !== BundelStatus.IN_VALIDATIE) return false

        return this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER) || this.hasRol(collectieAdaUserLinks, AdaUserRol.VALIDATOR)
    }

    canRejectBundelValidatie = (bundelCollectie, bundel, bundelAdaUserLinks, collectieAdaUserLinks) => {
        /*
        * Een bundel kan geweigerd worden indien:
        * De collectie setting dit toelaat
        * De bundel status op GEVALIDEERD of IN_VALIDATIE staat
        * collectie beheerder bent
        * */

        if (!this.settingActive(bundelCollectie, CollectieSettings.BUNDEL_VALIDATIE_ACTIEF)) return false

        if (bundel.status !== BundelStatus.GEVALIDEERD && bundel.status !== BundelStatus.IN_VALIDATIE) return false

        return this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canReopenBundel = (bundel, bundelAdaUserLinks, collectieAdaUserLinks) => {
        /*
        * Een bundel kan heroopend worden indien:
        * De bundel status op GEVALIDEERD staat
        * collectie beheerder bent
        * */

        if (bundel.status !== BundelStatus.GEVALIDEERD) return false

        return this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canViewBundelStatusInCard = (bundel, bundelAdaUserLinks, collectieAdaUserLinks) => {

        return this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER) || this.hasRol(collectieAdaUserLinks, AdaUserRol.VALIDATOR)
    }

    canSetBundelTeKoop = (collectieAdaUserLinks) => {
        return this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    canEditBundelEmbargo = (collectie, bundelAdaUserLinks, collectieAdaUserLinks) => {
        if (this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)) return true
        if (!this.settingActive(collectie, CollectieSettings.INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN)) return false
        return this.hasRol(bundelAdaUserLinks, AdaUserRol.EIGENAAR)
    }

    canAddBundelTrefwoordKandidaat = (collectie, bundel, collectieAdaUserLinks, bundelAdaUserLinks) => {

        //collectiebeheerder
        if (this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)) return true

        //Moet bundel eigenaar zijn
        if (!this.hasRol(bundelAdaUserLinks, AdaUserRol.EIGENAAR)) return false

        //Collectie setting moeten dit toelaten
        return this.settingActive(collectie, CollectieSettings.INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN)
    }

    //==============================================================
    //==============================================================
    //                      ITEM
    //==============================================================
    //==============================================================


    canSetItemTeKoop = (collectieAdaUserLinks) => {
        return this.hasRol(collectieAdaUserLinks, AdaUserRol.COLLECTIE_BEHEERDER)
    }

    //==============================================================
    //==============================================================
    //                      EMBARGO
    //==============================================================
    //==============================================================

    resolveEmbargo = (provider, adaUserLinks, level, toegangsticket) => {

        //level = metadata of bundelInhoud
        //adaUserLinks bevat zowel de collectie als de bundel userLinks

        return new Promise((resolve, reject) => {

            //Geen embargo
            if (!provider.embargo || provider.embargo === '') {
                resolve(this.resolve(true, 'GEEN_EMBARGO'))
                return
            }

            //superAdmin altijd
            if (this.isAdmin()) {
                resolve(this.resolve(true, 'ADMIN'))
                return
            }

            //collectie medewerkers kunnen altijd binnen
            const hasAdaUserLink = this.adaUser && adaUserLinks && adaUserLinks.reduce((has, aul) => aul.adaUser === this.adaUser.id ? true : has, false)
            if (hasAdaUserLink) {
                resolve(this.resolve(true, 'COLLECTIE_MEDEWERKER'))
                return
            }

            const embargo = Embargo.fromString(provider.embargo)

            //Geen embargo
            if (embargo[level] === Embargo.EMBARGO_IEDEREEN) {
                resolve(this.resolve(true, 'EMBARGO_IEDEREEN'))
                return
            }

            //Toegangsticket
            if (toegangsticket){
                //als er een toegangsticket is, dan is de verificatie reeds gebeurd
                resolve(this.resolve(true, 'TOEGANGSTICKET'))
                return
            }

            //op dit moment MOET je ingelogd zijn

            if (!this.loggedIn()) {
                resolve(this.resolve(false, 'EMBARGO_GEEN_ADA_GEBRUIKER'))
                return
            }

            //UITZONDERINGEN
            if (embargo.uitzonderingen) {
                const currentUitzonderingen = embargo.uitzonderingen.filter(uitz => uitz.van <= this.today && uitz.tot >= this.today)
                for (const uitzondering of currentUitzonderingen) {


                    if (uitzondering.gebruiker === this.adaUser.id) {
                        resolve(this.resolve(true, 'TOEGANGSTICKET'))
                        return
                    }
                }
            }

            switch (embargo[level]) {
                case Embargo.NIEMAND:
                    resolve(this.resolve(false, 'EMBARGO_BLOCK_WAT'))
                    return
                case Embargo.EMBARGO_ADA_GEBRUIKERS:
                    if (!this.loggedIn()) {
                        resolve(this.resolve(false, 'EMBARGO_GEEN_ADA_GEBRUIKER'))
                        return
                    } else {
                        resolve(this.resolve(true, 'EMBARGO_ADA_GEBRUIKER'))
                    }
                    break
                case Embargo.EMBARGO_ARTEVELDE:
                    if (!this.isArteveldeUser(this.adaUser)) {
                        resolve(this.resolve(false, 'EMBARGO_GEEN_ARTEVELDE'))
                    } else {
                        resolve(this.resolve(true, 'EMBAROG_ARTEVELDE'))
                    }
                    break
                case Embargo.EMBARGO_ARTEVELDE_MEDEWERKERS:
                    if (!this.isArteveldeMedewerker(this.adaUser)) {
                        resolve(this.resolve(false, 'EMBARGO_GEEN_ARTEVELDE_MEDEWERKER'))
                    } else {
                        resolve(this.resolve(true, 'EMBARGO_ARTEVELDE_MEDEWERKER'))
                    }
                    break
                case Embargo.EMBARGO_ARTEVELDE_MEDEWERKERS_AAN_GERELATEERDE_OPLEIDINGEN:
                    if (!provider.opleiding || !provider.academiejaar || provider.opleiding === '' || provider.academiejaar === '') {
                        //resolve(false)
                        resolve(this.resolve(false, 'Enkel Artevelde personeel dat verbonden is aan de opleiding(en) [[opleiding]] kan de inhoud van deze bundel zien maar er is geen opleiding aan deze collectie verbonden'))

                        return
                    }

                    const opleidingen = provider.opleiding.split(',')
                    const promises = opleidingen.map(opleiding => {
                        return new Promise((res, rej) => {

                            this.arteveldeApiService.getOpleidingDocenten(opleiding, provider.academiejaar).then(resp => {
                                const docenten = (resp as any[]).filter(docent => docent.Upn === this.adaUser.email)
                                res(docenten)
                            })
                        })
                    })

                    Promise.all(promises).then(responses => {
                        for (const response of responses) {
                            if ((response as []).length > 0) {

                                resolve(this.resolve(true, 'EMBARGO_ARTEVELDE_MEDEWERKER_OPLEIDING'))
                                return
                            }
                        }
                        resolve(this.resolve(false, 'EMBARGO_ADA_USER_ZIT_NIET_IN_OPLEIDING'))
                        return
                    })
                    break
                default:
                    console.error('CANVIEW BUNDEL KAN NIET GERESOLVED WORDEN', adaUserLinks, embargo)
                    break
            }


        })
    }

    //==============================================================
    //==============================================================
    //                      ADA USER
    //==============================================================
    //==============================================================

    canSearchUpn = () => {
        return this.isAdmin()
    }

    isArteveldeUser = (adaUser) => {
        return this.loggedIn() && adaUser.email.includes('@arteveldehs.be')
    }

    isArteveldeMedewerker = (adaUser) => {
        return this.loggedIn() && adaUser.email.includes('@arteveldehs.be')
    }

    isArteveldeStudent = (adaUser) => {
        return this.loggedIn() && adaUser.email.includes('@student.arteveldehs.be')
    }

    //==============================================================
    //==============================================================
    //                      BACKOFFICE
    //==============================================================
    //==============================================================

    canAccessBackoffice = () => {
        if (!this.adaUser) return false
        if (this.isAdmin(null, false)) return true
        return this.adaUserService.hasRol(this.adaUser, AdaUserRol.ADMIN)
    }

    canChangeRol = () => {
        if (!this.adaUser) return false

        return this.isSuperAdmin(null, false)
    }


    //==============================================================
    //==============================================================
    //                      DIVERS
    //==============================================================
    //==============================================================

    canViewInfoButtons = () => {
        return this.isAdmin()
    }
}
