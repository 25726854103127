import React, {useContext} from 'react'
import ServiceContext from '../../context/ServiceContext'
import {Form, Header} from "semantic-ui-react"
import ReferentiebeeldDropzone from "../../component/referentiebeeld/ReferentiebeeldDropzone";
import {CompilatieCard} from '../../component/CompilatieCard'
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const CompilatieSettingsReferentiebeeld = () => {


    const serviceContext = useContext(ServiceContext)

    return (
        <Form>
            <ComponentIdentifier displayName='CompilatieSettingsReferentiebeeld'/>

            <Header dividing size='huge'>{serviceContext.localeManager.getString('REFERENTIEBEELD')}</Header>

            <Form.Field>
                <label>{serviceContext.localeManager.getString('VOORVERTONING')}</label>
                <CompilatieCard locale={serviceContext.localeManager.locale} compilatie={serviceContext.compilatie}/>
            </Form.Field>

            <Form.Field>
                <label>{serviceContext.localeManager.getString('REFERENTIEBEELD')}</label>
                <ReferentiebeeldDropzone provider={serviceContext.compilatie}/>
            </Form.Field>
        </Form>
    )
}

export default CompilatieSettingsReferentiebeeld
