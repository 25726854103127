import React, {useState, useEffect, useContext , useRef} from 'react'
import { Container, Header, Card , Grid, Icon, Label , Dimmer , Segment , Form , Input, TextArea, Divider }  from "semantic-ui-react";
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


const MetadataManager = ({adaUser}) => {
    const [ loadingProfiles , setLoadingProfiles ] = useState(true)
    const [ selectedType, setSelectedType ] = useState({ type : { label : 'loading'}})
    const testData = [
        {
            "id": "https://schema.org/abstract",
            "label": "abstract",
            "comment": "An abstract is a short description that summarizes a <a class=\"localLink\" href=\"https://schema.org/CreativeWork\">CreativeWork</a>.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/accessMode",
            "label": "accessMode",
            "comment": "The human sensory perceptual system or cognitive faculty through which a person may process or perceive information. Expected values include: auditory, tactile, textual, visual, colorDependent, chartOnVisual, chemOnVisual, diagramOnVisual, mathOnVisual, musicOnVisual, textOnVisual.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/accessModeSufficient",
            "label": "accessModeSufficient",
            "comment": "A list of single or combined accessModes that are sufficient to understand all the intellectual content of a resource. Expected values include:  auditory, tactile, textual, visual.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/ItemList",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/accessibilityAPI",
            "label": "accessibilityAPI",
            "comment": "Indicates that the resource is compatible with the referenced accessibility API (<a href=\"http://www.w3.org/wiki/WebSchemas/Accessibility\">WebSchemas wiki lists possible values</a>).",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/accessibilityControl",
            "label": "accessibilityControl",
            "comment": "Identifies input methods that are sufficient to fully control the described resource (<a href=\"http://www.w3.org/wiki/WebSchemas/Accessibility\">WebSchemas wiki lists possible values</a>).",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/accessibilityFeature",
            "label": "accessibilityFeature",
            "comment": "Content features of the resource, such as accessible media, alternatives and supported enhancements for accessibility (<a href=\"http://www.w3.org/wiki/WebSchemas/Accessibility\">WebSchemas wiki lists possible values</a>).",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/accessibilityHazard",
            "label": "accessibilityHazard",
            "comment": "A characteristic of the described resource that is physiologically dangerous to some users. Related to WCAG 2.0 guideline 2.3 (<a href=\"http://www.w3.org/wiki/WebSchemas/Accessibility\">WebSchemas wiki lists possible values</a>).",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/accessibilitySummary",
            "label": "accessibilitySummary",
            "comment": "A human-readable summary of specific accessibility features or deficiencies, consistent with the other accessibility metadata but expressing subtleties such as \"short descriptions are present but long descriptions will be needed for non-visual users\" or \"short descriptions are present and no long descriptions are needed.\"",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/accountablePerson",
            "label": "accountablePerson",
            "comment": "Specifies the Person that is legally accountable for the CreativeWork.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/acquireLicensePage",
            "label": "acquireLicensePage",
            "comment": "Indicates a page documenting how licenses can be purchased or otherwise acquired, for the current item.",
            "subPropertyOf": "https://schema.org/usageInfo",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/additionalType",
            "label": "additionalType",
            "comment": "An additional type for the item, typically used for adding more specific types from external vocabularies in microdata syntax. This is a relationship between something and a class that the thing is in. In RDFa syntax, it is better to use the native RDFa syntax - the 'typeof' attribute - for multiple types. Schema.org tools may have only weaker understanding of extra types, in particular those defined externally.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/aggregateRating",
            "label": "aggregateRating",
            "comment": "The overall rating, based on a collection of reviews or ratings, of the item.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Brand, https://schema.org/CreativeWork, https://schema.org/Event, https://schema.org/Offer, https://schema.org/Organization, https://schema.org/Place, https://schema.org/Product, https://schema.org/Service",
            "rangeIncludes": "https://schema.org/AggregateRating",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/alternateName",
            "label": "alternateName",
            "comment": "An alias for the item.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/alternativeHeadline",
            "label": "alternativeHeadline",
            "comment": "A secondary title of the CreativeWork.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/assesses",
            "label": "assesses",
            "comment": "The item being described is intended to assess the competency or learning outcome defined by the referenced term.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/EducationEvent, https://schema.org/LearningResource",
            "rangeIncludes": "https://schema.org/DefinedTerm, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/associatedMedia",
            "label": "associatedMedia",
            "comment": "A media object that encodes this CreativeWork. This property is a synonym for encoding.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/MediaObject",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/audience",
            "label": "audience",
            "comment": "An intended audience, i.e. a group for whom something was created.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Event, https://schema.org/LodgingBusiness, https://schema.org/PlayAction, https://schema.org/Product, https://schema.org/Service",
            "rangeIncludes": "https://schema.org/Audience",
            "inverseOf": "",
            "supersedes": "https://schema.org/serviceAudience",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/audio",
            "label": "audio",
            "comment": "An embedded audio object.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/AudioObject, https://schema.org/Clip, https://schema.org/MusicRecording",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/author",
            "label": "author",
            "comment": "The author of this content or rating. Please note that author is special in that HTML 5 provides a special mechanism for indicating authorship via the rel tag. That is equivalent to this and may be used interchangeably.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Rating",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/award",
            "label": "award",
            "comment": "An award won by or for this item.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Organization, https://schema.org/Person, https://schema.org/Product, https://schema.org/Service",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "https://schema.org/awards",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/awards",
            "label": "awards",
            "comment": "Awards won by or for this item.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Organization, https://schema.org/Person, https://schema.org/Product",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": "https://schema.org/award"
        },
        {
            "id": "https://schema.org/character",
            "label": "character",
            "comment": "Fictional person connected with a creative work.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/citation",
            "label": "citation",
            "comment": "A citation or reference to another creative work, such as another publication, web page, scholarly article, etc.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/comment",
            "label": "comment",
            "comment": "Comments, typically from users.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/RsvpAction",
            "rangeIncludes": "https://schema.org/Comment",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/commentCount",
            "label": "commentCount",
            "comment": "The number of comments this CreativeWork (e.g. Article, Question or Answer) has received. This is most applicable to works published in Web sites with commenting system; additional comments may exist elsewhere.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Integer",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/conditionsOfAccess",
            "label": "conditionsOfAccess",
            "comment": "Conditions that affect the availability of, or method(s) of access to, an item. Typically used for real world items such as an <a class=\"localLink\" href=\"https://schema.org/ArchiveComponent\">ArchiveComponent</a> held by an <a class=\"localLink\" href=\"https://schema.org/ArchiveOrganization\">ArchiveOrganization</a>. This property is not suitable for use as a general Web access control mechanism. It is expressed only in natural language.<br/><br/><br/><br/>For example \"Available by appointment from the Reading Room\" or \"Accessible only from logged-in accounts \".",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/contentLocation",
            "label": "contentLocation",
            "comment": "The location depicted or described in the content. For example, the location in a photograph or painting.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/spatialCoverage",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Place",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/contentRating",
            "label": "contentRating",
            "comment": "Official rating of a piece of content&#x2014;for example,'MPAA PG-13'.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Rating, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/contentReferenceTime",
            "label": "contentReferenceTime",
            "comment": "The specific time described by a creative work, for works (e.g. articles, video objects etc.) that emphasise a particular moment within an Event.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/DateTime",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/contributor",
            "label": "contributor",
            "comment": "A secondary contributor to the CreativeWork or Event.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Event",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/copyrightHolder",
            "label": "copyrightHolder",
            "comment": "The party holding the legal copyright to the CreativeWork.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/copyrightYear",
            "label": "copyrightYear",
            "comment": "The year during which the claimed copyright for the CreativeWork was first asserted.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Number",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/correction",
            "label": "correction",
            "comment": "Indicates a correction to a <a class=\"localLink\" href=\"https://schema.org/CreativeWork\">CreativeWork</a>, either via a <a class=\"localLink\" href=\"https://schema.org/CorrectionComment\">CorrectionComment</a>, textually or in another document.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CorrectionComment, https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/creativeWorkStatus",
            "label": "creativeWorkStatus",
            "comment": "The status of a creative work in terms of its stage in a lifecycle. Example terms include Incomplete, Draft, Published, Obsolete. Some organizations define a set of terms for the stages of their publication lifecycle.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/DefinedTerm, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/creator",
            "label": "creator",
            "comment": "The creator/author of this CreativeWork. This is the same as the Author property for CreativeWork.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/legislationPassedBy",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/UserComments",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/dateCreated",
            "label": "dateCreated",
            "comment": "The date on which the CreativeWork was created or the item was added to a DataFeed.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/legislationDate",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/DataFeedItem",
            "rangeIncludes": "https://schema.org/Date, https://schema.org/DateTime",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/dateModified",
            "label": "dateModified",
            "comment": "The date on which the CreativeWork was most recently modified or when the item's entry was modified within a DataFeed.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/DataFeedItem",
            "rangeIncludes": "https://schema.org/Date, https://schema.org/DateTime",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/datePublished",
            "label": "datePublished",
            "comment": "Date of first broadcast/publication.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Date, https://schema.org/DateTime",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/description",
            "label": "description",
            "comment": "A description of the item.",
            "subPropertyOf": "",
            "equivalentProperty": "http://purl.org/dc/terms/description",
            "subproperties": "https://schema.org/disambiguatingDescription",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/disambiguatingDescription",
            "label": "disambiguatingDescription",
            "comment": "A sub property of description. A short description of the item used to disambiguate from other, similar items. Information from other properties (in particular, name) may be necessary for the description to be useful for disambiguation.",
            "subPropertyOf": "https://schema.org/description",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/discussionUrl",
            "label": "discussionUrl",
            "comment": "A link to the page containing the comments of the CreativeWork.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/editEIDR",
            "label": "editEIDR",
            "comment": "An <a href=\"https://eidr.org/\">EIDR</a> (Entertainment Identifier Registry) <a class=\"localLink\" href=\"https://schema.org/identifier\">identifier</a> representing a specific edit / edition for a work of film or television.<br/><br/><br/><br/>For example, the motion picture known as \"Ghostbusters\" whose <a class=\"localLink\" href=\"https://schema.org/titleEIDR\">titleEIDR</a> is \"10.5240/7EC7-228A-510A-053E-CBB8-J\", has several edits e.g. \"10.5240/1F2A-E1C5-680A-14C6-E76B-I\" and \"10.5240/8A35-3BEE-6497-5D12-9E4F-3\".<br/><br/><br/><br/>Since schema.org types like <a class=\"localLink\" href=\"https://schema.org/Movie\">Movie</a> and <a class=\"localLink\" href=\"https://schema.org/TVEpisode\">TVEpisode</a> can be used for both works and their multiple expressions, it is possible to use <a class=\"localLink\" href=\"https://schema.org/titleEIDR\">titleEIDR</a> alone (for a general description), or alongside <a class=\"localLink\" href=\"https://schema.org/editEIDR\">editEIDR</a> for a more edit-specific description.",
            "subPropertyOf": "https://schema.org/identifier",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/editor",
            "label": "editor",
            "comment": "Specifies the Person who edited the CreativeWork.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/educationalAlignment",
            "label": "educationalAlignment",
            "comment": "An alignment to an established educational framework.<br/><br/><br/><br/>This property should not be used where the nature of the alignment can be described using a simple property, for example to express that a resource <a class=\"localLink\" href=\"https://schema.org/teaches\">teaches</a> or <a class=\"localLink\" href=\"https://schema.org/assesses\">assesses</a> a competency.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/LearningResource",
            "rangeIncludes": "https://schema.org/AlignmentObject",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/educationalLevel",
            "label": "educationalLevel",
            "comment": "The level in terms of progression through an educational or training context. Examples of educational levels include 'beginner', 'intermediate' or 'advanced', and formal sets of level indicators.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/EducationEvent, https://schema.org/EducationalOccupationalCredential, https://schema.org/LearningResource",
            "rangeIncludes": "https://schema.org/DefinedTerm, https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/educationalUse",
            "label": "educationalUse",
            "comment": "The purpose of a work in the context of education; for example, 'assignment', 'group work'.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/LearningResource",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/encoding",
            "label": "encoding",
            "comment": "A media object that encodes this CreativeWork. This property is a synonym for associatedMedia.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/MediaObject",
            "inverseOf": "https://schema.org/encodesCreativeWork",
            "supersedes": "https://schema.org/encodings",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/encodingFormat",
            "label": "encodingFormat",
            "comment": "Media type typically expressed using a MIME format (see <a href=\"http://www.iana.org/assignments/media-types/media-types.xhtml\">IANA site</a> and <a href=\"https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types\">MDN reference</a>) e.g. application/zip for a SoftwareApplication binary, audio/mpeg for .mp3 etc.).<br/><br/><br/><br/>In cases where a <a class=\"localLink\" href=\"https://schema.org/CreativeWork\">CreativeWork</a> has several media type representations, <a class=\"localLink\" href=\"https://schema.org/encoding\">encoding</a> can be used to indicate each <a class=\"localLink\" href=\"https://schema.org/MediaObject\">MediaObject</a> alongside particular <a class=\"localLink\" href=\"https://schema.org/encodingFormat\">encodingFormat</a> information.<br/><br/><br/><br/>Unregistered or niche encoding and file formats can be indicated instead via the most appropriate URL, e.g. defining Web page or a Wikipedia/Wikidata entry.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/MediaObject",
            "rangeIncludes": "https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "https://schema.org/fileFormat",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/encodings",
            "label": "encodings",
            "comment": "A media object that encodes this CreativeWork.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/MediaObject",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": "https://schema.org/encoding"
        },
        {
            "id": "https://schema.org/exampleOfWork",
            "label": "exampleOfWork",
            "comment": "A creative work that this work is an example/instance/realization/derivation of.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork",
            "inverseOf": "https://schema.org/workExample",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/expires",
            "label": "expires",
            "comment": "Date the content expires and is no longer useful or available. For example a <a class=\"localLink\" href=\"https://schema.org/VideoObject\">VideoObject</a> or <a class=\"localLink\" href=\"https://schema.org/NewsArticle\">NewsArticle</a> whose availability or relevance is time-limited, or a <a class=\"localLink\" href=\"https://schema.org/ClaimReview\">ClaimReview</a> fact check whose publisher wants to indicate that it may no longer be relevant (or helpful to highlight) after some date.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Date",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/fileFormat",
            "label": "fileFormat",
            "comment": "Media type, typically MIME format (see <a href=\"http://www.iana.org/assignments/media-types/media-types.xhtml\">IANA site</a>) of the content e.g. application/zip of a SoftwareApplication binary. In cases where a CreativeWork has several media type representations, 'encoding' can be used to indicate each MediaObject alongside particular fileFormat information. Unregistered or niche file formats can be indicated instead via the most appropriate URL, e.g. defining Web page or a Wikipedia entry.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": "https://schema.org/encodingFormat"
        },
        {
            "id": "https://schema.org/funder",
            "label": "funder",
            "comment": "A person or organization that supports (sponsors) something through some kind of financial contribution.",
            "subPropertyOf": "https://schema.org/sponsor",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Event, https://schema.org/MonetaryGrant, https://schema.org/Organization, https://schema.org/Person",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/genre",
            "label": "genre",
            "comment": "Genre of the creative work, broadcast channel or group.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/legislationType",
            "domainIncludes": "https://schema.org/BroadcastChannel, https://schema.org/CreativeWork, https://schema.org/MusicGroup",
            "rangeIncludes": "https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/hasPart",
            "label": "hasPart",
            "comment": "Indicates an item or CreativeWork that is part of this item, or CreativeWork (in some sense).",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/containsSeason, https://schema.org/episode, https://schema.org/hasDefinedTerm, https://schema.org/season",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork",
            "inverseOf": "https://schema.org/isPartOf",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/headline",
            "label": "headline",
            "comment": "Headline of the article.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/identifier",
            "label": "identifier",
            "comment": "The identifier property represents any kind of identifier for any kind of <a class=\"localLink\" href=\"https://schema.org/Thing\">Thing</a>, such as ISBNs, GTIN codes, UUIDs etc. Schema.org provides dedicated properties for representing many of these, either as textual strings or as URL (URI) links. See <a href=\"/docs/datamodel.html#identifierBg\">background notes</a> for more details.",
            "subPropertyOf": "",
            "equivalentProperty": "http://purl.org/dc/terms/identifier",
            "subproperties": "https://schema.org/accountId, https://schema.org/callSign, https://schema.org/confirmationNumber, https://schema.org/duns, https://schema.org/editEIDR, https://schema.org/flightNumber, https://schema.org/globalLocationNumber, https://schema.org/gtin, https://schema.org/gtin12, https://schema.org/gtin13, https://schema.org/gtin14, https://schema.org/gtin8, https://schema.org/isbn, https://schema.org/issn, https://schema.org/legislationIdentifier, https://schema.org/leiCode, https://schema.org/nsn, https://schema.org/orderNumber, https://schema.org/productID, https://schema.org/serialNumber, https://schema.org/sku, https://schema.org/taxID, https://schema.org/titleEIDR",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/PropertyValue, https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/image",
            "label": "image",
            "comment": "An image of the item. This can be a <a class=\"localLink\" href=\"https://schema.org/URL\">URL</a> or a fully described <a class=\"localLink\" href=\"https://schema.org/ImageObject\">ImageObject</a>.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/layoutImage, https://schema.org/logo, https://schema.org/photo",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/ImageObject, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/inLanguage",
            "label": "inLanguage",
            "comment": "The language of the content or performance or used in an action. Please use one of the language codes from the <a href=\"http://tools.ietf.org/html/bcp47\">IETF BCP 47 standard</a>. See also <a class=\"localLink\" href=\"https://schema.org/availableLanguage\">availableLanguage</a>.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/BroadcastService, https://schema.org/CommunicateAction, https://schema.org/CreativeWork, https://schema.org/Event, https://schema.org/LinkRole, https://schema.org/PronounceableText, https://schema.org/WriteAction",
            "rangeIncludes": "https://schema.org/Language, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "https://schema.org/language",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/inSupportOf",
            "label": "inSupportOf",
            "comment": "Qualification, candidature, degree, application that Thesis supports.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thesis",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/interactionStatistic",
            "label": "interactionStatistic",
            "comment": "The number of interactions for the CreativeWork using the WebSite or SoftwareApplication. The most specific child type of InteractionCounter should be used.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Organization, https://schema.org/Person",
            "rangeIncludes": "https://schema.org/InteractionCounter",
            "inverseOf": "",
            "supersedes": "https://schema.org/interactionCount",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/interactivityType",
            "label": "interactivityType",
            "comment": "The predominant mode of learning supported by the learning resource. Acceptable values are 'active', 'expositive', or 'mixed'.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/isAccessibleForFree",
            "label": "isAccessibleForFree",
            "comment": "A flag to signal that the item, event, or place is accessible for free.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Event, https://schema.org/Place",
            "rangeIncludes": "https://schema.org/Boolean",
            "inverseOf": "",
            "supersedes": "https://schema.org/free",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/isBasedOn",
            "label": "isBasedOn",
            "comment": "A resource from which this work is derived or from which it is a modification or adaption.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/Product, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "https://schema.org/isBasedOnUrl",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/isBasedOnUrl",
            "label": "isBasedOnUrl",
            "comment": "A resource that was used in the creation of this resource. This term can be repeated for multiple sources. For example, http://example.com/great-multiplication-intro.html.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/Product, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": "https://schema.org/isBasedOn"
        },
        {
            "id": "https://schema.org/isFamilyFriendly",
            "label": "isFamilyFriendly",
            "comment": "Indicates whether this content is family friendly.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Boolean",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/isPartOf",
            "label": "isPartOf",
            "comment": "Indicates an item or CreativeWork that this item, or CreativeWork (in some sense), is part of.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/inDefinedTermSet, https://schema.org/partOfEpisode, https://schema.org/partOfSeason, https://schema.org/partOfSeries, https://schema.org/partOfTVSeries",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/URL",
            "inverseOf": "https://schema.org/hasPart",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/keywords",
            "label": "keywords",
            "comment": "Keywords or tags used to describe this content. Multiple entries in a keywords list are typically delimited by commas.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/DefinedTerm, https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/learningResourceType",
            "label": "learningResourceType",
            "comment": "The predominant type or kind characterizing the learning resource. For example, 'presentation', 'handout'.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/LearningResource",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/license",
            "label": "license",
            "comment": "A license document that applies to this content, typically indicated by URL.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/locationCreated",
            "label": "locationCreated",
            "comment": "The location where the CreativeWork was created, which may not be the same as the location depicted in the CreativeWork.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Place",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/mainEntity",
            "label": "mainEntity",
            "comment": "Indicates the primary entity described in some page or other CreativeWork.",
            "subPropertyOf": "https://schema.org/about",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Thing",
            "inverseOf": "https://schema.org/mainEntityOfPage",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/mainEntityOfPage",
            "label": "mainEntityOfPage",
            "comment": "Indicates a page (or other CreativeWork) for which this thing is the main entity being described. See <a href=\"/docs/datamodel.html#mainEntityBackground\">background notes</a> for details.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/URL",
            "inverseOf": "https://schema.org/mainEntity",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/maintainer",
            "label": "maintainer",
            "comment": "A maintainer of a <a class=\"localLink\" href=\"https://schema.org/Dataset\">Dataset</a>, software package (<a class=\"localLink\" href=\"https://schema.org/SoftwareApplication\">SoftwareApplication</a>), or other <a class=\"localLink\" href=\"https://schema.org/Project\">Project</a>. A maintainer is a <a class=\"localLink\" href=\"https://schema.org/Person\">Person</a> or <a class=\"localLink\" href=\"https://schema.org/Organization\">Organization</a> that manages contributions to, and/or publication of, some (typically complex) artifact. It is common for distributions of software and data to be based on \"upstream\" sources. When <a class=\"localLink\" href=\"https://schema.org/maintainer\">maintainer</a> is applied to a specific version of something e.g. a particular version or packaging of a <a class=\"localLink\" href=\"https://schema.org/Dataset\">Dataset</a>, it is always  possible that the upstream source has a different maintainer. The <a class=\"localLink\" href=\"https://schema.org/isBasedOn\">isBasedOn</a> property can be used to indicate such relationships between datasets to make the different maintenance roles clear. Similarly in the case of software, a package may have dedicated maintainers working on integration into software distributions such as Ubuntu, as well as upstream maintainers of the underlying work.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/material",
            "label": "material",
            "comment": "A material that something is made from, e.g. leather, wool, cotton, paper.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/artMedium, https://schema.org/surface",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Product",
            "rangeIncludes": "https://schema.org/Product, https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/materialExtent",
            "label": "materialExtent",
            "comment": "The quantity of the materials being described or an expression of the physical space they occupy.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/QuantitativeValue, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/mentions",
            "label": "mentions",
            "comment": "Indicates that the CreativeWork contains a reference to, but is not necessarily about a concept.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Thing",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/name",
            "label": "name",
            "comment": "The name of the item.",
            "subPropertyOf": "",
            "equivalentProperty": "http://purl.org/dc/terms/title",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/offers",
            "label": "offers",
            "comment": "An offer to provide this item&#x2014;for example, an offer to sell a product, rent the DVD of a movie, perform a service, or give away tickets to an event. Use <a class=\"localLink\" href=\"https://schema.org/businessFunction\">businessFunction</a> to indicate the kind of transaction offered, i.e. sell, lease, etc. This property can also be used to describe a <a class=\"localLink\" href=\"https://schema.org/Demand\">Demand</a>. While this property is listed as expected on a number of common types, it can be used in others. In that case, using a second type, such as Product or a subtype of Product, can clarify the nature of the offer.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/AggregateOffer, https://schema.org/CreativeWork, https://schema.org/EducationalOccupationalProgram, https://schema.org/Event, https://schema.org/MenuItem, https://schema.org/Product, https://schema.org/Service, https://schema.org/Trip",
            "rangeIncludes": "https://schema.org/Demand, https://schema.org/Offer",
            "inverseOf": "https://schema.org/itemOffered",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/pattern",
            "label": "pattern",
            "comment": "A pattern that something has, for example 'polka dot', 'striped', 'Canadian flag'. Values are typically expressed as text, although links to controlled value schemes are also supported.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Product",
            "rangeIncludes": "https://schema.org/DefinedTerm, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/position",
            "label": "position",
            "comment": "The position of an item in a series or sequence of items.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/clipNumber, https://schema.org/episodeNumber, https://schema.org/issueNumber, https://schema.org/seasonNumber, https://schema.org/volumeNumber",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/ListItem",
            "rangeIncludes": "https://schema.org/Integer, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/potentialAction",
            "label": "potentialAction",
            "comment": "Indicates a potential Action, which describes an idealized action in which this thing would play an 'object' role.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/Action",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/producer",
            "label": "producer",
            "comment": "The person or organization who produced the work (e.g. music album, movie, tv/radio series etc.).",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/provider",
            "label": "provider",
            "comment": "The service provider, service operator, or service performer; the goods producer. Another party (a seller) may offer those services or goods on behalf of the provider. A provider may also serve as the seller.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/EducationalOccupationalProgram, https://schema.org/Invoice, https://schema.org/ParcelDelivery, https://schema.org/Reservation, https://schema.org/Service, https://schema.org/Trip",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "https://schema.org/carrier",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/publication",
            "label": "publication",
            "comment": "A publication event associated with the item.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/PublicationEvent",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/publisher",
            "label": "publisher",
            "comment": "The publisher of the creative work.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/publisherImprint",
            "label": "publisherImprint",
            "comment": "The publishing division which published the comic.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Organization",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/publishingPrinciples",
            "label": "publishingPrinciples",
            "comment": "The publishingPrinciples property indicates (typically via <a class=\"localLink\" href=\"https://schema.org/URL\">URL</a>) a document describing the editorial principles of an <a class=\"localLink\" href=\"https://schema.org/Organization\">Organization</a> (or individual e.g. a <a class=\"localLink\" href=\"https://schema.org/Person\">Person</a> writing a blog) that relate to their activities as a publisher, e.g. ethics or diversity policies. When applied to a <a class=\"localLink\" href=\"https://schema.org/CreativeWork\">CreativeWork</a> (e.g. <a class=\"localLink\" href=\"https://schema.org/NewsArticle\">NewsArticle</a>) the principles are those of the party primarily responsible for the creation of the <a class=\"localLink\" href=\"https://schema.org/CreativeWork\">CreativeWork</a>.<br/><br/><br/><br/>While such policies are most typically expressed in natural language, sometimes related information (e.g. indicating a <a class=\"localLink\" href=\"https://schema.org/funder\">funder</a>) can be expressed using schema.org terminology.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/actionableFeedbackPolicy, https://schema.org/correctionsPolicy, https://schema.org/diversityStaffingReport, https://schema.org/masthead, https://schema.org/missionCoveragePrioritiesPolicy, https://schema.org/noBylinesPolicy, https://schema.org/ownershipFundingInfo, https://schema.org/unnamedSourcesPolicy, https://schema.org/verificationFactCheckingPolicy",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Organization, https://schema.org/Person",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/recordedAt",
            "label": "recordedAt",
            "comment": "The Event where the CreativeWork was recorded. The CreativeWork may capture all or part of the event.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Event",
            "inverseOf": "https://schema.org/recordedIn",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/releasedEvent",
            "label": "releasedEvent",
            "comment": "The place and time the release was issued, expressed as a PublicationEvent.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/PublicationEvent",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/review",
            "label": "review",
            "comment": "A review of the item.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Brand, https://schema.org/CreativeWork, https://schema.org/Event, https://schema.org/Offer, https://schema.org/Organization, https://schema.org/Place, https://schema.org/Product, https://schema.org/Service",
            "rangeIncludes": "https://schema.org/Review",
            "inverseOf": "",
            "supersedes": "https://schema.org/reviews",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/reviews",
            "label": "reviews",
            "comment": "Review of the item.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Offer, https://schema.org/Organization, https://schema.org/Place, https://schema.org/Product",
            "rangeIncludes": "https://schema.org/Review",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": "https://schema.org/review"
        },
        {
            "id": "https://schema.org/sameAs",
            "label": "sameAs",
            "comment": "URL of a reference Web page that unambiguously indicates the item's identity. E.g. the URL of the item's Wikipedia page, Wikidata entry, or official website.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/schemaVersion",
            "label": "schemaVersion",
            "comment": "Indicates (by URL or string) a particular version of a schema used in some CreativeWork. For example, a document could declare a schemaVersion using an URL such as https://schema.org/version/2.0/ if precise indication of schema version was required by some application.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/sdDatePublished",
            "label": "sdDatePublished",
            "comment": "Indicates the date on which the current structured data was generated / published. Typically used alongside <a class=\"localLink\" href=\"https://schema.org/sdPublisher\">sdPublisher</a>",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Date",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/sdLicense",
            "label": "sdLicense",
            "comment": "A license document that applies to this structured data, typically indicated by URL.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/sdPublisher",
            "label": "sdPublisher",
            "comment": "Indicates the party responsible for generating and publishing the current structured data markup, typically in cases where the structured data is derived automatically from existing published content but published on a different site. For example, student projects and open data initiatives often re-publish existing content with more explicitly structured metadata. The<a class=\"localLink\" href=\"https://schema.org/sdPublisher\">sdPublisher</a> property helps make such practices more explicit.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/size",
            "label": "size",
            "comment": "A standardized size of a product or creative work, often simplifying richer information into a simple textual string, either through referring to named sizes or (in the case of product markup), by adopting conventional simplifications. Use of QuantitativeValue with a unitCode or unitText can add more structure; in other cases, the /width, /height, /depth and /weight properties may be more applicable.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Product",
            "rangeIncludes": "https://schema.org/DefinedTerm, https://schema.org/QuantitativeValue, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/sourceOrganization",
            "label": "sourceOrganization",
            "comment": "The Organization on whose behalf the creator was working.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Organization",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/spatial",
            "label": "spatial",
            "comment": "The \"spatial\" property can be used in cases when more specific properties(e.g. <a class=\"localLink\" href=\"https://schema.org/locationCreated\">locationCreated</a>, <a class=\"localLink\" href=\"https://schema.org/spatialCoverage\">spatialCoverage</a>, <a class=\"localLink\" href=\"https://schema.org/contentLocation\">contentLocation</a>) are not known to be appropriate.",
            "subPropertyOf": "",
            "equivalentProperty": "http://purl.org/dc/terms/spatial",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Place",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/spatialCoverage",
            "label": "spatialCoverage",
            "comment": "The spatialCoverage of a CreativeWork indicates the place(s) which are the focus of the content. It is a subproperty of      contentLocation intended primarily for more technical and detailed materials. For example with a Dataset, it indicates      areas that the dataset describes: a dataset of New York weather would have spatialCoverage which was the place: the state of New York.",
            "subPropertyOf": "https://schema.org/contentLocation",
            "equivalentProperty": "http://purl.org/dc/terms/spatial",
            "subproperties": "https://schema.org/announcementLocation, https://schema.org/legislationJurisdiction",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Place",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/sponsor",
            "label": "sponsor",
            "comment": "A person or organization that supports a thing through a pledge, promise, or financial contribution. e.g. a sponsor of a Medical Study or a corporate sponsor of an event.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/funder",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Event, https://schema.org/Grant, https://schema.org/MedicalStudy, https://schema.org/Organization, https://schema.org/Person",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/subjectOf",
            "label": "subjectOf",
            "comment": "A CreativeWork or Event about this Thing.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/Event",
            "inverseOf": "https://schema.org/about",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/teaches",
            "label": "teaches",
            "comment": "The item being described is intended to help a person learn the competency or learning outcome defined by the referenced term.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/EducationEvent, https://schema.org/LearningResource",
            "rangeIncludes": "https://schema.org/DefinedTerm, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/temporal",
            "label": "temporal",
            "comment": "The \"temporal\" property can be used in cases where more specific properties(e.g. <a class=\"localLink\" href=\"https://schema.org/temporalCoverage\">temporalCoverage</a>, <a class=\"localLink\" href=\"https://schema.org/dateCreated\">dateCreated</a>, <a class=\"localLink\" href=\"https://schema.org/dateModified\">dateModified</a>, <a class=\"localLink\" href=\"https://schema.org/datePublished\">datePublished</a>) are not known to be appropriate.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/DateTime, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/temporalCoverage",
            "label": "temporalCoverage",
            "comment": "The temporalCoverage of a CreativeWork indicates the period that the content applies to, i.e. that it describes, either as a DateTime or as a textual string indicating a time period in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#Time_intervals\">ISO 8601 time interval format</a>. In      the case of a Dataset it will typically indicate the relevant time period in a precise notation (e.g. for a 2011 census dataset, the year 2011 would be written \"2011/2012\"). Other forms of content e.g. ScholarlyArticle, Book, TVSeries or TVEpisode may indicate their temporalCoverage in broader terms - textually or via well-known URL.      Written works such as books may sometimes have precise temporal coverage too, e.g. a work set in 1939 - 1945 can be indicated in ISO 8601 interval format format via \"1939/1945\".<br/><br/><br/><br/>Open-ended date ranges can be written with \"..\" in place of the end date. For example, \"2015-11/..\" indicates a range beginning in November 2015 and with no specified final date. This is tentative and might be updated in future when ISO 8601 is officially updated.",
            "subPropertyOf": "",
            "equivalentProperty": "http://purl.org/dc/terms/temporal",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/DateTime, https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "https://schema.org/datasetTimeInterval",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/text",
            "label": "text",
            "comment": "The textual content of this CreativeWork.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/thumbnailUrl",
            "label": "thumbnailUrl",
            "comment": "A thumbnail image relevant to the Thing.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/timeRequired",
            "label": "timeRequired",
            "comment": "Approximate or typical time it takes to work with or through this learning resource for the typical intended target audience, e.g. 'PT30M', 'PT1H25M'.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Duration",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/translationOfWork",
            "label": "translationOfWork",
            "comment": "The work that this work has been translated from. e.g. 物种起源 is a translationOf “On the Origin of Species”",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork",
            "inverseOf": "https://schema.org/workTranslation",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/translator",
            "label": "translator",
            "comment": "Organization or person who adapts a creative work to different languages, regional differences and technical requirements of a target market, or that translates during some event.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Event",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/typicalAgeRange",
            "label": "typicalAgeRange",
            "comment": "The typical expected age range, e.g. '7-9', '11-'.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Event",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/url",
            "label": "url",
            "comment": "URL of the item.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/usageInfo",
            "label": "usageInfo",
            "comment": "The schema.org <a class=\"localLink\" href=\"https://schema.org/usageInfo\">usageInfo</a> property indicates further information about a <a class=\"localLink\" href=\"https://schema.org/CreativeWork\">CreativeWork</a>. This property is applicable both to works that are freely available and to those that require payment or other transactions. It can reference additional information e.g. community expectations on preferred linking and citation conventions, as well as purchasing details. For something that can be commercially licensed, usageInfo can provide detailed, resource-specific information about licensing options.<br/><br/><br/><br/>This property can be used alongside the license property which indicates license(s) applicable to some piece of content. The usageInfo property can provide information about other licensing options, e.g. acquiring commercial usage rights for an image that is also available under non-commercial creative commons licenses.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/acquireLicensePage",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/version",
            "label": "version",
            "comment": "The version of the CreativeWork embodied by a specified resource.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Number, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/video",
            "label": "video",
            "comment": "An embedded video object.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Clip, https://schema.org/VideoObject",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/workExample",
            "label": "workExample",
            "comment": "Example/instance/realization/derivation of the concept of this creative work. eg. The paperback edition, first edition, or eBook.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/appearance, https://schema.org/firstAppearance",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork",
            "inverseOf": "https://schema.org/exampleOfWork",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/workTranslation",
            "label": "workTranslation",
            "comment": "A work that is a translation of the content of this work. e.g. 西遊記 has an English workTranslation “Journey to the West”,a German workTranslation “Monkeys Pilgerfahrt” and a Vietnamese  translation Tây du ký bình khảo.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork",
            "inverseOf": "https://schema.org/translationOfWork",
            "supersedes": "",
            "supersededBy": ""
        }
    ]
    const [ test , setTest ] = useState([])
   /* 
    const [ test , setTest ] = useState([
        {
            "id": "https://schema.org/locationCreated",
            "label": "locationCreated",
            "comment": "The location where the CreativeWork was created, which may not be the same as the location depicted in the CreativeWork.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Place",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/mainEntity",
            "label": "mainEntity",
            "comment": "Indicates the primary entity described in some page or other CreativeWork.",
            "subPropertyOf": "https://schema.org/about",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Thing",
            "inverseOf": "https://schema.org/mainEntityOfPage",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/mainEntityOfPage",
            "label": "mainEntityOfPage",
            "comment": "Indicates a page (or other CreativeWork) for which this thing is the main entity being described. See <a href=\"/docs/datamodel.html#mainEntityBackground\">background notes</a> for details.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/URL",
            "inverseOf": "https://schema.org/mainEntity",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/maintainer",
            "label": "maintainer",
            "comment": "A maintainer of a <a class=\"localLink\" href=\"https://schema.org/Dataset\">Dataset</a>, software package (<a class=\"localLink\" href=\"https://schema.org/SoftwareApplication\">SoftwareApplication</a>), or other <a class=\"localLink\" href=\"https://schema.org/Project\">Project</a>. A maintainer is a <a class=\"localLink\" href=\"https://schema.org/Person\">Person</a> or <a class=\"localLink\" href=\"https://schema.org/Organization\">Organization</a> that manages contributions to, and/or publication of, some (typically complex) artifact. It is common for distributions of software and data to be based on \"upstream\" sources. When <a class=\"localLink\" href=\"https://schema.org/maintainer\">maintainer</a> is applied to a specific version of something e.g. a particular version or packaging of a <a class=\"localLink\" href=\"https://schema.org/Dataset\">Dataset</a>, it is always  possible that the upstream source has a different maintainer. The <a class=\"localLink\" href=\"https://schema.org/isBasedOn\">isBasedOn</a> property can be used to indicate such relationships between datasets to make the different maintenance roles clear. Similarly in the case of software, a package may have dedicated maintainers working on integration into software distributions such as Ubuntu, as well as upstream maintainers of the underlying work.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/material",
            "label": "material",
            "comment": "A material that something is made from, e.g. leather, wool, cotton, paper.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "https://schema.org/artMedium, https://schema.org/surface",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Product",
            "rangeIncludes": "https://schema.org/Product, https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/materialExtent",
            "label": "materialExtent",
            "comment": "The quantity of the materials being described or an expression of the physical space they occupy.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/QuantitativeValue, https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/mentions",
            "label": "mentions",
            "comment": "Indicates that the CreativeWork contains a reference to, but is not necessarily about a concept.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Thing",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/name",
            "label": "name",
            "comment": "The name of the item.",
            "subPropertyOf": "",
            "equivalentProperty": "http://purl.org/dc/terms/title",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thing",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
    ])
    */

    const [ profiles , setProfiles ] = useState([])

    useEffect(() => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
        fetch("https://www.arteveldehogeschool.be/digitaalarchief-dev/ada-server/ada-api/getMetadataProfiles/", requestOptions)
            .then(response => response.json())
            .then(result => {
                setProfiles(result)
                setLoadingProfiles(false)
                console.log(result)
            })
            .catch(error => console.log('error', error));
    },[])
 
    const [ req , setReq ] = useState([
        {
            "id": "https://schema.org/abstract",
            "label": "abstract",
            "comment": "An abstract is a short description that summarizes a <a class=\"localLink\" href=\"https://schema.org/CreativeWork\">CreativeWork</a>.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/license",
            "label": "license",
            "comment": "A license document that applies to this content, typically indicated by URL.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/inSupportOf",
            "label": "inSupportOf",
            "comment": "Qualification, candidature, degree, application that Thesis supports.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/Thesis",
            "rangeIncludes": "https://schema.org/Text",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/keywords",
            "label": "keywords",
            "comment": "Keywords or tags used to describe this content. Multiple entries in a keywords list are typically delimited by commas.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/DefinedTerm, https://schema.org/Text, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },

    ])
    const [ rec , setRec ] = useState([
        {
            "id": "https://schema.org/accountablePerson",
            "label": "accountablePerson",
            "comment": "Specifies the Person that is legally accountable for the CreativeWork.",
            "subPropertyOf": "",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        }, 
        {
            "id": "https://schema.org/acquireLicensePage",
            "label": "acquireLicensePage",
            "comment": "Indicates a page documenting how licenses can be purchased or otherwise acquired, for the current item.",
            "subPropertyOf": "https://schema.org/usageInfo",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork",
            "rangeIncludes": "https://schema.org/CreativeWork, https://schema.org/URL",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
        {
            "id": "https://schema.org/funder",
            "label": "funder",
            "comment": "A person or organization that supports (sponsors) something through some kind of financial contribution.",
            "subPropertyOf": "https://schema.org/sponsor",
            "equivalentProperty": "",
            "subproperties": "",
            "domainIncludes": "https://schema.org/CreativeWork, https://schema.org/Event, https://schema.org/MonetaryGrant, https://schema.org/Organization, https://schema.org/Person",
            "rangeIncludes": "https://schema.org/Organization, https://schema.org/Person",
            "inverseOf": "",
            "supersedes": "",
            "supersededBy": ""
        },
    ])
    const [ selectedProfile , setSelectedProfile ] = useState(null)
   
    const  htmlDecode = (input) => {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
      }

    const editProfile = (profileObject) => {
        setSelectedProfile(profileObject)
        
        let type = profileObject.type.split("https://schema.org/")[1]
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://www.arteveldehogeschool.be/digitaalarchief-dev/ada-server/ada-api/getMetadataType/"+type, requestOptions)
            .then(response => response.json())
            .then(result => {
                setSelectedType(result)
                setTest(result.properties)
                console.log(result)            })
            .catch(error => console.log('error', error));
        console.log(profileObject)
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
      };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
          return;
        }
        if(source.droppableId === destination.droppableId) {
            if(source.droppableId === 'allFields') {
                const items = reorder(
                    test,
                    source.index,
                    destination.index
                );
                setTest(
                    items
                );
            }

            if(source.droppableId === 'required') {
                const items = reorder(
                    req,
                    source.index,
                    destination.index
                );
                setReq(
                    items
                );
            }

            if(source.droppableId === 'recommended') {
                const items = reorder(
                    rec,
                    source.index,
                    destination.index
                );
                setRec(
                    items
                );
            }      
            
        } else {
            var moveThisObject = null;
            
            if(source.droppableId === 'allFields') {
                moveThisObject = test.filter(obj => {
                    return obj.id === result.draggableId
                  })[0]
                setTest(current => {
                    return current.filter(item => !item.id.includes(result.draggableId))
                })
            }

            if(source.droppableId === 'required') {
                moveThisObject = req.filter(obj => {
                    return obj.id === result.draggableId
                  })[0]
                setReq(current => {
                    return current.filter(item => !item.id.includes(result.draggableId))
                })
            }

            if(source.droppableId === 'recommended') {
                moveThisObject = rec.filter(obj => {
                    return obj.id === result.draggableId
                  })[0]
                setRec(current => {
                    return current.filter(item => !item.id.includes(result.draggableId))
                })
            }  
            
            if(destination.droppableId === 'allFields') {
                let arr = test
                arr.push(moveThisObject) 
                const items = reorder(
                    arr,
                    arr.length - 1,
                    destination.index
                );
                setTest(
                    items
                );             
            }

            if(destination.droppableId === 'required') {
                let arr = req
                arr.push(moveThisObject) 
                const items = reorder(
                    arr,
                    arr.length - 1,
                    destination.index
                );
                setReq(
                    items
                );                 
            }

            if(destination.droppableId === 'recommended') {
                let arr = rec
                arr.push(moveThisObject) 
                const items = reorder(
                    arr,
                    arr.length - 1,
                    destination.index
                );
                setRec(
                    items
                );                  
            }
        }
      }
    return(
        <Container>
            <Header>Metadata Manager</Header>

                { selectedProfile === null &&
                
                <Container>
                    <Dimmer active={loadingProfiles} />
                    <Header>Profielen</Header>
                    <Masonry 
                        columns={4} 
                        gutter='1em'
                    >
                        <Card>
                            <Card.Content>
                                <Card.Header>Nieuw profiel</Card.Header>
                                <Card.Description>
                                    Maak een nieuw metadata-profiel aan:
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <Label color='green' as='a'>
                                    <Icon name='edit' /> Start
                                </Label>
                            </Card.Content>
                        </Card>
                    { profiles && profiles.map((p,i) => 
                        <Card>
                            <Card.Content>
                                <Card.Header>{p.label}</Card.Header>
                                <Card.Meta>{p.type}</Card.Meta>
                                <Card.Description>
                                    {p.omschrijving}
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                <Label color='green' as='a'
                                    onClick={() => editProfile(p)}
                                >
                                    <Icon name='edit' /> Bewerk
                                </Label>
                                <Label color='orange'>
                                    <Icon name='paste' /> Dupliceer
                                </Label>
                                <Label color='red'>
                                    <Icon name='trash alternate' /> Delete
                                </Label>
                            </Card.Content>
                        </Card>
                    )}
                    
                    </Masonry>
            </Container>
                }
                { selectedProfile !== null &&
                <Container>
                    <Header>Edit {selectedProfile.label}</Header> 
                    <Segment>
                        <Form>
                        <Input 
                            fluid
                            label="Naam:"
                            value={selectedProfile.label} 
                        />
                        <Divider />
                        <TextArea 
                            fluid
                            label="Omschrijving:"
                            value={selectedProfile.omschrijving} 
                        />
                        <Divider />
                        {selectedType.type.label ? selectedType.type.label : 'loading'}
                        </Form>
                    </Segment>
                    <DragDropContext onDragEnd={onDragEnd}>
                       <Grid columns='equal' divided >   
                       <Grid.Column>
                        <Header>Alle velden</Header>
                        <Droppable droppableId="allFields">
                            {(provided) =>
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                
                                {test.map((v,i) =>
                                <Draggable key={v.id} draggableId={v.id} index={i}>

                                    {(provided) =>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                    <Card
                                    >
                                        <Card.Content>
                                            <Card.Header>
                                                {v.label}
                                            </Card.Header>
                                            <Card.Meta>
                                            {v.id}
                                            </Card.Meta>
                                            <Card.Description>
                                            {`${htmlDecode(v.comment).substring(0,45)}...`}
                                            </Card.Description>
                                        </Card.Content>
                                        
                                    </Card>
                                    </div>

                                    }
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </div>
                            }
                        </Droppable>
                        </Grid.Column>
                        <Grid.Column>
                        <Header>Verplichte velden</Header>
                        <Droppable droppableId="required">
                            {(provided) =>
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {req.map((v,i) =>
                                <Draggable key={v.id} draggableId={v.id} index={i}>

                                    {(provided) =>
                                    <div
                                    ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                    <Card
                                    >
                                        <Card.Content>
                                            <Card.Header>
                                                {v.label}
                                            </Card.Header>
                                            <Card.Meta>
                                            {v.id}
                                            </Card.Meta>
                                        </Card.Content>
                                        
                                    </Card>
                                    </div>

                                    }
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </div>
                            }
                        </Droppable>
                        </Grid.Column>
                        <Grid.Column>
                        <Header>Aangeraden velden</Header>
                        <Droppable droppableId="recommended">
                            {(provided) =>
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {rec.map((v,i) =>
                                <Draggable key={v.id} draggableId={v.id} index={i}>

                                    {(provided) =>
                                    <div
                                    ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                    <Card
                                    >
                                        <Card.Content>
                                            <Card.Header>
                                                {v.label}
                                            </Card.Header>
                                            <Card.Meta>
                                            {v.id}
                                            </Card.Meta>
                                        </Card.Content>
                                        
                                    </Card>
                                    </div>

                                    }
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </div>
                            }
                        </Droppable>
                        </Grid.Column>
                    </Grid>   
                    
                        
                        
                    </DragDropContext>
                </Container>
                }
</Container>
    )
}

export default MetadataManager