import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';


const ENWKleuren = {
    'AHS': 'orange',
    'ENWBMG': 'blue',
    'ENWCMD': 'purple',
    'ENWGEZ': 'red',
    'ENWMES': 'pink',
    'ENWONW': 'green'
};

const ENWOpleidingLabels = ({ localeManager, ENWs = [], opleidingen = [], diensten = [], selectie = '' }) => {
    const split = value => ['', null, undefined].includes(value) ? [] : value.split(',');
    const [categories, setCategories] = useState(new Map());
    const [items, setItems] = useState(new Map());
    const [selectedCategories, setSelectedCategories] = useState(new Set());
    const [selectedItems, setSelectedItems] = useState(new Set());

    useEffect(() => {
        const categories = new Map([
            ...(ENWs.length === 0 ? [
                [
                    'AHS',
                    {
                        id: 'AHS',
                        label: localeManager.getString('ALLE OPLEIDINGEN'),
                        children: opleidingen.map(opleiding => opleiding.OplId),
                        color: 'orange'
                    }
                ]
            ] : (
                ENWs.map(ENW => ([
                    ENW.Id,
                    {
                        id: ENW.Id,
                        label: ENW.Omschrijving,
                        children: opleidingen.filter(({ DirectieId }) => (DirectieId === ENW.Id)).map(opleiding => opleiding.OplId),
                        color: ENWKleuren[ENW.Id]
                    }
                ]))
            )),
            [
                'ADI',
                {
                    id: 'ADI',
                    label: localeManager.getString('DIENSTEN'),
                    children: diensten.map(dienst => dienst.Id),
                    color: 'grey'
                }
            ]
        ]);
        const items = new Map([
            ...opleidingen.map(opleiding => ([
                opleiding.OplId,
                {
                    id: opleiding.OplId,
                    label: opleiding['Opleiding' + localeManager.locale.toUpperCase()] || opleiding.OpleidingNL,
                    color: ENWKleuren[opleiding.DirectieId ?? 'AHS']
                }
            ])),
            ...diensten.map(dienst => ([
                dienst.Id,
                {
                    id: dienst.Id,
                    label: `${localeManager.getString('DIENST')} ${dienst.Dienstnaam}`,
                    color: 'grey'
                }
            ]))
        ]);
        const selectedItems = new Set(split(selectie));

        categories.forEach(category => {
            const allSelected = (category.children.length > 0) && category.children.every(id => selectedItems.has(id));

            if (allSelected) {
                selectedCategories.add(category.id);
                category.children.forEach(id => {
                    selectedItems.delete(id);
                });
            } else {
                selectedCategories.delete(category.id);
                category.children.forEach(id => {
                    if (selectedItems.has(id)) {
                        selectedItems.add(id);
                    } else {
                        selectedItems.delete(id);
                    }
                });
            }
        });

        setItems(items);
        setCategories(categories);

        if (selectedCategories.size === 0 && selectedItems.size === 0) {
            setSelectedItems(new Set(split(selectie)));
        } else {
            setSelectedCategories(new Set(selectedCategories));
            setSelectedItems(new Set(selectedItems));
        }
    }, [ENWs, opleidingen, localeManager, selectie]);

    return (
        <span>
            {Array.from(selectedCategories)
                .map(id => categories.get(id))
                .map(item => (item && (
                    <Label
                        as='span'
                        key={item.id}
                        content={item.label}
                        color={item.color}
                        style={{
                            margin: '0.15rem 0.3rem 0.15rem 0',
                            padding: '0.25rem 0.5rem',
                            borderWidth: '1px'
                        }}
                    />
                )))}
            {Array.from(selectedItems)
                .map(id => items.get(id))
                .map(item => (item && (
                    <Label
                        basic
                        as='span'
                        key={item.id}
                        content={item.label}
                        color={item.color}
                        style={{
                            margin: '0.15rem 0.3rem 0.15rem 0',
                            padding: '0.25rem 0.5rem',
                        }}
                    />
                )))}
        </span>
    );
};

export default ENWOpleidingLabels;

ENWOpleidingLabels.propTypes = {
    opleidingen: PropTypes.array.isRequired,
    localeManager: PropTypes.object.isRequired,
    selectedOpleiding: PropTypes.string
};
