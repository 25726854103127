export class Mimetype {

    static readonly DIRECTORY: string = 'directory'

    static readonly APPLICATION_PDF: string = 'application/pdf'
    static readonly APPLICATION_OCTET_STREAM: string = 'application/octet-stream'
    static readonly APPLICATION_ZIP: string = 'application/zip'
    static readonly POWERPOINT: string = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    static readonly WORD: string = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    static readonly KEYNOTE: string = 'application/vnd.apple.keynote'
    static readonly NUMBERS: string = 'application/vnd.apple.numbers'
    static readonly EXCEL_X: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    static readonly EXCEL: string = 'application/vnd.ms-excel'
    static readonly URL_ENCODED: string = 'application/x-www-form-urlencoded'

    static readonly AUDIO_MPEG: string = 'audio/mpeg'

    static readonly IMAGE_JPEG: string = 'image/jpeg'
    static readonly IMAGE_PHOTOSHOP: string = 'image/vnd.adobe.photoshop'
    static readonly APPLICATION_PHOTOSHOP: string = 'application/x-photoshop'
    static readonly POSTSCRIPT: string = 'application/postscript'
    static readonly IMAGE_PNG: string = 'image/png'
    static readonly IMAGE_SVG: string = 'image/svg'

    static readonly TEXT_RTF: string = 'text/rtf'
    static readonly TEXT_PLAIN: string = 'text/plain'
    static readonly TEXT_CSV: string = 'text/csv'

    static readonly VIDEO_MP4: string = 'video/mp4'
    static readonly VIDEO_OGG: string = 'video/ogg'
    static readonly VIDEO_QUICKTIME: string = 'video/quicktime'
    static readonly VIDEO_MS_AVI: string = "video/x-msvideo"
    static readonly VIDEO_AVI: string = "video/avi"
    static readonly VIDEO_M3GP: string = "video/3gpp"
    static readonly VIDEO_WMV: string = "video/x-ms-wmv"
}

export const getMimetypeString = (mimetype) => {
    switch(mimetype){
        case Mimetype.DIRECTORY:
            return 'directory'
        case Mimetype.APPLICATION_ZIP:
            return 'zip_archive'
        case Mimetype.TEXT_PLAIN:
            return 'plain_text'
        case Mimetype.TEXT_RTF:
            return 'rich_text'
        case Mimetype.POWERPOINT:
            return 'powerpoint'
        case Mimetype.WORD:
            return 'word'
        case Mimetype.APPLICATION_PDF:
            return 'pdf'
        case Mimetype.IMAGE_JPEG:
            return 'jpeg_image'
        case Mimetype.IMAGE_PNG:
            return 'png_image'
        case Mimetype.VIDEO_MP4:
        case Mimetype.VIDEO_OGG:
        case Mimetype.VIDEO_QUICKTIME:
        case Mimetype.VIDEO_AVI:
        case Mimetype.VIDEO_M3GP:
        case Mimetype.VIDEO_MS_AVI:
        case Mimetype.VIDEO_WMV:
            return 'video'
        default:
            return mimetype
    }
}
