import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'

import {Step, Segment} from 'semantic-ui-react'
import ServiceContext from '../../../context/ServiceContext'
import { Compilatie } from "@ahsmediatheek/ada-lib";
import WelkomPage from "./pages/WelkomPage";
import NaamPage from "./pages/NaamPage";
import AfrondingPage from "./pages/AfrondingPage";
import {ComponentIdentifier} from "../../../component/ComponentIdentifier";

const STEP_WELKOM = 'welkom'
const STEP_NAAM = 'naam'
const STEP_AFRONDING = 'afronding'

const STEPS = [
    {name: STEP_WELKOM, title: 'WELKOM', infoKey: 'NB_WIZARD_CONTENT_WELKOM'},
    {name: STEP_NAAM, title: 'TITEL_EN_BESCHRIJVING', infoKey: 'NB_WIZARD_CONTENT_NAAM'},
    {name: STEP_AFRONDING, title: 'AFRONDING', infoKey: 'NB_WIZARD_CONTENT_AFRONDING'},
]

const NieuweCompilatieWizard = ({onMaakCompilatie}) => {

    const serviceContext = useContext(ServiceContext)

    const [activeStep, setActiveStep] = useState(STEPS[0])
    const [wizardCompilatie, setWizardCompilatie] = useState()
    const [opleidingen, setOpleidingen] = useState()

    useEffect(() => {
        if (!wizardCompilatie) {
            const compilatie = new Compilatie()
            compilatie.aangemaakt_door = serviceContext.privilegeManager.adaUser.email
            compilatie.academiejaar = serviceContext.arteveldeApiService.getCurrentAcademiejaar()
            compilatie.defaultReferentiebeeld = serviceContext.applicationPath.geen_referentiebeeld()
            
            setWizardCompilatie(compilatie)
        }
    }, [serviceContext.arteveldeApiService, wizardCompilatie])

    useEffect(() => {
        if (!wizardCompilatie) return

        serviceContext.arteveldeApiService.getOpleidingen(wizardCompilatie.academiejaar).then(res => {
            setOpleidingen(res)
        })

    }, [wizardCompilatie, serviceContext.arteveldeApiService])

    const onStepClick = (event, {name}) => {
        const as = STEPS.reduce((acc, step) => step.name === name ? step : acc)
        setActiveStep(as)
    }

    const onNavigationClick = (direction) => {
        const currIndex = STEPS.indexOf(activeStep)
        setActiveStep(STEPS[currIndex + direction])
    }

    const onChangeProp = (event, {name, value}) => {
        setWizardCompilatie({...wizardCompilatie, [name]: value})
    }

    const onMaakCompilatieButtonClick = () => {
        onMaakCompilatie(wizardCompilatie)
    }

    return (
        <ServiceContext.Provider value={{
            ...serviceContext,
            wizardCompilatie,
            opleidingen,
            STEPS,
            activeStep,
            onNavigationClick,
            onChangeProp,
            onMaakCompilatieButtonClick,
        }}>
            <Fragment>
                <ComponentIdentifier displayName='NieuweCompilatieWizard'/>

                <div className='main-content'>
                    <Step.Group size='mini' attached='top'>

                        {STEPS.map((step, index) => {
                            return <Fragment key={index}><Step name={step.name} active={activeStep.name === step.name} onClick={onStepClick}>
                                <Step.Content>
                                    <Step.Title>{serviceContext.localeManager.getString(step.title)}</Step.Title>
                                </Step.Content>
                            </Step></Fragment>
                        })}


                    </Step.Group>

                    <Segment attached>
                        {activeStep.name === STEP_WELKOM && <WelkomPage/>}
                        {activeStep.name === STEP_NAAM && <NaamPage/>}
                        {activeStep.name === STEP_AFRONDING && <AfrondingPage/>}
                    </Segment>
                </div>
            </Fragment>
        </ServiceContext.Provider>
    )
}

export default NieuweCompilatieWizard

NieuweCompilatieWizard.propTypes = {
    onAddCompilatie: PropTypes.func
}

NieuweCompilatieWizard.defaultProps = {}
