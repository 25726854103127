import React, {useState, useEffect, useRef, useLayoutEffect} from 'react'
import moment from 'moment'
import {
    Switch,
    Route,
    Redirect,
    useHistory,
} from "react-router-dom"


import {
    ApplicationPath, ApplicationMode,
    ApplicationFooter,
    AdaUserService,
    BundelService,
    CollectieService,
    CompilatieService,
    AuthenticationService,
    LocaleDataService,
    ArteveldeApiService,
    MetadataService,
    ApplicationService,
    ToegangsticketService,
    ZoekService,
    MailService,
    LocaleManager,
    AdaUserLink,
    AdaUserLinkRef,
    AdaUserRol,
    PrivilegeManager,
    StatusManager,
    ActiviteitService,
    ActiviteitType,
    AdaUserPref,
    DateUtil,
    LocalStorageKeys,
    CollectieSettings,
    CompilatieSettings
} from "@ahsmediatheek/ada-lib"

import Home from "./view/Home"
import CompilatieView from "./view/CompilatieView"
import CollectieView from "./view/CollectieView"
import MetadataManager from './view/admin/MetaDataManager'
//import AdminView from './view/admin/AdminView'
import ServiceContext from './context/ServiceContext'

import "@ahsmediatheek/ada-lib/build/index.css"
import BundelView from "./view/BundelView";
import NieuweCollectieWizard from "./view/collectie/nieuweCollectieWizard/NieuweCollectieWizard";
import NieuweCompilatieWizard from "./view/compilatie/nieuweCompilatieWizard/NieuweCompilatieWizard";

import version from './version'
import ApplicationHeader from "./component/ApplicationHeader";
import PilotAccessView from "./view/PilotAccessView";
import AdminView from "./view/admin/AdminView";
import AdaUserHomeView from "./view/AdaUserHomeView";
import SuperAdminLogin from "./component/debug/SuperAdminLogin";
import ToegangsticketView from "./view/ToegangsticketView";
import CwCopyright from "./view/collectie/nieuweCollectieWizard/pages/CwCopyright";
import { Message , Icon } from 'semantic-ui-react'


const {detect} = require('detect-browser')
const browser = detect()

const VIEW_PILOT_ACCESS = '/pilotAccess'
const VIEW_HOME = '/home'
const VIEW_COMPILATIE = '/compilatie'
const VIEW_COLLECTIE = '/collectie'
const VIEW_BUNDEL = '/bundel'
const VIEW_TOEGANGSTICKET = '/toegangsticket'
//const VIEW_ITEM = '/item'
const VIEW_ADMIN = '/admin'
const METADATA_MANAGER = '/metadata'
const VIEW_MIJN_STARTPAGINA = '/mijnStartpagina'
const VIEW_NIEUWE_COLLECTIE_WIZARD = 'nieuweCollectieWizard'
const VIEW_NIEUWE_COMPILATIE_WIZARD = 'nieuweCompilatieWizard'

const isLocalHost = window.location.hostname === "localhost"

const TREFWOORDEN_CONSUMER_KEY = '3d2bb2a1-fe92-4883-96ea-c99a7c037eb8'

const DISPLAY_TILE = 'tile'
const DISPLAY_LIST = 'list'

const App = ({ serverMode }) => {
    const [applicationPath, setApplicationPath] = useState()
    const [applicationInitialized, setApplicationInitialized] = useState(false)
    const [adaConfig, setAdaConfig] = useState()

    const [pilotAccess] = useState(false)
    const [devLoginId, setDevLoginId] = useState(0)
    const [adminEnabled, setAdminEnabled] = useState()

    const [view, setView] = useState()
    const [applicationService, setApplicationService] = useState()
    const [authenticationService, setAuthenticationService] = useState()
    const [adaUserService, setAdaUserService] = useState()
    const [bundelService, setBundelService] = useState()
    const [compilatieService, setCompilatieService] = useState()
    const [compilatieLocaleDataService, setCompilatieLocaleDataService] = useState()
    const [collectieService, setCollectieService] = useState()
    const [collectieLocaleDataService, setCollectieLocaleDataService] = useState()
    const [bundelLocaleDataService, setBundelLocaleDataService] = useState()
    const [adaUserLocaleDataService, setAdaUserLocaleDataService] = useState()
    const [infoLocaleDataService, setInfoLocaleDataService] = useState()
    const [arteveldeApiService, setArteveldeApiService] = useState()
    const [activiteitService, setActiviteitService] = useState()
    const [metadataService, setMetadataService] = useState()
    const [toegangsticketService, setToegangsticketService] = useState()
    const [zoekService, setZoekService] = useState()
    const [mailService, setMailService] = useState()

    const [localeManager, setLocaleManager] = useState()
    const [locale, setLocale] = useState('nl')
    const [showInfoButtons] = useState(false)
    //const [mainMenuOptions, setMainMenuOptions] = useState()
    //const [userMenuOptions, setUserMenuOptions] = useState()
    //const [nieuweCollectieModalOpen, setNieuweCollectieModalOpen] = useState(false)
    const [privilegeManager, setPrivilegeManager] = useState()
    const [statusManager, setStatusManager] = useState()
    const [infoEditable, setInfoEditable] = useState(false)
    const [componentIdentifierActive, setComponentIdentifierActive] = useState(false)

    //IMPORTANT!
    const [cachedZoekdata, setCachedZoekdata] = useState()
    const [cachedFilterData, setCachedFilterData] = useState()

    //const [mainMenu] = useState(['Collecties', 'Aanvraag toegangsticket'])
    //const [userMenu] = useState(['Mijn collecties', 'Mijn lijsten', 'Mijn bibliografie', 'Mijn toegangstickets', 'Mijn notificaties', 'Mijn profiel', 'Uitloggen'])
    const [resultContainerAvailableWidth, setResultContainerAvailableWidth] = useState()

    const [bundelFilterText, setBundelFilterText] = useState('')
    const [filterGemarkeerd, setFilterGemarkeerd] = useState(false)

    const [toegangsticket, setToegangsticket] = useState()

    const [resultDisplay, setResultDisplay] = useState(DISPLAY_TILE)

    const [copyrightsAct, setCopyrightsAct] = useState(false)

    const containerRef = useRef()

    //ROUTER
    const history = useHistory()

    //DEV
    useEffect(() => {
        const ap = new ApplicationPath(serverMode)
        setApplicationPath(ap)
        setApplicationService(new ApplicationService(ap))
        setActiviteitService(new ActiviteitService(ap))
        setZoekService(new ZoekService(ap))
        //console.log('SERVER MODE', serverMode)
        //console.log('APPLICATION PATH', ap)
        //console.log('APPLICATION PATH PATHS', ap.getPaths())
    }, [])

    //DISPLAY
    useEffect(() => {
        const display = localStorage.getItem('ada_display')
        if (display) setResultDisplay(display)
    }, [])

    //RESIZE HANDLING
    useEffect(() => {
        window.addEventListener('resize', onResize)
        return () => window.removeEventListener('resize', onResize)
    }, [])

    useLayoutEffect(() => {
        if (!containerRef.current) return
        setResultContainerAvailableWidth(containerRef.current.offsetWidth)
    }, [])

    //DEV LOGIN
    useEffect(() => {
        if (!isLocalHost) return
        setDevLoginId(parseInt(localStorage.getItem('ada_dev_login_id')))
    }, [])

    useEffect(() => {
        if (!copyrightsAct) return
        setTimeout(() => setCopyrightsAct(false), 30000)
    }, [copyrightsAct])

    //APPLICATION
    useEffect(() => {
        if (!applicationService) return

        applicationService.getConfig().then(config => {
            setAdaConfig(config)
            setAuthenticationService(new AuthenticationService('ada-desktop'))
        })
    }, [applicationService])

    //PRIVILEGE MANAGER
    useEffect(() => {

        if (applicationInitialized) return

        if (!compilatieService || !collectieService || !bundelService || !adaUserService || !arteveldeApiService || !localeManager || !authenticationService) return

        adaUserService.getLoggedInAdaUser().then(res => {

            setApplicationInitialized(true)
            console.info('Application initialized, creating PM')

            const privilegeManager = new PrivilegeManager(collectieService, bundelService, adaUserService, arteveldeApiService, localeManager)
            privilegeManager.adminEnabled = (localStorage.getItem(LocalStorageKeys.ADMIN_ACTIVE) === 'true')
            if (mailService) mailService.privilegeManager = privilegeManager
            //console.log('LOC ST', localStorage, localStorage.getItem(LocalStorageKeys.ADMIN_ACTIVE), (localStorage.getItem(LocalStorageKeys.ADMIN_ACTIVE)  === 'true'), privilegeManager.adminEnabled)

            const statusManager = new StatusManager(localeManager)

            if (!res) {
                if (isLocalHost) {
                    authenticationService.setToken(authenticationService.token)
                    if (devLoginId && devLoginId > 0) {
                        adaUserService.getAdaUser(devLoginId).then(res => {
                            privilegeManager.setAdaUser(res)
                            setPrivilegeManager(privilegeManager)
                        })
                    } else {
                        setPrivilegeManager(privilegeManager)
                    }
                } else {
                    privilegeManager.setAdaUser(null)
                    setPrivilegeManager(privilegeManager)
                }
            } else {
                authenticationService.setToken(res.token)
                privilegeManager.setAdaUser(res.adaUser)
                setPrivilegeManager(privilegeManager)
                res.adaUser.numLogins++
                res.adaUser.lastLogin = DateUtil.getNowMysql()
                adaUserService.updateAdaUser(res.adaUser)
            }


            setStatusManager(statusManager)
        })
    }, [compilatieService, collectieService, bundelService, adaUserService, arteveldeApiService, localeManager, authenticationService, devLoginId, adminEnabled])

    //INFO LOCALE DATA SERVICE
    useEffect(() => {
        if (!privilegeManager || !infoLocaleDataService) return
        infoLocaleDataService.loginUser = privilegeManager.adaUser

    }, [privilegeManager, infoLocaleDataService])

    //AUTHENTICATION
    useEffect(() => {

        if (!authenticationService || !applicationPath) return

        const localeManager = new LocaleManager(applicationPath.locale_files())
        localeManager.init(locale).then(() => setLocaleManager(localeManager))


        authenticationService.authenticate().then(res => {


            setArteveldeApiService(new ArteveldeApiService(authenticationService, applicationPath))

            const adaUserService = new AdaUserService(authenticationService, applicationPath)
            setAdaUserService(adaUserService)

            const bundelService = new BundelService(authenticationService, adaUserService, applicationPath)
            setBundelService(bundelService)

            const collectieService = new CollectieService(authenticationService, adaUserService, applicationPath)
            setCollectieService(collectieService)

            const compilatieService = new CompilatieService(authenticationService, adaUserService, applicationPath)
            setCompilatieService(compilatieService)

            /*const zoekService = new ZoekService(authenticationService, applicationPath)
            setZoekService(zoekService)*/

            setToegangsticketService(new ToegangsticketService(authenticationService, adaUserService, collectieService, bundelService, applicationPath))
            setMailService(new MailService(authenticationService, adaUserService, collectieService, bundelService, applicationPath, localeManager))

            setMetadataService(new MetadataService(authenticationService, applicationService, applicationPath))

            //setEmbargoService(new EmbargoService(authenticationService))
            setCompilatieLocaleDataService(new LocaleDataService(authenticationService, applicationPath, 'compilatie', ['titel', 'subtitel', 'beschrijving']))
            setCollectieLocaleDataService(new LocaleDataService(authenticationService, applicationPath, 'collectie', ['titel', 'subtitel', 'beschrijving']))
            setBundelLocaleDataService(new LocaleDataService(authenticationService, applicationPath, 'bundel', ['titel', 'subtitel', 'abstract']))
            setInfoLocaleDataService(new LocaleDataService(authenticationService, applicationPath, 'info', ['info']))
            setAdaUserLocaleDataService(new LocaleDataService(authenticationService, applicationPath, 'adaUser', ['profiel']))

            //console.log('UUID', bundelService.generateUuid())

        })
    }, [authenticationService, applicationService, applicationPath, locale])

    //PREFS
    useEffect(() => {
        if (!privilegeManager || !privilegeManager.adaUser || !localeManager) return

        const localePref = adaUserService.getPref(privilegeManager.adaUser, AdaUserPref.LOCALE)
        if (!localePref) {
            adaUserService.setPref(privilegeManager.adaUser, AdaUserPref.LOCALE, localeManager.locale)
        } else {
            if (localePref !== locale) {
                //onChangeLocale(localePref)
            }
        }

    }, [privilegeManager, locale, adaUserService, localeManager])

    //LOCAL STORAGE
    useEffect(() => {
        setInfoEditable(localStorage.getItem(LocalStorageKeys.INFO_EDITABLE) === 'true')
        setComponentIdentifierActive(localStorage.getItem(LocalStorageKeys.COMPONENT_IDENTIFIER_ACTIVE) === 'true')
        setLocale(localStorage.getItem(LocalStorageKeys.LOCALE) || 'nl')
        setAdminEnabled(localStorage.getItem(LocalStorageKeys.ADMIN_ACTIVE) === 'true')
    }, [])

    const onSuperAdminLoginClick = (login) => {
        setDevLoginId(login.id)
        localStorage.setItem('ada_dev_login_id', login.id)
        document.location.reload()
    }

    const onResize = () => {
        setResultContainerAvailableWidth(containerRef.current.offsetWidth)
    }

    const onCollectieClick = (collectie, fromPage) => {
        /*return
        setView(VIEW_COLLECTIE)
        setSelectedCollectie(collectie)*/

    }

    const onMainMenuClick = (item) => {
        setView(null)
        /*console.log('CLICK MAIN MENU', item)

        switch (item) {
            case VIEW_HOME:
                setView(VIEW_HOME)
                break
            case VIEW_ADMIN:
                setView(VIEW_ADMIN)
                break
            case VIEW_MIJN_STARTPAGINA:
                setView(VIEW_MIJN_STARTPAGINA)
                break
            default:
                break
        }*/
    }

    const onUserMenuClick = (item) => {
        console.log('CLICK USER MENU', item, privilegeManager, privilegeManager.adminEnabled)

        switch (item) {
            case 'canEditInfo':
                setInfoEditable(!infoEditable)
                localStorage.setItem(LocalStorageKeys.INFO_EDITABLE, !infoEditable)
                break
            case 'componentIdentifierActive':
                setComponentIdentifierActive(!componentIdentifierActive)
                localStorage.setItem(LocalStorageKeys.COMPONENT_IDENTIFIER_ACTIVE, !componentIdentifierActive)
                break
            case 'toggleAdminRole':
                //privilegeManager.adminEnabled = !privilegeManager.adminEnabled
                //setPrivilegeManager({...privilegeManager})
                //setDevLoginId(devLoginId)
                setAdminEnabled(!adminEnabled)
                localStorage.setItem(LocalStorageKeys.ADMIN_ACTIVE, !adminEnabled)
                history.push('/home')
                document.location.reload()
            default:
                setView(null)
                break
        }
    }

    const openNieuweCollectieWizard = () => {
        setView(VIEW_NIEUWE_COLLECTIE_WIZARD)
    }

    const openNieuweCompilatieWizard = () => {
        setView(VIEW_NIEUWE_COMPILATIE_WIZARD)
    }

    const onMaakCollectie = (collectie, inzenders) => {
        console.log('MAAK COLLECTIE', collectie, inzenders)

        collectie.defaultReferentiebeeld = applicationPath.geen_referentiebeeld()


        const adaUserLink = new AdaUserLink()
        adaUserLink.adaUser = privilegeManager.adaUser.id
        adaUserLink.ref = AdaUserLinkRef.COLLECTIE
        adaUserLink.rol = AdaUserRol.COLLECTIE_BEHEERDER

        //COLLECTIE MAKEN
        collectieService.createCollectie(collectie).then(coll => {
            if(coll.id === null) {
                alert("Er ging iets fout bij het aanmaken van deze collectie.")
            }

            //TODO: instelling minimum aantal trefwoorden standaard aan zetten
            collectieService.setCollectieSetting(coll, CollectieSettings.VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN, 1)
            collectieService.setCollectieSetting(coll, CollectieSettings.MINIMUUM_AANTAL_TREFWOORDEN, 2)
            collectieService.updateCollectie(coll)

            coll.newlyCreated = true

            //LOCALE DATA OPHALEN
            collectieLocaleDataService.getLocaleData(coll.id).then(({localeContent}) => {
                for (const lData of localeContent) {
                    lData.content = coll[lData.field + '_' + lData.locale]
                }
                collectieLocaleDataService.saveLocaleData(localeContent).then(res => {
                    const promises = []
                    //INZENDERS
                    let inzenderAdaUserLink
                    for (const inzender of inzenders) {
                        inzenderAdaUserLink = new AdaUserLink()
                        inzenderAdaUserLink.adaUser = inzender.id
                        inzenderAdaUserLink.ref = AdaUserLinkRef.COLLECTIE
                        inzenderAdaUserLink.refId = coll.id
                        inzenderAdaUserLink.rol = AdaUserRol.INZENDER
                        promises.push(adaUserService.createAdaUserLink(inzenderAdaUserLink))
                    }
                    //ADA USERS
                    adaUserLink.refId = coll.id
                    promises.push(adaUserService.createAdaUserLink(adaUserLink))
                    promises.push(activiteitService.createActiviteit(privilegeManager.adaUser, ActiviteitType.COLLECTIE_AANGEMAAKT, coll.id))

                    //GO
                    Promise.all(promises).then(results => {
                        console.log('COLL CREATED', results)
                        history.push('/collectie/' + coll.uuid)
                        setView(null)
                    })
                })
            })
        })
    }

    const onMaakCompilatie = (compilatie) => {
        console.log('MAAK COMPILATIE', compilatie)

        compilatie.defaultReferentiebeeld = applicationPath.geen_referentiebeeld()

        const adaUserLink = new AdaUserLink()
        adaUserLink.adaUser = privilegeManager.adaUser.id
        adaUserLink.ref = AdaUserLinkRef.COMPILATIE
        adaUserLink.rol = AdaUserRol.EIGENAAR

        //COMPILATIE MAKEN
        compilatieService.createCompilatie(compilatie).then(comp => {

            //TODO: instelling minimum aantal trefwoorden standaard aan zetten
            compilatieService.setCompilatieSetting(comp, CompilatieSettings.VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN, 1)
            compilatieService.setCompilatieSetting(comp, CompilatieSettings.MINIMUUM_AANTAL_TREFWOORDEN, 2)
            compilatieService.updateCompilatie(comp)

            comp.newlyCreated = true

            //LOCALE DATA OPHALEN
            compilatieLocaleDataService.getLocaleData(comp.id).then(({localeContent}) => {
                for (const lData of localeContent) {
                    lData.content = comp[lData.field + '_' + lData.locale]
                }
                compilatieLocaleDataService.saveLocaleData(localeContent).then(res => {
                    //ADA USERS
                    adaUserLink.refId = comp.id

                    //GO
                    Promise.all([
                        adaUserService.createAdaUserLink(adaUserLink),
                        activiteitService.createActiviteit(privilegeManager.adaUser, ActiviteitType.COMPILATIE_AANGEMAAKT, comp.id)
                    ]).then(results => {
                        console.log('COMP CREATED', results)
                        history.push('/compilatie/' + comp.uuid)
                        setView(null)
                    })
                })
            })
        })
    }

    /*const onNieuweCollectieModalClose = () => {
        setNieuweCollectieModalOpen(false)
    }*/

    const onChangeLocale = (locale) => {
        localeManager.loadLocale(locale).then(res => {
            //privilegeManager.adaUser && adaUserService.setPref(privilegeManager.adaUser, AdaUserPref.LOCALE, locale)
            localStorage.setItem(LocalStorageKeys.LOCALE, locale)
            setLocaleManager({...res})
            setLocale(locale)
        })
    }

    const onLoginClick = () => {
        adaUserService.loginAhs()
    }

    const onLogoutClick = () => {
        setView(VIEW_HOME)
        adaUserService.logoutAhs()
    }


    const onDeleteCollectie = (collectie, bundels) => {
        console.log('DELETE COLLECTIE', collectie, bundels)
        if (bundels.length > 0) {
            window.alert('Deze collectie bevat nog bundels. Gelieve eerst alle bundels te verwijderen')
            return
        }

        Promise.all([
            adaUserService.deleteAdaUserLinks(collectie),
            collectieLocaleDataService.deleteLocaleData(collectie.id),
            collectieService.deleteCollectie(collectie),

        ]).then((...results) => {
            console.log('DELETE RESULTS', results)
            history.push('/home')
        })

    }

    return (
        <ServiceContext.Provider value={{
            adaConfig,
            browser,
            applicationPath,
            authenticationService,
            applicationService,
            adaUserService,
            bundelService,
            collectieService,
            compilatieService,
            activiteitService,
            metadataService,
            resultContainerAvailableWidth,
            localeManager,
            collectieLocaleDataService,
            compilatieLocaleDataService,
            bundelLocaleDataService,
            infoLocaleDataService,
            adaUserLocaleDataService,
            arteveldeApiService,
            toegangsticketService,
            zoekService,
            mailService,
            toegangsticket,
            showInfoButtons,
            privilegeManager,
            statusManager,
            infoEditable, componentIdentifierActive,
            adminEnabled,
            onCollectieClick,
            onDeleteCollectie,
            bundelFilterText, setBundelFilterText,
            filterGemarkeerd, setFilterGemarkeerd,
            pilotAccess,
            openNieuweCollectieWizard,
            openNieuweCompilatieWizard,
            TREFWOORDEN_CONSUMER_KEY,
            cachedZoekdata, setCachedZoekdata,
            cachedFilterData, setCachedFilterData,
            resultDisplay, setResultDisplay
        }}>


            <div ref={containerRef} style={{display: 'flex', flexDirection: 'column'}}>
                {isLocalHost && <SuperAdminLogin onLoginClick={onSuperAdminLoginClick} devLoginId={devLoginId}/>}

                {serverMode !== ApplicationMode.LIVE && <div style={{backgroundColor: serverMode === ApplicationMode.DEV ? 'red' : 'teal'}} className='dev'>
                    Let op: U bent in {serverMode} omgeving!</div>}
                    
                {localeManager && privilegeManager &&
                <ApplicationHeader showMainMenu={true}
                                   infoEditable={infoEditable}
                                   componentIdentifierActive={componentIdentifierActive}
                                   privilegeManager={privilegeManager}
                                   view={view}
                                   localeManager={localeManager}
                                   onLoginClick={onLoginClick}
                                   onLogoutClick={onLogoutClick}
                                   locale={locale}
                                   onChangeLocale={onChangeLocale}
                                   onMainMenuClick={onMainMenuClick}
                                   onUserMenuClick={onUserMenuClick}
                                   onLogoClick={() => setView(VIEW_HOME)}
                />}
                {/*
                browserName && browserName === 'Edge' &&
                  <Message icon warning>
                    <Icon name='edge' />
                    <Message.Content>
                        Er zijn momenteel inlogproblemen met Microsoft Edge in het Digitaal Archief. 
                        Probeer u aan te melden in een privévenster of een andere browser.
                        We werken zo snel mogelijk aan een oplossing.
                    </Message.Content>
                    </Message>
                */}
                {!applicationInitialized && <div>Laden...</div>}

                {!view && <Switch>

                    <Route path={VIEW_ADMIN}>
                        {bundelService && collectieService && localeManager && pilotAccess &&
                        <AdminView />
                        }
                    </Route>

                    <Route path={METADATA_MANAGER}>  
                    {privilegeManager && privilegeManager.isAdmin() &&
                        <MetadataManager adaUser={privilegeManager.adaUser} /> 
                    }                      
                                              
                    </Route>
                    <Route path={VIEW_MIJN_STARTPAGINA}>
                        {privilegeManager && privilegeManager.adaUser &&
                        <AdaUserHomeView adaUser={privilegeManager.adaUser}/>}
                    </Route>

                    <Route path={VIEW_HOME}>
                        {bundelService && collectieService && localeManager && privilegeManager &&
                        <Home onCollectieClick={onCollectieClick}/>
                        }
                    </Route>

                    <Route path={`${VIEW_TOEGANGSTICKET}/:uuid`}>
                        {bundelService && collectieService && localeManager && toegangsticketService && privilegeManager &&
                        <ToegangsticketView setToegangsticket={setToegangsticket}/>
                        }
                    </Route>

                    <Route path={`${VIEW_COLLECTIE}/:uuid`}>
                        {bundelService && collectieService && localeManager &&
                        <CollectieView/>
                        }
                    </Route>

                    <Route path={`${VIEW_COMPILATIE}/:uuid`}>
                        {compilatieService && localeManager &&
                        <CompilatieView/>
                        }
                    </Route>

                    <Route path={`${VIEW_BUNDEL}/:uuid`}>
                        {bundelService && collectieService &&
                        <BundelView/>
                        }
                    </Route>

                    <Route path='/'>
                        <Redirect to={VIEW_HOME}/>
                    </Route>

                </Switch>}

                {view === VIEW_PILOT_ACCESS && adaUserService && localeManager && privilegeManager &&
                <PilotAccessView adaUserService={adaUserService} privilegeManager={privilegeManager}/>
                }

                {localeManager && view === VIEW_NIEUWE_COLLECTIE_WIZARD && <NieuweCollectieWizard onMaakCollectie={onMaakCollectie}/>}

                {localeManager && view === VIEW_NIEUWE_COMPILATIE_WIZARD && <NieuweCompilatieWizard onMaakCompilatie={onMaakCompilatie}/>}


                {localeManager && <ApplicationFooter

                    contentCol2={
                        <div>
                            <h3>{localeManager.getString('SNEL_NAAR').toUpperCase()}</h3>
                            <p><a href={ApplicationPath.WEBSITE_MEDIATHEEK} target="_blank" rel='noopener noreferrer'>{localeManager.getString('WEBSITE_MEDIATHEEK')}</a></p>
                            <p><a href={ApplicationPath.WEBSITE_ARTEVELDE} target="_blank" rel='noopener noreferrer'>{localeManager.getString('WEBSITE_ARTEVELDE')}</a></p>
                        </div>
                    }

                    contentCol3={
                        <div onClick={(e) => !copyrightsAct && setCopyrightsAct(e.shiftKey)}>
                            <h3>{localeManager.getString('ARTEVELDE_DIGITAAL_ARCHIEF').toUpperCase()}</h3>
                            <p>© {moment().format('YYYY')} Arteveldehogeschool - v{version}</p>

                            {copyrightsAct && <CwCopyright/>}
                        </div>
                    }/>}


            </div>
        </ServiceContext.Provider>
    )
}

export default App
