import React, {useState, useEffect, useCallback, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Menu, Image} from "semantic-ui-react";
import {AdaUserIcon, AdaUserRol} from "@ahsmediatheek/ada-lib";
import ServiceContext from "../context/ServiceContext";
import AdaUserCollecties from "./adaUser/AdaUserCollecties";
import AdaUserCompilaties from "./adaUser/AdaUserCompilaties";
import AdaUserBundels from "./adaUser/AdaUserBundels";
import AdaUserSettingsMain from "./adaUser/AdaUserSettingsMain";
import {ComponentIdentifier} from "../component/ComponentIdentifier";

const VIEW_COMPILATIES = 'compilaties'
const VIEW_COLLECTIES = 'collecties'
const VIEW_BUNDELS = 'bundels'
const VIEW_INSTELLINGEN = 'instellingen'

const AdaUserHomeView = ({adaUser}) => {

    const [adaUserBanner, setAdaUserBanner] = useState()
    const [view, setView] = useState(VIEW_COLLECTIES)
    const [compilaties, setCompilaties] = useState()
    const [collecties, setCollecties] = useState()
    const [bundels, setBundels] = useState()


    const serviceContext = useContext(ServiceContext)

    const resolveAdaUserBanner = useCallback(() => {
        return !adaUser.adaUserBanner || adaUser.adaUserBanner === ''
            ? serviceContext.applicationPath.ada_user_banner_default()
            : serviceContext.applicationPath.ada_user_banners() + adaUser.adaUserBanner + '.jpg?d=' + new Date().getTime()
    }, [adaUser])

    useEffect(() => {

        setAdaUserBanner(resolveAdaUserBanner())

        //async
        Promise.all([
            serviceContext.adaUserService.getAdaUserLinksForAdaUser(adaUser),
            serviceContext.adaUserLocaleDataService.getLocaleData(adaUser.id),
        ])
            .then(([adaUserAdaUserLinks, localeContentResponse]) => {

                Promise.all([
                        serviceContext.compilatieService.getCompilaties(adaUserAdaUserLinks.reduce((ids, aul) => {
                            if (aul.ref === 'compilatie' && !ids.includes(aul.refId)) ids.push(aul.refId)
                            return ids
                        }, [])),
                        serviceContext.collectieService.getCollecties(adaUserAdaUserLinks.reduce((ids, aul) => {
                            //enkel collecties waar ik beheerder of validator van ben!
                            if (aul.ref === 'collectie'
                                && !ids.includes(aul.refId)
                                && (aul.rol === AdaUserRol.COLLECTIE_BEHEERDER || aul.rol === AdaUserRol.VALIDATOR))
                                ids.push(aul.refId)
                            return ids
                        }, [])),
                        serviceContext.bundelService.getBundels(adaUserAdaUserLinks.reduce((ids, aul) => {
                            if (aul.ref === 'bundel' && !ids.includes(aul.refId)) ids.push(aul.refId)
                            return ids
                        }, []))
                    ]
                ).then(([compilaties, collecties, bundels]) => {
                    setCompilaties(compilaties)
                    setCollecties(collecties)
                    setBundels(bundels)
                })
            }).catch(error => console.log('PA ERROR', error))

    }, [adaUser, serviceContext.adaUserService, serviceContext.adaUserLocaleDataService, serviceContext.bundelService, serviceContext.collectieService, resolveAdaUserBanner])


    const getUserNaam = () => {
        return `${adaUser.voornaam} ${adaUser.familienaam}`
    }

    const onMenuChange = (event, {name}) => {
        setView(name)
    }

    const onAdaUserBannerUploaded = (adaUser, callback) => {
        serviceContext.adaUserService.updateAdaUser(adaUser).then(res => {
            setAdaUserBanner(resolveAdaUserBanner())
            serviceContext.privilegeManager.setAdaUser({...adaUser})
            callback()
        })
    }

    if (!serviceContext.localeManager) return null

    return (
        <ServiceContext.Provider value={{
            ...serviceContext, onAdaUserBannerUploaded
        }}><Fragment>
            <ComponentIdentifier displayName='AdaUserHomeView'/>

            <div className='detail-view-header'
                 style={{
                     backgroundImage: `url(${adaUserBanner})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center center',
                     backgroundRepeat: 'no-repeat',
                 }}>
                <div className='detail-view-titel'>
                    <AdaUserIcon style={{flexShrink: 0}}/>
                    <div style={{marginLeft: '10px'}}>{getUserNaam()}</div>
                </div>

                <div className='banner-profielfoto'>
                    <Image size='small' src={adaUser.profielfoto}/>
                </div>

                <div className='detail-view-menu'>
                    <Menu pointing secondary inverted>
                        <Menu.Item
                            name={VIEW_COLLECTIES}
                            active={view === VIEW_COLLECTIES}
                            onClick={onMenuChange}
                        >{serviceContext.localeManager.getString('MIJN_COLLECTIES')}</Menu.Item>
                        <Menu.Item
                            name={VIEW_BUNDELS}
                            active={view === VIEW_BUNDELS}
                            onClick={onMenuChange}
                        >{serviceContext.localeManager.getString('MIJN_BUNDELS')}</Menu.Item>
                        <Menu.Item
                            name={VIEW_COMPILATIES}
                            active={view === VIEW_COMPILATIES}
                            onClick={onMenuChange}
                        >{serviceContext.localeManager.getString('MIJN_COMPILATIES')}</Menu.Item>
                        <Menu.Item
                            name={VIEW_INSTELLINGEN}
                            active={view === VIEW_INSTELLINGEN}
                            onClick={onMenuChange}
                        >{serviceContext.localeManager.getString('INSTELLINGEN')}</Menu.Item>
                    </Menu>
                </div>

                {(!adaUser.adaUserBanner || adaUser.adaUserBanner === '') && <div className='detail-view-message'><h3>{serviceContext.localeManager.getString('MOOIE_BANNER_INSTELLEN')}</h3></div>}
            </div>

            <div className='main-content'>

                {view === VIEW_COMPILATIES && compilaties && <AdaUserCompilaties compilaties={compilaties} onCompilatieSelect={serviceContext.onCompilatieClick}/>}
                {view === VIEW_COLLECTIES && collecties && <AdaUserCollecties collecties={collecties} onCollectieSelect={serviceContext.onCollectieClick}/>}
                {view === VIEW_BUNDELS && bundels && <AdaUserBundels bundels={bundels}/>}
                {view === VIEW_INSTELLINGEN && <AdaUserSettingsMain/>}
            </div>
        </Fragment>


        </ServiceContext.Provider>
    )
}

export default AdaUserHomeView

AdaUserHomeView.propTypes = {
    adaUser: PropTypes.object.isRequired
}

AdaUserHomeView.defaultProps = {}
