import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Icon} from 'semantic-ui-react'
import ServiceContext from '../../context/ServiceContext'
import {ApplicationPath, TrashIcon, ViewIcon, DownloadIcon, ReferentieitemIcon, TeKoopItemIcon, Mimetype, ContentViewer, MimetypeIcon_Illustrator} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "../ComponentIdentifier";

const DropzoneItem = ({item, bundel, onClick, onDoubleClick, selected, onDelete, onView, onDownload}) => {

    const [fileroot, setFileroot] = useState()
    const [downloadLink, setDownloadLink] = useState('')

    const [type, setType] = useState()
    const [source, setSource] = useState()
    const [filename, setFilename] = useState()
    const [icon, setIcon] = useState()
    const [referentiebestand, setReferentiebestand] = useState()
    const [teKoopItem, setTeKoopItem] = useState()

    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        const fileroot = serviceContext.applicationPath.bundel() + 'b_' + bundel.uuid + '/data'
        setFileroot(fileroot)
        setDownloadLink(fileroot + item.file.path) //.replace(/^\//g, ''))
    }, [bundel, item])

    useEffect(() => {
        if (!fileroot) return
        const referentiebestand = serviceContext.bundelService.bundelReferentiebestantdenLookup[bundel.id].reduce((rb, inst) => {
            if (inst.referentiebestand === item.file.path) return {...inst}
            return rb
        }, null)

        setReferentiebestand(referentiebestand)

    }, [fileroot, bundel, item, serviceContext.bundelService.bundelReferentiebestantdenLookup])

    useEffect(() => {
        if (!fileroot) return
        const teKoopItem = serviceContext.bundelService.bundelTeKoopItemsLookup[bundel.id].reduce((rb, inst) => {
            if (inst.bestand === item.file.path) return {...inst}
            return rb
        }, null)

        setTeKoopItem(teKoopItem)

    }, [fileroot, bundel, item, serviceContext.bundelService.bundelTeKoopItemsLookup])

    useEffect(() => {
        if (!fileroot) return
        const type = item.type //item.file.mimetype || Mimetype.DIRECTORY
        setType(type)

        if (type === Mimetype.DIRECTORY) {
            setSource(null)
        } else {
            setSource(fileroot + item.file.path)
        }

        setIcon(resolveIcon(item))

        const parts = item.file.path.split('/')
        const filename = parts[parts.length - 1].split('.')

        setFilename(referentiebestand && referentiebestand.alias !== '' ? referentiebestand.alias : filename[0])
    }, [fileroot, item, referentiebestand])

    const resolveIcon = (item) => {

        //console.log('RES ICON', item)
        if (!item.icon) return null
        return <item.icon size='5em' color='#555555'/>
        /*if (item.icon.includes('fab ')) return <i className={item.icon} style={{fontSize: '3em', color: 'grey'}}/>
        return <Icon name={item.icon} color='grey' size='huge'/>*/
    }


    return (
        <div className={`dropzone-item ${selected && 'selected'}`}
             style={{width: item.file.mimetype.includes('audio') ? '220px' : '200px'}}
             onClick={(event) => onClick && onClick(event, {item, type})}
             onDoubleClick={(event) => onDoubleClick && onDoubleClick(event, {item, type})}>

            <ComponentIdentifier displayName='DropzoneItem'/>

            {!icon && <ContentViewer streamingUrl={item.file.streamingUrl} mimetype={item.file.mimetype} path={source} filesize={item.file.filesize}>
                <ComponentIdentifier displayName='ContentViewer'/>
            </ContentViewer>}
        
            {icon}
       
            <div style={{width: '100%', marginTop: '10px', textAlign: 'center'}}><strong>{filename}</strong><br/><span>{type}</span></div>

            <div className='icon-group-top-right'>
                {selected &&
                <Fragment>
                    {onView && serviceContext.privilegeManager.canUploadBundelItems(serviceContext.bundelCollectieCollectie, bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks) &&
                    <ViewIcon className='icon-hoverable' onClick={(event) => onView(event, {item, type})}/>}

                    {onDelete && !item.isReferentiebestand && !item.hasReferentiebestand && serviceContext.privilegeManager.canUploadBundelItems(serviceContext.bundelCollectieCollectie, bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks) &&
                    <TrashIcon className='icon-hoverable' onClick={(event) => onDelete(event, {item, type})}/>}

                    {item.type !== 'directory' && <a href={downloadLink} download={item.file.name}><DownloadIcon className='icon-hoverable'/></a>}
                </Fragment>}
            </div>

            <div className='icon-group-bottom-left' style={{fontSize: '1.3em'}}>
                {(item.isReferentiebestand || item.hasReferentiebestand) && <ReferentieitemIcon size='small'/>}
                {(item.isTeKoopItem || item.hasTeKoopItem) && <TeKoopItemIcon size='small'/>}
            </div>
        </div>
    )
}

export default DropzoneItem

DropzoneItem.propTypes = {
    bundel: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    selected: PropTypes.bool,
    onDelete: PropTypes.func,
    onView: PropTypes.func,
    onDownload: PropTypes.func,
}

DropzoneItem.defaultProps = {
    selected: false
}
