import React, {useState, useEffect, useContext} from 'react'
import ServiceContext from '../../context/ServiceContext'
import {Form} from "semantic-ui-react"
import {LocaleContentViewer} from '@ahsmediatheek/med-component-locale-content'
//import '@ahsmediatheek/med-component-locale-content/build/index.css'
import {SaveBar, MetadataUtil} from "@ahsmediatheek/ada-lib"
import "@ahsmediatheek/ada-lib/build/index.css"
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const BundelSettingsGegevens = (props) => {

    const [isDirty, setIsDirty] = useState(false)

    const [tempBundel, setTempBundel] = useState()
    const [localeContent, setLocaleContent] = useState()
    const [changedLocales, setChangedLocales] = useState([])
    const [, setOpleidingen] = useState()

    const serviceContext = useContext(ServiceContext)

    //TEMP BUNDEL
    useEffect(() => {
        setTempBundel({...serviceContext.bundel})
    }, [serviceContext.bundel])

    //LOCALE DATA
    useEffect(() => {
        console.log(tempBundel , localeContent)
        if (!tempBundel) return

        setLocaleContent(serviceContext.localeContent.map(localeData => {
            const ldArr = []
            if (localeData.field === 'titel' && localeData.locale === 'nl' && localeData.content === '') {
                localeData.content = tempBundel.titel_nl
                ldArr.push(localeData)
            }
            if (localeData.field === 'titel' && localeData.locale === 'en' && localeData.content === '') {
                localeData.content = tempBundel.titel_en
                ldArr.push(localeData)
            }
            /* NOG TE BEKIJKEN EN VOLLEDIG TE FIXEN!*/
            if (localeData.field === 'subtitel' && localeData.locale === 'nl' && localeData.content === '') {
                localeData.content = tempBundel.subtitel_nl
                ldArr.push(localeData)
            }

            if (localeData.field === 'subtitel' && localeData.locale === 'en' && localeData.content === '') {
                localeData.content = tempBundel.subtitel_en
                ldArr.push(localeData)
            }
            /**/
            //serviceContext.localeDataService.saveLocaleData(ldArr)

            return {...localeData}
        }))
    }, [serviceContext.localeContent, tempBundel])

    useEffect(() => {
        if (!tempBundel || !tempBundel.academiejaar) return
        serviceContext.arteveldeApiService.getOpleidingen(tempBundel.academiejaar).then(res => {
            setOpleidingen(res)
        })
    }, [tempBundel, serviceContext.arteveldeApiService])

    const changeDirty = (value) => {
        setIsDirty(value)
        serviceContext.setIsDirty(value)
    }

    const onLocaleContentChange = (localeContentObject, data, plainText) => {
        console.log(localeContentObject, data, plainText)

        localeContentObject.content = data
        changeDirty(true)

        if (localeContentObject.field === 'titel' && localeContentObject.locale === 'nl') tempBundel.titel_nl = localeContentObject.content
        if (localeContentObject.field === 'titel' && localeContentObject.locale === 'en') tempBundel.titel_en = localeContentObject.content
        if (localeContentObject.field === 'subtitel' && localeContentObject.locale === 'nl') tempBundel.subtitel_nl = localeContentObject.content
        if (localeContentObject.field === 'subtitel' && localeContentObject.locale === 'en') tempBundel.subtitel_en = localeContentObject.content
        if (localeContentObject.field === 'abstract' && localeContentObject.locale === 'nl') tempBundel.abstract_nl = localeContentObject.content
        if (localeContentObject.field === 'abstract' && localeContentObject.locale === 'en') tempBundel.abstract_en = localeContentObject.content

        if (!changedLocales.includes(localeContentObject.id))
            changedLocales.push(localeContentObject.id)
    }

    /*const onChangeBundelProp = (prop, value) => {
        console.log('B PROP', prop, value)
        changeDirty(true)
        setTempBundel({...tempBundel, [prop]: value})
    }*/

    /*const onChangeAcademiejaar = (aj) => {
        changeDirty(true)

        tempBundel.opleiding = null
        tempBundel.academiejaar = aj
        serviceContext.arteveldeApiService.getOpleidingen(tempBundel.academiejaar).then(res => {
            console.log('OPLEIDINGEN', res)
            setOpleidingen(res)
        })

        setTempBundel({...tempBundel})
    }*/

   /* const kopieerMetadataVanCollectie = () => {
        changeDirty(true)
        tempBundel.academiejaar = serviceContext.bundelCollectieCollectie.academiejaar
        tempBundel.opleiding = serviceContext.bundelCollectieCollectie.opleiding
        setTempBundel({...tempBundel})
    }*/

    const onCancel = () => {
        changeDirty(false)
        setTempBundel({...serviceContext.bundel})
        setLocaleContent(serviceContext.localeContent.map(localeData => ({...localeData})))
    }

    const onSubmit = (...args) => {

        //checken of er een titel beschikbaar is!
        if(MetadataUtil.resolveTitel(tempBundel) === ''){
            window.alert(serviceContext.localeManager.getString('TITEL_MAG_NIET_LEEG_ZIJN'))
            return
        }

        changeDirty(false)
        let cLocales = localeContent.filter(item => changedLocales.includes(item.id))

        console.log('SAVING', serviceContext.onSaveBundel, tempBundel, cLocales)
        serviceContext.onSaveBundel && serviceContext.onSaveBundel(tempBundel, cLocales)
        setChangedLocales([])
    }


    return (
        <Form>
            <ComponentIdentifier displayName='BundelSettingsGegevens'/>

            {tempBundel && localeContent &&
            <Form.Field>
                <label>{serviceContext.localeManager.getString('TITEL')}</label>
                <LocaleContentViewer placeholder={serviceContext.localeManager.getString('TITEL')}
                                     localeContent={localeContent}
                                     referenceId={tempBundel.id.toString()}
                                     dataField='titel'
                                     noLocaleWarning={serviceContext.localeManager.getString('GEEN_CONTENT_VOOR_LOCALE')}
                                     editMode='plain'
                                     maxChars={200} optimalChars={30}
                                     maxCharsLabel={serviceContext.localeManager.getString('MAXIMUM_AANTAL_KARAKTERS')}
                                     optimalCharsLabel={serviceContext.localeManager.getString('OPTIMAAL_AANTAL_KARAKTERS')}
                                     onChange={onLocaleContentChange}/>
            </Form.Field>}

            {tempBundel && localeContent &&
            <Form.Field>
                {console.log(tempBundel)}
                <label>{serviceContext.localeManager.getString('SUBTITEL')}</label>
                <LocaleContentViewer placeholder={serviceContext.localeManager.getString('SUBTITEL')}
                                     localeContent={localeContent}
                                     referenceId={tempBundel.id.toString()}
                                     dataField='subtitel'
                                     noLocaleWarning={serviceContext.localeManager.getString('GEEN_CONTENT_VOOR_LOCALE')}
                                     editMode='plain'
                                     maxChars={200}
                                     maxCharsLabel={serviceContext.localeManager.getString('MAXIMUM_AANTAL_KARAKTERS')}
                                     optimalCharsLabel={serviceContext.localeManager.getString('OPTIMAAL_AANTAL_KARAKTERS')}
                                     onChange={onLocaleContentChange}/>
            </Form.Field>}

            {tempBundel && localeContent &&
            <Form.Field>
                <label>{serviceContext.localeManager.getString('ABSTRACT')}</label>
                <LocaleContentViewer placeholder={serviceContext.localeManager.getString('ABSTRACT')}
                                     localeContent={localeContent}
                                     textAreaHeight={200}
                                     referenceId={tempBundel.id.toString()}
                                     dataField='abstract'
                                     noLocaleWarning={serviceContext.localeManager.getString('GEEN_CONTENT_VOOR_LOCALE')}
                                     editMode='plain'
                                     maxCharsLabel={serviceContext.localeManager.getString('MAXIMUM_AANTAL_KARAKTERS')}
                                     optimalCharsLabel={serviceContext.localeManager.getString('OPTIMAAL_AANTAL_KARAKTERS')}
                                     onChange={onLocaleContentChange}/>
            </Form.Field>}


            {isDirty && <SaveBar cancelButtonLabel={serviceContext.localeManager.getString('ANNULEER')}
                                 saveButtonLabel={serviceContext.localeManager.getString('BEWAAR')}
                                 onCancelClick={onCancel} onSaveClick={onSubmit}/>}
        </Form>
    )
}

export default BundelSettingsGegevens
