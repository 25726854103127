import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {Button, Icon} from "semantic-ui-react";
import ServiceContext from '../../context/ServiceContext'

import ReactExport from "react-export-excel";
import {AdaUserLinkRef} from "@ahsmediatheek/ada-lib";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ExportXls = ({collectie, bundels, collectieAdaUserLinks}) => {

    const [collectieDataset, setCollectieDataset] = useState()
    const [bundelsDataset, setBundelsDataset] = useState()
    const [rolColumns, setRolColumns] = useState([])
    const [bundelRolColumns, setBundelRolColumns] = useState([])

    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        const dso = {...collectie}
        const rolColumns = []
        for (const collectieAdaUserLink of collectieAdaUserLinks) {
            if (!dso[collectieAdaUserLink.rol]) {
                dso[collectieAdaUserLink.rol] = []
                if (!rolColumns.includes(collectieAdaUserLink.rol)) rolColumns.push(collectieAdaUserLink.rol)
            }
            const au = serviceContext.adaUserService.adaUserLookup[collectieAdaUserLink.adaUser]
            dso[collectieAdaUserLink.rol].push(au.email)
        }
        setRolColumns(rolColumns)
        //dso[collectieAdaUserLink.rol] = dso[collectieAdaUserLink.rol].join(', ')
        for (const rolColumn of rolColumns) {
            dso[rolColumn] = dso[rolColumn].join(', ')
        }
        setCollectieDataset([dso])
    }, [collectie, collectieAdaUserLinks])

    useEffect(() => {

        const promises = []
        const bundelsDataset = bundels.map((bundel, index) => {
            const dso = {...bundel}
            dso.volgnummer = index + 1
            promises.push(serviceContext.adaUserService.getAdaUserLinks(AdaUserLinkRef.BUNDEL, bundel.id))
            return dso
        })

        Promise.all(promises).then(res => {
            const bundelRolColumns = []
            for (const bo of bundelsDataset) {
                const adaUserLinks = serviceContext.adaUserService.adaUserLinkLookup[AdaUserLinkRef.BUNDEL][bo.id]

                for (const adaUserLink of adaUserLinks) {
                    if (!bo[adaUserLink.rol]) {
                        bo[adaUserLink.rol] = []
                        if (!bundelRolColumns.includes(adaUserLink.rol)) bundelRolColumns.push(adaUserLink.rol)
                    }
                    const au = serviceContext.adaUserService.adaUserLookup[adaUserLink.adaUser]
                    bo[adaUserLink.rol].push(au.email)
                }

                for (const bundelRolColumn of bundelRolColumns) {
                    if (bo[bundelRolColumn]) bo[bundelRolColumn] = bo[bundelRolColumn].join(', ')
                }

                setBundelRolColumns(bundelRolColumns)
            }

            setBundelsDataset(bundelsDataset)

        })
    }, [bundels])

    if (!collectieDataset || !bundelsDataset) return null

    return (
        <ExcelFile element={<Button icon><Icon name='file excel'/>{serviceContext.localeManager.getString('EXPORTEER_XLS')}</Button>}
                   filename={collectie.titel_nl}>
            <ExcelSheet data={collectieDataset} name={serviceContext.localeManager.getString('COLLECTIE')}>
                <ExcelColumn label={serviceContext.localeManager.getString('TITEL')} value={`titel_${serviceContext.localeManager.locale}`}/>
                <ExcelColumn label={serviceContext.localeManager.getString('SUBTITEL')} value={`subtitel_${serviceContext.localeManager.locale}`}/>
                <ExcelColumn label={serviceContext.localeManager.getString('ACADEMIEJAAR')} value='academiejaar'/>
                <ExcelColumn label={serviceContext.localeManager.getString('OPLEIDING')} value='opleiding'/>
                <ExcelColumn label={serviceContext.localeManager.getString('STATUS')} value='status'/>
                {/*<ExcelColumn label="Marital Status"
                             value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                {rolColumns.map(col => {
                    return <ExcelColumn key={col} label={serviceContext.localeManager.getString(col)} value={col}/>
                })}
            </ExcelSheet>
            <ExcelSheet data={bundelsDataset} name={serviceContext.localeManager.getString('BUNDEL') + 's'}>
                <ExcelColumn label='#' value='volgnummer'/>
                <ExcelColumn slabel={serviceContext.localeManager.getString('TITEL')} value={`titel_${serviceContext.localeManager.locale}`}/>
                <ExcelColumn label={serviceContext.localeManager.getString('SUBTITEL')} value={`subtitel_${serviceContext.localeManager.locale}`}/>
                <ExcelColumn label={serviceContext.localeManager.getString('ACADEMIEJAAR')} value='academiejaar'/>
                <ExcelColumn label={serviceContext.localeManager.getString('OPLEIDING')} value='opleiding'/>
                <ExcelColumn label={serviceContext.localeManager.getString('STATUS')} value='status'/>

                {bundelRolColumns.map(col => {
                    return <ExcelColumn key={col} label={serviceContext.localeManager.getString(col)} value={col}/>
                })}
            </ExcelSheet>
        </ExcelFile>
    );
}

export default ExportXls

ExportXls.propTypes = {
    collectie: PropTypes.object.isRequired,
    bundels: PropTypes.array.isRequired,
    collectieAdaUserLinks: PropTypes.array.isRequired,
}

ExportXls.defaultProps = {}
