import React, {useState, useEffect, useContext} from 'react'
import ServiceContext from '../../context/ServiceContext'
import {Form, Header} from "semantic-ui-react"
import {LocaleContentViewer} from '@ahsmediatheek/med-component-locale-content'
//import '@ahsmediatheek/med-component-locale-content/build/index.css'
import {TrefwoordenViewer} from '@ahsmediatheek/med-component-trefwoorden'
import {SaveBar, InfoViewer, MetadataUtil} from "@ahsmediatheek/ada-lib"
import AcademiejaarKiezer from "../../component/spinbutton/AcademiejaarKiezer";
import ENWOpleidingKiezer from "../../component/dropdown/ENWOpleidingKiezer";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const CollectieSettingsGegevens = (props) => {

    const [isDirty, setIsDirty] = useState(false)

    const [collectie, setCollectie] = useState()
    const [localeContent, setLocaleContent] = useState()
    const [changedLocales, setChangedLocales] = useState([])
    const [opleidingen, setOpleidingen] = useState()
    const [diensten, setDiensten] = useState()
    const [olods] = useState()

    const serviceContext = useContext(ServiceContext)


    useEffect(() => {
        setCollectie({...serviceContext.collectie})
    }, [serviceContext.collectie])

    useEffect(() => {
        setLocaleContent(serviceContext.localeContent.map(localeData => ({...localeData})))
    }, [serviceContext.localeContent])

    useEffect(() => {
        if (!collectie || !collectie.academiejaar) return
        Promise.all([
            serviceContext.arteveldeApiService.getOpleidingen(collectie.academiejaar),
            serviceContext.arteveldeApiService.getDiensten(collectie.academiejaar)
        ]).then(([opleidingen, diensten]) => {
            setOpleidingen(opleidingen);
            setDiensten(diensten);
        })
    }, [collectie, serviceContext.arteveldeApiService])

    /*useEffect(() => {
        if (!collectie || !collectie.opleiding) return

        Promise.all(collectie.opleiding.split(',').map(opleiding => serviceContext.arteveldeApiService.getOpleidingOlods(opleiding))).then(res => {
            //setOlods(res)
        })
    }, [collectie, serviceContext.arteveldeApiService])*/

    const changeDirty = (value) => {
        setIsDirty(value)
        serviceContext.setIsDirty(value)
    }

    const onLocaleContentChange = (localeContentObject, data, plainText) => {
        localeContentObject.content = data
        changeDirty(true)
//console.log('CHANGE TITEL', localeContentObject, data)
        if (localeContentObject.field === 'titel' && localeContentObject.locale === 'nl') collectie.titel_nl = localeContentObject.content
        if (localeContentObject.field === 'titel' && localeContentObject.locale === 'en') collectie.titel_en = localeContentObject.content
        if (localeContentObject.field === 'beschrijving' && localeContentObject.locale === 'nl') collectie.beschrijving_nl = localeContentObject.content
        if (localeContentObject.field === 'beschrijving' && localeContentObject.locale === 'en') collectie.beschrijving_en = localeContentObject.content

        setCollectie({...collectie})

        if (!changedLocales.includes(localeContentObject.id))
            changedLocales.push(localeContentObject.id)
    }

    const onChangeCollectieProp = (prop, value) => {
        if(collectie[prop] !== value) {
            changeDirty(true)
            setCollectie({...collectie, [prop]: value})
        }
    }

    /* const onChangeOpleiding = (prop, value) => {
         console.log('OPLEIDING', prop, value)
         changeDirty(true)
         setCollectie({...collectie, opleiding: value})
     }*/

    const onChangeAcademiejaar = (aj) => {
        changeDirty(true)

        collectie.opleiding = ''
        collectie.academiejaar = aj

        Promise.all([
            serviceContext.arteveldeApiService.getOpleidingen(collectie.academiejaar),
            serviceContext.arteveldeApiService.getDiensten(collectie.academiejaar)
        ]).then(([opleidingen, diensten]) => {
            setOpleidingen(opleidingen);
            setDiensten(diensten);
        })

        setCollectie({...collectie})
    }

    const onCancel = () => {
        changeDirty(false)
        setCollectie({...serviceContext.collectie})
        setLocaleContent(serviceContext.localeContent.map(localeData => ({...localeData})))
    }

    const onSubmit = (...args) => {

        //checken of er een titel beschikbaar is!
        if(MetadataUtil.resolveTitel(collectie) === ''){
            window.alert(serviceContext.localeManager.getString('TITEL_MAG_NIET_LEEG_ZIJN'))
            return
        }

        changeDirty(false)
        let cLocales = localeContent.filter(item => changedLocales.includes(item.id))
        serviceContext.onSaveCollectie && serviceContext.onSaveCollectie(collectie, cLocales)
        setChangedLocales([])
    }

    const onChangeTrefwoorden = (trefwoorden) => {
            changeDirty(true)
            setCollectie({...collectie, trefwoorden: trefwoorden.join('|')})
    }


    const candidateContentFunction = (candidate) => {
        return <InfoViewer editable={serviceContext.infoEditable}
                           refId={'INFO_TREFWOORD_KANDIDAAT_TOEVOEGEN'}
                           localeManager={serviceContext.localeManager}
                           localeDataService={serviceContext.infoLocaleDataService}/>
    }

    return (
        <Form>
            <ComponentIdentifier displayName='CollectieSettingsGegevens'/>

            <Header dividing size='huge'>{serviceContext.localeManager.getString('METADATA')}</Header>

            <InfoViewer editable={serviceContext.infoEditable}
                        refId={'INFO_COLLECTIE_TITEL_BESCHRIJVING'}
                        localeManager={serviceContext.localeManager}
                        localeDataService={serviceContext.infoLocaleDataService}/>

            {collectie && localeContent &&
            <Form.Field>
                <label>{serviceContext.localeManager.getString('TITEL')}</label>
                {
                <LocaleContentViewer placeholder={serviceContext.localeManager.getString('TITEL')}
                                     localeContent={localeContent}
                                     referenceId={collectie.id.toString()}
                                     dataField='titel'
                                     noLocaleWarning={serviceContext.localeManager.getString('GEEN_CONTENT_VOOR_LOCALE')}
                                     editMode='plain'
                                     singleLine
                                     maxChars={80} optimalChars={30}
                                     maxCharsLabel={serviceContext.localeManager.getString('MAXIMUM_AANTAL_KARAKTERS')}
                                     optimalCharsLabel={serviceContext.localeManager.getString('OPTIMAAL_AANTAL_KARAKTERS')}
                                     onChange={onLocaleContentChange}/>
            }
            </Form.Field>}

            {collectie && localeContent &&
            <Form.Field>
                <label>{serviceContext.localeManager.getString('SUBTITEL')}</label>
                {
                <LocaleContentViewer placeholder={serviceContext.localeManager.getString('SUBTITEL')}
                                     localeContent={localeContent}
                                     referenceId={collectie.id.toString()}
                                     dataField='subtitel'
                                     noLocaleWarning={serviceContext.localeManager.getString('GEEN_CONTENT_VOOR_LOCALE')}
                                     editMode='plain'
                                     maxChars={150}
                                     maxCharsLabel={serviceContext.localeManager.getString('MAXIMUM_AANTAL_KARAKTERS')}
                                     optimalCharsLabel={serviceContext.localeManager.getString('OPTIMAAL_AANTAL_KARAKTERS')}
                                     onChange={onLocaleContentChange}/>
            }
            </Form.Field>}

            {collectie && localeContent &&
            <Form.Field>
                <label>{serviceContext.localeManager.getString('BESCHRIJVING')}</label>
                {
                <LocaleContentViewer placeholder={serviceContext.localeManager.getString('BESCHRIJVING')}
                                     localeContent={localeContent}
                                     referenceId={collectie.id.toString()}
                                     dataField='beschrijving'
                                     noLocaleWarning={serviceContext.localeManager.getString('GEEN_CONTENT_VOOR_LOCALE')}
                                     editMode='plain'
                                     maxChars={300}
                                     maxCharsLabel={serviceContext.localeManager.getString('MAXIMUM_AANTAL_KARAKTERS')}
                                     optimalCharsLabel={serviceContext.localeManager.getString('OPTIMAAL_AANTAL_KARAKTERS')}
                                     onChange={onLocaleContentChange}/>
            }
            </Form.Field>}

            {collectie && <Form.Field>
                <label>{serviceContext.localeManager.getString('ACADEMIEJAAR')}</label>
                <AcademiejaarKiezer academiejaren={serviceContext.arteveldeApiService.academiejaren}
                                    selectedAcademiejaar={collectie.academiejaar}
                                    onSelectAcademiejaar={onChangeAcademiejaar}/>
            </Form.Field>}

            {opleidingen && diensten && <Form.Field>
                <label>
                    ENW's/
                    {serviceContext.localeManager.getString('OPLEIDINGEN')}/
                    {serviceContext.localeManager.getString('DIENSTEN')}
                </label>
                <ENWOpleidingKiezer
                    opleidingen={opleidingen}
                    diensten={diensten}
                    localeManager={serviceContext.localeManager}
                    selectie={collectie.opleiding}
                    onSelect={(opl) => onChangeCollectieProp('opleiding', opl)}
                />
            </Form.Field>}

            {collectie && <Form.Field>
                <label>{serviceContext.localeManager.getString('TREFWOORDEN')}</label>
                <InfoViewer editable={serviceContext.infoEditable}
                            refId={'INFO_COLLECTIE_TREFWOORDEN'}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>
                {<TrefwoordenViewer consumerKey={serviceContext.TREFWOORDEN_CONSUMER_KEY}
                                   allowCandidates={true}
                                   onChangeTrefwoorden={onChangeTrefwoorden}
                                   trefwoordenString={collectie.trefwoorden}
                                   locale={serviceContext.localeManager.locale}
                                   candidateInfoContent={candidateContentFunction}
                                   labelFunction={serviceContext.localeManager.getString}/>
                                   }
            </Form.Field>}

            {/*olods && <Form.Field>
                <label>{serviceContext.localeManager.getString('OLOD')}</label>
                {<OlodKiezer olods={olods}
                                 localeManager={serviceContext.localeManager}
                                 selectedOlod={collectie.olod}
                                 onSelectOlod={(olod) => onChangeCollectieProp('olod', olod)}/>}
            </Form.Field>*/}

            {isDirty && <SaveBar cancelButtonLabel={serviceContext.localeManager.getString('ANNULEER')}
                                 saveButtonLabel={serviceContext.localeManager.getString('BEWAAR')}
                                 onCancelClick={onCancel} onSaveClick={onSubmit}/>}
        </Form>
    )
}

export default CollectieSettingsGegevens
