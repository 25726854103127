import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Segment, Button, Modal} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'

import {InfoViewer} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "./ComponentIdentifier";

const CompilatieActies = ({compilatie}) => {

    const serviceContext = useContext(ServiceContext)

    const [acties, setActies] = useState([])
    const [warningModalOpen, setWarningModalOpen] = useState(false)
    const [actieInfoKey, setActieInfoKey] = useState()
    const [bevestigLabel, setBevestigLabel] = useState()

    useEffect(() => {
        const acties = []

        setActies(acties)
    }, [compilatie, serviceContext.privilegeManager, serviceContext.compilatieAdaUserLinks])


    const onCloseModal = () => {
        setActieInfoKey(null)
        setWarningModalOpen(false)
    }

    const onActieClick = (event, {name}) => {
        setWarningModalOpen(true)
        setActieInfoKey('ACTIE_' + name.toUpperCase())
        setBevestigLabel(name)
    }

    const modalExtraContentFunction = (actie) => {
    }

    const voerActieUit = (actie) => {

        switch (actie) {
            default:
                throw (new Error('Kan actie niet uitvoeren. Incorrecte Actie'))
        }

        setWarningModalOpen(false)
    }

    return (
        <Fragment>
            <ComponentIdentifier displayName='CompilatieActies'/>

            <Segment>

                {acties.map(actie => {
                    return <Button key={actie} color='teal' name={actie} onClick={onActieClick}>{serviceContext.localeManager.getString(actie)}</Button>
                })}

            </Segment>

            <Modal open={warningModalOpen && actieInfoKey !== ''} onClose={onCloseModal} dimmer='inverted'>
                <Modal.Content>
                    <InfoViewer editable={serviceContext.infoEditable}
                                refId={actieInfoKey}
                                extraContentFunction={modalExtraContentFunction}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={onCloseModal}>
                        {serviceContext.localeManager.getString('ANNULEER')}
                    </Button>
                    <Button color='green' content={serviceContext.localeManager.getString(bevestigLabel)}
                            onClick={() => voerActieUit(bevestigLabel)}
                    />

                </Modal.Actions>

            </Modal>

        </Fragment>
    )
}

export default CompilatieActies

CompilatieActies.propTypes = {
    compilatie: PropTypes.object.isRequired
}

CompilatieActies.defaultProps = {}
