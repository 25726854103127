export class Activiteit {
    id: number
    adaUser: number
    datum: string
    activiteit: string
    detail: string
}

export class ActiviteitType {

    static readonly COMPILATIE_AANGEMAAKT: string = 'Collectie aangemaakt'
    static readonly COMPILATIE_VERWIJDERD: string = 'Collectie verwijderd'
    static readonly COMPILATIE_REFERENTIEBEELD_VERVANGEN: string = 'Collectie referentiebeeld vervangen'

    static readonly COLLECTIE_AANGEMAAKT: string = 'Collectie aangemaakt'
    static readonly COLLECTIE_VERWIJDERD: string = 'Collectie verwijderd'
    static readonly COLLECTIE_REFERENTIEBEELD_VERVANGEN: string = 'Collectie referentiebeeld vervangen'

    static readonly BUNDEL_AANGEMAAKT: string = 'Bundel aangemaakt'
    static readonly BUNDEL_VERWIJDERD: string = 'Bundel verwijderd'

    static readonly ADA_USER_BANNER_VERVANGEN: string = 'AdaUser banner vervangen'

    static readonly EMBARGO_AANGEMAAKT: string = 'Embargo aangemaakt'
    static readonly EMBARGO_VERWIJDERD: string = 'Embargo verwijderd'

}