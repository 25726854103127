import React, {useState, useEffect, useContext, useRef, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Link,useHistory } from "react-router-dom"

import {ApplicationPath, CarrouselContext, ResultContainer, CollectieStatus, Query} from "@ahsmediatheek/ada-lib";
import {Header, Form, Icon, Dimmer, Loader, Button, Input , Divider} from "semantic-ui-react";
import ServiceContext from "../context/ServiceContext"
import _ from 'lodash'
import { CollectieCard } from "../component/CollectieCard";
import { ComponentIdentifier } from "../component/ComponentIdentifier";
import InhoudZoekresultaten from './InhoudZoekresultaten';

const VIEW_BROWSE = 'viewBrowse'
const VIEW_ZOEKRESULTATEN = 'viewZoekresultaten'

//const ICON_SIZE = 'big'

const Home = () => {

    const [query, setQuery] = useState('')
    const [recents, setRecents] = useState()
    const [searching, setSearching] = useState(false)
    const [view, setView] = useState(VIEW_BROWSE)
    const [zoekresultaten, setZoekresultaten] = useState()


    const serviceContext = useContext(ServiceContext)
    const searchFieldRef = useRef()

    const history = useHistory();

    useEffect(() => {
        if (serviceContext.cachedZoekdata) {
            setView(VIEW_ZOEKRESULTATEN)
            setZoekresultaten(serviceContext.cachedZoekdata.zoekresultaten)
            setQuery(serviceContext.cachedZoekdata.query)
        }
    }, [])

    useEffect(() => {
        if (searchFieldRef) searchFieldRef.current.focus()
    }, [searchFieldRef])

    useEffect(() => {

        Promise.all([
            serviceContext.collectieService.getCarrouselContent(CarrouselContext.RECENT),
        ]).then(([collRes, /*bundRes*/]) => {
            const recents = _.orderBy([...collRes, /*...bundRes*/], ['aangemaakt'], ['desc'])
                .filter(coll => {
                    if (coll.status === CollectieStatus.GEPUBLICEERD || coll.status === CollectieStatus.GEARCHIVEERD) return true
                    return serviceContext.privilegeManager.isAdmin()
                })

            setRecents(recents)
        })
    }, [serviceContext])

    const onKeyDownEventHandler = (event) => {
        //if (event.keyCode === 13) { keyCode = deprecated, code ook universeel
        if (event.code === "Enter") {
            setSearching(true)
            serviceContext.zoekService.getZoekresultaten(new Query(query) , 'foo').then(res => {
                setSearching(false)
                setZoekresultaten(res)
                setView(VIEW_ZOEKRESULTATEN)
                serviceContext.setCachedZoekdata({zoekresultaten: res, query})
            })
        }
        }
    const onChangeQuery = (event, { value }) => {
        setQuery(value)
    }

    const zoekAfbrekenButton_clickHandler = () => {
        setView(VIEW_BROWSE)
        setZoekresultaten(null)
        setQuery('')
        searchFieldRef.current.focus()
        serviceContext.setCachedZoekdata(null)
    }

    const openZoekresultaat = (item) => {
        //console.log(`/${item.class}/${item.uuid}/`)
        history.push(`/${item.class}/${item.uuid}/`);
    }

    return (
        <Fragment>
            
            <ComponentIdentifier displayName='Home' />


            <div style={{ position: 'relative', marginBottom: '60px' }}>
                <div style={{ height: '300px', overflow: 'hidden', position: 'relative' }}>
                    <img alt='Landing page' style={{ position: 'absolute', top: '-20px' }} src={serviceContext.applicationPath.assets() + 'image/main-bg.jpg'} width='100%' />
                </div>


                <div className='home-search-overlay'>

                    <Dimmer active={searching} inverted>
                        <Loader active={searching}>{serviceContext.localeManager.getString('BEZIG_MET_ZOEKEN')}...</Loader>
                    </Dimmer>

                    <Header size='huge' textAlign='center'>{serviceContext.localeManager.getString('WELKOM_BIJ_ADA')}</Header>
                    <Form autoComplete='off'>
                        <Input autoComplete='false' ref={searchFieldRef} fluid size='huge' disabled={searching}
                            icon={<Icon name='search' />}
                            value={query}
                            onChange={onChangeQuery}
                            onKeyDown={onKeyDownEventHandler}
                            placeholder={serviceContext.localeManager.getString('ZOEK_IN_ONS_ARCHIEF')}
                        /></Form>
                </div>

            </div>

            <div className='main-content'>

                {recents && view === VIEW_BROWSE &&

                    <ResultContainer containerWidth={serviceContext.resultContainerAvailableWidth}
                        display={serviceContext.resultDisplay}
                        setDisplay={serviceContext.setResultDisplay}
                        header={serviceContext.localeManager.getString('RECENT_TOEGEVOEGD')}>

                        {recents.map((recent, index) => {
                            if (recent.class === 'collectie') {
                                return <Link key={index} to={`/collectie/${recent.uuid}`}>
                                    <CollectieCard key={index} display={serviceContext.resultDisplay} toonGemarkeerdIcon={true}
                                        showStatus={serviceContext.privilegeManager.isAdmin()}
                                        showBundelUuid={serviceContext.privilegeManager.isAdmin()}
                                        collectie={recent}
                                        locale={serviceContext.localeManager.locale} />
                            </Link>
                            } else {
                            return null
                            }
                        })}

                    </ResultContainer>}

                {view === VIEW_ZOEKRESULTATEN && (
                    <Fragment>
                        <Header as='h2'>
                            {serviceContext.localeManager.getString('ZOEKRESULTATEN')}
                            <Button
                                floated='right'
                                onClick={zoekAfbrekenButton_clickHandler}
                                >
                                {serviceContext.localeManager.getString('ZOEK_AFBREKEN')}
                            </Button>
                        </Header>

                        <Divider />

                        {zoekresultaten && (
                            <InhoudZoekresultaten
                                zoekresultaten={zoekresultaten}
                                onSelect={openZoekresultaat}
                            />
                        )}
                    </Fragment>
                )}

            </div>
        </Fragment>
    )
}

export default Home

Home.propTypes = {
    onCollectieClick: PropTypes.func,
    onBundelClick: PropTypes.func
}

