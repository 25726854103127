import React, {useState, useEffect, useContext} from 'react'
import ServiceContext from '../../context/ServiceContext'
import {InfoViewer, ToegangsticketTargetType, ToegangsticketStatus} from "@ahsmediatheek/ada-lib";
import ToegangsticketRenderer from "../../component/toegangsticket/ToegangsticketRenderer";
import ToegangsticketCreator from "../../component/toegangsticket/ToegangsticketCreator";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const BundelSettingsToegangstickets = ({toegangstickets, deleteTicket, maakTicket, heropenTicket}) => {


    const serviceContext = useContext(ServiceContext)

    return (
        <div>
            <ComponentIdentifier displayName='BundelSettingsToegangstickets'/>

            <InfoViewer editable={serviceContext.infoEditable}
                        refId={'INFO_TOEGANGSTICCKETS'}
                        localeManager={serviceContext.localeManager}
                        localeDataService={serviceContext.infoLocaleDataService}/>

            <ToegangsticketCreator onMaakTicket={maakTicket}/>

            <div>
                {toegangstickets.map(tt => <ToegangsticketRenderer key={tt.id} toegangsticket={tt} deleteTicket={deleteTicket} heropenTicket={heropenTicket}/>)}
            </div>

        </div>
    )
}

export default BundelSettingsToegangstickets
