import React, {useState, useEffect, useContext} from 'react'
import {Form, Divider, Checkbox, Header} from 'semantic-ui-react'
import ServiceContext from '../../context/ServiceContext'
import {CollectieSettings, InfoViewer} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const CollectieSettingsInstellingen = () => {


    const [BUNDEL_VALIDATIE_ACTIEF, SET_BUNDEL_VALIDATIE_ACTIEF] = useState()
    const [GROEPSBUNDELS_TOEGESTAAN, SET_GROEPSBUNDELS_TOEGESTAAN] = useState()
    const [INZENDERS_MOGEN_BUNDELS_AANMAKEN, SET_INZENDERS_MOGEN_BUNDELS_AANMAKEN] = useState()
    const [INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN, SET_INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN] = useState()
    const [INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN, SET_INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN] = useState()
    const [INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN, SET_INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN] = useState()
    const [INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN, SET_INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN] = useState()
    const [BUNDEL_AUTOMATISCH_PUBLICEREN, SET_BUNDEL_AUTOMATISCH_PUBLICEREN] = useState()
    const [, SET_COLLECTIE_VERPLICHTE_METADATA] = useState()
    const [COLLECTIE_AUTOMATISCH_SLUITEN, SET_COLLECTIE_AUTOMATISCH_SLUITEN] = useState()
    const [, SET_COLLECTIE_AFSLUITEN_NA] = useState()
    const [, SET_COLLECTIE_AFSLUITEN_OP] = useState()



    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        SET_BUNDEL_VALIDATIE_ACTIEF(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.BUNDEL_VALIDATIE_ACTIEF) === 1)
        SET_GROEPSBUNDELS_TOEGESTAAN(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.GROEPSBUNDELS_TOEGESTAAN) === 1)
        SET_INZENDERS_MOGEN_BUNDELS_AANMAKEN(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.INZENDERS_MOGEN_BUNDELS_AANMAKEN) === 1)
        SET_INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN) === 1)
        SET_INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN) === 1)
        SET_INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN) === 1)
        SET_INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN) === 1)
        SET_BUNDEL_AUTOMATISCH_PUBLICEREN(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.BUNDEL_AUTOMATISCH_PUBLICEREN) === 1)
        SET_COLLECTIE_VERPLICHTE_METADATA(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.COLLECTIE_VERPLICHTE_METADATA))
        SET_COLLECTIE_AUTOMATISCH_SLUITEN(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.COLLECTIE_AUTOMATISCH_SLUITEN) === 1)
        SET_COLLECTIE_AFSLUITEN_NA(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.COLLECTIE_AFSLUITEN_NA))
        SET_COLLECTIE_AFSLUITEN_OP(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.COLLECTIE_AFSLUITEN_OP))
    }, [serviceContext.collectieSettings, serviceContext.collectie, serviceContext.collectieService])

    const onCheckboxChange = (event, {name, checked}) => {
        console.log('CBC', name, checked)
        const newSettings = serviceContext.collectieService.setCollectieSetting(serviceContext.collectie, name, checked ? 1 : 0)
        serviceContext.setCollectieSettings({...newSettings})

        //SAVE
        serviceContext.collectieService.updateCollectie(serviceContext.collectie).then(res => {
            console.log('COLLECTIE UPDATED', serviceContext.collectie)

        })
    }
    const [zichtbaarInCarrousel,setZichtbaarInCarrousel] = useState(Boolean(serviceContext.collectie.zichtbaarInCarrousel))
    const onZichtbaarChange = (event, {name, checked}) => {
        console.log(name,checked)
        serviceContext.collectie.zichtbaarInCarrousel = +checked
        //SAVE
        serviceContext.collectieService.updateCollectie(serviceContext.collectie).then(res => {
            console.log('ZichtbaarInCarrousel changed', serviceContext.collectie)
            setZichtbaarInCarrousel(checked)
        })
    }

    return (
        <Form>
            <ComponentIdentifier displayName='CollectieSettingsInstellingen'/>

            <Header dividing size='huge'>{serviceContext.localeManager.getString('SETTINGS')}</Header>
            <Form.Field>
                <label>{serviceContext.localeManager.getString('CARROUSEL')}</label>
                <Checkbox
                    toggle
                    name='zichtbaarInCarrousel'
                    checked={zichtbaarInCarrousel} 
                    label={<label>{serviceContext.localeManager.getString('ZICHTBAAR_IN_CARROUSEL')}</label>} 
                    onChange={onZichtbaarChange}
                    />
            </Form.Field>
            <Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.BUNDEL_VALIDATIE_ACTIEF)}</label>

                <div><InfoViewer editable={serviceContext.infoEditable}
                               refId='INFO_BUNDEL_VALIDATIE_ACTIEF'
                               localeDataService={serviceContext.infoLocaleDataService}
                               localeManager={serviceContext.localeManager}/></div>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.BUNDEL_VALIDATIE_ACTIEF)}
                          name={CollectieSettings.BUNDEL_VALIDATIE_ACTIEF}
                          checked={BUNDEL_VALIDATIE_ACTIEF}
                          onChange={onCheckboxChange}/>
            </Form.Field>

            {/*<Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.GROEPSBUNDELS_TOEGESTAAN)}</label>

                <div><InfoViewer editable={serviceContext.infoEditable}
                               refId='INFO_GROEPSBUNDELS_TOEGESTAAN'
                               localeDataService={serviceContext.infoLocaleDataService}
                               localeManager={serviceContext.localeManager}/></div>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.GROEPSBUNDELS_TOEGESTAAN)}
                          name={CollectieSettings.GROEPSBUNDELS_TOEGESTAAN}
                          checked={GROEPSBUNDELS_TOEGESTAAN}
                          onChange={onCheckboxChange}/>
            </Form.Field>*/}

            {/*<Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.INZENDERS_MOGEN_BUNDELS_AANMAKEN)}</label>

                <InfoViewer editable={serviceContext.infoEditable}
                            refId='INFO_INZENDERS_MOGEN_BUNDELS_AANMAKEN'
                            localeDataService={serviceContext.infoLocaleDataService}
                            localeManager={serviceContext.localeManager}/>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.INZENDERS_MOGEN_BUNDELS_AANMAKEN)}
                          name={CollectieSettings.INZENDERS_MOGEN_BUNDELS_AANMAKEN}
                          checked={INZENDERS_MOGEN_BUNDELS_AANMAKEN}
                          onChange={onCheckboxChange}/>
            </Form.Field>*/}

            {/*<Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN)}</label>

                <InfoViewer editable={serviceContext.infoEditable}
                            refId='INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN'
                            localeDataService={serviceContext.infoLocaleDataService}
                            localeManager={serviceContext.localeManager}/>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN)}
                          name={CollectieSettings.INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN}
                          checked={INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN}
                          onChange={onCheckboxChange}/>
            </Form.Field>*/}

            <Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN)}</label>

                <InfoViewer editable={serviceContext.infoEditable}
                            refId='INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN'
                            localeDataService={serviceContext.infoLocaleDataService}
                            localeManager={serviceContext.localeManager}/>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN)}
                          name={CollectieSettings.INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN}
                          checked={INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN}
                          onChange={onCheckboxChange}/>
            </Form.Field>

            <Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN)}</label>

                <InfoViewer editable={serviceContext.infoEditable}
                            refId='INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN'
                            localeDataService={serviceContext.infoLocaleDataService}
                            localeManager={serviceContext.localeManager}/>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN)}
                          name={CollectieSettings.INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN}
                          checked={INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN}
                          onChange={onCheckboxChange}/>
            </Form.Field>

            {/*<Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN)}</label>

                <InfoViewer editable={serviceContext.infoEditable}
                            refId='INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN'
                            localeDataService={serviceContext.infoLocaleDataService}
                            localeManager={serviceContext.localeManager}/>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN)}
                          name={CollectieSettings.INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN}
                          checked={INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN}
                          onChange={onCheckboxChange}/>
            </Form.Field>*/}

            {/*<Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.BUNDEL_AUTOMATISCH_PUBLICEREN)}</label>

                <InfoViewer editable={serviceContext.infoEditable}
                            refId='BUNDEL_AUTOMATISCH_PUBLICEREN'
                            localeDataService={serviceContext.infoLocaleDataService}
                            localeManager={serviceContext.localeManager}/>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.BUNDEL_AUTOMATISCH_PUBLICEREN)}
                          name={CollectieSettings.BUNDEL_AUTOMATISCH_PUBLICEREN}
                          checked={BUNDEL_AUTOMATISCH_PUBLICEREN}
                          onChange={onCheckboxChange}/>
            </Form.Field>*/}

            <Divider/>

            {/*<Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.COLLECTIE_VERPLICHTE_METADATA)}</label>

                <InfoViewer editable={serviceContext.infoEditable}
                            refId='COLLECTIE_VERPLICHTE_METADATA'
                            localeDataService={serviceContext.infoLocaleDataService}
                            localeManager={serviceContext.localeManager}/>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.COLLECTIE_VERPLICHTE_METADATA)}
                          name={CollectieSettings.COLLECTIE_VERPLICHTE_METADATA}
                          checked={COLLECTIE_VERPLICHTE_METADATA}
                          onChange={onCheckboxChange}/>
            </Form.Field>*/}

            {/*<Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.COLLECTIE_AUTOMATISCH_SLUITEN)}</label>

                <InfoViewer editable={serviceContext.infoEditable}
                            refId='COLLECTIE_AUTOMATISCH_SLUITEN'
                            localeDataService={serviceContext.infoLocaleDataService}
                            localeManager={serviceContext.localeManager}/>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.COLLECTIE_AUTOMATISCH_SLUITEN)}
                          name={CollectieSettings.COLLECTIE_AUTOMATISCH_SLUITEN}
                          checked={COLLECTIE_AUTOMATISCH_SLUITEN}
                          onChange={onCheckboxChange}/>
            </Form.Field>*/}

            {/*<Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.COLLECTIE_AFSLUITEN_NA)}</label>

                <InfoViewer editable={serviceContext.infoEditable}
                            refId='COLLECTIE_AFSLUITEN_NA'
                            localeDataService={serviceContext.infoLocaleDataService}
                            localeManager={serviceContext.localeManager}/>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.COLLECTIE_AFSLUITEN_NA)}
                          name={CollectieSettings.COLLECTIE_AFSLUITEN_NA}
                          checked={COLLECTIE_AFSLUITEN_NA}
                          onChange={onCheckboxChange}/>
            </Form.Field>*/}

            {/*<Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.COLLECTIE_AFSLUITEN_OP)}</label>

                <InfoViewer editable={serviceContext.infoEditable}
                            refId='COLLECTIE_AFSLUITEN_OP'
                            localeDataService={serviceContext.infoLocaleDataService}
                            localeManager={serviceContext.localeManager}/>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.COLLECTIE_AFSLUITEN_OP)}
                          name={CollectieSettings.COLLECTIE_AFSLUITEN_OP}
                          checked={COLLECTIE_AFSLUITEN_OP}
                          onChange={onCheckboxChange}/>
            </Form.Field>*/}

        </Form>
    )
}

export default CollectieSettingsInstellingen
