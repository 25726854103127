import {v4 as uuidv4} from 'uuid'
//import moment from 'moment'
const moment = require('moment')


export class BundelStatus {

    //LET OP: de volgorde van de statussen is belangrijk voor de status manager
//static readonly CREATED: string = 'created'
    static readonly DRAFT: string = 'draft'
    static readonly IN_VALIDATIE: string = 'in_validatie'
    static readonly VALIDATIE_AFGEWEZEN: string = 'validatie_afgewezen'
    static readonly GEVALIDEERD: string = 'gevalideerd'

    static readonly STATUS: string[] = [BundelStatus.DRAFT, BundelStatus.IN_VALIDATIE, BundelStatus.GEVALIDEERD, BundelStatus.VALIDATIE_AFGEWEZEN]
}

export class Bundel {
    id: number
    uuid: string = uuidv4()
    class: string = 'bundel'
    aangemaakt: string = moment().format('YYYY-MM-DD HH:mm:ss')
    gewijzigd: string = moment().format('YYYY-MM-DD HH:mm:ss')
    titel_nl: string = 'Nieuwe bundel'
    titel_en: string = ''
    subtitel_nl: string = ''
    subtitel_en: string = ''
    abstract_nl: string = ''
    abstract_en: string = ''
    referentiebeeld: string
    status: string = BundelStatus.DRAFT
    trefwoorden: string = ''
    settings: string
    publicatietype: string
    academiejaar: string
    opleiding: string
    embargo: string
    redenValidatieAfwijzing: string
    gemarkeerd: boolean = false
    gebruikersSearchString: string = ''
}

export class CollectieBundel {
    id: number
    collectie: number
    collectie_uuid: string
    bundel: number
    bundel_uuid: string
    link: string = 'fysiek'
}

export class BundelActie {
    static readonly BUNDEL_INZENDEN: string = 'BUNDEL_INZENDEN'
    static readonly BUNDEL_VALIDEREN: string = 'BUNDEL_VALIDEREN'
    static readonly BUNDEL_VALIDATIE_AFWIJZEN: string = 'BUNDEL_VALIDATIE_AFWIJZEN'
    static readonly BUNDEL_HEROPENEN: string = 'BUNDEL_HEROPENEN'
}
