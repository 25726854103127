import React, {useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {CompilatieIcon, ResultContainer} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../../context/ServiceContext'
import {Link} from "react-router-dom";
import {CompilatieCard} from "../../component/CompilatieCard";

import {Segment, Button} from "semantic-ui-react";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const AdaUserCompilaties = ({compilaties, onCompilatieSelect}) => {

    const serviceContext = useContext(ServiceContext)


    return (
        <Fragment>
            <ComponentIdentifier displayName='AdaUserCompilaties'/>

            <ResultContainer containerWidth={serviceContext.resultContainerAvailableWidth}
                             display={serviceContext.resultDisplay}
                             setDisplay={serviceContext.setResultDisplay}
                             header={serviceContext.localeManager.getString('MIJN_COMPILATIES')}
                             headerIcon={<CompilatieIcon size='large' style={{marginRight: '10px'}}/>}
                             extraContent={() => {
                                 return <div style={{marginBottom: '20px'}}>
                                     <Segment>
                                         {serviceContext.privilegeManager.canAddCompilatie() && <Button onClick={serviceContext.openNieuweCompilatieWizard}>
                                             {serviceContext.localeManager.getString('MAAK_NIEUWE_COMPILATIE')}
                                         </Button>}
                                     </Segment>
                                 </div>
                             }}
            >

                {compilaties.map((compilatie, index) => {
                    return <Link key={index} to={`/compilatie/${compilatie.uuid}`}>
                        <CompilatieCard compilatie={compilatie} showStatus
                                        display={serviceContext.resultDisplay}
                                        locale={serviceContext.localeManager.locale}/>
                    </Link>
                })}
            </ResultContainer>
        </Fragment>
    )
}

export default AdaUserCompilaties


AdaUserCompilaties.propTypes = {
    compilaties: PropTypes.array.isRequired,
    onCompilatieSelect: PropTypes.func
}

AdaUserCompilaties.defaultProps = {}
