import axios from 'axios'
import {ApplicationPath} from "../class/general/ApplicationPath";
import {AuthenticationService} from "./AuthenticationService";
import {AdaUserService} from "./AdaUserService";


const MAIL_TEMPLATE_TABLE: string = 'mailTemplate'


export class EmailTemplateService {

    applicationPath: ApplicationPath
    authenticationInstance: AuthenticationService
    adaUserService: AdaUserService
    mailTemplates: any[]

    constructor(authenticationInstance, adaUserService, applicationPath) {
        this.authenticationInstance = authenticationInstance
        this.adaUserService = adaUserService
        this.applicationPath = applicationPath
    }

    //==============================================================
    //==============================================================
    //                      MAIL TEMPLATE
    //==============================================================
    //==============================================================


    getMailTemplates = () => {
        return new Promise((resolve, reject) => {

            axios.get(`${this.applicationPath.crud_api()}${MAIL_TEMPLATE_TABLE}?order=id,desc&transform=1`, this.authenticationInstance.axiosOptions)
                .then(response => {
                    let templates = response.data[MAIL_TEMPLATE_TABLE]

                    resolve(templates)
                })
                .catch(error => {
                    console.error('GET ERROR', error)
                    reject(error)
                })
        })
    }

    updateMailTemplate = (mailTemplate) => {

        const url = `${this.applicationPath.crud_api()}${MAIL_TEMPLATE_TABLE}/${mailTemplate.id}?transform=1`

        return new Promise((resolve, reject) => {
                axios.put(url, mailTemplate)
                    .then((result) => {
                        resolve(result)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    updateMailContent = (mailTemplate, htmlContent) => {

        const url = `${this.applicationPath.email_api()}saveTemplate/${mailTemplate.id}/`


        const data = JSON.stringify({"content": htmlContent})
        const config = {headers: {"content-type": "application/json"}}
        return new Promise((resolve, reject) => {
                axios.post(url, data, config)
                    .then((result) => {
                        resolve(result)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    createMailTemplate = (mailTemplate) => {

        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api()}${MAIL_TEMPLATE_TABLE}/?transform=1`

            axios.post(url, mailTemplate)
                .then((result) => {
                    mailTemplate.id = result.data
                    resolve(mailTemplate)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    dupliceerMailTemplate = (mailTemplate) => {
        return new Promise((resolve, reject) => {
            mailTemplate.id = null
            mailTemplate.label += ' [duplicaat]'
            this.createMailTemplate(mailTemplate).then(mt => {
                resolve(mt)
            })
        })
    }

    deleteMailTemplate = (mailTemplate, collectie) => {

        return new Promise((resolve, reject) => {


            //Delete mailTemplate where id = mailTemplate.id
            const url = `${this.applicationPath.crud_api()}${MAIL_TEMPLATE_TABLE}/${mailTemplate.id}?transform=1`

            axios.delete(url)
                .then((result) => {
                    resolve('MailTemplate deleted')
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

}
