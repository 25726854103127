import React, {useState, useEffect, useCallback, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Modal, Header, Button, Popup} from "semantic-ui-react";
import {LocaleContentViewer} from '@ahsmediatheek/med-component-locale-content'
import Parser from 'html-react-parser'
import {EditIcon, InfoIcon} from "./icon/Icons";

const DISPLAY_MODE_NORMAL = 'normal'
const DISPLAY_MODE_POPUP = 'popup'
const DISPLAY_MODE_CLICK = 'click'
const LEES_MEER_PLACEHOLDER = '<p>[LEES_MEER]</p>'

const InfoViewer = (props) => {

    const [localeContent, setLocaleContent] = useState()
    const [content, setContent] = useState('')
    const [extraContent, setExtraContent] = useState('')
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [displayModalOpen, setDisplayModalOpen] = useState(false)
    const [hasExtraContent, setHasExtraContent] = useState(false)
    const [extraContentVisible, setExtraContentVisible] = useState(false)
    const [datumWijziging, setDatumWijziging] = useState('')
    const [userWijziging, setUserWijziging] = useState('')


    const getContent = useCallback(() => {
        let content = props.localeDataService.getPropertyValue(props.refId, 'info', props.localeManager.locale)
        if (props.localeManager.locale !== 'nl' && contentIsEmpty(content)) {
            content = props.localeDataService.getPropertyValue(props.refId, 'info', 'nl')
        }
        for (const key in props.substitutions) {
            content = content.replace(key, props.substitutions[key])
        }
        const leesMeerIndex = content.indexOf(LEES_MEER_PLACEHOLDER)
        if (leesMeerIndex > -1) {
            setHasExtraContent(true)
            setExtraContentVisible(false)
            const mc = content.substr(0, leesMeerIndex)
            const ec = content.substr(leesMeerIndex + LEES_MEER_PLACEHOLDER.length)
            content = mc
            setExtraContent(ec)
        }
        return content
    }, [props.localeDataService, props.localeManager.locale, props.refId, props.substitutions])


    useEffect(() => {
        props.localeDataService.getLocaleData(props.refId).then(res => {
            if (res === null) return
            setLocaleContent(res.localeContent)
            const localeObject = res.localeContent.reduce((lco, o) => {
                if (o.locale === props.localeManager.locale) return o
                return lco
            })
            setDatumWijziging(localeObject.datum_wijziging)
            setUserWijziging(localeObject.user_wijziging)
            setContent(getContent())
        })
    }, [props.refId, props.localeDataService, getContent])


    const onEditButtonClick = () => {
        setEditModalOpen(true)
    }

    const handleClose = () => {
        setEditModalOpen(false)
        setDisplayModalOpen(false)
    }


    const updateInfo = () => {
        setEditModalOpen(false)

        props.localeDataService.saveLocaleData(localeContent).then(result => {
            setContent(getContent())
        })
    }

    const onLocaleContentChange = (localeContentObject, data) => {
        localeContentObject.content = data
    }

    const contentIsEmpty = (content) => {
        return content === '' || content === '<p></p>'
    }

    const getDisplayedContent = (content) => {

        return <div className='info-viewer' style={props.style}>
            {props.showIcons &&
            <span>
                <InfoIcon style={{marginBottom: '10px'}}/>
                {props.editable && <span><EditIcon onClick={onEditButtonClick}/> ({datumWijziging} - {userWijziging})</span>}
            </span>}

            {contentIsEmpty(content) ? props.editable ? props.refId : '' : Parser(content)}

            {hasExtraContent && extraContentVisible && <Fragment>
                {Parser(extraContent)}
                <Button size='mini' onClick={() => setExtraContentVisible(false)}>{props.localeManager.getString('VERBERG')}</Button>
            </Fragment>}

            {hasExtraContent && !extraContentVisible && <Button size='mini' onClick={() => setExtraContentVisible(true)}>{props.localeManager.getString('LEES_MEER')}</Button>}

            {props.extraContentFunction && props.extraContentFunction(props.refId)}
        </div>
    }

    const onHandleDisplayModeClick = () => {
        setDisplayModalOpen(true)
    }
    return (
        <Fragment>


            {props.displayMode === DISPLAY_MODE_NORMAL && getDisplayedContent(content)}

            {props.displayMode === DISPLAY_MODE_POPUP && <Popup content={getDisplayedContent(content)} trigger={<InfoIcon style={{marginLeft: '10px'}}/>}/>}

            {props.displayMode === DISPLAY_MODE_CLICK && <InfoIcon style={{marginLeft: '10px'}} onClick={onHandleDisplayModeClick}/>}

            {props.editable && props.displayMode !== DISPLAY_MODE_NORMAL && <EditIcon onClick={onEditButtonClick}/>}

            {editModalOpen &&
            <Modal open={editModalOpen} onClose={handleClose} dimmer='inverted'>
                <Header icon={<EditIcon/>} content={props.refId}/>
                <Modal.Content>
                    <LocaleContentViewer localeContent={localeContent}
                                         referenceId={props.refId}
                                         dataField='info'
                                         onChange={onLocaleContentChange}/>

                </Modal.Content>
                <Modal.Actions>

                    <Button onClick={handleClose}>
                        {props.localeManager.getString('ANNULEER')}
                    </Button>
                    <Button color='green' onClick={updateInfo}>
                        {props.localeManager.getString('BEWAAR')}
                    </Button>
                </Modal.Actions>

            </Modal>}

            {displayModalOpen &&
            <Modal open={displayModalOpen} onClose={handleClose} dimmer='inverted'>
                <Modal.Content>
                    {getDisplayedContent(content)}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleClose}>
                        {props.localeManager.getString('SLUIT')}
                    </Button>
                </Modal.Actions>

            </Modal>}
        </Fragment>
    )
}

export {InfoViewer}

InfoViewer.propTypes = {
    localeDataService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    displayMode: PropTypes.oneOf([DISPLAY_MODE_NORMAL, DISPLAY_MODE_POPUP, DISPLAY_MODE_CLICK]),
    refId: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    substitutions: PropTypes.object,
    extraContentFunction: PropTypes.func,
    showIcons: PropTypes.bool
}

InfoViewer.defaultProps = {
    displayMode: DISPLAY_MODE_NORMAL,
    editable: false,
    showIcons: true
}
