import React from 'react'
import {Form} from "semantic-ui-react";
//import ServiceContext from '../../../../context/ServiceContext'
import AbstractWizardPage from "./AbstractWizardPage";
import {ComponentIdentifier} from "../../../../component/ComponentIdentifier";


const CwTrefwoorden = () => {

    //const serviceContext = useContext(ServiceContext)

    return (
        <AbstractWizardPage>
            <ComponentIdentifier displayName='CwTrefwoorden'/>

            <Form>



            </Form>
        </AbstractWizardPage>
    )
}

export default CwTrefwoorden
