import React, {useState, useEffect, useContext, useCallback, Component } from 'react'
import PropTypes from 'prop-types'

import {Segment, Button , Form , Input , Dropdown , Header} from "semantic-ui-react";
import Dropzone from "react-dropzone";
import {InfoViewer, Bundel, BundelStatus, ResultContainer} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../../context/ServiceContext'
//import AcademiejaarKiezer from '../dropdown/AcademiejaarKiezer';

const DropzoneBundelsMetadata = ({setupCompilaties}) => {
    const [compilaties, setCompilaties] = useState([]);
    const [selectedCompilaties, setSelectedCompilaties] = useState([]);
    //const [academiejaren,setAcademiejaren] = useState([])
    
useEffect(async () => {
    const adaUserAdaUserLinks = await serviceContext.adaUserService.getAdaUserLinksForAdaUser(serviceContext.privilegeManager.adaUser);
    const compilatieIds = adaUserAdaUserLinks.reduce((ids, aul) => {
        if (aul.ref === 'compilatie' && !ids.includes(aul.refId) && aul.refId !== serviceContext.compilatie?.id) ids.push(aul.refId)
        return ids
    }, []);
    const compilaties = await serviceContext.compilatieService.getCompilaties(compilatieIds);
    setCompilaties(parseCompilatiesForSearch(compilaties));
}, []);

//const setupCompilaties = (arr) => {
//    setSelectedCompilaties(arr)
//}

    const parseCompilatiesForSearch = (compilaties) => {
        const arr = []
        compilaties.map((compilatie) => {
            let ref = compilatie.defaultReferentiebeeld !== ''
            ? compilatie.defaultReferentiebeeld
            : serviceContext.applicationPath.referentiebeelden() + compilatie.uuid + '_thumb.jpg?d=' + new Date().getTime()
            console.log(ref)
            arr.push({ key : compilatie.uuid , value : compilatie.uuid , text : compilatie.titel_nl , image: { avatar: true, src: ref }})
        })
        return arr
    }

    const serviceContext = useContext(ServiceContext);

    /*
    useEffect(() => {
        let thisAcademiejaren = serviceContext.arteveldeApiService.academiejaren
        thisAcademiejaren.unshift(null)
        console.log(thisAcademiejaren)
        setAcademiejaren(thisAcademiejaren)
    }, [])
    */
    //const academiejaren = serviceContext.arteveldeApiService.academiejaren
    //academiejaren.unshift({key: 0 , value: null, text: 'Geen AJ'})
    return (<Segment>
        <Header>
            {serviceContext.localeManager.getString('KIES_COMPILATIES')}
        </Header>
        <Dropdown
            placeholder={serviceContext.localeManager.getString('KIES_COMPILATIES')}
            fluid
            search
            multiple
            selection
            options={compilaties}
            onChange={(e,data) => setupCompilaties(data.value)}
        />
    </Segment>)
}

DropzoneBundelsMetadata.propTypes = {
    setupCompilaties: PropTypes.func
}


export default DropzoneBundelsMetadata