export class Academiejaar {

    year: number

    constructor(year){
        this.year = year
    }

    get short(): string {
        return `${this.year}-${(this.year + 1).toString().substr(2)}`
    }

    get long(): string {
        return `${this.year}-${this.year + 1}`
    }


}