import {v4 as uuidv4} from 'uuid'
//import moment from 'moment'
const moment = require('moment')



export class CompilatieStatus {
    static readonly DRAFT: string = 'draft'
    static readonly GEPUBLICEERD: string = 'gepubliceerd'
    static readonly GEARCHIVEERD: string = 'gearchiveerd'

    static readonly STATUS: string[] = [CompilatieStatus.DRAFT, CompilatieStatus.GEPUBLICEERD, CompilatieStatus.GEARCHIVEERD]
}

export class Compilatie {
    id: number
    uuid: string = uuidv4()
    class: string = 'compilatie'
    titel_nl: string = 'Nieuwe compilatie'
    titel_en: string = ''
    subtitel_nl: string
    subtitel_en: string
    beschrijving_nl: string
    beschrijving_en: string
    aangemaakt: string = moment().format('YYYY-MM-DD HH:mm:ss')
    aangemaakt_door: string = ''
    gewijzigd: string = moment().format('YYYY-MM-DD HH:mm:ss')
    trefwoorden: string = ''
    tags_conv: string = ''
    settings: string
    embargo: string
    zichtbaarInCarrousel: boolean
    defaultReferentiebeeld: string = ''
    referentiebeeldSettings: string
    isMetadataDirty: boolean
    status: string = CompilatieStatus.DRAFT
}

export class CompilatieActie {
    static readonly COLLECTIE_PUBLICEREN: string = 'COLLECTIE_PUBLICEREN'
    static readonly COLLECTIE_ARCHIVEREN: string = 'COLLECTIE_ARCHIVEREN'
}
