import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import ServiceContext from '../../context/ServiceContext'
import {AdaUserCard, Spacer, InfoViewer, AdaUserRol, AdaUserLinkRef} from "@ahsmediatheek/ada-lib";
import {Header, Button} from "semantic-ui-react";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const CollectieSettingsGebruikers = ({onAddAdaUsers, onDeleteAdaUserLink}) => {

    const [bundelAdaUserLinks, setBundelAdaUserLinks] = useState()

    const serviceContext = useContext(ServiceContext)

    useEffect(() => {

        //we moeten voor alle bundels de adaUsers ophalen!
        const promises = []
        for (const bundel of serviceContext.bundels) {
            promises.push(serviceContext.adaUserService.getAdaUserLinks(AdaUserLinkRef.BUNDEL, bundel.id))
        }

        Promise.all(promises).then(adaUserLinks => {
            const combine = Array.prototype.concat.apply([], adaUserLinks);
            setBundelAdaUserLinks(combine)
        })
    }, [serviceContext.bundels, serviceContext.adaUserService])

    const resolveAddButtonLabel = (rol) => {
        return serviceContext.localeManager.getString('VOEG_ITEM_TOE').replace('[[item]]', serviceContext.localeManager.getString(rol)) + '(s)'
    }

    const hasBundel = (adaUserLink) => {
        if (adaUserLink.rol !== AdaUserRol.INZENDER) return true
        return bundelAdaUserLinks.reduce((hb, aul) => {
            if (adaUserLink.adaUser === aul.adaUser && aul.rol === AdaUserRol.EIGENAAR) return true
            return hb
        }, false)
    }

    const getRolEnabled = (rol) => {
        return true
    }

    if (!bundelAdaUserLinks) return null

    return (
        <div>
            <ComponentIdentifier displayName='CollectieSettingsGebruikers'/>

            {AdaUserRol.COLLECTIE_ROLLEN.map(rol => {
                return <Fragment key={rol}>
                    <Header dividing size='huge'>{serviceContext.localeManager.getString(rol)}s</Header>

                    <InfoViewer refId={`INFO_SETTINGS_COLLECTION_MANAGERS_${rol.toUpperCase()}`}
                                editable={serviceContext.infoEditable}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>

                    <Spacer vertical distance={20}/>

                    {serviceContext.privilegeManager.canAddCollectieAdaUserLink(serviceContext.collectie, rol) && serviceContext.collectieAdaUserLinks.filter(adaUserLink => adaUserLink.rol === rol).map((adaUserLink, index) => {
                        const adaUser = serviceContext.collectieService.adaUserService.adaUserLookup[adaUserLink.adaUser]
                        const heeftBundel = hasBundel(adaUserLink)

                        return <div key={index} style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
                            <AdaUserCard deleteable={serviceContext.privilegeManager.canDeleteCollectieAdaUserLink(serviceContext.collectie, adaUser)}
                                         onDelete={() => serviceContext.onDeleteAdaUserLink && serviceContext.onDeleteAdaUserLink(adaUserLink)}
                                         style={{marginBottom: '20px'}}
                                         key={index}
                                         adaUser={adaUser}/>
                            {serviceContext.privilegeManager.canAddBundel(serviceContext.collectie, serviceContext.collectieAdaUserLinks) && !heeftBundel &&
                            <Button size='mini' onClick={() => serviceContext.onMaakBundelVoorInzender(adaUserLink)}>{serviceContext.localeManager.getString('MAAK_BUNDEL')}</Button>}
                        </div>
                    })}

                    {serviceContext.privilegeManager.canAddCollectieAdaUserLink(serviceContext.collectie, rol) &&
                    <Button size='mini'
                            onClick={() => onAddAdaUsers && onAddAdaUsers(rol)}>
                        {resolveAddButtonLabel(rol)}
                    </Button>
                    }
                </Fragment>
            })}
        </div>
    )
}

export default CollectieSettingsGebruikers

CollectieSettingsGebruikers.propTypes = {
    onAddAdaUsers: PropTypes.func,
    trigger: PropTypes.any
}
