import React, {useState, useEffect, useContext, Fragment} from 'react'
import {useParams, useHistory, useLocation} from "react-router-dom"
import {InfoViewer, ToegangsticketHistoriekActie, ToegangsticketStatus, DateUtil} from "@ahsmediatheek/ada-lib";

import ServiceContext from "../context/ServiceContext";
import ToegangsticketValidator from "../component/toegangsticket/ToegangsticketValidator";
import {format} from "date-fns";
import {ComponentIdentifier} from "../component/ComponentIdentifier";

const ToegangsticketView = ({setToegangsticket}) => {

    const [localToegangsticket, setLocalToegangsticket] = useState()
    const serviceContext = useContext(ServiceContext)
    const params = useParams()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {

        serviceContext.toegangsticketService.getToegangsticket(params.uuid).then(res => {

            if (!res) {
                history.push('/home')
                return
            }

            setLocalToegangsticket(res)

        })
    }, [])

    const onToegangAanvaard = () => {
        const initiator = serviceContext.privilegeManager.adaUser ? serviceContext.privilegeManager.adaUser.email : 'anoniem'

        serviceContext.toegangsticketService.createQuickToegangsticketHistoriek(localToegangsticket, ToegangsticketHistoriekActie.TICKET_VALIDATIE, initiator)

        const now = DateUtil.getNowMysql()
        const dateString = format(DateUtil.dateFromMysql(localToegangsticket.geldig_van), 'PPPPp', {locale: serviceContext.localeManager.dateFnsLocaleLookup[serviceContext.localeManager.locale]})

        if (localToegangsticket.status === ToegangsticketStatus.GEBLOKKEERD) {
            alert(serviceContext.localeManager.getString('TOEGANGSTICKET_GEBLOKKEERD'))
            history.push('/home')
        } else if (now < localToegangsticket.geldig_van) {
            alert(serviceContext.localeManager.getString('TT_NOG_NIET_GELDIG').replace('[[GELDIG_TOT]]', dateString))
            history.push('/home')
        } else if (now > localToegangsticket.geldig_tot) {
            //niet nodig want dan moet dit ticket al van de server verdwenen zijn?
            alert(serviceContext.localeManager.getString('TT_NIET_MEER_GELDIG'))
            history.push('/home')
        } else {
            setToegangsticket(localToegangsticket)
            alert(serviceContext.localeManager.getString('TOEGANGSTICKET_AANVAARD')
                .replace('[[CONSUMER_TYPE]]', serviceContext.localeManager.getString(localToegangsticket.consumer_type)))
            history.push(`/${localToegangsticket.consumer_type}/${localToegangsticket.consumer_uuid}`)
        }

    }

    return (

        <div className='main-content'>
            <ComponentIdentifier displayName='ToegangsticketView'/>

            {localToegangsticket && localToegangsticket.status === ToegangsticketStatus.OPEN && <Fragment>
                <InfoViewer editable={serviceContext.privilegeManager.canViewInfoButtons()}
                            refId={'TOEGANGSTICKET_WELKOM'}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>

                <ToegangsticketValidator toegangsticket={localToegangsticket} onToegangAanvaard={onToegangAanvaard}/>
            </Fragment>}

            {localToegangsticket && localToegangsticket.status === ToegangsticketStatus.GEBLOKKEERD && <Fragment>
                <InfoViewer editable={serviceContext.privilegeManager.canViewInfoButtons()}
                            refId={'TOEGANGSTICKET_GEBLOKKEERD'}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>
            </Fragment>}
        </div>
    )
}

export default ToegangsticketView
