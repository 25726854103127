import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Card} from "semantic-ui-react";
import {ApplicationPath} from "../../class/general/ApplicationPath";
import {ContentViewer} from "./ContentViewer";

const ItemCard = ({bundel, referentiebestand, locale, onClick, browser, applicationPath, children}) => {

    const [referentieSource, setReferentieSource] = useState()
    const [label, setLabel] = useState('')

    console.log('ITEM CARD', referentiebestand)

    useEffect(() => {
        if (!bundel || !referentiebestand) return
        //let op: item begint met een '/'
        setReferentieSource(`${applicationPath.bundel()}b_${bundel.uuid}/data${referentiebestand.referentiebestand}`)
        setLabel(referentiebestand.alias !== '' ? referentiebestand.alias : getCleanFilename(referentiebestand.referentiebestand))
    }, [bundel, referentiebestand])


    const getCleanFilename = (path) => {
        const pathParts = path.split('/')
        const lastPart = pathParts[pathParts.length-1]
        return lastPart.split('.').join(' - ')
    }

    if (!bundel || !locale || !referentiebestand) return null

    return (
        <Card color='orange' className='result-card' onClick={() => onClick && onClick(bundel, referentiebestand, referentieSource)}>

            {children}

            <ContentViewer streamingUrl={referentiebestand.streamingUrl}
                           streamingState={referentiebestand.streamingState}
                           mimetype={referentiebestand.mimetype} path={referentieSource} browser={browser}  />

            <Card.Content>
                <Card.Meta style={{wordWrap: 'break-word'}}>{label}</Card.Meta>
            </Card.Content>

        </Card>
    )
}

export {ItemCard}

ItemCard.propTypes = {
    bundel: PropTypes.object.isRequired,
    referentiebestand: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    browser: PropTypes.object
}

ItemCard.defaultProps = {}
