import React, {useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {BundelIcon, CollectieIcon, ResultContainer} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../../context/ServiceContext'
import {Link} from "react-router-dom";
import {CollectieCard} from "../../component/CollectieCard";

import {Divider, Segment, Button} from "semantic-ui-react";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const AdaUserCollecties = ({collecties, onCollectieSelect}) => {

    const serviceContext = useContext(ServiceContext)


    return (
        <Fragment>
            <ComponentIdentifier displayName='AdaUserCollecties'/>

            <ResultContainer containerWidth={serviceContext.resultContainerAvailableWidth}
                             display={serviceContext.resultDisplay}
                             setDisplay={serviceContext.setResultDisplay}
                             header={serviceContext.localeManager.getString('MIJN_COLLECTIES')}
                             headerIcon={<CollectieIcon size='large' style={{marginRight: '10px'}}/>}
                             extraContent={() => {
                                 return <div style={{marginBottom: '20px'}}>
                                     <Segment>
                                         {serviceContext.privilegeManager.canAddCollectie() && <Button onClick={serviceContext.openNieuweCollectieWizard}>
                                             {serviceContext.localeManager.getString('MAAK_NIEUWE_COLLECTIE')}
                                         </Button>}
                                     </Segment>
                                 </div>
                             }}
                             >

                {collecties.map((collectie, index) => {
                    return <Link key={index} to={`/collectie/${collectie.uuid}`}>
                        <CollectieCard collectie={collectie} showStatus
                                       display={serviceContext.resultDisplay}
                                       locale={serviceContext.localeManager.locale}/>
                    </Link>
                })}

            </ResultContainer>
        </Fragment>
    )
}

export default AdaUserCollecties

AdaUserCollecties.propTypes = {
    collecties: PropTypes.array.isRequired,
    onCollectieSelect: PropTypes.func
}

AdaUserCollecties.defaultProps = {}
