import React, {useContext} from 'react'

import ServiceContext from '../../context/ServiceContext'
import {InfoViewer} from "@ahsmediatheek/ada-lib";
import {EmbargoEditor} from "../../embargo/EmbargoEditor";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const BundelSettingsEmbargo = () => {

    const serviceContext = useContext(ServiceContext)

    return (
        <div>
            <ComponentIdentifier displayName='BundelSettingsEmbargo'/>

            <InfoViewer editable={serviceContext.infoEditable}
                        refId={'INFO_EMBARGOS'}
                        localeManager={serviceContext.localeManager}
                        localeDataService={serviceContext.infoLocaleDataService}/>

            {serviceContext.bundelCollectieCollectie && serviceContext.bundelCollectieCollectie.embargo
            && serviceContext.bundelCollectieCollectie.embargo !== '' &&
            <EmbargoEditor provider={serviceContext.bundelCollectieCollectie} localeManager={serviceContext.localeManager} editable={false} title={serviceContext.localeManager.getString('COLLECTIE_EMBARGO')}/>
            }

            {serviceContext.bundel && serviceContext.localeManager &&
            <EmbargoEditor provider={serviceContext.bundel} localeManager={serviceContext.localeManager}
                           editable={serviceContext.privilegeManager.canEditBundelEmbargo(serviceContext.bundelCollectieCollectie, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks)}
                           masterProvider={serviceContext.bundelCollectieCollectie}
                           onUpdate={serviceContext.onSaveBundel} title={serviceContext.localeManager.getString('BUNDEL_EMBARGO')}/>

            }

        </div>
    )
}

export default BundelSettingsEmbargo
