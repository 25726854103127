import React from 'react'
import PropTypes from 'prop-types'
import {Menu} from "semantic-ui-react";

const TaalKiezer = ({locale, onChangeLocale}) => <Menu style={{margin: 0}} text>
            <Menu.Item
                name='Nederlands'
                value='nl'
                active={locale === 'nl'}
                onClick={(event, {value}) => onChangeLocale && onChangeLocale(value)}
            />
            <Menu.Item
                name='English'
                value='en'
                active={locale === 'en'}
                onClick={(event, {value}) => onChangeLocale && onChangeLocale(value)}
            />
        </Menu>

export {TaalKiezer}

TaalKiezer.propTypes = {
    locale: PropTypes.string.isRequired,
    onChangeLocale: PropTypes.func
}

TaalKiezer.defaultProps = {

}