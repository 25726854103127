import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {ApplicationPath} from "../../class/general/ApplicationPath";
import {BundelIcon, EmbargoIcon} from "../..";

const BundelCardCarrousel = ({bundel, locale, onClick, applicationPath}) => {

    const [referentieBeeld, setReferentieBeeld] = useState()
    //const [imageWidth, setImageWidth] = useState(0)
    const [hasEmbargo, setHasEmbargo] = useState(false)



    useEffect(() => {
        if (!bundel) return


        const resolveReferentiebeeld = () => {
            return bundel.defaultReferentiebeeld !== ''
                ? bundel.defaultReferentiebeeld
                : applicationPath.referentiebeelden() + bundel.uuid + '_thumb.jpg?d=' + new Date().getTime()
        }


        setReferentieBeeld(resolveReferentiebeeld())
        setHasEmbargo(bundel.embargo && bundel.embargo !== '')

    }, [bundel])


    /*const onImageLoad = (event) => {
        setImageWidth(event.target.width)
    }*/

    if (!bundel || !locale) return null

    return (
        <div className='result-card-carrousel' onClick={() => onClick && onClick(bundel)}>
            <img src={referentieBeeld} alt='Bundel reference'/>

            <div className='icon-group-top-left'>
                <BundelIcon/>
                {hasEmbargo && <EmbargoIcon/>}
            </div>

            <div className='title-overlay'>
                {bundel[`titel_${locale}`] || bundel.titel_nl}
            </div>
        </div>
    )
}

export {BundelCardCarrousel}

BundelCardCarrousel.propTypes = {
    bundel: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

BundelCardCarrousel.defaultProps = {

}
