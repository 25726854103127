import React, {useState, useEffect, useContext} from 'react'
import {Button, Menu, Modal} from "semantic-ui-react";
import ServiceContext from '../../context/ServiceContext'
import {AdaUserCard, AdaUserLink} from "@ahsmediatheek/ada-lib";
import AdaUserSearch from "../../component/AdaUserSearch";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";


const AdminView = (props) => {

    const [activeItem, setActiveItem] = useState('algemeen')
    const [pilotUsers, setPilotUsers] = useState()
    const [adaUserSearchModalOpen, setAdaUserSearchModalOpen] = useState(false)
    const [adaUserLink, setAdaUserLink] = useState()


    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        console.log('SC', serviceContext)
        setPilotUsers(serviceContext.adaUserService.pilotAccess.map(pa => serviceContext.collectieService.adaUserService.adaUserLookup[pa.adaUser]))
    }, [serviceContext])

    const menuClick = (event, {name}) => {
        setActiveItem(name)
    }

    const onDeletePilotAccessUser = (adaUser) => {
        const pilotAccess = serviceContext.adaUserService.pilotAccess.filter(pa => pa.adaUser === adaUser.id)
        if (pilotAccess.length > 1) {
            throw (new Error('Er kan maar één pilot access record zijn!'))
        }
        serviceContext.adaUserService.deletePilotAccess(pilotAccess[0]).then(res => {
            const pilotUsers = res.map(pa => serviceContext.collectieService.adaUserService.adaUserLookup[pa.adaUser])
            console.log('DELETE', pilotUsers)
            setPilotUsers(pilotUsers)
        })
    }

    const onAddPilotAccessUser = () => {
        const adaUserLink = new AdaUserLink()
        setAdaUserLink(adaUserLink)
        setAdaUserSearchModalOpen(true)
    }

    const onModalClose = () => {
        setAdaUserSearchModalOpen(false)
    }

    const onAdaUserSelect = (adaUser) => {
        onModalClose()
        const exists = pilotUsers.map(pu => pu.id).indexOf(adaUser.id) > -1

        if (exists) {
            window.alert('De gebruiker heeft reeds toegang')
            return
        }
        serviceContext.adaUserService.createPilotAccess({adaUser: adaUser.id}).then(res => {
            const pilotUsers = res.map(pa => serviceContext.collectieService.adaUserService.adaUserLookup[pa.adaUser])
            setPilotUsers(pilotUsers)
        })
    }

    const genereerUuid = () => {
        console.log('UUID', serviceContext.bundelService.generateUuid())
    }

    return (

        <div className='main-content' style={{justifyContent: 'center'}}>
            <span>FOOOO</span>
            <ComponentIdentifier displayName='AdminView'/>


            <Menu pointing secondary>
                <Menu.Item
                    name='algemeen'
                    active={activeItem === 'algemeen'}
                    onClick={menuClick}
                />
                <Menu.Item
                    name='pilotAccess'
                    active={activeItem === 'pilotAccess'}
                    onClick={menuClick}
                />
            </Menu>


            {activeItem === 'algemeen' && <div>
                <Button onClick={genereerUuid}>Genereer uuid</Button>
            </div>}

            {activeItem === 'pilotAccess' && pilotUsers && <div>
                {pilotUsers.map((adaUser, index) => {
                    return <AdaUserCard deleteable={true}
                                        onDelete={() => onDeletePilotAccessUser(adaUser)}
                                        style={{fontSize: '1.3em', marginBottom: '20px'}}
                                        key={index}
                                        adaUser={adaUser} s/>
                })}

                <Button size='mini' onClick={onAddPilotAccessUser}>Voeg iemand toe</Button>

            </div>}

            {adaUserSearchModalOpen && adaUserLink &&
            <Modal open={adaUserSearchModalOpen} onClose={onModalClose} dimmer='inverted'>
                <Modal.Header>Voeg iemand toe voor pilot access</Modal.Header>
                <Modal.Content scrolling>
                    <AdaUserSearch adaUserLink={adaUserLink} onUserSelect={onAdaUserSelect}/>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onModalClose}>
                        {serviceContext.localeManager.getString('SLUIT')}
                    </Button>
                </Modal.Actions>

            </Modal>}


        </div>
    )
}

export default AdminView

AdminView.propTypes = {}

AdminView.defaultProps = {}
