import {v4 as uuidv4} from 'uuid'
//import moment from 'moment'
const moment = require('moment')



export class CollectieStatus {
    static readonly DRAFT: string = 'draft'
    static readonly OPEN_VOOR_INZENDEN: string = 'open_voor_inzenden'
    static readonly GESLOTEN_VOOR_INZENDEN: string = 'gesloten_voor_inzenden'
    static readonly GESLOTEN: string = 'gesloten'
    static readonly GEPUBLICEERD: string = 'gepubliceerd'
    static readonly GEARCHIVEERD: string = 'gearchiveerd'

    static readonly STATUS: string[] = [CollectieStatus.DRAFT, CollectieStatus.OPEN_VOOR_INZENDEN, CollectieStatus.GESLOTEN_VOOR_INZENDEN, CollectieStatus.GESLOTEN, CollectieStatus.GEPUBLICEERD, CollectieStatus.GEARCHIVEERD]
}

export class Collectie {
    id: number
    uuid: string = uuidv4()
    class: string = 'collectie'
    type: string = 'fysiek'
    titel_nl: string = 'Nieuwe collectie'
    titel_en: string = ''
    subtitel_nl: string
    subtitel_en: string
    beschrijving_nl: string
    beschrijving_en: string
    aangemaakt: string = moment().format('YYYY-MM-DD HH:mm:ss')
    aangemaakt_door: string = ''
    gewijzigd: string = moment().format('YYYY-MM-DD HH:mm:ss')
    academiejaar: string
    publicatietype: string
    opleiding: string
    trefwoorden: string = ''
    settings: string
    embargo: string
    status: string = CollectieStatus.DRAFT

    defaultReferentiebeeld: string = ''
}

export class CollectieActie {
    static readonly COLLECTIE_PUBLICEREN: string = 'COLLECTIE_PUBLICEREN'
    static readonly COLLECTIE_SLUITEN_VOOR_INZENDEN: string = 'COLLECTIE_SLUITEN_VOOR_INZENDEN'
    static readonly COLLECTIE_OPENEN_VOOR_INZENDEN: string = 'COLLECTIE_OPENEN_VOOR_INZENDEN'
    static readonly COLLECTIE_HEROPENEN: string = 'COLLECTIE_HEROPENEN'
    static readonly COLLECTIE_ARCHIVEREN: string = 'COLLECTIE_ARCHIVEREN'
}
