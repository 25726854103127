import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Grid, Menu} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'
import {AdaUserLink, AdaUserLinkRef} from '@ahsmediatheek/ada-lib'
import CompilatieSettingsGegevens from "./settingsCompilatie/CompilatieSettingsGegevens";
import CompilatieSettingsGebruikers from "./settingsCompilatie/CompilatieSettingsGebruikers";
import CompilatieSettingsTrefwoorden from "./settingsCompilatie/CompilatieSettingsTrefwoorden";
import AdaUserSearch from "../component/AdaUserSearch";
import CompilatieSettingsAdmin from "./settingsCompilatie/CompilatieSettingsAdmin";
import CompilatieSettingsReferentiebeeld from "./settingsCompilatie/CompilatieSettingsReferentiebeeld";
import CompilatieActies from "../component/CompilatieActies";
import {ComponentIdentifier} from "../component/ComponentIdentifier";

const VIEW_GEGEVENS = 'gegevens'
const VIEW_GEBRUIKERS = 'gebruikers'
const VIEW_TREFWOORDEN = 'trefwoorden'
const VIEW_ADMIN = 'admin'
const VIEW_REFERENTIEBEELD = 'referentiebeeld'

const MENU_GROUPS = [
    {label: 'algemeen', items: [VIEW_GEGEVENS, VIEW_TREFWOORDEN, VIEW_REFERENTIEBEELD]},
    {label: 'gebruikers', items: [VIEW_GEBRUIKERS]},
    {label: 'Admin', items: [VIEW_ADMIN]} ,
    //{label : 'Inhoud' , items : [VIEW_GEGEVENS]}
]

const CompilatieDetailSettingsView = () => {

    const [view, setView] = useState(VIEW_GEGEVENS)
    const [isDirty, setIsDirty] = useState(false)
    const [compilatieSettings, setCompilatieSettings] = useState()
    const [adaUserSearchModalOpen, setAdaUserSearchModalOpen] = useState(false)
    const [rol, setRol] = useState('')
    const [adaUserSearchSelectedUsers, setAdaUserSearchSelectedUsers] = useState([])


    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        const settings = serviceContext.compilatieService.getCompilatieSettings(serviceContext.compilatie)
        setCompilatieSettings(settings)
    }, [serviceContext.compilatie, serviceContext.compilatieService])

    const handleMenuClick = (event, {name}) => {
        if (isDirty) {
            const ok = window.confirm(serviceContext.localeManager.getString('GEGEVENS_NOG_NIET_BEWAARD'))
            if (ok) {
                setView(name)
                setIsDirty(false)
            }
        } else setView(name)

    }

    const onAddAdaUsers = (rol) => {
        setAdaUserSearchSelectedUsers(serviceContext.compilatieAdaUserLinks
            .filter(adaUserLink => adaUserLink.rol === rol)
            .map(adaUserLink => serviceContext.compilatieService.adaUserService.adaUserLookup[adaUserLink.adaUser]))
        setRol(rol)
        setAdaUserSearchModalOpen(true)
    }

    const onAdaUsersSelect = (adaUsers) => {

        const promises = []
        for (const adaUser of adaUsers) {

            const adaUserLink = new AdaUserLink()
            adaUserLink.rol = rol
            adaUserLink.ref = AdaUserLinkRef.COMPILATIE
            adaUserLink.refId = serviceContext.compilatie.id
            adaUserLink.adaUser = adaUser.id

            promises.push(serviceContext.adaUserService.createAdaUserLink(adaUserLink))
        }

        Promise.all(promises).then(res => {
            setAdaUserSearchModalOpen(false)
            serviceContext.updateAdaUserLinks()
        })

    }

    return (
        <Fragment>
            <ComponentIdentifier displayName='CompilatieDetailSettingsView'/>

            <CompilatieActies compilatie={serviceContext.compilatie}/>

            {<Grid columns={2}>
                <Grid.Column width={4}>

                    <div>
                        <Menu fluid vertical pointing>
                            {MENU_GROUPS.map((group, index) => {

                                if (group.access && group.access === 'admin' && !serviceContext.privilegeManager.isAdmin()) return null

                                return (
                                    <Fragment key={index}>
                                        <Menu.Item>
                                            <Menu.Header style={{padding: '5px', backgroundColor: '#eff6fa'}}>
                                                {serviceContext.localeManager.getString(group.label)}
                                            </Menu.Header>
                                            <Menu.Menu>
                                                {group.items.map((item, index) => {
                                                    return <Menu.Item key={index} name={item} active={view === item} onClick={handleMenuClick}>

                                                        {serviceContext.localeManager.getString(item)}
                                                        {/*getMenuIcon(item)*/}

                                                    </Menu.Item>
                                                })}
                                            </Menu.Menu>
                                        </Menu.Item>
                                    </Fragment>
                                )
                            })}
                        </Menu>
                    </div>
                </Grid.Column>

                <ServiceContext.Provider value={{...serviceContext, setIsDirty, compilatieSettings, setCompilatieSettings}}>
                    <Grid.Column style={{backgroundColor: isDirty ? 'linen' : ''}} width={12}>

                        {view === VIEW_GEGEVENS && <CompilatieSettingsGegevens/>}
                        {view === VIEW_TREFWOORDEN && <CompilatieSettingsTrefwoorden/>}
                        {view === VIEW_REFERENTIEBEELD && <CompilatieSettingsReferentiebeeld/>}
                        {view === VIEW_GEBRUIKERS && <CompilatieSettingsGebruikers onAddAdaUsers={onAddAdaUsers}/>}
                        {view === VIEW_ADMIN && <CompilatieSettingsAdmin/>}

                    </Grid.Column>
                </ServiceContext.Provider>
            </Grid>}

            {adaUserSearchModalOpen &&
            <AdaUserSearch modalOpen={adaUserSearchModalOpen} rol={rol}
                           adaUsers={adaUserSearchSelectedUsers}
                           onUsersSelect={onAdaUsersSelect}
                           academiejaar={serviceContext.compilatie.academiejaar}
            opleiding={serviceContext.compilatie.opleiding}/>}
        </Fragment>
    )
}

export default CompilatieDetailSettingsView
