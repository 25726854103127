import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'

const DropzoneError = ({localeManager, errors, substitutionFileType}) => {

    const [errStrings, setErrStrings] = useState([])
    useEffect(() => {
        const errStrings = []
        const filteredErrors = errors.reduce((fErrors, errorcode) => {
            if (!fErrors.includes(errorcode)) fErrors.push(errorcode)
            return fErrors
        }, [])
        for (const error of filteredErrors)  {
            errStrings.push(
                localeManager.getString(`DZ_${error.toUpperCase()}`)
                    .replace('[[FILE_TYPE]]', substitutionFileType)
            )
        }
        setErrStrings(errStrings)
    }, [errors, substitutionFileType])

    return (
        <div>
            {errStrings.map((errString, index) => {
                return <div key={index}>{errString}</div>
            })}
        </div>
    )
}

export default DropzoneError

DropzoneError.propTypes = {
    localeManager: PropTypes.object.isRequired,
    errors: PropTypes.array.isRequired,
    substitutionFileType: PropTypes.string,
}

DropzoneError.defaultProps = {}
