import React, {useState, useContext, useCallback} from 'react'
import PropTypes from 'prop-types'
import {Segment, Button} from "semantic-ui-react";
import Dropzone from "react-dropzone";
import {InfoViewer, Bundel, CollectieBundel, ApplicationPath, AdaUserLink, AdaUserLinkRef, AdaUserRol} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../../context/ServiceContext'
import DropzoneError from "./DropzoneError";
import {allowedHeaders , parseBundelCsv} from "./BundelCsvParser";
import VirtualBundelRenderer from "./VirtualBundelRenderer";
import {ComponentIdentifier} from "../ComponentIdentifier";
import { get } from 'http';

const VIEW_IDLE = 'idle'
const VIEW_PREVIEW = 'preview'
const VIEW_COMPLETE = 'complete'

const DropzoneBundelsCsv = ({collectie, onCancel, onUploadsComplete}) => {

    const [view, setView] = useState(VIEW_IDLE)
    //const [statusMessage, setStatusMessage] = useState('')
    const [errors, setErrors] = useState()
    const [csvFileErrors, setCsvFileErrors] = useState()
    const [previewBundels, setPreviewBundels] = useState()
    const [uploadBundelsButtonEnabled, setUploadBundelsButtonEnabled] = useState(true)

    const serviceContext = useContext(ServiceContext)

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {

        setErrors(null)
        setCsvFileErrors(null)
        setUploadBundelsButtonEnabled(true)

        if (rejectedFiles && rejectedFiles.length > 0) {
            setErrors(rejectedFiles.reduce((errors, file) => {
                return errors.concat(file.errors.map(error => error.code))
            }, []))
            return
        } else {
            setErrors(null)
        }

        //hier kan er maar één correcte file zijn

        readFile(acceptedFiles[0]).then(rawData => {
            parseBundelCsv(rawData)
                .then(res => {
                    console.log(res)
                    const bundels = res.data.map(bundelData => {                        
                        const bundel = new Bundel()
                        bundel.titel_nl = bundelData.titel_nl
                        bundel.titel_en = bundelData.titel_en
                        bundel.subtitel_nl = bundelData.subtitel_nl
                        bundel.subtitel_en = bundelData.subtitel_en
                        bundel.abstract_nl = bundelData.abstract_nl
                        bundel.abstract_en = bundelData.abstract_en
                        bundel.academiejaar = bundelData.academiejaar
                        bundel.opleiding = bundelData.opleiding
                        bundel.publicatietype = bundelData.publicatietype
                        bundel.compilaties = bundelData.compilaties
                        bundel.trefwoorden = bundelData.trefwoorden

                        let keys = Object.keys(bundelData)
                        //console.log(keys,allowedHeaders)
                        let unused_headers = []
                        keys.map(key => {                           
                            if(!allowedHeaders.includes(key)) {
                                unused_headers.push(key)
                            } 
                                                            
                        })
                        bundelData['unused_headers'] = unused_headers
                        bundel.bundelData = bundelData
                        //console.log(bundel)
                        return bundel
                    })
                    setView(VIEW_PREVIEW)
                    setPreviewBundels(bundels)
                })
                .catch(errors => setCsvFileErrors(errors))
        })

    }, [serviceContext.localeManager])

    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.onload = () => {
                const fileContent = reader.result
                resolve(fileContent)
            }
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')

            reader.readAsText(file)
        })
    }

    const onAnnuleer = () => {
        onCancel()
    }

    const onMaakBundels = async () => {
        
        console.log(previewBundels)
        
        const promises = []
        

        const adaUsersLinksInzendersArray = async (previewBundel) => {
            return new Promise(( resolve , reject ) => {
                if (previewBundel.inzenders){              
                    Promise.all(
                        (previewBundel.inzenders).map(
                                serviceContext.adaUserService.addIncompleteAdaUser
                        )
                    ).then(res => 
                            res.map(getInzenderId => {
                                console.log(getInzenderId)
                                const bundelEigenaar = new AdaUserLink()
                                bundelEigenaar.adaUser = getInzenderId.id
                                bundelEigenaar.ref = AdaUserLinkRef.BUNDEL
                                bundelEigenaar.rol = AdaUserRol.EIGENAAR
                                return bundelEigenaar
                            })  
        
                    ).then(resolve)
                } else { resolve([])}   
            })

        } 

        const adaUserLinksPromotorenArray = async (previewBundel) => {
            return new Promise((resolve,reject) => {
                if (previewBundel.promotors){
                    //for (const promotor of previewBundel.promotors) {
                    Promise.all(
                        (previewBundel.promotors).map( 
                            serviceContext.adaUserService.addIncompleteAdaUser
                        )
                    ).then(res => 
                        res.map(getInzenderId => {
                            const bundelPromotor = new AdaUserLink()
                            bundelPromotor.adaUser = getInzenderId.id
                            bundelPromotor.ref = AdaUserLinkRef.BUNDEL
                            bundelPromotor.rol = AdaUserRol.PROMOTOR
                            return bundelPromotor
                        })
                    ).then(resolve)
                //resolve(adaUserLinksPromotoren)
                } else { resolve([])}
                
            })
        }
        
       

        for (const previewBundel of previewBundels) {
            const adaUserLinksAsync = (await Promise.all( [ adaUserLinksPromotorenArray(previewBundel) , adaUsersLinksInzendersArray(previewBundel) ])).flat()
            console.log(adaUserLinksAsync)
            previewBundel.defaultReferentiebeeld = serviceContext.applicationPath.geen_referentiebeeld()
            previewBundel.academiejaar = collectie.academiejaar
            previewBundel.opleiding = collectie.opleiding
            if(previewBundel.trefwoorden != null) {
                previewBundel.trefwoorden = (previewBundel.trefwoorden).split(',').join('|')
            }
            //Collectiebundel
            const collectieBundel = new CollectieBundel()
            collectieBundel.bundel_uuid = previewBundel.uuid
            collectieBundel.collectie = collectie.id
            collectieBundel.collectie_uuid = collectie.uuid

            //const adaUserLinksAsync = adaUsersLinksArray(previewBundel)
            

            if(previewBundel.compilaties) {
                previewBundel.compilaties.map((compilatieUuid) => {
                    console.log('serviceContext>addToCompilatie',compilatieUuid,'bundel',previewBundel.uuid)
                    promises.push(serviceContext.compilatieService.addToCompilatie( { uuid : compilatieUuid } , 'bundel' , previewBundel.uuid ))
                })
            }

            serviceContext.bundelService.createBundel(previewBundel).then(b => {
                collectieBundel.bundel = b.id
                promises.push(serviceContext.bundelService.createCollectieBundel(collectieBundel, previewBundel))

                console.log("CreateBundel",adaUserLinksAsync)
                for (const adaUserLink of adaUserLinksAsync) {
                    adaUserLink.refId = b.id
                    promises.push(serviceContext.adaUserService.createAdaUserLink(adaUserLink))
                }

                Promise.all(promises).then((res) => {
                    onUploadsComplete()
                })
            })
        }
        
    }

    return (
        <div>
            <ComponentIdentifier displayName='DropzoneBundelCsv'/>

            {view !== VIEW_COMPLETE && <div>
                <InfoViewer refId='INFO_UPLOAD_BUNDELS_CSV'
                            editable={serviceContext.infoEditable}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>
            </div>}

            <div>
                {/*<Header as='h3'>{statusMessage}</Header>*/}
                <Dropzone onDrop={onDrop} maxFiles={1} accept='.csv'>
                    {({getRootProps, getInputProps}) => {
                        return <div className='dropzone' style={{justifyContent: 'left'}} {...getRootProps()}>
                            <input {...getInputProps()} accept=".csv,text/csv,application/vnd.ms-excel,application/csv,text/x-csv application/x-csv,text/comma-separated-values,text/x-comma-separated-values" />

                            {errors && <DropzoneError errors={errors} localeManager={serviceContext.localeManager} substitutionFileType='.csv'/>}
                            {csvFileErrors && Array.isArray(csvFileErrors) && <div>
                                {console.log(csvFileErrors)}
                                {csvFileErrors.map((error, index) => {
                                    return <div key={index}>
                                        {serviceContext.localeManager.getString(error.error).replace('[[subst]]', error.subst)}
                                    </div>
                                })}
                            </div>}
                            {csvFileErrors && !Array.isArray(csvFileErrors) && <div>
                                {console.log(csvFileErrors.message)}
                                {console.log(typeof csvFileErrors)}
                                    <div>
                                        {csvFileErrors.message}
                                    </div>
                                </div>}

                            {view === VIEW_IDLE && !csvFileErrors && <div>
                                {serviceContext.localeManager.getString('SLEEP_CSV_HIERNAARTOE')}
                            </div>}

                            {previewBundels && <div style={{width: '100%'}}>
                                {previewBundels.map((bundel, index) => {
                                    return <VirtualBundelRenderer key={bundel.uuid}
                                                                  bundel={bundel}
                                                                  keyNumber={index + 1}
                                                                  invalidateUploadButton={() => setUploadBundelsButtonEnabled(false)}/>
                                })}
                            </div>}
                        </div>
                    }}

                </Dropzone>
            </div>

            {view === VIEW_IDLE && <Segment>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={onAnnuleer}>{serviceContext.localeManager.getString('ANNULEER')}</Button>
                </div>
            </Segment>}

            {view === VIEW_PREVIEW && previewBundels && previewBundels.length > 0 && <Segment>

                <InfoViewer refId='INFO_UPLOAD_BUNDELS_CSV_VIRTUAL'
                            editable={serviceContext.infoEditable}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={onAnnuleer}>{serviceContext.localeManager.getString('ANNULEER')}</Button>
                    <Button onClick={onMaakBundels} color='green' disabled={previewBundels.length === 0 || !uploadBundelsButtonEnabled}>{serviceContext.localeManager.getString('MAAK_BUNDELS').replace('[[count]]', previewBundels.length)}</Button>
                </div>
            </Segment>}

            {view === VIEW_COMPLETE && <Segment>
                <InfoViewer refId='INFO_UPLOAD_BUNDELS_COMPLETE_CSV'
                            editable={serviceContext.infoEditable}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button color='green' onClick={onUploadsComplete}>{serviceContext.localeManager.getString('SLUIT')}</Button>
                </div>
            </Segment>}
        </div>
    )
}

export default DropzoneBundelsCsv

DropzoneBundelsCsv.propTypes = {
    collectie: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onUploadsComplete: PropTypes.func,
}

DropzoneBundelsCsv.defaultProps = {}
