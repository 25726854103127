import React, {useState, useEffect, useContext, Fragment} from 'react'
import {useParams, useHistory} from "react-router-dom"
import {Segment, Divider, Label, Dimmer, Loader, Icon} from "semantic-ui-react";
import {
    ApplicationPath,
    CollectieIcon,
    SettingsIcon,
    EmbargoIcon,
    Spacer,
    PreviousIcon,
    Bundel,
    CollectieBundel,
    AdaUserLinkRef,
    AdaUserLink,
    AdaUserRol,
    InfoViewer,
    MetadataUtil
} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../context/ServiceContext'
import CollectieDetailMainView from "./CollectieDetailMainView";
import CollectieDetailSettingsView from "./CollectieDetailSettingsView";
import orderBy from 'lodash/orderBy'
import Parser from "html-react-parser";
import ProviderStatus from "../component/ProviderStatus";
import {ComponentIdentifier} from "../component/ComponentIdentifier";

//const IDLE = 'idle'
//const COLLECTIE_LOADING = 'collectieLoading'
const BUNDELS_LOADING = 'bundelsLoading'
const COLLECTIE_DETAIL_MAIN_VIEW = 'collectieDetailMainView'
const COLLECTIE_DETAIL_SETTINGS_VIEW = 'collectieDetailSettingView'

const CollectieView = (props) => {

    const [bundels, setBundels] = useState()
    const [collectieAdaUserLinks, setCollectieAdaUserLinks] = useState()
    const [view, setView] = useState(BUNDELS_LOADING)
    const [localeContent, setLocaleContent] = useState()
    const [collectie, setCollectie] = useState()
    const [uuid, setUuid] = useState()

    const [referentieBeeld, setReferentieBeeld] = useState()
    const [resolvedCollectieEmbargo, setResolvedCollectieEmbargo] = useState()
    const [opleidingString, setOpleidingString] = useState('')
    const [collectieTitel, setCollectieTitel] = useState('')

    const [toegangsticketEigenaar, setToegangsticketEigenaar] = useState()
    const serviceContext = useContext(ServiceContext)


    const params = useParams()
    const history = useHistory()

    //COLLECTIE
    useEffect(() => {
        if (!params.uuid) return
        if (uuid === params.uuid) return

        //uuid kan zowel een collectie uuid zijn of een toegangsticket uuid

        serviceContext.collectieService.getCollectie(params.uuid).then(res => {
            if (!res) {
                history.push('/home')
                return
            }
            if (res !== collectie) setCollectie(res)
            setUuid(res.uuid)
        })
    }, [params.uuid, collectie, history, serviceContext.collectieService, uuid])

    //TICKET
    useEffect(() => {
        if (!serviceContext.toegangsticket) return
        serviceContext.adaUserService.getAdaUser(serviceContext.toegangsticket.aangemaakt_door).then(au => {
            console.log('TT EIG', au)
            setToegangsticketEigenaar(au)
        })
    }, [serviceContext.toegangsticket])

    useEffect(() => {
        if (!collectie) return

        const resolveReferentiebeeld = () => {
            return collectie.defaultReferentiebeeld !== ''
                ? collectie.defaultReferentiebeeld
                : serviceContext.applicationPath.referentiebeelden() + collectie.uuid + '.jpg?d=' + new Date().getTime()
        }

        setReferentieBeeld(resolveReferentiebeeld())

        //async
        Promise.all([
            serviceContext.bundelService.getCollectieBundels(collectie),
            serviceContext.adaUserService.getAdaUserLinks(AdaUserLinkRef.COLLECTIE, collectie.id),
            serviceContext.collectieLocaleDataService.getLocaleData(collectie.id),
            serviceContext.arteveldeApiService.getOpleidingen(collectie.academiejaar),
        ])
            .then(([bundels, collectieAdaUserLinks, localeContentResponse, opleidingen, trefwoorden]) => {
                if (collectie.newlyCreated) {
                    collectie.newlyCreated = false
                    setView(COLLECTIE_DETAIL_SETTINGS_VIEW)
                }

                setCollectieAdaUserLinks(collectieAdaUserLinks)
                setLocaleContent(localeContentResponse.localeContent)
                setBundels(orderBy(bundels, `titel_${serviceContext.localeManager.locale}`))
            }).catch(error => console.log('PA ERROR', error))


    }, [collectie, serviceContext.adaUserService, serviceContext.arteveldeApiService, serviceContext.bundelService, serviceContext.collectieLocaleDataService, serviceContext.localeManager.locale])

    //EMBARGO
    useEffect(() => {
        if (!collectie || !collectieAdaUserLinks || !serviceContext.privilegeManager) return

        serviceContext.privilegeManager.resolveEmbargo(collectie, collectieAdaUserLinks, 'metadata', serviceContext.toegangsticket).then(resp => {
                setResolvedCollectieEmbargo(resp)
                if (resp.allow && view === BUNDELS_LOADING) {
                    setView(COLLECTIE_DETAIL_MAIN_VIEW)
                }
            }
        )
    }, [collectie, collectieAdaUserLinks, serviceContext.privilegeManager, view])

    //OPLEIDING
    useEffect(() => {
        if (!collectie) return
        serviceContext.arteveldeApiService.getOpleidingen(collectie.academiejaar).then(() => {
            setOpleidingString(serviceContext.arteveldeApiService.getOpleidingString(collectie.academiejaar, collectie.opleiding, serviceContext.localeManager.locale))
        })

        setCollectieTitel(MetadataUtil.resolveTitel(collectie, serviceContext.localeManager.locale))
    }, [collectie, serviceContext.localeManager.locale, serviceContext.arteveldeApiService])


    const onSettingsIconClick = () => {
        setView(COLLECTIE_DETAIL_SETTINGS_VIEW)
    }

    const onAddBundel = (bundel) => {
        bundel.opleiding = collectie.opleiding
        const collectieBundel = new CollectieBundel()
        collectieBundel.collectie = collectie.id //obsolete
        collectieBundel.collectie_uuid = collectie.uuid

        serviceContext.bundelService.createBundel(bundel).then(b => {
            collectieBundel.bundel = b.id //obsolete
            collectieBundel.bundel_uuid = b.uuid
            serviceContext.bundelService.createCollectieBundel(collectieBundel, bundel).then(cb => {
                //router redirect
                //useCallback(() => history.push('/bundel/' + b.uuid), [history]);
                history.push('/bundel/' + b.uuid)
            })
        })
    }

    const onDeleteAdaUserLink = (adaUserLink) => {

        serviceContext.adaUserService.deleteAdaUserLink(adaUserLink).then(res => {
            setCollectieAdaUserLinks(res)
        })
    }

    const updateAdaUserLinks = () => {
        serviceContext.adaUserService.getAdaUserLinks(AdaUserLinkRef.COLLECTIE, collectie.id).then(res => {
            setCollectieAdaUserLinks([...res])
        })
    }

    const onMaakBundelVoorInzender = (adaUserLink) => {

        const bundel = new Bundel()
        bundel.defaultReferentiebeeld = serviceContext.applicationPath.geen_referentiebeeld()
        bundel.academiejaar = collectie.academiejaar
        bundel.opleiding = collectie.opleiding

        const collectieBundel = new CollectieBundel()
        collectieBundel.collectie = collectie.id //deprecated?
        collectieBundel.collectie_uuid = collectie.uuid

        const bundelEigenaar = new AdaUserLink()
        bundelEigenaar.adaUser = adaUserLink.adaUser
        bundelEigenaar.ref = AdaUserLinkRef.BUNDEL
        bundelEigenaar.rol = AdaUserRol.EIGENAAR

        serviceContext.bundelService.createBundel(bundel).then(b => {
            collectieBundel.bundel = b.id
            bundelEigenaar.refId = b.id

            Promise.all([
                serviceContext.bundelService.createCollectieBundel(collectieBundel, bundel),
                serviceContext.adaUserService.createAdaUserLink(bundelEigenaar)
            ]).then(([cb, aul]) => {
                serviceContext.adaUserService.getAdaUserLinks(AdaUserLinkRef.COLLECTIE, collectie.id).then(res => {
                    setCollectieAdaUserLinks(res)
                    setBundels(bundels.concat(b))
                })

            })
        })
    }

    const onSaveCollectie = (collectie, changedLocales) => {
        if (changedLocales && changedLocales.length > 0) {
            for (let localeContentObject of changedLocales) {
                if (localeContentObject.field === 'titel' && localeContentObject.locale === 'nl') collectie.titel_nl = localeContentObject.content
                if (localeContentObject.field === 'titel' && localeContentObject.locale === 'en') collectie.titel_en = localeContentObject.content
                if (localeContentObject.field === 'subtitel' && localeContentObject.locale === 'nl') collectie.subtitel_nl = localeContentObject.content
                if (localeContentObject.field === 'subtitel' && localeContentObject.locale === 'en') collectie.subtitel_en = localeContentObject.content
                if (localeContentObject.field === 'beschrijving' && localeContentObject.locale === 'nl') collectie.beschrijving_nl = localeContentObject.content
                if (localeContentObject.field === 'beschrijving' && localeContentObject.locale === 'en') collectie.beschrijving_en = localeContentObject.content
            }
        }

        serviceContext.collectieLocaleDataService.saveLocaleData(changedLocales).then(result => {
            serviceContext.collectieService.updateCollectie(collectie).then(res => {
                setCollectie({...collectie})
            })
        })
    }

    const onReferentiebeeldUploaded = (provider) => {
        provider.defaultReferentiebeeld = ''
        serviceContext.collectieService.updateCollectie(provider).then(res => {
            setCollectie({...collectie})
        })
    }

    if (!collectie || !serviceContext.privilegeManager) return null

    return (
        <ServiceContext.Provider value={{
            ...serviceContext, collectie, onSaveCollectie, collectieAdaUserLinks,
            bundels, localeContent, onDeleteAdaUserLink,
            updateAdaUserLinks, onMaakBundelVoorInzender, onReferentiebeeldUploaded
        }}><Fragment>

            <ComponentIdentifier displayName='CollectieView' info={collectie && `uuid: ${collectie.uuid} | id: ${collectie.id}`}/>


            <div className='detail-view-header'
                 style={{
                     backgroundImage: `url(${referentieBeeld})`,
                     backgroundSize: 'cover',
                     backgroundPosition: 'center center',
                     backgroundRepeat: 'no-repeat',
                 }}>
                <div className='detail-view-titel'>
                    <CollectieIcon style={{flexShrink: 0}}/>
                    <div style={{marginLeft: '10px'}}>{collectieTitel}</div>

                </div>

                {collectieAdaUserLinks && serviceContext.privilegeManager.canEditCollectieSettings(collectie, collectieAdaUserLinks) &&
                <div className='detail-view-icons'>
                    <SettingsIcon onClick={onSettingsIconClick}/>
                </div>}

                <div className='detail-view-message'>
                    <ProviderStatus provider={collectie}/>
                </div>

            </div>


            <div className='main-content'>

                {serviceContext.toegangsticket && toegangsticketEigenaar &&
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}} secondary>
                    <Icon name='ticket' size='huge' color='orange'/>
                    <InfoViewer editable={serviceContext.privilegeManager.canViewInfoButtons()}
                                refId={'TT_HEADER_IN_MAIN_VIEW'}
                                showIcons={false}
                                substitutions={{
                                    '[[CONSUMER_TYPE]]': serviceContext.localeManager.getString(serviceContext.toegangsticket.consumer_type),
                                    '[[AANGEMAAKT_DOOR]]': toegangsticketEigenaar.email}}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>
                </div>}

                {view === BUNDELS_LOADING &&
                <Segment padded='very'>
                    {!resolvedCollectieEmbargo && <Dimmer active inverted>
                        <Loader inverted>{serviceContext.localeManager.getString('EMBARGO_RESOLVING')}</Loader>
                    </Dimmer>}

                    {resolvedCollectieEmbargo && <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.2em'}}>
                        <EmbargoIcon/>
                        <Spacer vertical distance={20}/>
                        {Parser(serviceContext.localeManager.getString(resolvedCollectieEmbargo.reason).replace('[[opleiding]]', '<strong>' + opleidingString + '</strong>'))}

                    </div>}
                </Segment>
                }

                {view !== COLLECTIE_DETAIL_MAIN_VIEW &&
                <Fragment>
                    <Label as='a' onClick={() => setView(COLLECTIE_DETAIL_MAIN_VIEW)}><PreviousIcon/> {serviceContext.localeManager.getString('TERUG_NAAR_COLLECTIE')}</Label>
                    <Divider/>
                </Fragment>
                }

                {view === COLLECTIE_DETAIL_MAIN_VIEW && collectieAdaUserLinks && bundels &&
                <CollectieDetailMainView onAddBundel={onAddBundel}/>}


                {view === COLLECTIE_DETAIL_SETTINGS_VIEW && collectieAdaUserLinks &&
                <CollectieDetailSettingsView/>}



            </div>
        </Fragment>
        </ServiceContext.Provider>
    )
}

export default CollectieView

CollectieView.propTypes = {}

CollectieView.defaultProps = {}
