import React, {useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import ServiceContext from '../../context/ServiceContext'
import {AdaUserCard, Spacer, InfoViewer, AdaUserRol} from "@ahsmediatheek/ada-lib";
import {Header, Button} from "semantic-ui-react";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const BundelSettingsGebruikers = ({onAddAdaUsers, onDeleteAdaUserLink}) => {

    const serviceContext = useContext(ServiceContext)

    const resolveAddButtonLabel = (rol) => {
        return serviceContext.localeManager.getString('VOEG_ITEM_TOE').replace('[[item]]', rol.toLowerCase())
    }

    console.log('BUNDEL GEBR', serviceContext.bundelAdaUserLinks)

    return (
        <div>
            <ComponentIdentifier displayName='BundelSettingsGebruikers'/>

            {AdaUserRol.BUNDEL_ROLLEN.map(rol => {
                return <Fragment key={rol}>
                    <Header dividing size='huge'>{serviceContext.localeManager.getString(rol)}s</Header>

                    <InfoViewer refId={`INFO_SETTINGS_BUNDEL_MANAGERS_${rol.toUpperCase()}`}
                                editable={serviceContext.infoEditable}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>
                    <Spacer vertical distance={20}/>

                    {serviceContext.bundelAdaUserLinks.filter(adaUserLink => adaUserLink.rol === rol).map((adaUserLink, index) => {
                        const adaUser = serviceContext.adaUserService.adaUserLookup[adaUserLink.adaUser]
                        return <AdaUserCard deleteable={serviceContext.privilegeManager.canDeleteBundelAdaUserLink(serviceContext.bundel, adaUser)}
                                            onDelete={() => serviceContext.onDeleteAdaUserLink && serviceContext.onDeleteAdaUserLink(adaUserLink)}
                                            style={{marginBottom: '20px'}}
                                            key={index}
                                            adaUser={adaUser} s/>
                    })}


                    {serviceContext.privilegeManager.canAddBundelAdaUserLink(serviceContext.bundel) &&
                    <Button size='mini' onClick={() => onAddAdaUsers && onAddAdaUsers(rol)}>{resolveAddButtonLabel(rol)}</Button>
                    }
                </Fragment>
            })}
        </div>
    )
}

export default BundelSettingsGebruikers

BundelSettingsGebruikers.propTypes = {
    onAddAdaUsers: PropTypes.func,
    trigger: PropTypes.any
}
