import {format, roundToNearestMinutes} from 'date-fns'

export class DateUtil {


    static readonly MYSQL_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'
    static readonly MYSQL_DATE_FORMAT = 'yyyy-MM-dd'

    static getNowMysql = (useTime: boolean = true, round: number = 1) => {
        return format(roundToNearestMinutes(new Date(), { nearestTo: round }), useTime ? DateUtil.MYSQL_DATE_TIME_FORMAT : DateUtil.MYSQL_DATE_FORMAT)
    }

    static dateFromMysql = (mysql) => {
        return new Date(mysql.replace(/-/g, '/'))
    }
}
