import React, {useState, useEffect, useContext} from 'react'
import ServiceContext from '../../context/ServiceContext'
import {Form, Header} from "semantic-ui-react"
import {LocaleContentViewer} from '@ahsmediatheek/med-component-locale-content'
//import '@ahsmediatheek/med-component-locale-content/build/index.css'
import {TrefwoordenViewer} from '@ahsmediatheek/med-component-trefwoorden'
import {SaveBar, InfoViewer, MetadataUtil} from "@ahsmediatheek/ada-lib"
import AcademiejaarKiezer from "../../component/dropdown/AcademiejaarKiezer"
import OpleidingKiezer from "../../component/dropdown/OpleidingKiezer"
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const CompilatieSettingsGegevens = (props) => {

    const [isDirty, setIsDirty] = useState(false)

    const [compilatie, setCompilatie] = useState()
    const [localeContent, setLocaleContent] = useState()
    const [changedLocales, setChangedLocales] = useState([])
    const [opleidingen, setOpleidingen] = useState()
    const [olods] = useState()

    const serviceContext = useContext(ServiceContext)


    useEffect(() => {
        setCompilatie({...serviceContext.compilatie})
    }, [serviceContext.compilatie])

    useEffect(() => {
        setLocaleContent(serviceContext.localeContent.map(localeData => ({...localeData})))
    }, [serviceContext.localeContent])

    useEffect(() => {
        if (!compilatie || !compilatie.academiejaar) return
        serviceContext.arteveldeApiService.getOpleidingen(compilatie.academiejaar).then(res => {
            setOpleidingen(res)
        })
    }, [compilatie, serviceContext.arteveldeApiService])

    /*useEffect(() => {
        if (!compilatie || !compilatie.opleiding) return

        Promise.all(compilatie.opleiding.split(',').map(opleiding => serviceContext.arteveldeApiService.getOpleidingOlods(opleiding))).then(res => {
            //setOlods(res)
        })
    }, [compilatie, serviceContext.arteveldeApiService])*/

    const changeDirty = (value) => {
        setIsDirty(value)
        serviceContext.setIsDirty(value)
    }

    const onLocaleContentChange = (localeContentObject, data, plainText) => {
        localeContentObject.content = data
        changeDirty(true)
//console.log('CHANGE TITEL', localeContentObject, data)
        if (localeContentObject.field === 'titel' && localeContentObject.locale === 'nl') compilatie.titel_nl = localeContentObject.content
        if (localeContentObject.field === 'titel' && localeContentObject.locale === 'en') compilatie.titel_en = localeContentObject.content
        if (localeContentObject.field === 'beschrijving' && localeContentObject.locale === 'nl') compilatie.beschrijving_nl = localeContentObject.content
        if (localeContentObject.field === 'beschrijving' && localeContentObject.locale === 'en') compilatie.beschrijving_en = localeContentObject.content

        setCompilatie({...compilatie})

        if (!changedLocales.includes(localeContentObject.id))
            changedLocales.push(localeContentObject.id)
    }

    const onChangeCompilatieProp = (prop, value) => {
        changeDirty(true)
        setCompilatie({...compilatie, [prop]: value})
    }

    /* const onChangeOpleiding = (prop, value) => {
         console.log('OPLEIDING', prop, value)
         changeDirty(true)
         setCompilatie({...compilatie, opleiding: value})
     }*/

    const onChangeAcademiejaar = (aj) => {
        changeDirty(true)

        compilatie.opleiding = null
        compilatie.academiejaar = aj
        serviceContext.arteveldeApiService.getOpleidingen(compilatie.academiejaar).then(res => {
            setOpleidingen(res)
        })

        setCompilatie({...compilatie})
    }

    const onCancel = () => {
        changeDirty(false)
        setCompilatie({...serviceContext.compilatie})
        setLocaleContent(serviceContext.localeContent.map(localeData => ({...localeData})))
    }

    const onSubmit = (...args) => {

        //checken of er een titel beschikbaar is!
        if(MetadataUtil.resolveTitel(compilatie) === ''){
            window.alert(serviceContext.localeManager.getString('TITEL_MAG_NIET_LEEG_ZIJN'))
            return
        }

        changeDirty(false)
        let cLocales = localeContent.filter(item => changedLocales.includes(item.id))
        serviceContext.onSaveCompilatie && serviceContext.onSaveCompilatie(compilatie, cLocales)
        setChangedLocales([])
    }

    const onChangeTrefwoorden = (trefwoorden) => {
            changeDirty(true)
            setCompilatie({...compilatie, trefwoorden: trefwoorden.join('|')})
    }


    const candidateContentFunction = (candidate) => {
        return <InfoViewer editable={serviceContext.infoEditable}
                           refId={'INFO_TREFWOORD_KANDIDAAT_TOEVOEGEN'}
                           localeManager={serviceContext.localeManager}
                           localeDataService={serviceContext.infoLocaleDataService}/>
    }

    return (
        <Form>
            <ComponentIdentifier displayName='CompilatieSettingsGegevens'/>

            <Header dividing size='huge'>{serviceContext.localeManager.getString('METADATA')}</Header>

            <InfoViewer editable={serviceContext.infoEditable}
                        refId={'INFO_COMPILATIE_TITEL_BESCHRIJVING'}
                        localeManager={serviceContext.localeManager}
                        localeDataService={serviceContext.infoLocaleDataService}/>

            {compilatie && localeContent &&
            <Form.Field>
                <label>{serviceContext.localeManager.getString('TITEL')}</label>
                <LocaleContentViewer placeholder={serviceContext.localeManager.getString('TITEL')}
                                     localeContent={localeContent}
                                     referenceId={compilatie.id.toString()}
                                     dataField='titel'
                                     noLocaleWarning={serviceContext.localeManager.getString('GEEN_CONTENT_VOOR_LOCALE')}
                                     editMode='plain'
                                     singleLine
                                     maxChars={80} optimalChars={30}
                                     maxCharsLabel={serviceContext.localeManager.getString('MAXIMUM_AANTAL_KARAKTERS')}
                                     optimalCharsLabel={serviceContext.localeManager.getString('OPTIMAAL_AANTAL_KARAKTERS')}
                                     onChange={onLocaleContentChange}/>
            </Form.Field>}

            {compilatie && localeContent &&
            <Form.Field>
                <label>{serviceContext.localeManager.getString('SUBTITEL')}</label>
                <LocaleContentViewer placeholder={serviceContext.localeManager.getString('SUBTITEL')}
                                     localeContent={localeContent}
                                     referenceId={compilatie.id.toString()}
                                     dataField='subtitel'
                                     noLocaleWarning={serviceContext.localeManager.getString('GEEN_CONTENT_VOOR_LOCALE')}
                                     editMode='plain'
                                     maxChars={150}
                                     maxCharsLabel={serviceContext.localeManager.getString('MAXIMUM_AANTAL_KARAKTERS')}
                                     optimalCharsLabel={serviceContext.localeManager.getString('OPTIMAAL_AANTAL_KARAKTERS')}
                                     onChange={onLocaleContentChange}/>
            </Form.Field>}

            {compilatie && localeContent &&
            <Form.Field>
                <label>{serviceContext.localeManager.getString('BESCHRIJVING')}</label>
                <LocaleContentViewer placeholder={serviceContext.localeManager.getString('BESCHRIJVING')}
                                     localeContent={localeContent}
                                     referenceId={compilatie.id.toString()}
                                     dataField='beschrijving'
                                     noLocaleWarning={serviceContext.localeManager.getString('GEEN_CONTENT_VOOR_LOCALE')}
                                     editMode='plain'
                                     maxChars={300}
                                     maxCharsLabel={serviceContext.localeManager.getString('MAXIMUM_AANTAL_KARAKTERS')}
                                     optimalCharsLabel={serviceContext.localeManager.getString('OPTIMAAL_AANTAL_KARAKTERS')}
                                     onChange={onLocaleContentChange}/>
            </Form.Field>}

            {compilatie && <Form.Field>
                <label>{serviceContext.localeManager.getString('TREFWOORDEN')}</label>
                <InfoViewer editable={serviceContext.infoEditable}
                            refId={'INFO_COMPILATIE_TREFWOORDEN'}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>
                <TrefwoordenViewer consumerKey={serviceContext.TREFWOORDEN_CONSUMER_KEY}
                                   allowCandidates={true}
                                   onChangeTrefwoorden={onChangeTrefwoorden}
                                   trefwoordenString={compilatie.trefwoorden}
                                   locale={serviceContext.localeManager.locale}
                                   candidateInfoContent={candidateContentFunction}
                                   labelFunction={serviceContext.localeManager.getString}/>
            </Form.Field>}

            {olods && <Form.Field>
                <label>{serviceContext.localeManager.getString('OLOD')}</label>
                {/*<OlodKiezer olods={olods}
                                 localeManager={serviceContext.localeManager}
                                 selectedOlod={compilatie.olod}
                                 onSelectOlod={(olod) => onChangeCompilatieProp('olod', olod)}/>*/}
            </Form.Field>}

            {isDirty && <SaveBar cancelButtonLabel={serviceContext.localeManager.getString('ANNULEER')}
                                 saveButtonLabel={serviceContext.localeManager.getString('BEWAAR')}
                                 onCancelClick={onCancel} onSaveClick={onSubmit}/>}
        </Form>
    )
}

export default CompilatieSettingsGegevens
