import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import DropzoneItem from "../component/dropzone/DropzoneItem";
import {PreviousIcon, Spacer, InfoViewer, SaveBar, ReferentieitemIcon, TeKoopItemIcon} from "@ahsmediatheek/ada-lib";
import {Label, Form, Checkbox, Input, Segment, Divider} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'
import {ComponentIdentifier} from "../component/ComponentIdentifier";


const ItemView = ({item, bundel, onBackClick}) => {

    const [isDirty, setIsDirty] = useState(false)
    const [alias, setAlias] = useState('')
    const [referentiebestand, setReferentiebestand] = useState()
    const [teKoopItem, setTeKoopItem] = useState()
    const [aliasTeKoopItem, setAliasTeKoopItem] = useState()

    const serviceContext = useContext(ServiceContext)

    useEffect(() => {

        const referentiebestandArr = serviceContext.referentiebestanden.filter(rf => rf.referentiebestand === item.file.path)
        const referentiebestand = referentiebestandArr.length > 0 ? referentiebestandArr[0] : null
        setReferentiebestand(referentiebestand)
        setAlias(referentiebestand ? referentiebestand.alias : '')
    }, [item, bundel, serviceContext.referentiebestanden])

    useEffect(() => {
        const teKoopItemsArr = serviceContext.teKoopItems.filter(rf => rf.bestand === item.file.path)
        const teKoopItem = teKoopItemsArr.length > 0 ? teKoopItemsArr[0] : null
console.log('TE KOOP ITEMS', serviceContext.teKoopItems, teKoopItemsArr, teKoopItem, item.file.path)
        setTeKoopItem(teKoopItem)
        setAliasTeKoopItem(teKoopItem ? teKoopItem.alias : '')
    }, [item, bundel, serviceContext.teKoopItems])

    const onChange = (setter, value) => {
        setIsDirty(true)
        setter(value)
    }

    const onCheckboxReferentiebestandChange = (event, {checked}) => {
        serviceContext.onChangeReferentiebestand && serviceContext.onChangeReferentiebestand(bundel, item, checked, referentiebestand)
    }

    const onCheckboxTeKoopItemChange = (event, {checked}) => {
        serviceContext.onChangeTeKoopItem && serviceContext.onChangeTeKoopItem(bundel, item, checked, teKoopItem)
    }

    const onCancel = () => {
        setIsDirty(false)
    }

    const onSubmit = (...args) => {
        if (referentiebestand) {
            referentiebestand.alias = alias
            serviceContext.bundelService.updateReferentiebestand(referentiebestand).then(res => setIsDirty(false))
        }

        if (teKoopItem) {
            teKoopItem.alias = aliasTeKoopItem
            serviceContext.bundelService.updateTeKoopItem(teKoopItem).then(res => setIsDirty(false))
        }
    }


    return (
        <div>
            <ComponentIdentifier displayName='ItemView'/>

            <Label as='a' onClick={onBackClick}><PreviousIcon/> {serviceContext.localeManager.getString('TERUG')}</Label>

            <Spacer vertical/>

            <div style={{display: 'flex'}}>
                <div><DropzoneItem item={item}
                                   bundel={bundel}/></div>

                <Form style={{margin: '5px 10px'}}>

                    <Form.Field>
                        <label>{serviceContext.localeManager.getString('BESTANDSNAAM')}</label>
                        <p>{item.file.path}</p>
                    </Form.Field>

                    <Segment>
                        <Form.Field>
                            <label>{serviceContext.localeManager.getString('REFERENTIEBESTAND')}</label>

                            <ReferentieitemIcon size='big'/>

                            <Spacer vertical/>
                            <InfoViewer editable={serviceContext.infoEditable}
                                        refId={'INFO_REFERENTIEBESTAND'}
                                        localeManager={serviceContext.localeManager}
                                        localeDataService={serviceContext.infoLocaleDataService}/>

                            {item.canBeReferentiebestand && <Checkbox toggle checked={referentiebestand !== null}
                                                                      onChange={onCheckboxReferentiebestandChange}
                                                                      label={serviceContext.localeManager.getString('MARKEER_ALS_REFERENTIEBESTAND')}/>}

                            {!item.canBeReferentiebestand && <span>{serviceContext.localeManager.getString('ITEM_KAN_GEEN_REFERENTIEBESTAND_ZIJN')}</span>}

                        </Form.Field>

                        {item.isReferentiebestand && referentiebestand &&
                            <Form.Field>
                                <Divider/>
                                <label>{serviceContext.localeManager.getString('ALIAS')}</label>

                                <InfoViewer editable={serviceContext.infoEditable}
                                            refId={'INFO_REFERENTIEBESTAND_ALIAS'}
                                            localeManager={serviceContext.localeManager}
                                            localeDataService={serviceContext.infoLocaleDataService}/>

                                <Input value={alias} name='alias' onChange={(e, {value}) => onChange(setAlias, value)}/>
                            </Form.Field>}
                    </Segment>




                    <Segment>
                        <Form.Field>
                            <label>{serviceContext.localeManager.getString('TE_KOOP')}</label>

                            <TeKoopItemIcon size='big'/>

                            <Spacer vertical/>
                            <InfoViewer editable={serviceContext.infoEditable}
                                        refId={'INFO_TEKOOPITEM'}
                                        localeManager={serviceContext.localeManager}
                                        localeDataService={serviceContext.infoLocaleDataService}/>

                            <Checkbox toggle checked={teKoopItem !== null}
                                      onChange={onCheckboxTeKoopItemChange}
                                      disabled={!serviceContext.privilegeManager.canSetItemTeKoop(serviceContext.collectieAdaUserLinks)}

                                      label={serviceContext.localeManager.getString('MARKEER_ALS_TE_KOOP_ITEM')}/>

                        </Form.Field>

                        {item.isTeKoopItem && teKoopItem &&
                            <Form.Field>
                                <Divider/>
                                <label>{serviceContext.localeManager.getString('ALIAS')}</label>

                                <InfoViewer editable={serviceContext.infoEditable}
                                            refId={'INFO_TE_KOOP_ITEM_ALIAS'}
                                            localeManager={serviceContext.localeManager}
                                            localeDataService={serviceContext.infoLocaleDataService}/>

                                <Input value={aliasTeKoopItem} name='aliasTeKoopItem'
                                       disabled={!serviceContext.privilegeManager.canSetBundelTeKoop(serviceContext.collectieAdaUserLinks)}

                                       onChange={(e, {value}) => onChange(setAliasTeKoopItem, value)}/>
                            </Form.Field>}
                    </Segment>



                </Form>
            </div>

            {isDirty && <SaveBar cancelButtonLabel={serviceContext.localeManager.getString('ANNULEER')}
                                 saveButtonLabel={serviceContext.localeManager.getString('BEWAAR')}
                                 onCancelClick={onCancel} onSaveClick={onSubmit}/>}
        </div>
    )
}

export default ItemView

ItemView.propTypes = {
    item: PropTypes.object.isRequired,
    bundel: PropTypes.object.isRequired,
    onBackClick: PropTypes.func,
}

ItemView.defaultProps = {}
