import React, { useState, useContext, useEffect, Fragment } from 'react'
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom";
import MetadataContainer from "../component/MetadataContainer";
import { AdaUserRol, Spacer, ApplicationPath } from "@ahsmediatheek/ada-lib";
import { CompilatieIcon, CollectieIcon, BundelIcon, ResultContainer } from "@ahsmediatheek/ada-lib";
import { Button, Divider, Segment } from "semantic-ui-react";
import { TrefwoordenViewer } from '@ahsmediatheek/med-component-trefwoorden'
import { CompilatieCard } from "../component/CompilatieCard";
import { CollectieCard } from "../component/CollectieCard";
import { BundelCard } from "../component/BundelCard";

import ServiceContext from '../context/ServiceContext'

import AdaUserDisplay from "../component/AdaUserDisplay";
import Sharing from "../component/sharing/Sharing";
import { ComponentIdentifier } from "../component/ComponentIdentifier";
import CompilatieZoeker from './CompilatieZoeker';
import CompilatieInhoudZoeker from './CompilatieInhoudZoeker';

const VIEW_ITEMS = 'viewBundels'
const VOEG_TOE_AAN_ANDERE_COMPILATIE = 'voegToeAanAndereCompilatie'
const VOEG_ITEMS_TOE_AAN_COMPILATIE = 'voegItemsToeAanCompilatie'

const CompilatieDetailMainView = () => {
    const [view, setView] = useState(VIEW_ITEMS)
    const [inhoud, setInhoud] = useState()
    const serviceContext = useContext(ServiceContext)

    const location = useLocation()

    useEffect(() => {
        serviceContext.compilatieService.getCompilatieInhoud(serviceContext.compilatie).then(setInhoud);
    }, []);

    function onSelectCompilatie(compilatie) {
        serviceContext.compilatieService.addToCompilatie(compilatie, 'compilatie', serviceContext.compilatie.uuid);
        
        alert(serviceContext.localeManager.getString('COMPILATIE_TOEGEVOEGD_AAN_COMPILATIE'));

        setView(VIEW_ITEMS);
    }

    function onSelectInhoud(item) {
        console.log(item.uuid,serviceContext.compilatie.class,serviceContext.compilatie.uuid)
        if(item.uuid !== serviceContext.compilatie.uuid && item.class != 'compilatie') {
            const aTC = serviceContext.compilatieService.addToCompilatie(serviceContext.compilatie, item.class, item.uuid).then((result) => {
                console.log(aTC)
                console.log(result)
                console.log(result.data.compilatie.compilatie_inhoud)
                setInhoud(result.data.compilatie.compilatie_inhoud)
                alert(serviceContext.localeManager.getString('ITEM_TOEGEVOEGD_AAN_COMPILATIE'));
                setView(VIEW_ITEMS);
            });
        } else {
            alert(serviceContext.localeManager.getString('KAN_COMPILATIE_NIET_AAN_ZICHZELF_TOEVOEGEN'));
        }
    }

    const verwijderUitCompilatieClick = (obj) => {
        console.log(obj)
        console.log(serviceContext.compilatie)
        const dFC = serviceContext.compilatieService.deleteFromCompilatie(serviceContext.compilatie,obj).then((result) => {
            console.log(dFC)
            console.log(result)
            console.log(result.data.compilatie.compilatie_inhoud)
            setInhoud(result.data.compilatie.compilatie_inhoud)
        })
        
    }

    return (
        <div>
            <ComponentIdentifier displayName='CompilatieDetailMainView' />


            <MetadataContainer provider={serviceContext.compilatie} locale={serviceContext.localeManager.locale} />

            <Spacer vertical distance={15} />

            <Segment>

                {serviceContext.compilatieAdaUserLinks &&
                    <AdaUserDisplay adaUserLinks={serviceContext.compilatieAdaUserLinks}
                        rollen={AdaUserRol.COMPILATIE_ROLLEN} />
                }

                {serviceContext.compilatie.trefwoorden !== '' && <div>

                    <Spacer vertical distance={20} />

                    <TrefwoordenViewer consumerKey={serviceContext.TREFWOORDEN_CONSUMER_KEY}
                        readonly
                        trefwoordenString={serviceContext.compilatie.trefwoorden}
                        locale={serviceContext.localeManager.locale} />

                </div>}
            </Segment>

            <Sharing url={ApplicationPath.ADA_HOST + location.pathname} />

            <Segment clearing>
                <Button onClick={() => setView(VIEW_ITEMS)}>{serviceContext.localeManager.getString('TOON_COMPILATIE_ITEMS')}</Button>
                {serviceContext.privilegeManager.canDeleteCompilatie() && (
                    <Fragment>
                        <Button onClick={() => setView(VOEG_ITEMS_TOE_AAN_COMPILATIE)}>{serviceContext.localeManager.getString('VOEG_ITEMS_TOE_AAN_DEZE_COMPILATIE')}</Button>
                        <Button onClick={() => setView(VOEG_TOE_AAN_ANDERE_COMPILATIE)}>{serviceContext.localeManager.getString('VOEG_COMPILATIE_TOE_AAN_ANDERE_COMPILATIE')}</Button>
                    </Fragment>
                )}
            </Segment>

            {view === VIEW_ITEMS && (
                <Fragment>

                    {inhoud && inhoud.compilatie && (
                        <Fragment>
                            <ResultContainer
                                containerWidth={serviceContext.resultContainerAvailableWidth}
                                display={serviceContext.resultDisplay}
                                setDisplay={serviceContext.setResultDisplay}
                                header={serviceContext.localeManager.getString('COMPILATIES')}
                                headerIcon={<CompilatieIcon size='large' style={{ marginRight: '10px' }} />}
                            >
                                {inhoud.compilatie.map((compilatie, index) => {
                                    return <div><Link key={index} to={`/compilatie/${compilatie.uuid}`}>
                                        <CompilatieCard compilatie={compilatie} showStatus
                                            display={serviceContext.resultDisplay}
                                            locale={serviceContext.localeManager.locale} 
                                            />
                                    </Link>
                                        {serviceContext.privilegeManager.canDeleteCompilatie() && <div>
                                            <Button color='red' style={{width:'100%'}} onClick={() => verwijderUitCompilatieClick(compilatie)}>
                                                {serviceContext.localeManager.getString('VERWIJDER_UIT_COMPILATIE')}
                                            </Button>
                                        </div>}
                                    </div>
                                })}
                            </ResultContainer>
                            <Divider />
                        </Fragment>
                    )}

                    {inhoud && inhoud.collectie && (
                        <Fragment>
                            <ResultContainer
                                containerWidth={serviceContext.resultContainerAvailableWidth}
                                display={serviceContext.resultDisplay}
                                setDisplay={serviceContext.setResultDisplay}
                                header={serviceContext.localeManager.getString('COLLECTIES')}
                                headerIcon={<CollectieIcon size='large' style={{ marginRight: '10px' }} />}
                            >
                                {inhoud && inhoud.collectie && inhoud.collectie.map((collectie, index) => {
                                    return <div><Link key={index} to={`/collectie/${collectie.uuid}`}>
                                        <CollectieCard collectie={collectie} showStatus
                                            display={serviceContext.resultDisplay}
                                            locale={serviceContext.localeManager.locale} />
                                            
                                    </Link>
                                        {serviceContext.privilegeManager.canDeleteCompilatie() && <div>
                                            {console.log(serviceContext.privilegeManager)}
                                            <Button color='red' style={{width:'100%'}} onClick={() => verwijderUitCompilatieClick(collectie)}>
                                                {serviceContext.localeManager.getString('VERWIJDER_UIT_COMPILATIE')}
                                            </Button>
                                        </div>}
                                    </div>
                                })}
                            </ResultContainer>
                            <Divider />
                        </Fragment>
                    )}

                    {inhoud && inhoud.bundel && (
                        <Fragment>
                            <ResultContainer
                                containerWidth={serviceContext.resultContainerAvailableWidth}
                                display={serviceContext.resultDisplay}
                                setDisplay={serviceContext.setResultDisplay}
                                header={serviceContext.localeManager.getString('BUNDELS')}
                                headerIcon={<BundelIcon size='large' style={{ marginRight: '10px' }} />}
                            >
                                {inhoud && inhoud.bundel && inhoud.bundel.map((bundel, index) => {
                                    return <div><Link key={index} to={`/bundel/${bundel.uuid}`}>
                                        <BundelCard bundel={bundel} showStatus
                                            display={serviceContext.resultDisplay}
                                            locale={serviceContext.localeManager.locale} />
                                            
                                    </Link>
                                        {serviceContext.privilegeManager.canDeleteCompilatie() && <div>
                                            <Button color='red' style={{width:'100%'}} onClick={() => verwijderUitCompilatieClick(bundel)}>
                                                {serviceContext.localeManager.getString('VERWIJDER_UIT_COMPILATIE')}
                                                
                                            </Button>
                                        </div>}
                                    </div>
                                })}
                            </ResultContainer>
                            <Divider />
                        </Fragment>
                    )}

                </Fragment>
            )}

            {view === VOEG_ITEMS_TOE_AAN_COMPILATIE && <CompilatieInhoudZoeker onSelect={onSelectInhoud} />}

            {view === VOEG_TOE_AAN_ANDERE_COMPILATIE && <CompilatieZoeker onSelect={onSelectCompilatie} />}

        </div>
    )
}

export default CompilatieDetailMainView

CompilatieDetailMainView.propTypes =
{
}

CompilatieDetailMainView.defaultProps =
    {}
