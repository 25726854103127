import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Segment, Button, Modal} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'

import {CollectieStatus, CollectieActie, InfoViewer, CollectieSettings, BundelStatus} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "./ComponentIdentifier";

const CollectieActies = ({collectie}) => {

    const serviceContext = useContext(ServiceContext)

    const [acties, setActies] = useState([])
    const [warningModalOpen, setWarningModalOpen] = useState(false)
    const [actieInfoKey, setActieInfoKey] = useState()
    const [bevestigLabel, setBevestigLabel] = useState()

    useEffect(() => {
        const acties = []

        if (serviceContext.privilegeManager.canPublishCollectie(collectie, serviceContext.collectieAdaUserLinks))
            acties.push(CollectieActie.COLLECTIE_PUBLICEREN)

        if (serviceContext.privilegeManager.canOpenCollectieVoorInzenden(collectie, serviceContext.collectieAdaUserLinks))
            acties.push(CollectieActie.COLLECTIE_OPENEN_VOOR_INZENDEN)

        if (serviceContext.privilegeManager.canSluitCollectieVoorInzenden(collectie, serviceContext.collectieAdaUserLinks))
            acties.push(CollectieActie.COLLECTIE_SLUITEN_VOOR_INZENDEN)

        if (serviceContext.privilegeManager.canArchiveerCollectie(collectie, serviceContext.collectieAdaUserLinks))
            acties.push(CollectieActie.COLLECTIE_ARCHIVEREN)

        setActies(acties)
    }, [collectie, serviceContext.privilegeManager, serviceContext.collectieAdaUserLinks])


    const onCloseModal = () => {
        setActieInfoKey(null)
        setWarningModalOpen(false)
    }

    const onActieClick = (event, {name}) => {
        const validationActive = serviceContext.privilegeManager.settingActive(collectie, CollectieSettings.BUNDEL_VALIDATIE_ACTIEF)

        const notValidatedBundels = serviceContext.bundels.reduce((ba, bundel) => {
            if (bundel.status !== BundelStatus.GEVALIDEERD) ba.push(bundel)
            return ba
        }, [])

        switch (name) {
            case CollectieActie.COLLECTIE_PUBLICEREN:
                if (validationActive && notValidatedBundels.length > 0) {
                    window.alert(serviceContext.localeManager.getString('NIET_ALLE_BUNDELS_GEVALIDEERD').replace('[[bundel_count]]', notValidatedBundels.length))
                    return
                }
                break
            default:
                break
        }
        setWarningModalOpen(true)
        setActieInfoKey('ACTIE_' + name.toUpperCase())
        setBevestigLabel(name)
    }

    const modalExtraContentFunction = (actie) => {
        const validationActive = serviceContext.privilegeManager.settingActive(collectie, CollectieSettings.BUNDEL_VALIDATIE_ACTIEF)

        const notValidatedBundels = serviceContext.bundels.reduce((ba, bundel) => {
            if (bundel.status !== BundelStatus.GEVALIDEERD) ba.push(bundel)
            return ba
        }, [])

        switch(actie){
            case 'ACTIE_COLLECTIE_SLUITEN_VOOR_INZENDEN':
                if (validationActive && notValidatedBundels.length > 0) return <strong>{serviceContext.localeManager.getString('NIET_ALLE_BUNDELS_GEVALIDEERD_BEVESTIG_SLUITEN_VOOR_INZENDEN').replace('[[bundel_count]]', notValidatedBundels.length)}</strong>
                break
        }
        return null
    }
    const voerActieUit = (actie) => {

        switch (actie) {
            case CollectieActie.COLLECTIE_PUBLICEREN:
                collectie.status = CollectieStatus.GEPUBLICEERD
                serviceContext.onSaveCollectie && serviceContext.onSaveCollectie(collectie)
                break
            case CollectieActie.COLLECTIE_OPENEN_VOOR_INZENDEN:
                collectie.status = CollectieStatus.OPEN_VOOR_INZENDEN
                serviceContext.onSaveCollectie && serviceContext.onSaveCollectie(collectie)
                break
            case CollectieActie.COLLECTIE_SLUITEN_VOOR_INZENDEN:
                collectie.status = CollectieStatus.GESLOTEN_VOOR_INZENDEN
                serviceContext.onSaveCollectie && serviceContext.onSaveCollectie(collectie)
                break
            case CollectieActie.COLLECTIE_ARCHIVEREN:
                setWarningModalOpen(false)

                const warn = window.confirm(serviceContext.localeManager.getString('COLLECTIE_ARCHIVEREN_LAATSTE_WAARSCHUWING'))
                if (!warn) return
                collectie.status = CollectieStatus.GEARCHIVEERD
                serviceContext.onSaveCollectie && serviceContext.onSaveCollectie(collectie)
                break
            default:
                throw (new Error('Kan actie niet uitvoeren. Incorrecte Actie'))
        }

        setWarningModalOpen(false)
    }

    return (
        <Fragment>
            <ComponentIdentifier displayName='CollectieActies'/>

            <Segment>

                {acties.map(actie => {
                    return <Button key={actie} color='teal' name={actie} onClick={onActieClick}>{serviceContext.localeManager.getString(actie)}</Button>
                })}

            </Segment>

            <Modal open={warningModalOpen && actieInfoKey !== ''} onClose={onCloseModal} dimmer='inverted'>
                <Modal.Content>
                    <InfoViewer editable={serviceContext.infoEditable}
                                refId={actieInfoKey}
                                extraContentFunction={modalExtraContentFunction}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={onCloseModal}>
                        {serviceContext.localeManager.getString('ANNULEER')}
                    </Button>
                    <Button color='green' content={serviceContext.localeManager.getString(bevestigLabel)}
                            onClick={() => voerActieUit(bevestigLabel)}
                    />

                </Modal.Actions>

            </Modal>

        </Fragment>
    )
}

export default CollectieActies

CollectieActies.propTypes = {
    collectie: PropTypes.object.isRequired
}

CollectieActies.defaultProps = {}
