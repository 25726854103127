import React, {useContext, useState} from 'react'
import {Form, Divider} from "semantic-ui-react";
import ServiceContext from '../../../../context/ServiceContext'
import AbstractWizardPage from "./AbstractWizardPage";
import {ComponentIdentifier} from "../../../../component/ComponentIdentifier";


const NaamPage = () => {
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
    const serviceContext = useContext(ServiceContext)

    const onChangeProp = (event, {name, value}) => {
        serviceContext.onChangeProp(event, {name, value})
    }

    return (
        <AbstractWizardPage nextButtonEnabled={!nextButtonDisabled}>
            <ComponentIdentifier displayName='NaamPage'/>

            <Form>
                <Form.Input name='titel_nl' label={serviceContext.localeManager.getString('TITEL_NL')}
                            onChange={onChangeProp}
                            value={serviceContext.wizardCompilatie.titel_nl}/>
                <Form.Input name='titel_en' label={serviceContext.localeManager.getString('TITEL_EN')}
                            onChange={onChangeProp}
                            value={serviceContext.wizardCompilatie.titel_en}/>

                <Divider/>

                <Form.Input name='subtitel_nl' label={serviceContext.localeManager.getString('SUBTITEL_NL')}
                            onChange={serviceContext.onChangeProp}
                            value={serviceContext.wizardCompilatie.subtitel_nl}/>
                <Form.Input name='subtitel_en' label={serviceContext.localeManager.getString('SUBTITEL_EN')}
                            onChange={serviceContext.onChangeProp}
                            value={serviceContext.wizardCompilatie.subtitel_en}/>

                <Divider/>

            </Form>
        </AbstractWizardPage>
    )
}

export default NaamPage
