import axios from "axios";
import {ApplicationPath} from "../class/general/ApplicationPath"
import {AuthenticationService} from "./AuthenticationService";
import {AdaUserService} from "./AdaUserService";
import {DateUtil} from "../util/DateUtil";
import {ToegangsticketHistoriek, ToegangsticketTargetType} from "../class/toegangsticket/Toegangsticket";
import {CollectieService} from "./CollectieService";
import {BundelService} from "./BundelService";
import {PrivilegeManager} from "../class/general/PrivilegeManager";
import {LocaleManager} from "./LocaleManager";
import {AdaUserRol} from "../class/user/AdaUser";
import {CollectieSettings} from "../class/collectie/CollectieSettings";


export class MailService {

    applicationPath: ApplicationPath
    privilegeManager: PrivilegeManager
    authenticationInstance: AuthenticationService
    adaUserService: AdaUserService
    collectieService: CollectieService
    bundelService: BundelService
    localeManager: LocaleManager


    constructor(authenticationInstance, adaUserService, collectieService, bundelService, applicationPath, localeManager) {
        this.authenticationInstance = authenticationInstance
        this.adaUserService = adaUserService
        this.collectieService = collectieService
        this.bundelService = bundelService
        this.applicationPath = applicationPath
        this.localeManager = localeManager
    }

    //==============================================================
    //==============================================================
    //                      NOTIFICATIE
    //==============================================================
    //==============================================================

    notificeerBundelStatusGewijzigd = (collectieAdaUserLinks, collectie, bundel, statusOud, statusNieuw) => {
        return new Promise((resolve, reject) => {

            const setting = Boolean(this.collectieService.getCollectieSetting(collectie, CollectieSettings.NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING))
            if (!setting){
                console.log('Setting staat niet aan')
                resolve('Setting staat niet aan')
                return
            }

            const adaUserPromises = collectieAdaUserLinks.reduce((promises, aul) => {
                if (aul.rol === AdaUserRol.COLLECTIE_BEHEERDER)
                    promises.push(this.adaUserService.getAdaUser(aul.adaUser))
                return promises
            }, [])

            Promise.all(adaUserPromises)
                .then(users => {
                    console.log('USERS', users)

                    const mailPromises = users.map(user => {
                        const formData = {
                            template: 'template_13',
                            subject: 'Er is een status van een bundel gewijzigd in het Arteveldehogeschool Digitaal Archief',
                            ontvangers: [user['email']],
                            substitute: {
                                '[IMAGE_URL]': `${this.applicationPath.referentiebeelden()}${bundel.uuid}`,
                                '[STATUS_OUD]': statusOud,
                                '[STATUS_NIEUW]': statusNieuw,
                                '[BUNDEL_UUID]': bundel.uuid,
                                '[BUNDEL_TITEL]': bundel.titel_nl,
                                '[BUNDEL_METADATA]': bundel.academiejaar + ' | ' + bundel.opleiding,
                                '[COLLECTIE_TITEL]': collectie.titel_nl,
                                '[COPYRIGHT_JAAR]': new Date().getFullYear(),
                                '[ADA_LOGIN_USER]': this.privilegeManager.adaUser.voornaam + ' ' + this.privilegeManager.adaUser.familienaam,
                                '[ADA_USER_VOORNAAM]': user['voornaam']
                            }
                        }

                        const url = this.applicationPath.email_api() + 'verstuurHtmlEmail/'

                        axios.post(url, formData)

                    })

                    Promise.all(mailPromises)
                        .then((result) => {
                            console.log('emails verstuurd')
                            resolve('Mails verstuurd')
                        })
                        .catch((error) => {
                            console.log(error)
                            reject(error)
                        })
                })

        })
    }
}
