import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { InputSpinner } from 'semantic-ui-react-spinbutton';


export default function AcademiejaarKiezer({ academiejaren, selectedAcademiejaar, onSelectAcademiejaar, style = {} }) {
    const laatsteAcademiejaar = academiejaren[0];
    const [selected, setSelected] = useState(selectedAcademiejaar);
    const [upDisabled, setUpDisabled] = useState(selectedAcademiejaar);

    function handleUp(value) {
        if (value === laatsteAcademiejaar) return;

        const jaar = parseInt(value);
        const academiejaar = `${jaar + 1}-${jaar + 2}`;

        return academiejaar;
    }

    function handleDown(value) {
        const jaar = parseInt(value);
        const academiejaar = `${jaar - 1}-${jaar}`;

        return academiejaar;
    }
    
    function handleChange(event) {
        const academiejaar = event.target.value;
        
        onSelectAcademiejaar(academiejaar);
        setSelected(academiejaar)
    }

    useEffect(() => {
        setUpDisabled(selected === laatsteAcademiejaar);
    }, [selected]);

    return (
        <div style={style}>
            <InputSpinner
                value={selected}
                icon='calendar alternate outline'
                upDisabled={upDisabled}
                onUp={handleUp}
                onDown={handleDown}
                onChange={handleChange}
                readOnly
            />
        </div>
    );
}

AcademiejaarKiezer.propTypes = {
    selectedAcademiejaar: PropTypes.string,
    onSelectAcademiejaar: PropTypes.func
}

AcademiejaarKiezer.defaultProps = {
}