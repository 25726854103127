export class MetadataUtil {

    //Static constants

    //Private variables

    //Public variables

    //Constructor

    constructor(){

    }

    //public api
static resolveTitel = (provider, locale) => {
    const titel_nl_unavail = provider.titel_nl === '' || provider.titel_nl === undefined
    const titel_en_unavail = provider.titel_en === '' || provider.titel_en === undefined
    if (locale === 'nl' && !titel_nl_unavail) return provider.titel_nl
    if (locale === 'en' && !titel_en_unavail) return provider.titel_en

    if (!titel_nl_unavail) return provider.titel_nl
    if (!titel_en_unavail) return provider.titel_en
    return ''
}

    //private methods

    //getters and setters
}
