import React, {useContext} from 'react'
import ServiceContext from '../../context/ServiceContext'
import {Button, Header} from "semantic-ui-react";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const CollectieSettingsAdmin = (props) => {

    const serviceContext = useContext(ServiceContext)

    return (
        <div>
            <ComponentIdentifier displayName='CollectieSettingsAdmin'/>

            {serviceContext.privilegeManager.canDeleteCollectie(serviceContext.collectie, serviceContext.collectieAdaUserLinks) &&
            <div>
                <Header>{serviceContext.collectie.uuid}</Header>
                <Button color='red'
                         onClick={() => serviceContext.onDeleteCollectie(serviceContext.collectie, serviceContext.bundels)}>{serviceContext.localeManager.getString('VERWIJDER_COLLECTIE')}</Button>
            </div>}

        </div>
    )
}

export default CollectieSettingsAdmin
