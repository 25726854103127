import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Label, Icon } from 'semantic-ui-react';

import { TreeView } from 'semantic-ui-react-treeview';

import classNames from 'classnames';


const ENWOpleidingKiezer = ({ localeManager, opleidingen = [], diensten = [], selectie = '', onSelect, multiple }) => {
    const split = value => (['', null, undefined].includes(value) ? [] : value.split(','));
    const [categories, setCategories] = useState(new Map());
    const [items, setItems] = useState(new Map());
    const [selectedCategories, setSelectedCategories] = useState(new Set());
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [treeData, setTreeData] = useState([]);
    const [treeValue, setTreeValue] = useState('');
    const [open, setOpen] = useState(false);
    const ref = useRef();

    const ENWData = {
        'AHS': {
            label: localeManager.getString('ALLE OPLEIDINGEN'),
            color: 'orange'
        },
        'ENWBMG': {
            label: 'ENW Business en Management',
            color: 'blue'
        },
        'ENWCMD': {
            label: 'ENW Communicatie, Media en Design',
            color: 'purple'
        },
        'ENWGEZ': {
            label: 'ENW Gezondheid en Zorg',
            color: 'red'
        },
        'ENWMES': {
            label: 'ENW Mens en Samenleving',
            color: 'pink'
        },
        'ENWONW': {
            label: 'ENW Onderwijs',
            color: 'green'
        }
    };


    useEffect(() => {
        const items = new Map([
            ...opleidingen.map(opleiding => ([
                opleiding.OplId,
                {
                    id: opleiding.OplId,
                    label: opleiding['Opleiding' + localeManager.locale.toUpperCase()] || opleiding.OpleidingNL,
                    color: ENWData[opleiding.DirectieId ?? 'AHS']?.color
                }
            ])),
            ...diensten.map(dienst => ([
                dienst.Id,
                {
                    id: dienst.Id,
                    label: `${localeManager.getString('DIENST')} ${dienst.Dienstnaam}`,
                    color: 'grey'
                }
            ]))
        ]);

        const categories = new Map([
            ...opleidingen.map(opleiding => ([
                opleiding.DirectieId ?? 'AHS',
                {
                    id: opleiding.DirectieId ?? 'AHS',
                    label: ENWData[opleiding.DirectieId ?? 'AHS']?.label ?? opleiding.DirectieId ?? 'AHS',
                    color: ENWData[opleiding.DirectieId ?? 'AHS']?.color
                }
            ])),
            [
                'ADI',
                {
                    id: 'ADI',
                    label: localeManager.getString('DIENSTEN'),
                    color: 'grey'
                }
            ]
        ]);

        const treeData = Array.from(categories.values()).map(({ id, label }) => ({
            id,
            label,
            children: (id === 'AHS') ?
                opleidingen.filter(({ DirectieId }) => (DirectieId === null)).map(opleiding => ({
                    id: opleiding.OplId,
                    label: opleiding['Opleiding' + localeManager.locale.toUpperCase()] || opleiding.OpleidingNL
                })) : (id === 'ADI') ?
                    diensten.map(dienst => ({
                        id: dienst.Id,
                        label: dienst.Dienstnaam
                    })) :
                    opleidingen.filter(({ DirectieId }) => (DirectieId === id)).map(opleiding => ({
                        id: opleiding.OplId,
                        label: opleiding['Opleiding' + localeManager.locale.toUpperCase()] || opleiding.OpleidingNL
                    }))
        }));

        setItems(items);
        setCategories(categories);
        setTreeData(treeData);
    }, [opleidingen, localeManager]);

    useEffect(() => {
        setTreeValue(selectie);
    }, [selectie]);

    useEffect(() => {
        const selectedItems = new Set(split(treeValue));

        treeData.forEach(category => {
            const allSelected = (category.children.length > 0) && category.children.every(item => selectedItems.has(item.id));

            if (allSelected) {
                selectedCategories.add(category.id);
                category.children.forEach(item => {
                    selectedItems.delete(item.id);
                });
            } else {
                selectedCategories.delete(category.id);
                category.children.forEach(item => {
                    if (selectedItems.has(item.id)) {
                        selectedItems.add(item.id);
                    } else {
                        selectedItems.delete(item.id);
                    }
                });
            }
        });

        setSelectedCategories(new Set(selectedCategories));
        setSelectedItems(new Set(selectedItems));
    }, [treeValue]);

    useEffect(() => {
        if (!open) return;

        function clickHandler(event) {
            if (event.target.closest('div.ui.dropdown') === ref.current) return;

            setOpen(false);
        }

        document.addEventListener('mousedown', clickHandler);

        return () => {
            document.removeEventListener('mousedown', clickHandler);
        };
    }, [open]);

    function handleChange(event, value) {
        setTreeValue(value);

        onSelect && onSelect(value);
    }

    function handleClick(event) {
        if (!event.target.matches('div.ui.dropdown, i.icon.dropdown')) return;

        setOpen(!open);
    }

    function removeSelectedCategory(id) {
        const values = new Set(split(treeValue));
        const opleidingIds = [...opleidingen, ...diensten]
            .filter(({ DirectieId }) => (DirectieId === id))
            .map(opleiding => opleiding.OplId);

        opleidingIds.forEach(id => {
            values.delete(id);
        });

        setTreeValue(Array.from(values).join(','));
    }

    function removeSelectedItem(id) {
        const values = new Set(split(treeValue));

        values.delete(id);

        setTreeValue(Array.from(values).join(','));
    }

    return (
        <div
            className={classNames(
                'ui dropdown',
                'fluid',
                'multiple',
                'selection',
                { 'visible': open }
            )}
            role='listbox'
            aria-multiselectable='true'
            aria-expanded={open ? 'true' : 'false'}
            tabIndex='0'
            onClick={handleClick}
            ref={ref}
        >
            {Array.from(selectedCategories)
                .map(id => categories.get(id))
                .map(item => (item && (
                    <Label
                        key={item.id}
                        content={item.label}
                        color={item.color}
                        icon='sitemap'
                        onRemove={() => removeSelectedCategory(item.id)}
                        style={{
                            borderWidth: '1px'
                        }}
                    />
                )))}
            {Array.from(selectedItems)
                .map(id => items.get(id))
                .map(item => (item && (
                    <Label
                        basic
                        key={item.id}
                        content={item.label}
                        color={item.color}
                        onRemove={() => removeSelectedItem(item.id)}
                    />
                )))}
            <i className='dropdown icon' aria-hidden='true'></i>
            <div className={classNames(
                'menu',
                'transition',
                { 'visible': open }
            )}>
                <TreeView
                    name='testje'
                    value={treeValue}
                    data={treeData}
                    onChange={handleChange}
                    style={{
                        borderStyle: 'none'
                    }}
                />
            </div>
        </div>
    );
};

export default ENWOpleidingKiezer;

ENWOpleidingKiezer.propTypes = {
    opleidingen: PropTypes.array.isRequired,
    localeManager: PropTypes.object.isRequired,
    selectedOpleiding: PropTypes.string,
    onSelectOpleiding: PropTypes.func,
    multiple: PropTypes.bool
};

ENWOpleidingKiezer.defaultProps = {
    multiple: true
};
