import React from 'react'
import PropTypes from 'prop-types'
import {Icon} from "semantic-ui-react";

const AbstractIcon = (props) => <Icon className='result-card-icon' {...props}
                                                                    style={{...props.style, cursor: props.onClick ? 'pointer' : 'default'}}
                                                                    name={props.name}
                                                                    circular
                                                                    inverted
                                                                    color={props.color}
                                                                    size={props.size}
                                                                    onClick={(event) => props.onClick && props.onClick(event, props.name)}/>

export default AbstractIcon

AbstractIcon.propTypes = {
    size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'big', 'huge', 'massive']),
    color: PropTypes.string,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
}

AbstractIcon.defaultProps = {
    size: 'small',
    color: 'grey'
}