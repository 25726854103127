export class ApplicationPath {

    static readonly ADA_HOST: string = "https://www.arteveldehogeschool.be/digitaalarchief"
    static readonly ADA_HOST_LIVE: string = "https://www.arteveldehogeschool.be/digitaalarchief"
    static readonly ADA_HOST_LIVE_BACKUP: string = "https://www.arteveldehogeschool.be/digitaalarchief-live-backup"
    static readonly ADA_HOST_DEV: string = "https://www.arteveldehogeschool.be/digitaalarchief-dev"
    static readonly ADA_HOST_STAGING: string = "https://www.arteveldehogeschool.be/digitaalarchief-staging"
    static readonly MEDIATHEKEN_HOST: string = "https://www.arteveldehogeschool.be/mediatheken"
    static readonly ARTEVELDE_HOST: string = "https://www.arteveldehogeschool.be"
    static readonly ARTEVELDE_AVATAR: string = `${ApplicationPath.ARTEVELDE_HOST}/mediatheken/dev/foto/foto.php?size=XXS&user=/`
    static readonly TREFWOORDEN_API: string = `${ApplicationPath.MEDIATHEKEN_HOST}/trefwoorden`
    static readonly WEBSITE_MEDIATHEEK: string = 'https://www.arteveldehogeschool.be/mediatheken'
    static readonly WEBSITE_ARTEVELDE: string = 'https://www.arteveldehogeschool.be'

    mode: string
    ada_host: string = ApplicationPath.ADA_HOST

    constructor(mode: string) {
        this.mode = mode
        if (mode !== ApplicationMode.LIVE) this.ada_host += `-${mode}`
    }

    getPaths = () => {
        const paths = {
            static: {
                ADA_HOST: ApplicationPath.ADA_HOST,
                MEDIATHEKEN_HOST: ApplicationPath.MEDIATHEKEN_HOST,
                ARTEVELDE_HOST: ApplicationPath.ARTEVELDE_HOST,
                ARTEVELDE_AVATAR: ApplicationPath.ARTEVELDE_AVATAR,
                TREFWOORDEN_API: ApplicationPath.TREFWOORDEN_API,
                WEBSITE_MEDIATHEEK: ApplicationPath.WEBSITE_MEDIATHEEK,
                WEBSITE_ARTEVELDE: ApplicationPath.WEBSITE_ARTEVELDE,
            },
            version: {
                ada_desktop_live: this.ada_desktop_live_version(),
                ada_desktop_live_backup: this.ada_desktop_live_backup_version(),
                ada_desktop_dev: this.ada_desktop_dev_version(),
                ada_desktop_staging: this.ada_desktop_staging_version(),
                ada_backoffice_live: this.ada_backoffice_live_version(),
                ada_backoffice_dev: this.ada_backoffice_dev_version(),
                ada_backoffice_staging: this.ada_backoffice_staging_version(),
                ada_server_live: this.ada_server_live_version(),
                ada_server_staging: this.ada_server_staging_version(),
                ada_server_dev: this.ada_server_dev_version(),
            },
            host: {
                mode: this.mode,
                host: this.ada_host,
            },
            roots: {
                ada_desktop: this.ada_desktop(),
                ada_lib: this.ada_lib(),
                ada_server: this.ada_server(),
                fileservice: this.fileservice(),
                bundel: this.bundel(),
            },
            paths: {
                assets: this.assets(),
                locale_files: this.locale_files(),
                referentiebeelden: this.referentiebeelden(),
                geen_referentiebeeld: this.geen_referentiebeeld(),
                nieuwe_collectie_wizard_referentibeeld: this.nieuwe_collectie_wizard_referentiebeeld(),
                ada_user_banners: this.ada_user_banners(),
                ada_user_banner_default: this.ada_user_banner_default(),
                toagangsticket_path: this.toagangsticket_path(),
            },
            api: {
                crud_api: this.crud_api(),
                crud_api_v2: this.crud_api_v2(),
                auth_api: this.auth_api(),
                ada_api: this.ada_api(),
                ahs_api: this.ahs_api(),
                email_api: this.email_api(),
                zoek_api: this.zoek_api(),
                sync_api: this.sync_api()
            },
            logo: {
                logo_ahs: this.logo_ahs(),
                logo_pixabay: this.logo_pixabay(),
            },
        }

        return paths
    }

    //==============================================================
    //==============================================================
    //                      VERSIONS
    //==============================================================
    //==============================================================

    ada_desktop_live_version = () => `${ApplicationPath.ADA_HOST_LIVE}/ada-desktop/version.json`
    ada_desktop_live_backup_version = () => `${ApplicationPath.ADA_HOST_LIVE_BACKUP}/ada-desktop/version.json`
    ada_desktop_dev_version = () => `${ApplicationPath.ADA_HOST_DEV}/ada-desktop/version.json`
    ada_desktop_staging_version = () => `${ApplicationPath.ADA_HOST_STAGING}/ada-desktop/version.json`
    ada_backoffice_live_version = () => `${ApplicationPath.ADA_HOST_LIVE}/ada-backoffice/version.json`
    ada_backoffice_dev_version = () => `${ApplicationPath.ADA_HOST_DEV}/ada-backoffice/version.json`
    ada_backoffice_staging_version = () => `${ApplicationPath.ADA_HOST_STAGING}/ada-backoffice/version.json`
    ada_server_dev_version = () => `${ApplicationPath.ADA_HOST_DEV}/ada-server/version.json`
    ada_server_staging_version = () => `${ApplicationPath.ADA_HOST_STAGING}/ada-server/version.json`
    ada_server_live_version = () => `${ApplicationPath.ADA_HOST_LIVE}/ada-server/version.json`

   //==============================================================
   //==============================================================
   //                      ROOTS
   //==============================================================
   //==============================================================

    ada_desktop = () => `${this.ada_host}/ada-desktop/`

    ada_lib = () => `${this.ada_host}/ada-lib/`

    ada_server = () => `${this.ada_host}/ada-server/`

    fileservice = () => `${this.ada_server()}fileservice/`

    bundel = () => `${this.fileservice()}bundel/`

    //==============================================================
    //==============================================================
    //                      PATH
    //==============================================================
    //==============================================================

    assets = () => `${this.ada_server()}assets/`

    locale_files = () => `${this.ada_server()}locale/`

    referentiebeelden = () => `${this.fileservice()}referentiebeelden/`

    geen_referentiebeeld = () => `${this.ada_host}/referentiebeelden/_default/geen_referentiebeeld.png`

    nieuwe_collectie_wizard_referentiebeeld = () => `${this.referentiebeelden()}_default/nieuweCollectieWizardReferentiebeeld.jpg`

    ada_user_banners = () => `${this.fileservice()}ada_user_banners/`

    ada_user_banner_default = () => `${this.ada_user_banners()}_default/geenAdaUserBanner.jpg`

    toagangsticket_path = () => `${this.ada_host}/toegangsticket`


    //==============================================================
    //==============================================================
    //                      API
    //==============================================================
    //==============================================================

    crud_api = () => `${this.ada_server()}ada-crud/v1/`

    crud_api_v2 = () => `${this.ada_server()}ada-crud/v2/records/`

    auth_api = () => `${this.ada_server()}ada-auth/`

    ada_api = () => `${this.ada_server()}ada-api`

    ahs_api = () => `${this.ada_server()}ada-ahs`

    email_api = () => `${this.ada_server()}ada-email/`

    zoek_api = () =>`${this.ada_server()}ada-search/search/`

    sync_api = () => `${ApplicationPath.ADA_HOST}/ada-sync/`


    //==============================================================
    //==============================================================
    //                      URL
    //==============================================================
    //==============================================================


    //==============================================================
    //==============================================================
    //                      ASSETS
    //==============================================================
    //==============================================================

    //static readonly LOGO_AHS: string = `${ApplicationPath.ASSETS}logo/ahs/logo_ahs.png`
    logo_ahs = () => {
        return `${this.assets()}logo/ahs/logo_ahs.png`
    }

    //static readonly LOGO_PIXABAY: string = `${ApplicationPath.ASSETS}logo/logo-pixabay.svg`
    logo_pixabay = () => {
        return `${this.assets()}logo/logo-pixabay.svg`
    }

}

export class ApplicationMode{
    static readonly DEV: string = 'dev'
    static readonly STAGING: string = 'staging'
    static readonly LIVE: string = 'live'
}
