import {v4 as uuidv4} from 'uuid'

export class ToegangsticketTargetType {
    static readonly COLLECTIE: string = 'collectie'
    static readonly BUNDEL: string = 'bundel'
}

export class ToegangsticketStatus {
    static readonly OPEN: string = 'open'
    static readonly GEBLOKKEERD: string = 'geblokkeerd'
}
export class Toegangsticket {
    id: number
    datum_aanmaak: string
    uuid: string = uuidv4()
    aangemaakt_door: number
    geldig_van: string
    geldig_tot: string
    begunstigde: string
    consumer_type: string
    consumer_uuid: string
    status: string = ToegangsticketStatus.OPEN
}

export class ToegangsticketHistoriek {
    id: number
    toegangsticket: string
    datum: string
    actie: string
    actie_initiator: string
    toelichting: string
}

export class ToegangsticketHistoriekActie {
    static readonly TICKET_VALIDATIE: string = 'ticket_validatie'
    static readonly TICKET_GEBLOKKEERD: string = 'ticket_geblokkeerd'

}
