import axios from 'axios'
import {ApplicationPath} from "../class/general/ApplicationPath";
import {Query} from "../class/zoek/Query";

export class ZoekService {

    applicationPath: ApplicationPath

    constructor(applicationPath) {
        this.applicationPath = applicationPath
    }

    getZoekresultaten = (query: Query , test ) => {
        console.log(test)

        return new Promise((resolve, reject) => {

            let url = `${this.applicationPath.zoek_api()}${query.query}/`

            axios.get(url)
                .then((response) => {
            console.log('ZOEK', url, response)
                    console.log(response)
                    resolve(response.data.zoekresultaten)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }
}
