import axios from "axios";
import {ApplicationPath} from "../class/general/ApplicationPath";

export class ApplicationService {

    applicationPath: ApplicationPath
    config

    constructor(applicationPath) {
        this.applicationPath = applicationPath
        //this.getConfig()
    }

    getConfig = () => {
        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.ada_server()}/__adaConfig.json?d=${new Date().getTime()}`
            if (this.config) {
                resolve(this.config)
                return
            }
            axios.get(url)
                .then(response => {
                    this.config = response.data
                    resolve(this.config)
                })
                .catch(error => {
                    console.error('CONFIG ERROR', error)
                    reject(error)
                })
        })
    }

    deploy = (source, destination, simulate: boolean = true) => {
        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.sync_api()}`
            const config = {
                source, destination, simulate,
                use_ignore_file_list: false,
                skip_hidden: false,
                "use_checksum" : true,
                "one_way_sync" : true,
                "update_files" : true,
                "update_string" : {
                    "source" : source,
                    "destination" : destination
                },
                "update_files_list" : [
                    "ada-desktop/index.php"
                ]
            }


            console.log('DEPLOYING', config, url)
            axios.post(url, config)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('DEPLOY ERROR', error)
                    reject(error)
                })
        })
    }
}
