import {LocaleContent} from "../class/locale/LocaleContent";
import axios from 'axios'
import {ApplicationPath} from "../class/general/ApplicationPath";
import {AuthenticationService} from "./AuthenticationService";
import {DateUtil} from "../util/DateUtil";


export class LocaleDataService {

    applicationPath: ApplicationPath
    authenticationInstance: AuthenticationService
    localeDataRef: string = ''
    minimalProps: string[] = []
    locales: string[] = ['nl', 'en']
    localeContentLookup: object = {} //key = refId, value = array
    fetchingRefId: any
    loginUser: any

    /*
    * De LocaleDataService klasse houdt een repository bij van localeData en laadt en bewaart die data indien nodig
    * Elke instantie van deze klasse bekommert zich om één ref: bv. user, faq, news, ...
    *
    * De lookup houdt refId's bij
    *
    * Arguments:
    * apiUrl: endpoint naar de centrale api (V1, V2, ...)
    * apiKey: security token
    * localeDataRef: verwijst naar het 'ref' veld in de database. Bv. 'user', 'faq', ...
    * minimalProps: array van properties die elk LocaleData object MOET bevatten. Indien één van deze velden niet
     * bestaat wordt het aangemaakt, één voor elke locale
     *

    * */


    constructor(authenticationInstance, applicationPath, localeDataRef: string, minimalProps?: string[]) {
        this.authenticationInstance = authenticationInstance
        this.localeDataRef = localeDataRef
        this.applicationPath = applicationPath
        if (minimalProps) this.minimalProps = minimalProps
    }

    getLocaleData = (refId) => {

        let me = this

        return new Promise(function (resolve, reject) {

            if (me.fetchingRefId === refId) {
                resolve(null)
                return
            }
            me.fetchingRefId = refId

            if (me.localeContentLookup[refId]) {
                me.fetchingRefId = null
                resolve({localeContent: me.localeContentLookup[refId], source: 'cache'})
            } else {
                let url = `${me.applicationPath.crud_api()}localeContent?filter[]=ref,eq,${me.localeDataRef}&filter[]=refId,eq,${refId}&transform=1&order[]=locale,asc`
                axios.get(url, me.authenticationInstance.axiosOptions)
                    .then(response => {
                        //debugger
                        let localeContent = response.data.localeContent;
                        let missingContent = me.getMissingLocaleContent(refId, localeContent);

                        if (missingContent.length > 0) {
                            //rare bug: soms worden de localeContent items meerdere keren aangemaakt. Dus eerst de lookup aanvullen
                            console.log('CREATING MISSING CONTENT', missingContent)
                            //create missing items
                            let url2 = `${me.applicationPath.crud_api()}localeContent?transform=1`
                            axios.post(url2, missingContent, me.authenticationInstance.axiosOptions)
                                .then(response2 => {
                                    //we kunnen nu de ids aan de missing content toewijzen:

                                    response2.data.forEach((id, key) => {
                                        missingContent[key].id = id
                                    })

                                    //en dan de 2 arrays samensmelten
                                    localeContent = localeContent.concat(missingContent)
                                    //console.log('LOCALE CONTENT', localeContent)
                                    me.fetchingRefId = null

                                    me.localeContentLookup[refId] = localeContent
                                    resolve({refId, localeContent, source: 'server|newLocales'})
                                })
                                .catch(error => console.log('ERROR CREATING LOCALE CONTENT', error))
                        } else {
                            me.fetchingRefId = null

                            me.localeContentLookup[refId] = localeContent
                            resolve({refId, localeContent, source: 'server'})
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        reject && reject(error)
                    });
            }


        });
    }

    getPropertyValue = (refId, property, locale) => {
        let me = this
        if (me.localeContentLookup[refId]) {
            const obj = me.localeContentLookup[refId].reduce((acc, value) => {
                if (value.field === property && value.locale === locale) return value.content
                return acc
            }, '')
            return obj
        } else {
            return 'no locale data'
        }
    }

    setPropertyValue = (refId, property, locale, value): any => {

        if (this.localeContentLookup[refId]) {
            const data = this.localeContentLookup[refId].reduce((acc, vo) => {
                if (vo.field === property && vo.locale === locale) return vo
                return acc
            }, null)

            if (data) data.content = value
        } else {
            return 'no locale data'
        }
    }

    saveLocaleData = (localeDataArray) => {

        return new Promise((resolve, reject) => {

                if (!localeDataArray || localeDataArray.length === 0) {
                    //nothing to save
                    resolve('Nothing to save')
                    return
                }
                const datum_wijziging = DateUtil.getNowMysql()
                let localeIds = localeDataArray.map(item => {
                    item.datum_wijziging = datum_wijziging
                    if (this.loginUser) item.user_wijziging = this.loginUser.voornaam + ' ' + this.loginUser.familienaam
                    return item.id
                }).join(',')

                let url = `${this.applicationPath.crud_api()}localeContent/${localeIds}/?transform=1`

                axios.put(url, localeDataArray)
                    .then(response => {
                        //de locale data objecten zijn gecloned: nu de lookups updaten

                        for (const localeDataArrayElement of localeDataArray) {
                            this.localeContentLookup[localeDataArrayElement.refId] = this.localeContentLookup[localeDataArrayElement.refId].map(el => {
                                if (el.id === localeDataArrayElement.id) return {...localeDataArrayElement}
                                return el
                            })
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject && reject(error)
                    });
            }
        )
    }

    deleteLocaleData = (refId) => {

        let localeData = this.localeContentLookup[refId]
        return new Promise((resolve, reject) => {

                let localeIds = localeData.map(item => item.id).join(',')
                let url = `${this.applicationPath.crud_api()}localeContent/${localeIds}`

                axios.delete(url, this.authenticationInstance.axiosOptions)
                    .then(response => {
                        resolve({response, deleted: localeIds})
                    })
                    .catch((error) => {
                        console.log(error)
                        reject && reject(error)
                    });
            }
        )
    }

    private getMissingLocaleContent = (refId, availableLocaleContent) => {
        let rv: LocaleContent[] = []

        this.locales.forEach(locale => {
            this.minimalProps.forEach(field => {
                let foundItem = availableLocaleContent.filter(item => item.locale === locale && item.field === field)
                if (foundItem.length === 0) {
                    let lc = {...new LocaleContent(), locale, field, ref: this.localeDataRef, refId: refId}
                    rv.push(lc)
                }
            })
        })

        return rv;
    }


}
