import React, {useState, useEffect, useContext} from 'react'
import {formatDistance, formatRelative, format} from 'date-fns'
import {Label, Divider} from "semantic-ui-react";
import {DateUtil} from "@ahsmediatheek/ada-lib";
import ServiceContext from "../../context/ServiceContext";


const ToegangsticketHistoriekRenderer = ({historiekItem}) => {

    const [datumString, setDatumString] = useState()
    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        const d = DateUtil.dateFromMysql(historiekItem.datum)
        setDatumString(format(d, 'PPPp', {locale: serviceContext.localeManager.dateFnsLocaleLookup[serviceContext.localeManager.locale]}))
    }, [historiekItem, serviceContext.localeManager.locale])

    return (
        <div>
            <span>{datumString} | <Label>{historiekItem.actie}</Label> | <strong>{historiekItem.actie_initiator}</strong></span>
            {historiekItem.toelichting && <span><br />{historiekItem.toelichting}</span>}
            <Divider/>
        </div>
    )
}

export default  ToegangsticketHistoriekRenderer
