import React, {useContext} from 'react'
import {Button} from "semantic-ui-react";
import ServiceContext from '../../../../context/ServiceContext'
import AbstractWizardPage from "./AbstractWizardPage";
import {ComponentIdentifier} from "../../../../component/ComponentIdentifier";


const AfrondingPage = () => {
    const serviceContext = useContext(ServiceContext)

    return (
        <AbstractWizardPage nextButtonVisible={false} additionalButtons={[
            <Button color='green' content={serviceContext.localeManager.getString('MAAK_COMPILATIE')} onClick={serviceContext.onMaakCompilatieButtonClick} />
        ]}>
            <ComponentIdentifier displayName='AfrondingPage'/>

        </AbstractWizardPage>
    )
}

export default AfrondingPage
