import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Select, Header, Divider} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'

import {InfoIcon, Embargo, Spacer} from "@ahsmediatheek/ada-lib";
import EmbargoUitzonderingen from "./EmbargoUitzonderingen";
import {ComponentIdentifier} from "../component/ComponentIdentifier";


const EmbargoEditor = ({provider, onUpdate, masterProvider, editable, title}) => {

    const [embargo, setEmbargo] = useState()
    const [masterEmbargo, setMasterEmbargo] = useState()
    const [metadataOptions, setMetadataOptions] = useState()
    const [inhoudOptions, setInhoudOptions] = useState()
    const [message, setMessage] = useState()
    const [messageTimer, setMessageTimer] = useState()
    //const [toonUitzonderingen, setToonUitzonderingen] = useState(false)

    const serviceContext = useContext(ServiceContext)

    //OPTIONS
    useEffect(() => {
        const embargo = Embargo.fromString(provider.embargo)

        const metadataOptions = Embargo.VALUES.map((optie, index) => {
            return {key: index, value: optie, disabled: false, text: `${serviceContext.localeManager.getString(optie)} (${index})`}
        })
        setMetadataOptions(metadataOptions)
        setInhoudOptions(metadataOptions.filter((o, i) => i < metadataOptions.length - 1))
        setEmbargo(embargo)

        if (masterProvider) {
            setMasterEmbargo(Embargo.fromString(masterProvider.embargo))
        }
    }, [provider, serviceContext.localeManager.locale, masterProvider, serviceContext.localeManager])

    //EMBARGO
    useEffect(() => {
        if (!embargo) return

        const metadataIndex = Embargo.VALUES.indexOf(embargo.metadata)
        const inhoudIndex = Embargo.VALUES.indexOf(embargo.bundelInhoud)
        let masterMetadataIndex, masterInhoudIndex

        if (masterEmbargo) {
            masterMetadataIndex = Embargo.VALUES.indexOf(masterEmbargo.metadata)
            masterInhoudIndex = Embargo.VALUES.indexOf(masterEmbargo.bundelInhoud)
        }

        setMetadataOptions(options => {
            return options.map((option, index) => {
                const newOption = {...option, disabled: index < inhoudIndex}
                if (masterMetadataIndex && index > masterMetadataIndex) newOption.disabled = true
                return newOption
            })
        })

        setInhoudOptions(options => {
             return options.map((option, index) => {
                const newOption = {...option, disabled: index > metadataIndex}
                if (masterInhoudIndex && index > masterInhoudIndex) newOption.disabled = true
                return newOption
            })
        })

        //setToonUitzonderingen(Embargo.allowUitzonderingen(embargo))

    }, [embargo, masterEmbargo])

    useEffect(() => {
        if (!message) {
            clearTimeout(messageTimer)
        } else {
            setMessageTimer(setTimeout(() => {
                setMessage(null)
            }, 8000))
        }

        return () => clearTimeout(messageTimer)
    }, [message, messageTimer])

    const onActiveerEmbargo = () => {
        let embargo = new Embargo()

        if (masterEmbargo) {
            embargo = {...masterEmbargo}
            embargo.uitzonderingen = []
        }
        provider.embargo = Embargo.toJsonString(embargo)
        onUpdate(provider)
        setEmbargo(embargo)
    }

    const onWisEmbargo = () => {
        provider.embargo = null
        setEmbargo(null)
        onUpdate(provider)
    }

    const onWijzigEmbargo = (event, {name, value, key}) => {

        if (value === embargo[name]) return

        const oldValue = embargo[name]
        embargo[name] = value

        const metadataIndex = Embargo.VALUES.indexOf(embargo.metadata)
        const inhoudIndex = Embargo.VALUES.indexOf(embargo.bundelInhoud)

        //metadata kan niet strenger zijn dan inhoud
        if (name === 'metadata' && metadataIndex < inhoudIndex) {
            setMessage('Instellingen voor metadata mogen niet strenger zijn dan die voor de inhoud')
            embargo.metadata = oldValue
        } else if (name === 'bundelInhoud' && inhoudIndex > metadataIndex) {
            setMessage('Instellingen voor bundel inhoud mogen niet soepeler zijn dan die voor de metadata')
            embargo.bundelInhoud = oldValue
        } else if (metadataIndex === Embargo.VALUES.length - 1 && metadataIndex === inhoudIndex) {
            setMessage('Zowel metadata als bundel inhoud zijn toegankelijk voor iedereen. Dit komt op hetzelfde neer als geen embargo. Je kan dus beter het embargo verwijderen')
        } else {
            setMessage(null)
            clearTimeout(messageTimer)
        }
        //inhoud kan niet toleranter zijn dan metadata

        provider.embargo = Embargo.toJsonString(embargo)
        onUpdate(provider)
        setEmbargo({...embargo})
    }

    const onUpdateUitzonderingen = (uitzonderingen) => {

        embargo.uitzonderingen = uitzonderingen
        provider.embargo = Embargo.toJsonString(embargo)
        onUpdate(provider)
        setEmbargo({...embargo})
    }

    return (
        <div className='embargo'>
            <ComponentIdentifier displayName='EmbargoEditor'/>

            {message && <div className='embargo-message'>
                <InfoIcon/>
                <span>{message}</span>
            </div>}

            {title && <Header>{title}</Header>}

            {editable && <div>
                {!embargo && <div>
                    <Button color='green' onClick={onActiveerEmbargo}>{serviceContext.localeManager.getString('ACTIVEER_EMBARGO')}</Button>
                </div>}

                {embargo && <Form>


                    <Form.Field>
                        <label>{serviceContext.localeManager.getString('WIE_KAN_METADATA_ZIEN')}</label>
                        <Select options={metadataOptions} name='metadata' value={embargo.metadata} onChange={onWijzigEmbargo}/>
                    </Form.Field>

                    <Form.Field>
                        <label>{serviceContext.localeManager.getString('WIE_KAN_BUNDEL_INHOUD_ZIEN')}</label>
                        <Select options={inhoudOptions} name='bundelInhoud' value={embargo.bundelInhoud} onChange={onWijzigEmbargo}/>
                    </Form.Field>

                    <Form.Field>
                        <label>{serviceContext.localeManager.getString('UITZONDERINGEN')}</label>
                        <Spacer distance='20px' vertical/>
                        <EmbargoUitzonderingen embargo={embargo} onUpdate={onUpdateUitzonderingen}/>
                    </Form.Field>

                    <Divider/>

                    <Form.Button color='red' icon='trash' content={serviceContext.localeManager.getString('WIS_EMBARGO')} onClick={onWisEmbargo}/>
                </Form>}
            </div>}


            {embargo && !editable && <div>
                <p>{serviceContext.localeManager.getString('WIE_KAN_METADATA_ZIEN')}: {serviceContext.localeManager.getString(embargo.metadata)}</p>
                <p>{serviceContext.localeManager.getString('WIE_KAN_BUNDEL_INHOUD_ZIEN')}: {serviceContext.localeManager.getString(embargo.bundelInhoud)}</p>
            </div>}
        </div>
    )
}

export {EmbargoEditor}

EmbargoEditor.propTypes = {
    provider: PropTypes.object.isRequired,
    masterProvider: PropTypes.object,
    onUpdate: PropTypes.func,
    editable: PropTypes.bool,
    title: PropTypes.string
}

EmbargoEditor.defaultProps = {
    editable: true
}
