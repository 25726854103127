import React from 'react'
import PropTypes from 'prop-types'
import {Segment, Button} from "semantic-ui-react";
import {Link} from "react-router-dom";

const logins = [
    {name: 'Geen', id: 0},
    {name: 'Dany', id: 1, role: 'superAdmin'},
    {name: 'Lennert', id: 2, role: 'superAdmin'},
    {name: 'Renaat', id: 16309, role: 'superAdmin'},
    {name: 'Liesbeth', id: 16472, role: 'medewerker'},
    {name: 'Eveline', id: 16263, role: 'medewerker'},
    {name: 'Ariane', id: 16977, role: 'medewerker'},
    {name: 'Tara', id: 17176, role: 'docent'},
    {name: 'Student 1', id: 2768, role: 'student'},
    {name: 'Barend', id: 6, role: 'docent'},
]
const SuperAdminLogin = ({onLoginClick, devLoginId}) => {

    return (
        <Segment inverted>
            {logins.map(login => <Link key={login.id} to={`/home}`}>
                <Button color={login.id === devLoginId ? 'teal' : 'grey'} onClick={() => onLoginClick(login)}>{login.name} ({login.id})</Button>
            </Link>)}
        </Segment>
    )
}

export default SuperAdminLogin

SuperAdminLogin.propTypes = {
    onLoginClick: PropTypes.func.isRequired,
    devLoginId: PropTypes.number
}