import React from 'react'
import { ComponentIdentifier } from '../../../../component/ComponentIdentifier';
import AbstractWizardPage from "./AbstractWizardPage";


const BwWelkom = () => {
    return (
        <AbstractWizardPage prevButtonVisible={false}>
            <ComponentIdentifier displayName='BwWelkom'/>
            
        </AbstractWizardPage>
    )
}

export default BwWelkom
