import React from 'react'
import PropTypes from 'prop-types'
import {Image, Icon} from "semantic-ui-react";

const AdaUserCard = ({adaUser, label, style, deleteable, onDelete, children}) => {

    return (
        <div style={{...style, display: 'flex', alignItems: 'center', marginRight: '15px'}}>
            
            <Image avatar src={adaUser.profielfoto}/>
            <div style={{margin: '0 5px'}}>
                {   adaUser.complete === 1 &&
                    <strong>{`${adaUser.voornaam} ${adaUser.familienaam}`}</strong>
                }
                {   adaUser.complete === 0 &&
                    <strong>{`${adaUser.email}`}</strong>
                }
                {label && <div style={{color: '#666', marginBottom: '-2px', fontSize: '0.8em'}}>{label}</div>}
            </div>
            {deleteable && onDelete && <Icon name='close' circular inverted color='grey' link size='tiny' onClick={() => onDelete(adaUser)}/>}
        </div>
    )
}

export {AdaUserCard}

AdaUserCard.propTypes = {
    adaUser: PropTypes.object.isRequired,
    label: PropTypes.string,
    deleteable: PropTypes.bool,
    onDelete: PropTypes.func
}

AdaUserCard.defaultProps = {
    deleteable: false
}