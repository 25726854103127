export const bundelCsvErrors = {
    geen_titel: {
        type: 'error',
        header_nl: 'Titel ontbreekt',
        header_en: 'Title missing',
        content_nl: 'Elke bundel moet minstens een Nederlandstalige titel bevatten. Gelieve uw csc bestand aan te passen',
        content_en: 'Each bundle should at least have a Dutch title. Please correct your cs-v file'
    },
    titel_te_lang: {
        type: 'warning',
        header_nl: 'Titel is te lang',
        header_en: 'Title is too long',
        content_nl: 'De titel van deze bundel is te lang. U kan overwegen om een deel van deze titel in het subtitel veld op te nemen',
        content_en: 'The title of this bundel is too long. It would be best to move part of this title to the subtitle field'
    },
    geen_inzenders: {
        type: 'error',
        header_nl: 'Geen inzender(s)',
        header_en: 'No committer(s)',
        content_nl: 'Deze bundel heeft geen inzender. Een bundel moet minstens één inzender hebben',
        content_en: 'This bundle has no committer. Each bundle should have at least one committer assigned'
    },
    fout_mail_adres: {
        type: 'error',
        header_nl: 'Fout mail adres',
        header_en: 'Wrong email',
        content_nl: 'Dit verkort email adres is niet gevonden. Controleer het email adres van [[mail]]',
        content_en: 'This short email address was not found. Please check the address of [[mail]]'
    }
}
