import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import DatePicker, {registerLocale} from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import nl from 'date-fns/locale/nl';
import {parse, format, startOfDay} from 'date-fns'

registerLocale('nl', nl)

//import styles from '../styles.css'
const MYSQL_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'
const MYSQL_DATE_FORMAT = 'yyyy-MM-dd'

const DatePickerInput = (props) => {

    const [date, setDate] = useState()
    const [maxDate, setMaxDate] = useState()

    useEffect(() => {
        let ds = props.datestring, date
        if (!ds) {
            date = props.useTime ? new Date() : startOfDay(new Date())
        } else {
            date = parse(ds, props.useTime ? MYSQL_DATE_TIME_FORMAT : MYSQL_DATE_FORMAT, new Date())
        }
        if (props.maxDatestring)
            setMaxDate(parse(props.maxDatestring, MYSQL_DATE_FORMAT, new Date()))

        setDate(date)
    }, [props.datestring, props.maxDatestring, props.useTime])

    const onChange = (date) => {
        setDate(date)
        props.onChange && props.onChange(format(date, props.useTime ? MYSQL_DATE_TIME_FORMAT : MYSQL_DATE_FORMAT))
    }

    if (!date) return null

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            {props.label && <div style={{marginRight: '10px'}}>{props.label}</div>}
            <div className={props.disabled ? 'content-disabled' : ''}
                 style={{display: 'flex', alignItems: 'center', marginRight: '10px', width: props.useTime ? '150px' : '110px'}}>

                {date && <DatePicker
                    selected={date}
                    locale={props.locale}
                    onChange={onChange}
                    showTimeSelect={props.useTime}
                    timeFormat="p"
                    timeIntervals={15}
                    maxDate={maxDate}
                    dateFormat={props.useTime ? 'Pp' : 'P'}
                />}
            </div>
        </div>
    )
}

export {DatePickerInput}

/*const InputComp = (props) => {

    return <div style={{width: '150px'}}>
        <Form.Input
            action={{color: "teal", icon: "calendar"}}
            actionPosition="left"
            onClick={props.onClick}
            value={props.label}
            fluid
        />
    </div>
}*/

DatePickerInput.propTypes = {
    disabled: PropTypes.bool,
    datestring: PropTypes.string,
    useTime: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
    locale: PropTypes.string,
    maxDatestring: PropTypes.object
}

DatePickerInput.defaultProps = {
    useTime: false,
    disabled: false,
    locale: 'nl',
    selected: new Date()
}
