export class Trefwoord {
    id: number
    refKey: string
    status: string
    candidateRef: string
    bron: string
    lastEdit: string
}

export class Tag {
    id: number
    refKey: string
    locale: string
    tag: string
    context: string
    type: string
}

export class Verwijzing {
    id: number
    refKey1: string
    refKey2: string
}

export class TrefwoordStatus {
    static readonly CANDIDATE: string = 'candidate'
    static readonly VALIDATED: string = 'validated'
    static readonly DEPRECATED: string = 'deprecated'
    static readonly SWAPPED: string = 'swapped'


    static getTrefwoordStatusColor(status){
        switch (status){
            case TrefwoordStatus.VALIDATED: return TrefwoordStatusColor.VALIDATED
            case TrefwoordStatus.DEPRECATED: return TrefwoordStatusColor.DEPRECATED
            case TrefwoordStatus.SWAPPED: return TrefwoordStatusColor.SWAPPED
            default: return TrefwoordStatusColor.CANDIDATE
        }
    }
    static getTrefwoordStatusTextColor(status){
        switch (status){
            case TrefwoordStatus.VALIDATED:
            case TrefwoordStatus.DEPRECATED:
            case TrefwoordStatus.SWAPPED:
                return 'white'
            default: return 'black'
        }
    }
}

export class TrefwoordStatusColor {
    static readonly CANDIDATE: any = null
    static readonly VALIDATED: string = 'green' //'#7bbaff'
    static readonly DEPRECATED: string = 'red' //'#FF2218'
    static readonly SWAPPED: string = 'purple' //'#D99FD5'
}

export class TrefwoordType {
    static readonly TREFWOORD: string = 'trefwoord'
    static readonly SYNONIEM: string = 'synoniem'
}

export class TrefwoordBron {
    static readonly ADA: string = 'ada'
    static readonly BIDOC: string = 'bidoc'
    static readonly CUSTOM: string = 'custom'
    static readonly BACKOFFICE: string = 'backoffice'
}
