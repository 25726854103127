import axios from "axios";
//import orderBy from 'lodash/orderBy'


const TREFWOORDEN_API: string = `https://www.arteveldehogeschool.be/mediatheken/trefwoorden`
const API_KEY: string = "qwertyuiopasdfghjklzxcvbnm"
const API_BASE_URL: string = "https://www.arteveldehogeschool.be/mediatheken/api/v1/"
const CONSUMER_TABLE: string = 'trefwoorden_consumer'
const TREFWOORDEN_TABLE: string = 'trefwoorden'
const TAGS_TABLE: string = 'tags'
const VERWIJZING_TABLE: string = 'trefwoorden_verwijzing'

const MODE_MATCH: string = 'match'
const MODE_STARTS: string = 'starts'
const MODE_CONTAINS: string = 'contains'

export class TrefwoordenService {

    trefwoordenLookup: object = {}
    consumer: object

    //==============================================================
    //==============================================================
    //                      CONSUMERS
    //==============================================================
    //==============================================================

    getConsumer = (consumerKey) => {
        return new Promise((resolve, reject) => {

                    const url = `${API_BASE_URL}${CONSUMER_TABLE}?filter=consumerKey,eq,${consumerKey}&token=${API_KEY}&transform=1`

                    axios.get(url)
                        .then(response => {
                            const consumers = response.data[CONSUMER_TABLE]
                            if (consumers.length === 1){
                                this.consumer = consumers[0]
                                resolve(this.consumer)
                            } else {
                                resolve(null)
                            }
                        })
                        .catch(error => {
                            console.error('CONSUMER ERROR', error)
                            reject(error)
                        })
                })
    }

    //==============================================================
    //==============================================================
    //                      TREFWOORDEN API
    //==============================================================
    //==============================================================

    /*
    * getTrefwoorden zoekt MATCH*/
    getTrefwoorden = (trefwoordenString) => {

        //wat binnenkomt is een tube separated string!

        return new Promise((resolve, reject) => {

            const url = `${TREFWOORDEN_API}/${MODE_MATCH}/${trefwoordenString}/`

            axios.get(url)
                .then(response => {
                    resolve(response.data.trefwoorden)
                })
                .catch(error => {
                    console.error('GET TREFWOORDEN ERROR', error)
                    reject(error)
                })
        })
    }

    /*
    * CONTAINS*/
    getTrefwoordSuggestions = (query, itemsPerPage = 20, page = 1) => {

        return new Promise((resolve, reject) => {

            const url = `${TREFWOORDEN_API}/${MODE_CONTAINS}/${query}?itemsPerPage=${itemsPerPage}&page=${page}`
            axios.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error('GET TREFWOORDEN ERROR', error)
                    reject(error)
                })
        })
    }

    /*
    * STARTS*/
    getTrefwoordenStartingWith = (query, itemsPerPage = 20, page = 1) => {

        return new Promise((resolve, reject) => {

            const url = `${TREFWOORDEN_API}/${MODE_STARTS}/${query}?itemsPerPage=${itemsPerPage}&page=${page}`
            axios.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error('GET TREFWOORDEN ERROR', error)
                    reject(error)
                })
        })
    }

    deprecateTrefwoord = (trefwoord) => {
        return new Promise((resolve, reject) => {

            const url = `${TREFWOORDEN_API}/deprecateTrefwoord/${trefwoord.refKey}/`
            axios.post(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error('DEPRECATE ERROR', error)
                    reject(error)
                })
        })
    }

    swapTrefwoord = (trefwoord, swapKey) => {
        return new Promise((resolve, reject) => {

            const url = `${TREFWOORDEN_API}/swapTrefwoord/${trefwoord.refKey}:${swapKey}/`
            axios.post(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error('SWAP ERROR', error)
                    reject(error)
                })
        })
    }

    unswapTrefwoord = (trefwoord) => {
        return new Promise((resolve, reject) => {

            const url = `${TREFWOORDEN_API}/unswapTrefwoord/${trefwoord.refKey}/`
            axios.post(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error('UNSWAP ERROR', error)
                    reject(error)
                })
        })
    }

    //==============================================================
    //==============================================================
    //                      CANDIDATE
    //==============================================================
    //==============================================================


    submitCandidates = (candidates) => {
        return new Promise((resolve, reject) => {

            //  const url = `${API_BASE_URL}${TAGS_TABLE}/?token=${API_KEY}&transform=1`


            //TODO: Refactor
            resolve('TO REFACTOR')
            return
            /*axios.post(url, candidates)
                .then(response => {
                    console.log('Candidates Created', response)
                    resolve(response)
                })
                .catch(error => {
                    console.error('Create Candidates Error', error)
                    reject(error)
                })*/
        })
    }

    //==============================================================
    //==============================================================
    //                      TREFWOORDEN
    //==============================================================
    //==============================================================

    updateTrefwoord = (trefwoord) => {
        return new Promise((resolve, reject) => {

            const url = `${API_BASE_URL}${TREFWOORDEN_TABLE}/${trefwoord.id}?token=${API_KEY}&transform=1`

            axios.put(url, trefwoord)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('UPDATE TREFWOORD ERROR', error)
                    reject(error)
                })
        })
    }

    createTrefwoord = (trefwoord) => {
        return new Promise((resolve, reject) => {

            const url = `${API_BASE_URL}${TREFWOORDEN_TABLE}/?token=${API_KEY}&transform=1`

            axios.post(url, trefwoord)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('CREATE TREFWOORD ERROR', error)
                    reject(error)
                })
        })
    }

    /*deleteTrefwoord = (trefwoord) => {
        return new Promise((resolve, reject) => {

            const url = `${API_BASE_URL}${TAGS_TABLE}/${trefwoord.id}?token=${API_KEY}&transform=1`

            console.log('CAND URL', url)

            //TODO: Refactor
            resolve('TO REFACTOR')
            return

            /!*axios.delete(url)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('DELETE TREFWOORD ERROR', error)
                    reject(error)
                })*!/
        })
    }*/

    //==============================================================
    //==============================================================
    //                      TAGS
    //==============================================================
    //==============================================================


    updateTag = (tag) => {
        return new Promise((resolve, reject) => {

            const url = `${API_BASE_URL}${TAGS_TABLE}/${tag.id}?token=${API_KEY}&transform=1`


            axios.put(url, tag)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('UPDATE TAG ERROR', error)
                    reject(error)
                })
        })
    }

    createTag = (tag) => {
        return new Promise((resolve, reject) => {

            const url = `${API_BASE_URL}${TAGS_TABLE}/?token=${API_KEY}&transform=1`

            axios.post(url, tag)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('CREATE TAG ERROR', error)
                    reject(error)
                })
        })
    }

    deleteTag = (tag) => {
        return new Promise((resolve, reject) => {

            const url = `${API_BASE_URL}${TAGS_TABLE}/${tag.id}?token=${API_KEY}&transform=1`

            axios.delete(url)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('DELETE TREFWOORD ERROR', error)
                    reject(error)
                })
        })
    }

    //==============================================================
    //==============================================================
    //                      VERWIJZINGEN
    //==============================================================
    //==============================================================


    createVerwijzing = (verwijzing) => {
        return new Promise((resolve, reject) => {

            const url = `${API_BASE_URL}${VERWIJZING_TABLE}/?token=${API_KEY}&transform=1`

            axios.post(url, verwijzing)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('CREATE VERWIJZING ERROR', error)
                    reject(error)
                })
        })
    }

    deleteVerwijzing = (verwijzing) => {
        return new Promise((resolve, reject) => {

            const url = `${API_BASE_URL}${VERWIJZING_TABLE}/${verwijzing.id}?token=${API_KEY}&transform=1`

            axios.delete(url)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('DELETE VERWIJZING ERROR', error)
                    reject(error)
                })
        })
    }
}