import React, {useContext} from 'react'

import ServiceContext from '../../context/ServiceContext'
import {InfoViewer} from "@ahsmediatheek/ada-lib";
import {EmbargoEditor} from "../../embargo/EmbargoEditor";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const CollectieSettingsEmbargo = () => {

    const serviceContext = useContext(ServiceContext)

    return (
        <div>
            <ComponentIdentifier displayName='CollectieSettingsEmbargo'/>

            <InfoViewer editable={serviceContext.infoEditable}
                        refId={'INFO_EMBARGOS'}
                        localeManager={serviceContext.localeManager}
                        localeDataService={serviceContext.infoLocaleDataService}/>

            {serviceContext.collectie && serviceContext.localeManager &&
            <EmbargoEditor provider={serviceContext.collectie} localeManager={serviceContext.localeManager}
                           onUpdate={serviceContext.onSaveCollectie}/>

            }
        </div>
    )
}

export default CollectieSettingsEmbargo
