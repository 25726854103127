import React, {useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {BundelIcon, CollectieIcon, ResultContainer} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../../context/ServiceContext'

import {Divider} from "semantic-ui-react";
import {BundelCard} from "../../component/BundelCard";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const AdaUserBundels = ({bundels}) => {

    const serviceContext = useContext(ServiceContext)


    return (
        <Fragment>
            <ComponentIdentifier displayName='AdaUserBundels'/>
            <ResultContainer containerWidth={serviceContext.resultContainerAvailableWidth}
                            display={serviceContext.resultDisplay} setDisplay={serviceContext.setResultDisplay}
                            header={serviceContext.localeManager.getString('MIJN_BUNDELS')}
                            headerIcon={<BundelIcon size='normal' style={{marginRight: '10px'}}/>}>


            {bundels.map((bundel, index) => {
                return <BundelCard key={index} showBundelUuid={serviceContext.privilegeManager.isAdmin()}
                                   showStatus
                                   display={serviceContext.resultDisplay}
                                   bundel={bundel}
                                   locale={serviceContext.localeManager.locale}/>
            })}

        </ResultContainer>
        </Fragment>
    )
}

export default AdaUserBundels

AdaUserBundels.propTypes = {
    bundels: PropTypes.array.isRequired
}

AdaUserBundels.defaultProps = {}
