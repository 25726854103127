import axios from 'axios'
//import moment from 'moment'
import {ApplicationPath} from "../class/general/ApplicationPath"
import {AuthenticationService} from "./AuthenticationService"
import {AdaUserService} from "./AdaUserService"
import {CarrouselContext} from "../class/carrousel/Carrousel";

const moment = require('moment')

const COLLECTIE_TABLE: string = 'collectie'

export class CollectieService {

    applicationPath: ApplicationPath
    authenticationInstance: AuthenticationService
    adaUserService: AdaUserService
    collectieLookup: object = {}
    collectieSettingsLookup: object = {}

    constructor(authenticationInstance, adaUserService, applicationPath) {
        this.authenticationInstance = authenticationInstance
        this.adaUserService = adaUserService
        this.applicationPath = applicationPath
    }

    //==============================================================
    //==============================================================
    //                      COLLECTIE
    //==============================================================
    //==============================================================

    getCollectie = (uuid: string) => {
        return new Promise((resolve, reject) => {

            if (this.collectieLookup[uuid]) {
                resolve(this.collectieLookup[uuid])
                return
            }

            //GET /records/posts?join=comments,users&join=tags

            const url = `${this.applicationPath.crud_api()}${COLLECTIE_TABLE}/?filter[]=uuid,eq,${uuid}&transform=1`
            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {

                    const collectieArr = response.data.collectie

                    if (collectieArr.length > 0) {
                        const collectie = collectieArr[0]
                        this.collectieLookup[collectie.uuid] = collectie
                        resolve(collectie)
                    } else {

                        resolve(null)
                    }
                })
                .catch(error => {
                    console.error('GET COLLECTIE ERROR', error)
                    reject(error)
                })
        })
    }

    //MULTIPLE COLLECTIES
    getCollecties = (ids: number[]) => {
        return new Promise((resolve, reject) => {

            if (ids.length === 0) {
                resolve([])
                return
            }

            const cachedCollecties = ids.reduce((collecties, id) => {
                if (this.collectieLookup[id]) collecties.push(this.collectieLookup[id])
                return collecties
            }, [])

            const collectiesToFetch = ids.filter(id => !this.collectieLookup[id])

            if (collectiesToFetch.length === 0) {
                resolve(cachedCollecties)
                return
            }

            axios.get(`${this.applicationPath.crud_api()}${COLLECTIE_TABLE}/${collectiesToFetch.join(',')}?transform=1`, this.authenticationInstance.axiosOptions)
                .then(response => {
                    let collecties = response.data
                    if (!Array.isArray(collecties)) collecties = [collecties]
                    for (const collectie of collecties) {
                        this.collectieLookup[collectie.id] = collectie
                    }
                    resolve([...collecties, ...cachedCollecties])
                })
                .catch(error => {
                    console.error('GET ADA USER ERROR', error)
                    reject(error)
                })
        })
    }

    updateCollectie = (collectie) => {

        collectie.gewijzigd = moment().format('YYYY-MM-DD HH:mm:ss')
        collectie.isMetadataDirty = 1

        const url = `${this.applicationPath.crud_api()}${COLLECTIE_TABLE}/${collectie.id}?transform=1`

        return new Promise((resolve, reject) => {
                axios.put(url, collectie)
                    .then((result) => {
                        this.collectieLookup[collectie.id] = collectie
                        resolve(result)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    createCollectie = (collectie) => {
        console.log('CollectieService')
        console.log(collectie)
        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api()}${COLLECTIE_TABLE}/?transform=1`

            axios.post(url, collectie)
                .then((result) => {
                    //console.log(result)
                    collectie.id = result.data
                    this.collectieLookup[collectie.id] = collectie
                    resolve(collectie)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    deleteCollectie = (collectie) => {

        return new Promise((resolve, reject) => {

            const config: any = this.authenticationInstance.axiosOptions

            let deleteRefBeeldUrl = `${this.applicationPath.ada_api()}/deleteReferentiebeeld/${collectie.uuid}/`
            axios.delete(deleteRefBeeldUrl, config)
                .then((result) => {
                    console.log('DELETE REFERENTIEBEELD', result)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })

            const url = `${this.applicationPath.crud_api()}collectie/${collectie.id}?transform=1`

            axios.delete(url)
                .then((result) => {
                    delete this.collectieLookup[collectie.id]
                    resolve('Collectie deleted')
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    //==============================================================
    //==============================================================
    //                      SETTINGS
    //==============================================================
    //==============================================================

    getCollectieSettings = (collectie) => {
        if (!collectie.settings) collectie.settings = '{}'

        if (!this.collectieSettingsLookup[collectie.id]) this.collectieSettingsLookup[collectie.id] = JSON.parse(collectie.settings)

        return this.collectieSettingsLookup[collectie.id]
    }

    getCollectieSetting = (collectie, setting) => {
        if (!collectie.settings) collectie.settings = '{}'

        if (!this.collectieSettingsLookup[collectie.id]) this.collectieSettingsLookup[collectie.id] = JSON.parse(collectie.settings)

        return this.collectieSettingsLookup[collectie.id][setting]
    }

    setCollectieSetting = (collectie, setting, value) => {
        if (!this.collectieSettingsLookup[collectie.id]) this.collectieSettingsLookup[collectie.id] = {}
        this.collectieSettingsLookup[collectie.id][setting] = value
        collectie.settings = JSON.stringify(this.collectieSettingsLookup[collectie.id])
        return this.collectieSettingsLookup[collectie.id]
    }

    //==============================================================
    //==============================================================
    //                      REFERENTIEBEELD
    //==============================================================
    //==============================================================

    uploadReferentiebeeld = (file, uuid, progressCallback) => {
        return new Promise((resolve, reject) => {


            const formData = new FormData()

            formData.append(file.path, file, uuid + '.jpg')

            const config = {
                onUploadProgress: progressCallback
            }

            console.group()
            const url = `${this.applicationPath.ada_api()}/uploadReferentiebeeld/${uuid}?transform=1`
            console.info('CALL uploadReferentiebeeld', url, formData, config)
            axios.post(url, formData, config)
                .then(response => {
                    console.log('SERVER RESPONSE', response)
                    resolve(response)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    //==============================================================
    //==============================================================
    //                      CARROUSSEL
    //==============================================================
    //==============================================================

    getCarrouselContent = (context) => {
        return new Promise((resolve, reject) => {

            let url

            switch (context) {
                case CarrouselContext.RECENT:
                    url = `${this.applicationPath.crud_api()}${COLLECTIE_TABLE}/?transform=1&order=aangemaakt,desc&size=10`
                    break
                default:
                    resolve([])
                    return
            }

            url += `&filter[]=zichtbaarInCarrousel,eq,1`

            axios.get(url)
                .then((result) => {
                    const collecties = result.data[COLLECTIE_TABLE].map(coll => {
                        if (!this.collectieLookup[coll.id]) this.collectieLookup[coll.id] = coll
                        return this.collectieLookup[coll.id]
                    })
                    resolve(collecties)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    //==============================================================
    //==============================================================
    //                      AHS API
    //==============================================================
    //==============================================================

    /*getOpleidingen = (collectie) => {
        return new Promise((resolve, reject) => {

                    const url = `${this.applicationPath.ahs_api()}users`
                    axios.get(url)
                        .then(response => {

                        })
                        .catch(error => {
                            console.error('GET OPLEIDINGEN ERROR', error)
                            reject(error)
                        })
                })
    }*/
}
