import React, {useContext} from 'react'
import {Button} from "semantic-ui-react";
import ServiceContext from '../../../../context/ServiceContext'
import AbstractWizardPage from "./AbstractWizardPage";
import {ComponentIdentifier} from "../../../../component/ComponentIdentifier";


const CwAfronding = () => {

    const serviceContext = useContext(ServiceContext)

    return (
        <AbstractWizardPage nextButtonVisible={false} additionalButtons={[
            <Button color='green' content={serviceContext.localeManager.getString('MAAK_COLLECTIE')} onClick={serviceContext.onMaakCollectieButtonClick}/>
        ]}>
            <ComponentIdentifier displayName='CwAfronding'/>

        </AbstractWizardPage>
    )
}

export default CwAfronding
