import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import ServiceContext from '../context/ServiceContext'

import {Label, Modal} from "semantic-ui-react";
import {InfoViewer, StatusManager} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "./ComponentIdentifier";

const ProviderStatus = ({provider, statusLabel, padded}) => {

    const serviceContext = useContext(ServiceContext)

    const [infoModalOpen, setInfoModalOpen] = useState(false)
    const [labelColor, setLabelColor] = useState()

    useEffect(() => {
        setLabelColor(StatusManager.getStatusColor(provider.status))
    }, [provider])

    return (
        <div style={{marginTop: padded ? '5px' : 0}}>
            <ComponentIdentifier displayName='ProviderStatus'/>
            {statusLabel}: <Label as='a' color={labelColor} onClick={() => setInfoModalOpen(true)}>{serviceContext.localeManager.getString(provider.status)}</Label>

            <Modal open={infoModalOpen} onClose={() => setInfoModalOpen(false)} dimmer='inverted'>
                <Modal.Content>
                    <InfoViewer editable={serviceContext.infoEditable}
                                refId={'INFO_STATUS_' + provider.class.toUpperCase()}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>
                </Modal.Content>


            </Modal>
        </div>
    )
}

export default  ProviderStatus

ProviderStatus.propTypes = {
    provider: PropTypes.object.isRequired,
    statusLabel: PropTypes.string,
    padded: PropTypes.bool,
}

ProviderStatus.defaultProps = {
    statusLabel: 'Status',
    padded: false
}
