import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ServiceContext from '../context/ServiceContext'
import {AdaUserCard, Spacer, DatePickerInput, TrashIcon} from "@ahsmediatheek/ada-lib";
import {Divider, Select} from "semantic-ui-react";
import {EmbargoUitzondering} from "@ahsmediatheek/ada-lib";


const EmbargoUitzonderingRenderer = ({uitzondering, onUpdate, onDelete}) => {

    const [adaUser, setAdaUser] = useState()
    const serviceContext = useContext(ServiceContext)
    const [toegangOptions, setToegangOptions] = useState()
    const [maxDatestring, setMaxDatestring] = useState()

    useEffect(() => {
        const toegangOptions = EmbargoUitzondering.VALUES.map((optie, index) => {
            return {key: index, value: optie, text: `${serviceContext.localeManager.getString(optie)}`}
        })
        setToegangOptions(toegangOptions)
    }, [serviceContext.localeManager])

    useEffect(() => {
        if (!uitzondering) return

        serviceContext.adaUserService.getAdaUser(uitzondering.gebruiker).then(res => {
            setAdaUser(res)
        })


    }, [uitzondering, serviceContext.adaUserService])

    useEffect(() => {
        if (!uitzondering) return
        const mds = moment(uitzondering.van).add(5, 'weeks').format('YYYY-MM-DD')
        setMaxDatestring(mds)
        if (uitzondering.tot > mds) uitzondering.tot = mds


    }, [uitzondering])

    const onWijzigToegang = (event, {value}) => {
        uitzondering.toegang = value
        onUpdate(uitzondering)
    }

    const onDateChange = (prop, date) => {
        uitzondering[prop] = date
        onUpdate(uitzondering)
    }

    if (!adaUser) return null

    return (
        <Fragment>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{width: '200px'}}><AdaUserCard deleteable={false}
                                                           style={{marginBottom: 0}}
                                                           adaUser={adaUser}/></div>
                <div>{serviceContext.localeManager.getString('TOEGANG')}:</div>
                <Spacer/>

                <Select size='mini' options={toegangOptions} name='toegang' value={uitzondering.toegang} onChange={onWijzigToegang}/>

                <Spacer/>
                <div>{serviceContext.localeManager.getString('VAN')}:</div>
                <Spacer/>
                <DatePickerInput datestring={uitzondering.van} locale={serviceContext.localeManager.locale} onChange={(date) => onDateChange('van', date)}/>

                <Spacer/>
                <div>{serviceContext.localeManager.getString('TOT')}:</div>
                <Spacer/>
                <DatePickerInput datestring={uitzondering.tot} maxDatestring={maxDatestring} locale={serviceContext.localeManager.locale} onChange={(date) => onDateChange('tot', date)}/>
                <Spacer/>
                <TrashIcon onClick={() => onDelete(uitzondering)}/>
            </div>
            <Divider/>
        </Fragment>
    )
}

export default EmbargoUitzonderingRenderer

EmbargoUitzonderingRenderer.propTypes = {
    uitzondering: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
}

EmbargoUitzonderingRenderer.defaultProps = {}
