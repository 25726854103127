import React, { Fragment, useContext } from 'react';
import { Divider } from "semantic-ui-react";

import { CompilatieIcon, CollectieIcon, BundelIcon, ResultContainer } from "@ahsmediatheek/ada-lib";

import ServiceContext from '../context/ServiceContext';
import { CompilatieCard } from "../component/CompilatieCard";
import { CollectieCard } from "../component/CollectieCard";
import { BundelCard } from "../component/BundelCard";


export default function InhoudZoekresultaten({ zoekresultaten, onSelect }) {
    const serviceContext = useContext(ServiceContext);

    return (
        <Fragment>
            <ResultContainer
                containerWidth={serviceContext.resultContainerAvailableWidth}
                display={serviceContext.resultDisplay}
                setDisplay={serviceContext.setResultDisplay}
                header={`${serviceContext.localeManager.getString('COMPILATIES')} (${zoekresultaten['compilatie'].length})`}
                headerIcon={<CompilatieIcon size='large' style={{ marginRight: '10px' }} />}
            >
                {zoekresultaten['compilatie'].filter(item => item.class === 'compilatie').map((compilatie, index) => (
                    <CompilatieCard
                        key={index}
                        onClick={() => { onSelect(compilatie); }}
                        compilatie={compilatie}
                        display={serviceContext.resultDisplay}
                        locale={serviceContext.localeManager.locale}
                        showStatus
                    />
                ))}
            </ResultContainer>

            <Divider />

            <ResultContainer
                containerWidth={serviceContext.resultContainerAvailableWidth}
                display={serviceContext.resultDisplay}
                setDisplay={serviceContext.setResultDisplay}
                header={`${serviceContext.localeManager.getString('COLLECTIES')} (${zoekresultaten['collectie'].length})`}
                headerIcon={<CollectieIcon size='large' style={{ marginRight: '10px' }} />}
            >
                {zoekresultaten['collectie'].filter(item => item.class === 'collectie').map((collectie, index) => (
                    <CollectieCard
                        key={index}
                        onClick={() => { onSelect(collectie); }}
                        collectie={collectie}
                        display={serviceContext.resultDisplay}
                        locale={serviceContext.localeManager.locale}
                        showStatus
                    />
                ))}
            </ResultContainer>

            <Divider />

            <ResultContainer
                containerWidth={serviceContext.resultContainerAvailableWidth}
                display={serviceContext.resultDisplay}
                setDisplay={serviceContext.setResultDisplay}
                header={`${serviceContext.localeManager.getString('BUNDELS')} (${zoekresultaten['bundel'].length})`}
                headerIcon={<BundelIcon size='large' style={{ marginRight: '10px' }} />}
            >
                {zoekresultaten['bundel'].filter(item => item.class === 'bundel').map((bundel, index) => (
                    <BundelCard
                        key={index}
                        onClick={() => { onSelect(bundel); }}
                        bundel={bundel}
                        display={serviceContext.resultDisplay}
                        locale={serviceContext.localeManager.locale}
                        showStatus
                    />
                ))}
            </ResultContainer>
        </Fragment>
    );
}
