import React, {useState, useEffect, useContext, useRef, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Segment, Button, Modal, TextArea, Divider, Header} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'

import {BundelStatus, BundelActie, InfoViewer, CollectieSettings, CollectieStatus} from "@ahsmediatheek/ada-lib";
import ProviderStatus from "./ProviderStatus";
import {ComponentIdentifier} from "./ComponentIdentifier";

const BundelActies = ({bundel}) => {

    const serviceContext = useContext(ServiceContext)

    const [acties, setActies] = useState([])
    const [warningModalOpen, setWarningModalOpen] = useState(false)
    const [actieInfoKey, setActieInfoKey] = useState()
    const [bevestigLabel, setBevestigLabel] = useState()
    const [textInputContent, setTextInputContent] = useState('')
    const [showTextInput, setShowTextInput] = useState(false)


    const textInputRef = useRef()

    useEffect(() => {
        if (textInputRef.current) textInputRef.current.focus()
    }, [textInputRef.current, showTextInput])

    useEffect(() => {

    }, [bundel])

    useEffect(() => {
        const acties = []

        if (serviceContext.privilegeManager.canCommitBundel(serviceContext.bundelCollectieCollectie, bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks))
            acties.push(BundelActie.BUNDEL_INZENDEN)

        if (serviceContext.privilegeManager.canValidateBundel(serviceContext.bundelCollectieCollectie, bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks))
            acties.push(BundelActie.BUNDEL_VALIDEREN)

        if (serviceContext.privilegeManager.canRejectBundelValidatie(serviceContext.bundelCollectieCollectie, bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks))
            acties.push(BundelActie.BUNDEL_VALIDATIE_AFWIJZEN)

        if (serviceContext.privilegeManager.canReopenBundel(bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks))
            acties.push(BundelActie.BUNDEL_HEROPENEN)

        setActies(acties)
    }, [bundel, serviceContext.privilegeManager, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks])


    const onCloseModal = () => {
        setActieInfoKey(null)
        setWarningModalOpen(false)
    }

    const onActieClick = (event, {name}) => {
        setWarningModalOpen(true)
        setActieInfoKey('ACTIE_' + name.toUpperCase())
        setBevestigLabel(name)

        switch (name) {
            case BundelActie.BUNDEL_VALIDATIE_AFWIJZEN:
                setShowTextInput(true)
                setTextInputContent('')
                break
            default:
                setShowTextInput(false)
                break
        }
    }

    const voerActieUit = (actie) => {

        //serviceContext.mailService.notificeerBundelStatusGewijzigd(serviceContext.bundelCollectieCollectie, bundel, BundelStatus.IN_VALIDATIE, BundelStatus.GEVALIDEERD)
        //return
        const statusOud = bundel.status

        switch (actie) {
            case BundelActie.BUNDEL_INZENDEN:
                bundel.redenValidatieAfwijzing = ''
                const validatieActief = serviceContext.privilegeManager.settingActive(serviceContext.bundelCollectieCollectie, CollectieSettings.BUNDEL_VALIDATIE_ACTIEF)
                bundel.status = validatieActief ? BundelStatus.IN_VALIDATIE : BundelStatus.GEVALIDEERD
                serviceContext.onSaveBundel && serviceContext.onSaveBundel(bundel, null, true)
                break
            case BundelActie.BUNDEL_VALIDEREN:
                bundel.redenValidatieAfwijzing = ''
                bundel.status = BundelStatus.GEVALIDEERD
                serviceContext.onSaveBundel && serviceContext.onSaveBundel(bundel)
                break
            case BundelActie.BUNDEL_VALIDATIE_AFWIJZEN:
                bundel.redenValidatieAfwijzing = textInputContent
                bundel.status = BundelStatus.VALIDATIE_AFGEWEZEN
                serviceContext.onSaveBundel && serviceContext.onSaveBundel(bundel)
                break
            case BundelActie.BUNDEL_HEROPENEN:
                bundel.redenValidatieAfwijzing = ''
                bundel.status = BundelStatus.DRAFT
                serviceContext.onSaveBundel && serviceContext.onSaveBundel(bundel)
                break
            default:
                throw (new Error('Kan actie niet uitvoeren. Incorrecte Actie'))
        }

        const statusNieuw = bundel.status

        if (statusOud !== statusNieuw) serviceContext.mailService
            .notificeerBundelStatusGewijzigd(serviceContext.collectieAdaUserLinks, serviceContext.bundelCollectieCollectie, bundel, statusOud, statusNieuw)

        setWarningModalOpen(false)
    }

    const onTextInputContentChange = (event, {value}) => {
        setTextInputContent(value)
    }

    const injectExtraContent = (refKey) => {
        switch (refKey) {
            case 'ACTIE_BUNDEL_INZENDEN':
                if (bundel.redenValidatieAfwijzing === '') return null
                return <Segment>
                    <Header size='small'>{serviceContext.localeManager.getString('OPMERKING_VALIDATOR')}</Header>
                    <p>{bundel.redenValidatieAfwijzing}</p>
                </Segment>
            default:
                return null
        }
    }

    const statusMessageExtraContentFunction = () => {
        if (serviceContext.bundelCollectieCollectie.status !== CollectieStatus.OPEN_VOOR_INZENDEN){
            return <div>
                <ProviderStatus provider={serviceContext.bundelCollectieCollectie} statusLabel={serviceContext.localeManager.getString('COLLECTIE') + ' status'}/>
            </div>
        }

        return null
    }

    return (
        <Fragment>
            <ComponentIdentifier displayName='BundelActies'/>

            <Segment>

                <Divider/>

                <InfoViewer editable={serviceContext.infoEditable}
                            refId={`BUNDEL_STATUS_MESSAGE_${bundel.status.toUpperCase()}`}
                            substitutions={{
                                '[[REDEN_VALIDATIE_AFWIJZING]]': bundel.redenValidatieAfwijzing
                            }}
                            extraContentFunction={statusMessageExtraContentFunction}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>

                <Divider/>

                {acties.map(actie => {
                    return <Button key={actie} color='teal' name={actie} onClick={onActieClick}>{serviceContext.localeManager.getString(actie)}</Button>
                })}

            </Segment>

            <Modal open={warningModalOpen && actieInfoKey !== ''} onClose={onCloseModal} dimmer='inverted'>
                <Modal.Content>
                    <InfoViewer editable={serviceContext.infoEditable}
                                refId={actieInfoKey}
                                substitutions={{
                                    '[[REDEN_VALIDATIE_AFWIJZING]]': bundel.redenValidatieAfwijzing
                                }}
                                extraContentFunction={injectExtraContent}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>

                    {showTextInput && <Fragment>
                        <Divider/>
                        <TextArea ref={textInputRef} style={{width: '100%'}} value={textInputContent} onChange={onTextInputContentChange}/>
                    </Fragment>}

                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={onCloseModal}>
                        {serviceContext.localeManager.getString('ANNULEER')}
                    </Button>
                    <Button color='red' content={serviceContext.localeManager.getString(bevestigLabel)}
                            disabled={showTextInput && textInputContent === ''}
                            onClick={() => voerActieUit(bevestigLabel)}
                    />

                </Modal.Actions>

            </Modal>

        </Fragment>
    )
}

export default BundelActies

BundelActies.propTypes = {
    bundel: PropTypes.object.isRequired,
}

BundelActies.defaultProps = {}
