import React, { useContext, useEffect, useState } from 'react';
import { Segment, Input } from 'semantic-ui-react';

import { CompilatieIcon, ResultContainer } from "@ahsmediatheek/ada-lib";

import ServiceContext from '../context/ServiceContext';
import { CompilatieCard } from "../component/CompilatieCard";


export default function CompilatieZoeker({ onSelect }) {
    const serviceContext = useContext(ServiceContext);

    const [compilaties, setCompilaties] = useState([]);
    const [query, setQuery] = useState('');
    const [zoekresultaten, setZoekresultaten] = useState();

    useEffect(async () => {
        const adaUserAdaUserLinks = await serviceContext.adaUserService.getAdaUserLinksForAdaUser(serviceContext.privilegeManager.adaUser);
        const compilatieIds = adaUserAdaUserLinks.reduce((ids, aul) => {
            if (aul.ref === 'compilatie' && !ids.includes(aul.refId) && aul.refId !== serviceContext.compilatie?.id) ids.push(aul.refId)
            return ids
        }, []);
        const compilaties = await serviceContext.compilatieService.getCompilaties(compilatieIds);

        setCompilaties(compilaties);
    }, []);

    useEffect(async () => {
        const result = compilaties.filter(compilatie => compilatie.titel_nl.includes(query));

        setZoekresultaten(query ? result : compilaties);
    }, [query, compilaties]);

    return (
        <ResultContainer
            containerWidth={serviceContext.resultContainerAvailableWidth}
            display={serviceContext.resultDisplay}
            setDisplay={serviceContext.setResultDisplay}
            header={serviceContext.localeManager.getString('MIJN_COMPILATIES')}
            headerIcon={<CompilatieIcon size='large' style={{ marginRight: '10px' }} />}
            extraContent={() => (
                <Segment>
                    <Input
                        value={query}
                        action={{ icon: 'search' }}
                        placeholder='Search...'
                        onChange={event => { setQuery(event.target.value) }}
                    />
                </Segment>
            )}
        >
            {zoekresultaten && zoekresultaten.map((compilatie, index) => (
                <div
                    key={index}
                    onClick={() => { onSelect(compilatie) }}
                >
                    <CompilatieCard
                        compilatie={compilatie}
                        display={serviceContext.resultDisplay}
                        locale={serviceContext.localeManager.locale}
                        showStatus
                    />
                </div>
            ))}
        </ResultContainer>
    )
};
