import React, {useState, useEffect, useContext} from 'react'
import {InfoViewer} from '@ahsmediatheek/ada-lib'
import PropTypes from 'prop-types'
import ServiceContext from '../context/ServiceContext'


const PilotAccessView = ({adaUserService, privilegeManager}) => {

    const [accessState, setAccessState] = useState()
    const serviceContext = useContext(ServiceContext)

    useEffect(() => {

        if (!privilegeManager.adaUser) {
            setAccessState('NO_ADA_USER')
            return
        }

        const hasPilotAccess = privilegeManager.hasPilotAccess()
        if (!hasPilotAccess){
            setAccessState('NO_PILOT_ACCESS')
            return
        }


    }, [privilegeManager])

    if (!accessState) return null

    return (
        <div className='main-content' style={{justifyContent: 'center'}}>

            {accessState === 'NO_ADA_USER' && <InfoViewer editable={serviceContext.infoEditable}
                                                          refId={'INFO_PILOT_NO_ADA_USER'}
                                                          localeManager={serviceContext.localeManager}
                                                          localeDataService={serviceContext.infoLocaleDataService}/>}

            {accessState === 'NO_PILOT_ACCESS' && <InfoViewer editable={serviceContext.infoEditable}
                                                              refId={'INFO_PILOT_ACCESS'}
                                                              localeManager={serviceContext.localeManager}
                                                              localeDataService={serviceContext.infoLocaleDataService}/>}
        </div>
    )
}

export default PilotAccessView

PilotAccessView.propTypes = {
    adaUserService: PropTypes.object.isRequired,
    privilegeManager: PropTypes.object.isRequired
}

PilotAccessView.defaultProps = {}
