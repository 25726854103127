import React, {useState, useEffect, useContext} from 'react'
import {Form} from "semantic-ui-react";
import ServiceContext from '../../../context/ServiceContext'
import AdaUserBannerDropzone from "../../../component/AdaUserBannerDropzone";
import {ComponentIdentifier} from "../../../component/ComponentIdentifier";

const AdaUserProfiel = (props) => {

    const [adaUser, setAdaUser] = useState()

    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        setAdaUser({...serviceContext.privilegeManager.adaUser})

    }, [serviceContext.privilegeManager.adaUser])

    return (
        <Form>
            <ComponentIdentifier displayName='AdaUserProfiel'/>

            {adaUser && <Form.Field>
                <label>{serviceContext.localeManager.getString('REFERENTIE_AFBEELDING')}</label>
                <AdaUserBannerDropzone adaUser={adaUser}/>
            </Form.Field>}


        </Form>
    )
}

export default  AdaUserProfiel

AdaUserProfiel.propTypes = {

}

AdaUserProfiel.defaultProps = {

}
