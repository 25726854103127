import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'

import {Step, Segment} from 'semantic-ui-react'
import {sortedUniqBy} from 'lodash'
import ServiceContext from '../../../context/ServiceContext'
import {Collectie, CollectieIcon, ApplicationPath} from "@ahsmediatheek/ada-lib";
import CwWelkom from "./pages/CwWelkom";
import CwNaam from "./pages/CwNaam";
import CwMetadata from "./pages/CwMetadata";
import CwTrefwoorden from "./pages/CwTrefwoorden";
import CwInzenders from "./pages/CwInzenders";
import CwAfronding from "./pages/CwAfronding";
import {ComponentIdentifier} from "../../../component/ComponentIdentifier";

const STEP_WELKOM = 'welkom'
const STEP_NAAM = 'naam'
const STEP_METADATA = 'metadata'
const STEP_TREFWOORDEN = 'trefwoorden'
const STEP_INZENDERS = 'inzenders'
const STEP_AFRONDING = 'afronding'

const STEPS = [
    {name: STEP_WELKOM, title: 'WELKOM', infoKey: 'NC_WIZARD_CONTENT_WELKOM'},
    {name: STEP_NAAM, title: 'TITEL_EN_BESCHRIJVING', infoKey: 'NC_WIZARD_CONTENT_NAAM'},
    {name: STEP_METADATA, title: 'METADATA', infoKey: 'NC_WIZARD_CONTENT_METADATA'},
    /*{name: STEP_TREFWOORDEN, title: 'TREFWOORDEN', infoKey: 'NC_WIZARD_CONTENT_TREFWOORDEN'},*/
    {name: STEP_INZENDERS, title: 'INZENDERS', infoKey: 'NC_WIZARD_CONTENT_INZENDERS'},
    {name: STEP_AFRONDING, title: 'AFRONDING', infoKey: 'NC_WIZARD_CONTENT_AFRONDING'},
]

const NieuweCollectieWizard = ({onMaakCollectie}) => {

    const serviceContext = useContext(ServiceContext)

    const [activeStep, setActiveStep] = useState(STEPS[0])
    const [wizardCollectie, setWizardCollectie] = useState()
    const [opleidingen, setOpleidingen] = useState()
    const [diensten, setDiensten] = useState()
    const [inzenders, setInzenders] = useState([])

    useEffect(() => {
        if (!wizardCollectie) {
            const wColl = new Collectie()
            wColl.aangemaakt_door = serviceContext.privilegeManager.adaUser.email
            wColl.academiejaar = serviceContext.arteveldeApiService.getCurrentAcademiejaar()
            wColl.opleiding = ''
            wColl.defaultReferentiebeeld = serviceContext.applicationPath.nieuwe_collectie_wizard_referentiebeeld()
            setWizardCollectie(wColl)
        }
    }, [serviceContext.arteveldeApiService, wizardCollectie])

    useEffect(() => {
        if (!wizardCollectie) return

        Promise.all([
            serviceContext.arteveldeApiService.getOpleidingen(wizardCollectie.academiejaar),
            serviceContext.arteveldeApiService.getDiensten(wizardCollectie.academiejaar)
        ]).then(([opleidingen, diensten]) => {
            setOpleidingen(opleidingen);
            setDiensten(diensten);
        })
    }, [wizardCollectie, serviceContext.arteveldeApiService])

    const onStepClick = (event, {name}) => {
        const as = STEPS.reduce((acc, step) => step.name === name ? step : acc)
        setActiveStep(as)
    }

    const onNavigationClick = (direction) => {
        const currIndex = STEPS.indexOf(activeStep)
        setActiveStep(STEPS[currIndex + direction])
    }

    const onChangeProp = (event, { name, value }) => {
        console.log('CHANGE', name, value)
        setWizardCollectie({...wizardCollectie, [name]: value})
    }

    const onChangeAcademiejaar = (aj) => {

        //wizardCollectie.opleiding = null
        wizardCollectie.opleiding = ""
        wizardCollectie.academiejaar = aj
        Promise.all([
            serviceContext.arteveldeApiService.getOpleidingen(wizardCollectie.academiejaar),
            serviceContext.arteveldeApiService.getDiensten(wizardCollectie.academiejaar)
        ]).then(([opleidingen, diensten]) => {
            setOpleidingen(opleidingen);
            setDiensten(diensten);
        })

        setWizardCollectie({...wizardCollectie})
    }

    const onMaakCollectieButtonClick = () => {
        onMaakCollectie(wizardCollectie, inzenders)
    }

    const onInzendersSelect = (users) => {
        console.log('USER SELECT', users, serviceContext.adaUserService)

        setInzenders(sortedUniqBy(inzenders.concat(users), inzender => inzender.id))
    }

    const onInzenderDelete = (inzender) => {
        setInzenders(inzenders.filter(inz => inz !== inzender))
    }

    console.log('ACT STEP', serviceContext.applicationPath.nieuwe_collectie_wizard_referentiebeeld())
    return (
        <ServiceContext.Provider value={{
            ...serviceContext,
            wizardCollectie,
            opleidingen,
            diensten,
            inzenders,
            STEPS,
            activeStep,
            onNavigationClick,
            onChangeProp,
            onChangeAcademiejaar,
            onMaakCollectieButtonClick,
            onInzendersSelect,
            onInzenderDelete,

        }}>
            <Fragment>
                <ComponentIdentifier displayName='NieuweCollectieWizard'/>

                <div className='detail-view-header'
                     style={{
                         backgroundImage: `url(${serviceContext.applicationPath.nieuwe_collectie_wizard_referentiebeeld()})`,
                         backgroundSize: 'cover',
                         backgroundPosition: 'center center',
                         backgroundRepeat: 'no-repeat',
                     }}>
                    <div className='detail-view-titel'>
                        <CollectieIcon style={{flexShrink: 0}}/>
                        <div style={{marginLeft: '10px'}}>{serviceContext.localeManager.getString('NIEUWE_COLLECTIE_WIZARD')}</div>

                    </div>
                </div>


                <div className='main-content'>
                    <Step.Group size='mini' attached='top'>

                        {STEPS.map((step, index) => {
                            return <Fragment key={index}><Step name={step.name} active={activeStep.name === step.name} onClick={onStepClick}>
                                <Step.Content>
                                    <Step.Title>{serviceContext.localeManager.getString(step.title)}</Step.Title>
                                    {/*{step.titleKey && <Step.Description>
                                <InfoViewer localeManager={serviceContext.localeManager} localeDataService={serviceContext.infoLocaleDataService}
                                            editable={serviceContext.infoEditable} refId={'NC_WIZARD_COLLECTIE_NAAM'}/>
                            </Step.Description>}*/}
                                </Step.Content>
                            </Step></Fragment>
                        })}


                    </Step.Group>

                    <Segment attached>
                        {activeStep.name === STEP_WELKOM && <CwWelkom/>}
                        {activeStep.name === STEP_NAAM && <CwNaam/>}
                        {activeStep.name === STEP_METADATA && <CwMetadata/>}
                        {activeStep.name === STEP_TREFWOORDEN && <CwTrefwoorden/>}
                        {activeStep.name === STEP_INZENDERS && <CwInzenders/>}
                        {activeStep.name === STEP_AFRONDING && <CwAfronding/>}
                    </Segment>
                </div>
            </Fragment>
        </ServiceContext.Provider>
    )
}

export default NieuweCollectieWizard

NieuweCollectieWizard.propTypes = {
    onMaakCollectie: PropTypes.func
}

NieuweCollectieWizard.defaultProps = {}
