import React, {useContext, useState} from 'react'
import {useHistory} from 'react-router-dom'
import ServiceContext from '../../context/ServiceContext'
import {InfoViewer} from "@ahsmediatheek/ada-lib";
import {Button, Form, Modal, Segment} from "semantic-ui-react";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const CompilatieSettingsAdmin = (props) => {

    const [, setCompilatieToDelete] = useState()
    const [confirmCompilatieToDeleteUuid, setConfirmCompilatieToDeleteUuid] = useState('')
    const [verwijderCompilatieModalOpen, setVerwijderCompilatieModalOpen] = useState(false)


    const serviceContext = useContext(ServiceContext)
    const history = useHistory()


    const onVerwijderColleciteModalClose = () => {
        setVerwijderCompilatieModalOpen(false)
        setConfirmCompilatieToDeleteUuid('')
    }

    const onDeleteCompilatie = () => {
        /*const outline = serviceContext.compilatieService.compilatieOutlineLookup[serviceContext.compilatie.id]
        if (outline.length > 0){
            window.alert(serviceContext.localeManager.getString('COMPILATIE_NIET_LEEG'))
            return
        }*/
        setVerwijderCompilatieModalOpen(true)
    }

    const onDeleteCompilatieConfirm = (event, props) => {

        if (confirmCompilatieToDeleteUuid !== serviceContext.compilatie.uuid) {
            setVerwijderCompilatieModalOpen(false)
            setConfirmCompilatieToDeleteUuid('')
            window.alert('Het ingegeven uuid komt niet overeen met het compilatie id!')
            return
        }

        Promise.all([
            serviceContext.adaUserService.deleteAdaUserLinks(serviceContext.compilatie),
            serviceContext.compilatieLocaleDataService.deleteLocaleData(serviceContext.compilatie.id),
            serviceContext.compilatieService.deleteCompilatie(serviceContext.compilatie, serviceContext.compilatieCollectieCollectie)

        ]).then((...results) => {
            //console.log('DELETE RESULTS', results)

            setCompilatieToDelete(null)
            setVerwijderCompilatieModalOpen(false)
            setConfirmCompilatieToDeleteUuid('')

            history.push('/home')
        })
    }

    return (
        <div>
            <ComponentIdentifier displayName='CompilatieSettingsAdmin'/>

            <Form>


                <Form.Field>
                    <label>UUID</label>
                    <h3>{serviceContext.compilatie.uuid}</h3>
                </Form.Field>

                <Segment>
                    <Form.Field>
                        <label>DANGER ZONE</label>

                        <InfoViewer editable={serviceContext.infoEditable}
                                    refId={'WARNING_DELETE_COMPILATIE'}
                                    localeManager={serviceContext.localeManager}
                                    localeDataService={serviceContext.infoLocaleDataService}/>

                        <Button color='red'
                                onClick={onDeleteCompilatie}>{serviceContext.localeManager.getString('VERWIJDER_COMPILATIE')}</Button>

                    </Form.Field>
                </Segment>
            </Form>


            {verwijderCompilatieModalOpen &&
            <Modal open={verwijderCompilatieModalOpen} onClose={onVerwijderColleciteModalClose} dimmer='inverted'>
                <Modal.Content>


                    <Form>
                        <Form.Input name='uuidField' value={confirmCompilatieToDeleteUuid}
                                    onChange={(event, {value}) => setConfirmCompilatieToDeleteUuid(value)}
                                    label='Plak hier de compilatie uuid'/>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onVerwijderColleciteModalClose}>
                        {serviceContext.localeManager.getString('SLUIT')}
                    </Button>
                    <Button color='red' content={serviceContext.localeManager.getString('VERWIJDER_COMPILATIE')}
                            onClick={onDeleteCompilatieConfirm}
                            /*disabled={confirmCompilatieToDeleteUuid.length !== 36}*/
                    />

                </Modal.Actions>

            </Modal>}

        </div>
    )





    //const serviceContext = useContext(ServiceContext)

    return (
        <div>
            <ComponentIdentifier displayName='CompilatieSettingsAdmin'/>

            {serviceContext.privilegeManager.canDeleteCompilatie(serviceContext.compilatie, serviceContext.compilatieAdaUserLinks) &&
            <div>
                <Header>{serviceContext.compilatie.uuid}</Header>
                <Button color='red'
                         onClick={() => serviceContext.onDeleteCompilatie(serviceContext.compilatie/*, serviceContext.bundels*/)}>{serviceContext.localeManager.getString('VERWIJDER_COMPILATIE')}</Button>
            </div>}

        </div>
    )
}

export default CompilatieSettingsAdmin
