import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Select} from "semantic-ui-react";
import orderBy from 'lodash/orderBy'

const OpleidingKiezer = ({localeManager, opleidingen, selectedOpleiding, onSelectOpleiding, multiple}) => {

    const [options, setOptions] = useState()
    const [, setSelectedOption] = useState()

    useEffect(() => {
        const options = opleidingen.map((opl, index) => {
            let oplNaam = opl['Opleiding' + localeManager.locale.toUpperCase()]
            if (oplNaam === '') oplNaam = opl.OpleidingNL
            return {key: index, value: opl.OplId,
                text: `${oplNaam} (${opl.Type})`}
        })
        setOptions(orderBy(options, 'text'))
    }, [opleidingen, localeManager])

    useEffect(() => {
        if (!options) return
        setSelectedOption(selectedOpleiding ? options.reduce((selOption, option) => option.value === selectedOpleiding ? option : selOption) : options[0])
    }, [options, selectedOpleiding])

    if (!options) return null

    return multiple ? (
        <Select
            multiple
            options={options}
            fluid
            search
            selection
            value={selectedOpleiding && selectedOpleiding.split(',')}
            onChange={(e, {value}) => onSelectOpleiding(value.join())}
        />
    ) : (
        <Select
            options={options}
            fluid
            search
            selection
            value={selectedOpleiding}
            onChange={(e, {value}) => onSelectOpleiding(value)}
        />
    );
}

export default  OpleidingKiezer

OpleidingKiezer.propTypes = {
    opleidingen: PropTypes.array.isRequired,
    localeManager: PropTypes.object.isRequired,
    selectedOpleiding: PropTypes.string,
    onSelectOpleiding: PropTypes.func,
    multiple: PropTypes.bool
}

OpleidingKiezer.defaultProps = {
    multiple: true
}
