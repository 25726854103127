import axios from 'axios'
import {ApplicationPath} from "../class/general/ApplicationPath";
import {Activiteit} from "../class/activiteit/Activiteit";

const ACTIVITEIT_TABLE: string = 'activiteit'

export class ActiviteitService {

    applicationPath: ApplicationPath

    constructor(applicationPath) {
        this.applicationPath = applicationPath
    }

    createActiviteit = (adaUser, activiteit, detail) => {

        return new Promise((resolve, reject) => {

            const instance = new Activiteit()
            instance.adaUser = adaUser.id
            instance.activiteit = activiteit
            instance.detail = detail

            let url = `${this.applicationPath.crud_api()}${ACTIVITEIT_TABLE}/?transform=1`

            axios.post(url, instance)
                .then(() => {
                    resolve(instance)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }
}
