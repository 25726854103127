import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {Button, Segment} from "semantic-ui-react";
import ServiceContext from '../../../../context/ServiceContext'
import {InfoViewer} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "../../../../component/ComponentIdentifier";


const AbstractWizardPage = (props) => {


    const serviceContext = useContext(ServiceContext)


    const index = serviceContext.STEPS.indexOf(serviceContext.activeStep)
    /*const prevButtonVisible = index > 0
    const nextButtonVisible = index < serviceContext.STEPS.length - 1*/

    return (
        <div>
            <ComponentIdentifier displayName='AbstractWizardPage'/>

            {serviceContext.activeStep.infoKey && <InfoViewer localeManager={serviceContext.localeManager} localeDataService={serviceContext.infoLocaleDataService}
                                                              editable={serviceContext.infoEditable} refId={serviceContext.activeStep.infoKey}/>}

            {props.children}

            <Segment textAlign='right'>
                {props.prevButtonVisible && <Button content={serviceContext.localeManager.getString('VORIGE_STAP')}
                                              onClick={() => serviceContext.onNavigationClick(-1)}/>}
                {props.nextButtonVisible && <Button disabled={props.nextButtonEnabled === false} content={serviceContext.localeManager.getString('VOLGENDE_STAP')}
                                              onClick={() => serviceContext.onNavigationClick(1)}/>}
                {props.additionalButtons}
            </Segment>
        </div>
    )
}

export default AbstractWizardPage

AbstractWizardPage.propTypes = {
    prevButtonVisible: PropTypes.bool.isRequired,
    nextButtonVisible: PropTypes.bool.isRequired,
    nextButtonEnabled: PropTypes.bool.isRequired,
    additionalButtons: PropTypes.array,
}

AbstractWizardPage.defaultProps = {
    prevButtonVisible: true,
    nextButtonVisible: true,
    nextButtonEnabled: true
}
