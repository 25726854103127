//import moment from 'moment'
import axios from 'axios'
import { nl, enGB } from 'date-fns/locale'
const moment = require('moment')

export class LocaleManager {

    initialized: boolean = false
    locale: string = 'nl'
    localePath: string
    localeContent: object = {}
    dateFnsLocaleLookup: object

    constructor(localePath: string) {
        this.localePath = localePath
        this.dateFnsLocaleLookup = {nl: nl, en: enGB}
    }

    init = (locale?: string) => {
        return new Promise((resolve) => {
            if (locale) this.locale = locale
            this.loadLocale(this.locale).then(() => {
                this.initialized = true
                resolve('initialized')
            })
        })
    }

    loadLocale = (locale: string) => {
        const url = this.localePath + locale + '.locale'
        moment.locale(locale)
        return new Promise((resolve, reject) => {
            axios.get(url).then(response => {
                this.locale = locale
                this.localeContent[locale] = this.buildLocaleData(response.data)
                resolve(this)
            }).catch(error => {
                reject(error)
            })
        })
    }

    private buildLocaleData = (content: string) => {
        const lineArray = content.split('\n')
        const localeData: object = {}
        for (let line of lineArray) {
            let wordArray: string[] = line.split('=')
            localeData[wordArray[0]] = wordArray[1]
        }
        return localeData
    }

    propertyExists = (property: string): boolean => {
        return this.localeContent[this.locale].hasOwnProperty(property.toUpperCase())
    }

    getString = (input: string): string => {
        if (!input) return ''
        input = input.toUpperCase()
        const rs: string = this.localeContent[this.locale][input]
        if (rs) return rs.replace(/\r/g, "")
        return input.replace(/_/g, ' ').toLowerCase()
    }

    handleLocaleBindings = (source) => {
        //deze functie zal in source op zoek gaan naar bindings en die vervangen door locale data
        //bindings zitten tussen [[ en ]]
        let found = true;
        while (found) {
            let s = source.indexOf("[[");
            let e = source.indexOf("]]");
            let l = e - s;

            if (s >= 0 && e >= 0) {
                let str = source.substring(s, e + 2);

                let key = str.substr(2, l - 2);

                let tr = this.getString(key);
                if (!tr)
                    tr = key;
                source = source.replace(str, tr);
            } else {
                found = false;
            }
        }

        return source;
    }
}
