import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {useParams, useHistory} from "react-router-dom"
import {Link} from "react-router-dom"

import {Segment, Divider, Label, Dimmer, Loader} from "semantic-ui-react";
import {BundelIcon, CollectieIcon, SettingsIcon, Spacer, PreviousIcon, AdaUserLinkRef, EmbargoIcon, BundelStatus, MetadataUtil} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../context/ServiceContext'
import BundelDetailMainView from "./BundelDetailMainView";
import BundelDetailSettingsView from "./BundelDetailSettingsView";
import BundelContentView from "./BundelContentView";
import ProviderStatus from "../component/ProviderStatus";
import {ComponentIdentifier} from "../component/ComponentIdentifier";

const BUNDEL_LOADING = 'bundelLoading'
const BUNDEL_DETAIL_MAIN_VIEW = 'bundelDetailMainView'
const BUNDEL_DETAIL_SETTINGS_VIEW = 'bundelDetailSettingView'
const BUNDEL_ITEMS_VIEW = 'bundelContentView'

const BundelView = ({onBackToCollectie}) => {

    const [referentiebestanden, setReferentiebestanden] = useState()

    const [bundelAdaUserLinks, setBundelAdaUserLinks] = useState()
    const [collectieAdaUserLinks, setCollectieAdaUserLinks] = useState()
    const [view, setView] = useState(BUNDEL_LOADING)
    const [localeContent, setLocaleContent] = useState()
    const [embargo] = useState()
    const [bundelCollectie, setBundelCollectie] = useState() //collectie_bundel object
    const [bundelCollectieCollectie, setBundelCollectieCollectie] = useState()
    const [itemEmbargos] = useState()
    const [bundel, setBundel] = useState()
    const [uuid, setUuid] = useState()

    const [referentieBeeld, setReferentieBeeld] = useState()
    const [resolvedBundelEmbargo, setResolvedBundelEmbargo] = useState()
    const [resolvedCollectieEmbargo, setResolvedCollectieEmbargo] = useState()
    const [resolvedCombinedEmbargo, setResolvedCombinedEmbargo] = useState()

    const serviceContext = useContext(ServiceContext)
    const params = useParams()
    const history = useHistory()

    //REST
    useEffect(() => {
        if (!params.uuid) return
        if (uuid === params.uuid) return

        serviceContext.bundelService.getBundel(params.uuid).then(res => {
            //wanneer de link niet meer bestaat
            if (!res) {
                history.push('/home')
            } else {
                if (res !== bundel) setBundel(res)
                setUuid(res.uuid)
            }
        })
    }, [params.uuid, bundel, history, serviceContext.bundelService, uuid])

    /*
    useEffect(() => {
        console.log(resolvedBundelEmbargo)
        setResolvedCombinedEmbargo(resolvedBundelEmbargo)
    } , [resolvedBundelEmbargo])
    */

    //EMBARGO BUNDEL
    useEffect(() => {
        if (!bundel || !bundelAdaUserLinks || !collectieAdaUserLinks) return

        const adaUserLinks = bundelAdaUserLinks.concat(collectieAdaUserLinks)

        //als we in bundelview geraken wil dit zeggen dat de metadata altijd zichtbaar is!
        //op dit moment draait het embargo enkel om de bundel inhoud

        //MAAR: als je de rechtstreekse link naar deze bundel gebruikt, dan 'bypassed' dat het ophalen van het collectie embargo!!

        serviceContext.privilegeManager.resolveEmbargo(bundel, adaUserLinks, 'bundelInhoud', serviceContext.toegangsticket).then(resp => {
                console.log(resp)
                setResolvedBundelEmbargo(resp)
            }
        )
    }, [bundel, bundelAdaUserLinks, collectieAdaUserLinks, serviceContext.privilegeManager])

    //EMBARGO COLLECTIE
    useEffect(() => {
        if (!resolvedBundelEmbargo || !bundelCollectieCollectie) return
        if (resolvedCollectieEmbargo) return

        const adaUserLinks = bundelAdaUserLinks.concat(collectieAdaUserLinks)

        serviceContext.privilegeManager.resolveEmbargo(bundelCollectieCollectie, adaUserLinks, 'bundelInhoud', serviceContext.toegangsticket).then(resp => {
                console.log(resp)
                setResolvedCollectieEmbargo(resp)
                //combineren van embargo's
                //voorlopig enkel de uitzonderingen combineren

                //TODO: Als een collectie embargo toegang geeft via een ticket, geeft dat dan ook toegang tot één bundel die op slot staat???

                /*console.log('B EMB', resolvedBundelEmbargo)
                console.log('C EMB', resp)*/

                setResolvedCombinedEmbargo(resolvedBundelEmbargo) 
                //setResolvedCombinedEmbargo({
                //    allow: true,
                //    reason: "ADMIN"
               // }) 
            }
        )

    }, [resolvedBundelEmbargo, bundelCollectieCollectie, bundelAdaUserLinks, collectieAdaUserLinks, resolvedCollectieEmbargo, serviceContext.privilegeManager])

    //GO!
    useEffect(() => {
        if (!resolvedCollectieEmbargo) return
        if (bundel.status === BundelStatus.CREATED) {
            bundel.status = BundelStatus.DRAFT
            serviceContext.bundelService.updateBundel(bundel)
            setView(BUNDEL_DETAIL_SETTINGS_VIEW)
        } else {

            if (view === BUNDEL_LOADING) setView(BUNDEL_DETAIL_MAIN_VIEW)
        }
    }, [resolvedCollectieEmbargo, bundel, serviceContext.bundelService, view])

    //FORCE REFRESH
    useEffect(() => {
        if (!serviceContext.updatedBundel) return
        setBundel({...serviceContext.updatedBundel})
    }, [serviceContext.updatedBundel])


    useEffect(() => {
        if (!bundel) return

        const resolveReferentiebeeld = () => {
            return bundel.defaultReferentiebeeld !== ''
                ? bundel.defaultReferentiebeeld
                : serviceContext.applicationPath.referentiebeelden() + bundel.uuid + '.jpg?d=' + new Date().getTime()
        }

        setReferentieBeeld(resolveReferentiebeeld())

        Promise.all([
            serviceContext.adaUserService.getAdaUserLinks(AdaUserLinkRef.BUNDEL, bundel.id),
            serviceContext.bundelLocaleDataService.getLocaleData(bundel.id),
            serviceContext.bundelService.getBundelCollecties(bundel),
            serviceContext.bundelService.getBundelReferentiebestanden(bundel),

        ])
            .then(([bundelAdaUserLinks, localeContentResponse, bundelCollecties, referentiebestanden]) => {

                //`${applicationPath.bundel()}b_${bundel.uuid}/data${referentiebestand.referentiebestand}`

                setLocaleContent(localeContentResponse.localeContent)
                setBundelAdaUserLinks(bundelAdaUserLinks)
                createAdaUsersSearchString(bundelAdaUserLinks)
                setReferentiebestanden(referentiebestanden)

                const bundelCollectiesFysiek = bundelCollecties.filter(bc => bc.link === 'fysiek')
                if (bundelCollectiesFysiek.length > 1) throw (new Error('Een bundel kan in slechts één fysieke collectie zitten'))
                if (bundelCollectiesFysiek.length === 1) setBundelCollectie(bundelCollectiesFysiek[0])


            }).catch(error => console.log('PA2 ERROR', error))

    }, [bundel, serviceContext.adaUserService, serviceContext.bundelLocaleDataService, serviceContext.bundelService])

    //BUNDEL COLLECTIE COLLECTIE & COLLECTIE ADA USER LINKS
    useEffect(() => {
        if (!bundelCollectie) return
        //Later zal hier een error komen: nu zijn alle bundels deel van een collectie maar later niet meer (bv. OED)
        //TODO: opvangen als bundelCollectie niet bestaat
        Promise.all([
            serviceContext.collectieService.getCollectie(bundelCollectie.collectie_uuid),
            serviceContext.adaUserService.getAdaUserLinks(AdaUserLinkRef.COLLECTIE, bundelCollectie.collectie),
        ]).then(([collectie, collectieAdaUserLinks]) => {
            setBundelCollectieCollectie(collectie)
            setCollectieAdaUserLinks(collectieAdaUserLinks)
        })
    }, [bundelCollectie, serviceContext.adaUserService, serviceContext.collectieService])


    const onChangeReferentiebestand = (bundel, item, checked, referentiebestand) => {

        serviceContext.bundelService.toggleReferentiebestand(bundel, item, checked, referentiebestand).then(referentiebestanden => {
            setReferentiebestanden(referentiebestanden)
        })
    }

    const onSettingsIconClick = () => {
        setView(BUNDEL_DETAIL_SETTINGS_VIEW)
    }

    const getBundelTitel = () => {
        return MetadataUtil.resolveTitel(bundel, serviceContext.localeManager.locale)
        //return bundel[`titel_${serviceContext.localeManager.locale}`] || bundel.titel_nl
    }

    const getCollectieTitel = () => {
        return bundelCollectieCollectie[`titel_${serviceContext.localeManager.locale}`] || bundelCollectieCollectie.titel_nl
    }

    const showBundleInhoud = () => {
        setView(BUNDEL_ITEMS_VIEW)
    }

    const onUpdateAlias = (alias) => {
        serviceContext.bundelService.updateReferentiebestand() //Bundel wordt niet meegestuurd??
    }

    const onUpdateAliasTeKoopItem = (alias) => {
        serviceContext.bundelService.updateTeKoopItem()
    }


    const updateReferentiebeeld = (path) => {
        bundel.referentiebeeld = `b_${bundel.uuid}/data${path}`
        bundel.defaultReferentiebeeld = ''
        serviceContext.bundelService.updateBundel(bundel).then(res => {
            //serviceContext.bundelService.setIsAndHasReferentiebestand(bundel, referentiebestanden)
            //serviceContext.bundelService.setIsAndHasTeKoopItem(bundel, teKoopItems)

            setReferentieBeeld(serviceContext.applicationPath.bundel() + bundel.referentiebeeld)
            setReferentiebestanden(referentiebestanden)
            setTeKoopItems(teKoopItems)

        })
    }

    const onDeleteAdaUserLink = (adaUserLink) => {
        //searchuser bij bundel instellen
        serviceContext.adaUserService.deleteAdaUserLink(adaUserLink).then(res => {
            createAdaUsersSearchString(res)
            setBundelAdaUserLinks(res)
        })
    }

    const updateAdaUserLinks = () => {
        serviceContext.adaUserService.getAdaUserLinks(AdaUserLinkRef.BUNDEL, bundel.id).then(res => {
            createAdaUsersSearchString(res)
            setBundelAdaUserLinks([...res])
        })
    }

    const createAdaUsersSearchString = (adaUsers) => {
        const ids = adaUsers.reduce((uniques, au) => {
            if (!uniques.includes(au.adaUser)) uniques.push(au.adaUser)
            return uniques
        }, [])
        serviceContext.adaUserService.getAdaUsers(ids).then(res => {
            const gebruikersSearchString = res.reduce((ss, adaUser) => {
                ss += `${adaUser.email} ${adaUser.voornaam} ${adaUser.familienaam}`
                return ss
            }, '')

            if (bundel.gebruikersSearchString !== gebruikersSearchString){
                console.log('updating gebruikersSearchString')
                bundel.gebruikersSearchString = gebruikersSearchString
                serviceContext.bundelService.updateBundel(bundel)
            }
        })
    }

    const onSaveBundel = (bundel, changedLocales, resetView) => {

        if (changedLocales && changedLocales.length > 0) {
            for (let localeContentObject of changedLocales) {
                if (localeContentObject.field === 'titel' && localeContentObject.locale === 'nl') bundel.titel_nl = localeContentObject.content
                if (localeContentObject.field === 'titel' && localeContentObject.locale === 'en') bundel.titel_en = localeContentObject.content
                if (localeContentObject.field === 'subtitel' && localeContentObject.locale === 'nl') bundel.subtitel_nl = localeContentObject.content
                if (localeContentObject.field === 'subtitel' && localeContentObject.locale === 'en') bundel.subtitel_en = localeContentObject.content
                if (localeContentObject.field === 'abstract' && localeContentObject.locale === 'nl') bundel.abstract_nl = localeContentObject.content
                if (localeContentObject.field === 'abstract' && localeContentObject.locale === 'en') bundel.abstract_en = localeContentObject.content
            }
        }

        serviceContext.bundelLocaleDataService.saveLocaleData(changedLocales).then(result => {
            serviceContext.bundelService.updateBundel(bundel).then(res => {
                setBundel({...bundel})
                if (resetView) setView(BUNDEL_DETAIL_MAIN_VIEW)
            })
        })
    }

    const onReferentiebeeldUploaded = (provider) => {
        provider.defaultReferentiebeeld = ''
        serviceContext.bundelService.updateBundel(provider).then(res => {
            setBundel({...bundel})
        })
    }

    const resolveCollectieLink = () => {

        //Wanneer de bundel rechtstreeks bezocht wordt, dus niet via de collectie, dan moeten we checken of je wel terug kan naar de collectie!
        const content = <div style={{marginTop: '10px', marginLeft: '10px'}}>
            <Label>
                <CollectieIcon/><strong>{getCollectieTitel()}</strong>
            </Label>
        </div>

        if (!serviceContext.privilegeManager.canAccessCollectie(bundelCollectieCollectie, collectieAdaUserLinks)) return content
        return <Link to={`/collectie/${bundelCollectieCollectie.uuid}`}>{content}</Link>
    }

    if (!serviceContext.localeManager || !serviceContext.privilegeManager) return null

    return (
        <ServiceContext.Provider value={{
            ...serviceContext, bundel, onSaveBundel, bundelAdaUserLinks, collectieAdaUserLinks, localeContent,
            onUpdateAlias, updateReferentiebeeld, onDeleteAdaUserLink, bundelCollectieCollectie,
            embargo, updateAdaUserLinks, onReferentiebeeldUploaded, referentiebestanden, onChangeReferentiebestand
        }}><Fragment>

            <ComponentIdentifier displayName='BundelView' info={bundel && `uuid: ${bundel.uuid} | id: ${bundel.id}`}/>

            {referentieBeeld && <div className='detail-view-header'
                                     style={{
                                         backgroundImage: `url(${referentieBeeld})`,
                                         backgroundSize: 'cover',
                                         backgroundPosition: 'center center',
                                         backgroundRepeat: 'no-repeat',
                                     }}>
                <div className='detail-view-titel'>
                    <BundelIcon style={{flexShrink: 0}}/>
                    <div style={{marginLeft: '10px'}}>{getBundelTitel()}</div>
                </div>

                {bundelAdaUserLinks && collectieAdaUserLinks && serviceContext.privilegeManager.canEditBundelSettings(bundelCollectieCollectie, bundel, bundelAdaUserLinks, collectieAdaUserLinks).allow &&
                <div className='detail-view-icons'>
                    <SettingsIcon onClick={onSettingsIconClick}/>
                </div>}

                {bundelCollectieCollectie && resolveCollectieLink()}

                <div className='detail-view-message'>
                    {bundelCollectieCollectie &&
                    <ProviderStatus provider={bundelCollectieCollectie} statusLabel={serviceContext.localeManager.getString('COLLECTIE') + ' status'}/>}
                    <ProviderStatus padded provider={bundel} statusLabel={serviceContext.localeManager.getString('BUNDEL') + ' status'}/>
                </div>
            </div>}


            <div className='main-content'>

                {view === BUNDEL_LOADING &&
                <Segment padded='very'>
                    {!resolvedCombinedEmbargo && <Dimmer active inverted>
                        <Loader inverted>{serviceContext.localeManager.getString('EMBARGO_RESOLVING')}</Loader>
                    </Dimmer>}

                    {resolvedCombinedEmbargo && <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.2em'}}>
                        <EmbargoIcon/>
                        <Spacer vertical distance={20}/>
                        <p>{serviceContext.localeManager.getString(resolvedCombinedEmbargo.reason).replace('[[opleiding]]', bundel.opleiding)}</p>
                    </div>}
                </Segment>
                }


                {view !== BUNDEL_DETAIL_MAIN_VIEW &&
                <Fragment>
                    <Label as='a' onClick={() => setView(BUNDEL_DETAIL_MAIN_VIEW)}><PreviousIcon/> {serviceContext.localeManager.getString('TERUG_NAAR_BUNDEL')}</Label>
                    <Divider/>
                </Fragment>
                }

                {view === BUNDEL_DETAIL_MAIN_VIEW && bundelAdaUserLinks && bundel && resolvedCombinedEmbargo &&
                <BundelDetailMainView bundel={bundel}
                                      resolvedCombinedEmbargo={resolvedCombinedEmbargo}
                    /*onDeleteBundel={() => serviceContext.onDeleteBundel(bundel, bundelAdaUserLinks, referentiebestanden, teKoopItems,bundelCollectie, localeContent, itemEmbargos)}*/
                                      onShowBundleInhoud={showBundleInhoud}/>
                }

                {view === BUNDEL_DETAIL_SETTINGS_VIEW && bundelAdaUserLinks &&
                <BundelDetailSettingsView/>}

                {view === BUNDEL_ITEMS_VIEW && bundelAdaUserLinks &&
                <BundelContentView
                    bundel={bundel}/>}


            </div>
        </Fragment></ServiceContext.Provider>
    )
}

export default BundelView

BundelView.propTypes = {
    onBackToCollectie: PropTypes.func
}

BundelView.defaultProps = {
    editable: false,
    contentViewable: false
}
