import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Select} from "semantic-ui-react";

const AcademiejaarKiezer = ({academiejaren, selectedAcademiejaar, onSelectAcademiejaar, style}) => {

    const [options, setOptions] = useState()
    const [, setSelectedOption] = useState()

    useEffect(() => {
        const options = academiejaren.map(aj => ({key: aj, value: aj, text: aj}))

        setOptions(options)
    }, [academiejaren])

    useEffect(() => {
        if (!options) return
        setSelectedOption(selectedAcademiejaar ? options.reduce((selOption, option) => option.value === selectedAcademiejaar ? option : selOption) : options[0])
    }, [options, selectedAcademiejaar])

    if (!options) return null

    return (
        <div style={style}><Select size='mini' options={options} value={selectedAcademiejaar} onChange={(e,{value}) => onSelectAcademiejaar && onSelectAcademiejaar(value)}/></div>
    )
}

export default  AcademiejaarKiezer

AcademiejaarKiezer.propTypes = {
    academiejaren: PropTypes.array.isRequired,
    selectedAcademiejaar: PropTypes.string,
    onSelectAcademiejaar: PropTypes.func
}

AcademiejaarKiezer.defaultProps = {
}