import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {ApplicationPath} from "../../class/general/ApplicationPath";
import {CollectieIcon, EmbargoIcon} from "../icon/Icons";

const CollectieCardCarrousel = ({collectie, locale, onClick, applicationPath}) => {

    const [referentieBeeld, setReferentieBeeld] = useState()
    const [hasEmbargo, setHasEmbargo] = useState(false)
    const [imageWidth, setImageWidth] = useState(0)

    useEffect(() => {
        if (!collectie) return


        const resolveReferentiebeeld = () => {

            return collectie.defaultReferentiebeeld !== ''
                ? collectie.defaultReferentiebeeld
                : applicationPath.referentiebeelden() + collectie.uuid + '_thumb.jpg?d=' + new Date().getTime()
        }

        setReferentieBeeld(resolveReferentiebeeld())
        setHasEmbargo(collectie.embargo && collectie.embargo !== '')
    }, [collectie])


    const onImageLoad = (event) => {
        setImageWidth(event.target.width)
    }

    const subLabel = () => {
        if (!collectie.academiejaar && !collectie.opleiding) return null
        return collectie.academiejaar + ' - ' + collectie.opleiding
    }

    if (!collectie || !locale) return null

    return (
        <div className='result-card-carrousel' onClick={() => onClick && onClick(collectie)}>
            <img src={referentieBeeld} alt='Reference' onLoad={onImageLoad}/>

            <div className='icon-group-top-left'>
                <CollectieIcon/>
                {hasEmbargo && <EmbargoIcon/>}
            </div>

            <div className='title-overlay' style={{width: imageWidth}}>
                {collectie[`titel_${locale}`] || collectie.titel_nl}
                <div className="subtitle">{subLabel()}</div>
            </div>
        </div>
    )
}

export {CollectieCardCarrousel}

CollectieCardCarrousel.propTypes = {
    collectie: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

CollectieCardCarrousel.defaultProps = {}
