import axios from 'axios'
//import moment from 'moment'
import {ApplicationPath} from "../class/general/ApplicationPath"
import {AuthenticationService} from "./AuthenticationService"
import {AdaUserService} from "./AdaUserService"
//import {CarrouselContext} from "../class/carrousel/Carrousel";

const moment = require('moment')

const COMPILATIE_TABLE: string = 'compilatie'

export class CompilatieService {

    applicationPath: ApplicationPath
    authenticationInstance: AuthenticationService
    adaUserService: AdaUserService
    compilatieLookup: object = {}
    compilatieSettingsLookup: object = {}

    constructor(authenticationInstance, adaUserService, applicationPath) {
        this.authenticationInstance = authenticationInstance
        this.adaUserService = adaUserService
        this.applicationPath = applicationPath
    }

    //==============================================================
    //==============================================================
    //                      COMPILATIE
    //==============================================================
    //==============================================================

    getCompilatie = (uuid: string) => {
        return new Promise((resolve, reject) => {

            /*if (this.compilatieLookup[uuid]) {
                resolve(this.compilatieLookup[uuid])
                return
            }*/

            //GET /records/posts?join=comments,users&join=tags

            const url = `${this.applicationPath.crud_api()}${COMPILATIE_TABLE}/?filter[]=uuid,eq,${uuid}&transform=1`
            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {

                    const compilatieArr = response.data.compilatie

                    if (compilatieArr.length > 0) {
                        const compilatie = compilatieArr[0]
                        this.compilatieLookup[compilatie.uuid] = compilatie
                        resolve(compilatie)
                    } else {

                        resolve(null)
                    }
                })
                .catch(error => {
                    console.error('GET COMPILATIE ERROR', error)
                    reject(error)
                })
        })
    }

    getCompilatieInhoud = (compilatie) => {
        const url = `${this.applicationPath.ada_api()}/getCompilatie/${compilatie.uuid}/`
        
        return new Promise((resolve, reject) => {
            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {
                    console.log(response)
                    resolve(response.data.compilatie.compilatie_inhoud)
                })
                .catch(error => {
                    console.error('GET COMPILATIE ERROR', error)
                    reject(error)
                })
        })
    }

    //MULTIPLE COMPILATIES
    getCompilaties = (ids: number[]) => {
        return new Promise((resolve, reject) => {

            if (ids.length === 0) {
                resolve([])
                return
            }

            const cachedCompilaties = ids.reduce((compilaties, id) => {
                if (this.compilatieLookup[id]) compilaties.push(this.compilatieLookup[id])
                return compilaties
            }, [])

            const compilatiesToFetch = ids.filter(id => !this.compilatieLookup[id])

            if (compilatiesToFetch.length === 0) {
                resolve(cachedCompilaties)
                return
            }

            axios.get(`${this.applicationPath.crud_api()}${COMPILATIE_TABLE}/${compilatiesToFetch.join(',')}?transform=1`, this.authenticationInstance.axiosOptions)
                .then(response => {
                    let compilaties = response.data
                    if (!Array.isArray(compilaties)) compilaties = [compilaties]
                    for (const compilatie of compilaties) {
                        this.compilatieLookup[compilatie.id] = compilatie
                    }
                    resolve([...compilaties, ...cachedCompilaties])
                })
                .catch(error => {
                    console.error('GET ADA USER ERROR', error)
                    reject(error)
                })
        })
    }

    updateCompilatie = (compilatie) => {

        compilatie.gewijzigd = moment().format('YYYY-MM-DD HH:mm:ss')
        compilatie.isMetadataDirty = 1

        const url = `${this.applicationPath.crud_api()}${COMPILATIE_TABLE}/${compilatie.id}?transform=1`

        return new Promise((resolve, reject) => {
                axios.put(url, compilatie)
                    .then((result) => {
                        this.compilatieLookup[compilatie.id] = compilatie
                        resolve(result)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    createCompilatie = (compilatie) => {

        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api()}${COMPILATIE_TABLE}/?transform=1`

            axios.post(url, compilatie)
                .then((result) => {
                    compilatie.id = result.data
                    this.compilatieLookup[compilatie.id] = compilatie
                    resolve(compilatie)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    deleteCompilatie = (compilatie) => {

        return new Promise((resolve, reject) => {

            const config: any = this.authenticationInstance.axiosOptions

            let deleteRefBeeldUrl = `${this.applicationPath.ada_api()}/deleteReferentiebeeld/${compilatie.uuid}/`
            axios.delete(deleteRefBeeldUrl, config)
                .then((result) => {
                    console.log('DELETE REFERENTIEBEELD', result)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })

            const url = `${this.applicationPath.crud_api()}compilatie/${compilatie.id}?transform=1`

            axios.delete(url)
                .then((result) => {
                    delete this.compilatieLookup[compilatie.id]
                    resolve('Compilatie deleted')
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    deleteFromCompilatie = (compilatie, toDelete) => {
        const url = `${this.applicationPath.ada_api()}/deleteFromCompilatie/${compilatie.uuid}/`
        return new Promise((resolve, reject) => {
            axios.post(url, {
                class : toDelete.class ,
                uuid : toDelete.uuid
            }).then((result) => {
                console.log(result)
                resolve(result)
            }).catch((error) => {
                console.log(error)
                reject(error)
            })
        })
    }

    addToCompilatie = (compilatie, className: string, uuid: string) => {
        const url = `${this.applicationPath.ada_api()}/addToCompilatie/${compilatie.uuid}/`

        return new Promise((resolve, reject) => {
                axios.post(url, {
                    class: className,
                    uuid
                }).then((result) => {
                    console.log(result)
                    resolve(result)
                }).catch((error) => {
                    console.log(error)
                    reject(error)
                })
            }
        )
    }

    //==============================================================
    //==============================================================
    //                      SETTINGS
    //==============================================================
    //==============================================================

    getCompilatieSettings = (compilatie) => {
        if (!compilatie.settings) compilatie.settings = '{}'

        if (!this.compilatieSettingsLookup[compilatie.id]) this.compilatieSettingsLookup[compilatie.id] = JSON.parse(compilatie.settings)

        return this.compilatieSettingsLookup[compilatie.id]
    }

    getCompilatieSetting = (compilatie, setting) => {
        if (!compilatie.settings) compilatie.settings = '{}'

        if (!this.compilatieSettingsLookup[compilatie.id]) this.compilatieSettingsLookup[compilatie.id] = JSON.parse(compilatie.settings)

        return this.compilatieSettingsLookup[compilatie.id][setting]
    }

    setCompilatieSetting = (compilatie, setting, value) => {
        if (!this.compilatieSettingsLookup[compilatie.id]) this.compilatieSettingsLookup[compilatie.id] = {}
        this.compilatieSettingsLookup[compilatie.id][setting] = value
        compilatie.settings = JSON.stringify(this.compilatieSettingsLookup[compilatie.id])
        return this.compilatieSettingsLookup[compilatie.id]
    }

    //==============================================================
    //==============================================================
    //                      REFERENTIEBEELD
    //==============================================================
    //==============================================================
/*
    uploadReferentiebeeld = (file, uuid, progressCallback) => {
        return new Promise((resolve, reject) => {


            const formData = new FormData()

            formData.append(file.path, file, uuid + '.jpg')

            const config = {
                onUploadProgress: progressCallback
            }

            console.group()
            const url = `${this.applicationPath.ada_api()}/uploadReferentiebeeld/${uuid}?transform=1`
            console.info('CALL uploadReferentiebeeld', url, formData, config)
            axios.post(url, formData, config)
                .then(response => {
                    console.log('SERVER RESPONSE', response)
                    resolve(response)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }
*/
    //==============================================================
    //==============================================================
    //                      CARROUSSEL
    //==============================================================
    //==============================================================
/*
    getCarrouselContent = (context) => {
        return new Promise((resolve, reject) => {

            let url

            switch (context) {
                case CarrouselContext.RECENT:
                    url = `${this.applicationPath.crud_api()}${COMPILATIE_TABLE}/?transform=1&order=aangemaakt,desc&size=10`
                    break
                default:
                    resolve([])
                    return
            }

            url += `&filter[]=zichtbaarInCarrousel,eq,1`

            axios.get(url)
                .then((result) => {
                    const compilaties = result.data[COMPILATIE_TABLE].map(coll => {
                        if (!this.compilatieLookup[coll.id]) this.compilatieLookup[coll.id] = coll
                        return this.compilatieLookup[coll.id]
                    })
                    resolve(compilaties)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }
*/
    //==============================================================
    //==============================================================
    //                      AHS API
    //==============================================================
    //==============================================================

    /*getOpleidingen = (compilatie) => {
        return new Promise((resolve, reject) => {

                    const url = `${this.applicationPath.ahs_api()}users`
                    axios.get(url)
                        .then(response => {

                        })
                        .catch(error => {
                            console.error('GET OPLEIDINGEN ERROR', error)
                            reject(error)
                        })
                })
    }*/
}
