import React from 'react'
import AbstractWizardPage from "./AbstractWizardPage";


const CwWelkom = () => {

    return (
        <AbstractWizardPage prevButtonVisible={false}/>
    )
}

export default CwWelkom
