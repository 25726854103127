export class AdaUser {
    id: number
    email: string
    profielfoto: string
    voornaam: string
    familienaam: string
    isSuperAdmin: boolean
    adaUserBanner: string
    prefs: string
    lastLogin: string
    numLogins: number = 0
    roles: string
    complete : number 
}

export class AdaUserRol {

    static readonly ADMIN: string = 'admin'

    static readonly COLLECTIE_BEHEERDER: string = 'collectiebeheerder'
    static readonly INZENDER: string = 'inzender'
    static readonly VALIDATOR: string = 'validator'
    static readonly EIGENAAR: string = 'eigenaar'
    static readonly PROMOTOR: string = 'promotor'
    static readonly LEZER: string = 'lezer'
    static readonly BEGELEIDER: string = 'begeleider'

    static readonly COMPILATIE_ROLLEN: string[] = [AdaUserRol.EIGENAAR]
    static readonly COLLECTIE_ROLLEN: string[] = [AdaUserRol.COLLECTIE_BEHEERDER, AdaUserRol.INZENDER, AdaUserRol.VALIDATOR]
    static readonly BUNDEL_ROLLEN: string[] = [AdaUserRol.EIGENAAR, AdaUserRol.PROMOTOR, AdaUserRol.BEGELEIDER, AdaUserRol.LEZER]
}

export class AdaUserLink {
    id: number
    ref: string
    refId: number
    adaUser: number
    rol: string
    complete : number = 0
}

export class AdaUserLinkRef {
    static readonly COMPILATIE: string = 'compilatie'
    static readonly COLLECTIE: string = 'collectie'
    static readonly BUNDEL: string = 'bundel'
}

export class AdaUserPref {
    static readonly LOCALE: string = 'locale'

}