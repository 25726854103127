import React from 'react'
import PropTypes from 'prop-types'
import {Segment, Grid} from "semantic-ui-react";

const ApplicationFooter = (props) => {

    return (
        <Segment className='footer' style={{borderRadius: 0, display: 'flex', justifyContent: 'center'}} padded='very' inverted color='black'>

            <Grid columns={3} divided centered padded stackable  style={{maxWidth: '1300px'}}>
                <Grid.Column>
                    {props.contentCol1}
                </Grid.Column>

                <Grid.Column>
                    {props.contentCol2}
                </Grid.Column>

                <Grid.Column>
                    {props.contentCol3}
                </Grid.Column>
            </Grid>
        </Segment>
    )
}

export {ApplicationFooter}

ApplicationFooter.propTypes = {
    contentCol1: PropTypes.any,
    contentCol2: PropTypes.any,
    contentCol3: PropTypes.any,
}

ApplicationFooter.defaultProps = {

}