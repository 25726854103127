import React, {useContext, useState} from 'react'
import {useHistory} from 'react-router-dom'
import ServiceContext from '../../context/ServiceContext'
import {InfoViewer} from "@ahsmediatheek/ada-lib";
import {Button, Form, Modal, Segment} from "semantic-ui-react";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const BundelSettingsAdmin = () => {

    const [, setBundelToDelete] = useState()
    const [confirmBundelToDeleteUuid, setConfirmBundelToDeleteUuid] = useState('')
    const [verwijderBundelModalOpen, setVerwijderBundelModalOpen] = useState(false)


    const serviceContext = useContext(ServiceContext)
    const history = useHistory()


    const onVerwijderColleciteModalClose = () => {
        setVerwijderBundelModalOpen(false)
        setConfirmBundelToDeleteUuid('')
    }

    const onDeleteBundel = () => {
        /*const outline = serviceContext.bundelService.bundelOutlineLookup[serviceContext.bundel.id]
        if (outline.length > 0){
            window.alert(serviceContext.localeManager.getString('BUNDEL_NIET_LEEG'))
            return
        }*/
        setVerwijderBundelModalOpen(true)
    }

    const onDeleteBundelConfirm = (event, props) => {

        if (confirmBundelToDeleteUuid !== serviceContext.bundel.uuid) {
            setVerwijderBundelModalOpen(false)
            setConfirmBundelToDeleteUuid('')
            window.alert('Het ingegeven uuid komt niet overeen met het bundel id!')
            return
        }

        Promise.all([
            serviceContext.adaUserService.deleteAdaUserLinks(serviceContext.bundel),
            serviceContext.bundelService.deleteBundelReferentiebestanden(serviceContext.bundel),
            serviceContext.bundelService.deleteBundelTeKoopItems(serviceContext.bundel),
            serviceContext.bundelService.deleteCollectieBundels(serviceContext.bundel),
            serviceContext.bundelLocaleDataService.deleteLocaleData(serviceContext.bundel.id),
            //TODO: Delete itemEmbargo where bundel = bundel.id

            serviceContext.bundelService.deleteBundel(serviceContext.bundel, serviceContext.bundelCollectieCollectie),

        ]).then((...results) => {
            //console.log('DELETE RESULTS', results)

            setBundelToDelete(null)
            setVerwijderBundelModalOpen(false)
            setConfirmBundelToDeleteUuid('')
            //switch to collectie or home

            if (serviceContext.bundelCollectieCollectie){
                history.push('/collectie/' + serviceContext.bundelCollectieCollectie.uuid)
            } else {
                history.push('/home')
            }
        })
    }

    return (
        <div>
            <ComponentIdentifier displayName='BundelSettingsAdmin'/>

            <Form>


                <Form.Field>
                    <label>UUID</label>
                    <h3>{serviceContext.bundel.uuid}</h3>
                </Form.Field>

                <Segment>
                    <Form.Field>
                        <label>DANGER ZONE</label>

                        <InfoViewer editable={serviceContext.infoEditable}
                                    refId={'WARNING_DELETE_BUNDEL'}
                                    localeManager={serviceContext.localeManager}
                                    localeDataService={serviceContext.infoLocaleDataService}/>

                        <Button color='red'
                                onClick={onDeleteBundel}>{serviceContext.localeManager.getString('VERWIJDER_BUNDEL')}</Button>

                    </Form.Field>
                </Segment>
            </Form>


            {verwijderBundelModalOpen &&
            <Modal open={verwijderBundelModalOpen} onClose={onVerwijderColleciteModalClose} dimmer='inverted'>
                <Modal.Content>


                    <Form>
                        <Form.Input name='uuidField' value={confirmBundelToDeleteUuid}
                                    onChange={(event, {value}) => setConfirmBundelToDeleteUuid(value)}
                                    label='Plak hier de bundel uuid'/>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onVerwijderColleciteModalClose}>
                        {serviceContext.localeManager.getString('SLUIT')}
                    </Button>
                    <Button color='red' content={serviceContext.localeManager.getString('VERWIJDER_BUNDEL')}
                            onClick={onDeleteBundelConfirm}
                            /*disabled={confirmBundelToDeleteUuid.length !== 36}*/
                    />

                </Modal.Actions>

            </Modal>}

        </div>
    )
}

export default BundelSettingsAdmin
