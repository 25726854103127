import React, {useState, useLayoutEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Divider, Icon} from "semantic-ui-react";
import {ApplicationConstant} from "../../class/general/ApplicationConstant";

const DISPLAY_LIST = "list"
const DISPLAY_TILE = "tile"

const ResultContainer = ({children, containerWidth, gutter, header, headerIcon, headerChildren, extraContent, display, setDisplay}) => {

    const [colCount, setColCount] = useState()
    const [childColumns, setChildColumns] = useState([])

    useLayoutEffect(() => {

        const calculateNumCols = (containerWidth) => {
            const minWidth = Math.min(containerWidth, ApplicationConstant.RESULT_CONTAINER_MAX_WIDTH) - 2 * gutter
            const numCols = Math.floor(minWidth / ApplicationConstant.RESULT_CONTAINER_TILE_WIDTH)
            return numCols
        }

        const cc = calculateNumCols(containerWidth)
        if (cc !== colCount) {
            setColCount(cc)
        }
    }, [containerWidth, colCount, gutter])

    useLayoutEffect(() => {
        if (!children) return

        const buildChildColumns = () => {
            const childColumns = []

            for (let i = 0; i < colCount; i++) {
                childColumns[i] = children.filter((child, index) => index % colCount === i)
            }

            setChildColumns(childColumns)
        }

        buildChildColumns()
    }, [colCount, children])

    const onDisplayChange = (display) => {
        setDisplay && setDisplay(display)
    }

    if (!children) return null

    return (
        <Fragment>

            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'centers'}}>

                <div style={{display: 'flex', alignItems: 'center'}}>
                    {headerIcon && headerIcon}
                    {header && <span style={{fontSize: '1.5em', fontWeight: 'bold'}}>{header}</span>}
                </div>

                <div style={{display: "flex", alignItems: "center", justifyContent: 'flex-end', flexGrow: 1}}>
                    {headerChildren}
                    {setDisplay && <div style={{marginLeft: '10px'}}>
                        <Icon name='th' size='big' color={display === 'list' ? 'grey' : 'blue'} onClick={() => onDisplayChange('tile')}/>
                        <Icon name='th list' size='big' color={display === 'tile' ? 'grey' : 'blue'} onClick={() => onDisplayChange('list')}/>
                    </div>}
                </div>

            </div>

            <Divider/>

            {extraContent && extraContent()}

            {display === DISPLAY_TILE && <div style={{display: 'flex', justifyContent: 'center'}}>
                {childColumns.map((column, index) => {
                    return <div key={index}>
                        {column.map((child, index) => {
                            return <div key={index} style={{margin: `${gutter / 2}px ${gutter / 2}px ${gutter}px ${gutter / 2}px`}}>{child}</div>
                        })}
                    </div>
                })}
            </div>}

            {display === DISPLAY_LIST && <div style={{width: '100%'}}>{children}</div>}
        </Fragment>
    )
}

export {ResultContainer}

ResultContainer.propTypes = {
    containerWidth: PropTypes.number,
    colCount: PropTypes.number,
    gutter: PropTypes.number,
    header: PropTypes.string,
    headerIcon: PropTypes.object,
    headerChildren: PropTypes.object,
    extraContent: PropTypes.func,
    display: PropTypes.string,
    setDisplay: PropTypes.func,
}

ResultContainer.defaultProps = {
    gutter: 15,
    display: DISPLAY_TILE
}
