import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import ServiceContext from '../context/ServiceContext'

import {Card, Image, Label} from "semantic-ui-react";
import {CompilatieIcon, EmbargoIcon, ApplicationPath, StatusManager, MetadataUtil} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "./ComponentIdentifier";


const DISPLAY_LIST = "list"
const DISPLAY_TILE = "tile"

const CompilatieCard = ({compilatie, locale, showStatus, display, onClick}) => {

    const [referentieBeeld, setReferentieBeeld] = useState()
    const [hasEmbargo, setHasEmbargo] = useState(false)
    const [labelColor, setLabelColor] = useState()
    const [isEigenaar , setIsEigenaar] = useState(false)

    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        setLabelColor(StatusManager.getStatusColor(compilatie.status))
        setIsEigenaar(serviceContext.privilegeManager.canAddCompilatie())
    }, [compilatie])

    useEffect(() => {
        if (!compilatie) return

        const resolveReferentiebeeld = () => {
            return compilatie.defaultReferentiebeeld !== ''
                ? compilatie.defaultReferentiebeeld
                : serviceContext.applicationPath.referentiebeelden() + compilatie.uuid + '_thumb.jpg?d=' + new Date().getTime()
        }

        setReferentieBeeld(resolveReferentiebeeld())
        setHasEmbargo(compilatie.embargo && compilatie.embargo !== '')

    }, [compilatie])

    if (!compilatie || !locale) return null

    const card = (
        display === DISPLAY_TILE ? (
            <Card color='orange' className='result-card'>
                <Image src={referentieBeeld} wrapped ui={true}  loading="lazy"/>

                <div className='icon-group-top-left'>
                    <CompilatieIcon/>
                    {hasEmbargo && <EmbargoIcon/>}
                </div>

                {showStatus && <div className='icon-group-top-right'>
                    <Label size='tiny' color={labelColor}>{serviceContext.localeManager.getString(compilatie.status)}</Label>
                </div>}

                <Card.Content>
                    <Card.Header>{MetadataUtil.resolveTitel(compilatie, locale)}</Card.Header>
                    <Card.Description>
                        {compilatie[`beschrijving_${locale}`] || compilatie.beschrijving_nl}
                    </Card.Description>

                </Card.Content>

            </Card>
        ) : display === DISPLAY_LIST && (
            <Card fluid color='orange' className='result-card-list'>
                <div className='icon-group-top-right'>
                    <CompilatieIcon/>
                    {hasEmbargo && <EmbargoIcon/>}
                </div>

                {showStatus && <div className='icon-group-bottom-right'>
                    <Label size='tiny' color={labelColor}>{serviceContext.localeManager.getString(compilatie.status)}</Label>
                </div>}
                

                <Card.Content>
                    <Image src={referentieBeeld} size='small' wrapped ui={true} floated='left'/>
                    <Card.Header>{MetadataUtil.resolveTitel(compilatie, locale)}</Card.Header>
                    <Card.Description>
                        {compilatie[`beschrijving_${locale}`] || compilatie.beschrijving_nl}
                    </Card.Description>

                </Card.Content>

            </Card>
        )
    );

    return (
        <Fragment>
            <ComponentIdentifier displayName='CompilatieCard'/>

            {onClick ? (
                    <div onClick={event => onClick(event, compilatie)}>
                        {card}
                    </div>
                ) : card
            }
        </Fragment>
    )
}

export {CompilatieCard}

CompilatieCard.propTypes = {
    compilatie: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    showStatus: PropTypes.bool,
    display: PropTypes.string,
}

CompilatieCard.defaultProps = {
    showCompilatieUuid: false,
    showStatus: false,
    display: 'tile'
}
