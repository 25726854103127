import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {Input, Button, Header, Divider} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'
import {AdaUserCard} from "@ahsmediatheek/ada-lib";
import orderBy from 'lodash/orderBy'
import {ComponentIdentifier} from "./ComponentIdentifier";

const NUM_ELEMENTS = 5

const AdaUserDisplay = ({adaUserLinks, editable}) => {

    //alle adaUserLinks hebben al een adaUser!

    const serviceContext = useContext(ServiceContext)

    const [showAll, setShowAll] = useState(false)
    const [filteredAdaUser, setFilteredAdaUser] = useState()
    const [adaUsers, setAdaUsers] = useState([])
    const [rollen,setRollen] = useState([])
    const [sortedUsers , setSortedUsers] = useState({}) 
    const [hasMore, setHasMore] = useState(false)
    const [moreNum, setMoreNum] = useState(0)
    const [showMoreLessButton , setShowMoreLessButton] = useState({})
    const [showMax , setShowMax] = useState({})

    useEffect(() => {

        //eerste alle ada users ophalen

        Promise.all(adaUserLinks.map(adaUserLink => serviceContext.adaUserService.getAdaUser(adaUserLink.adaUser))).then(resp => {
            const adaUsers = orderBy(orderBy(adaUserLinks, 'rol').map(aul => {
                const au = {...serviceContext.adaUserService.adaUserLookup[aul.adaUser], rol: aul.rol}
                
                return au
            }), ['familienaam', 'voornaam'])
            setAdaUsers(adaUsers)
            setFilteredAdaUser(adaUsers.slice(0, NUM_ELEMENTS))
            setHasMore(adaUsers.length > NUM_ELEMENTS)
            setMoreNum(adaUsers.length - NUM_ELEMENTS)
            
        })
        
    }, [adaUserLinks, serviceContext.adaUserService])

    useEffect(() => {
        adaUsers.map(user => {
            let rol = user.rol
            setRollen( arr => (!arr.includes(rol)) ? [...arr , rol] : arr )
            let sortedUsersObj = sortedUsers
            if(!(rol in sortedUsersObj)) {
                sortedUsersObj[rol] = [ ]
            } 
            if(!(sortedUsersObj[rol].includes(user))) { sortedUsersObj[rol].push(user) }
            setSortedUsers(sortedUsersObj)
            let showMoreLessButtonObj = showMoreLessButton
            let showMaxObj = showMax
            if(sortedUsersObj[rol].length > NUM_ELEMENTS) {
                showMoreLessButtonObj[rol] = true
                showMaxObj[rol] = NUM_ELEMENTS
            } else {
                showMoreLessButtonObj[rol] = false
                showMaxObj[rol] = sortedUsersObj[rol].length
            }
            setShowMoreLessButton(showMoreLessButtonObj)
            setShowMax(showMaxObj)
            
        })
    } , [adaUsers])

    const onShowMoreButtonClick = () => {
        setShowAll(true)
        setFilteredAdaUser(adaUsers)
    }
    const onShowLessButtonClick = () => {
        setShowAll(false)
        setFilteredAdaUser(adaUsers.slice(0, NUM_ELEMENTS))
    }

    const onShowMinMaxToggle = (rol,num) => {
        console.log(rol,num)
    }

    if (!filteredAdaUser) return null

    

    return (
        <div>
            <ComponentIdentifier displayName='AdaUserDisplay'/>
            {   
                rollen.map((rol,rolidx) => {
                    return <div key={rolidx}>
                        <Header>{serviceContext.localeManager.getString(rol)}</Header>
                        <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '10px', alignItems: 'center'}}>
                        { sortedUsers[rol].map((adaUser,idx) => {
                                
                                let ListKey = rolidx + '_' + idx
                                return <AdaUserCard deleteable={editable} key={ListKey}
                                        adaUser={adaUser}
                                        label={serviceContext.localeManager.getString(adaUser.rol)}/>
                        })}
                        
                        </div>
                    </div>
                })
            }
            {/*
            <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '10px', alignItems: 'center'}}>
                {filteredAdaUser.map((adaUser, index) => {
                    return <AdaUserCard deleteable={editable} key={index}
                                        adaUser={adaUser}
                                        label={serviceContext.localeManager.getString(adaUser.rol)}/>
                })}

                {hasMore && !showAll && <Button size='mini' onClick={onShowMoreButtonClick}>{serviceContext.localeManager.getString('TOON_N_MEER').replace('[[n]]', moreNum)}</Button>}
                {hasMore && showAll && <Button size='mini' onClick={onShowLessButtonClick}>{serviceContext.localeManager.getString('TOON_MINDER')}</Button>}
            </div>
            */}
            {/*
            <Header>{serviceContext.localeManager.getString('EIGENAAR')}s</Header>
            <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '10px', alignItems: 'center'}}>
                
                {adaEigenaars.map((adaUser, index) => {
                    
                    return <AdaUserCard deleteable={editable} key={index}
                                        adaUser={adaUser}
                                        label={serviceContext.localeManager.getString(adaUser.rol)}/>
                })}

                {hasMore && !showAll && <Button size='mini' onClick={onShowMoreButtonClick}>{serviceContext.localeManager.getString('TOON_N_MEER').replace('[[n]]', moreNum)}</Button>}
                {hasMore && showAll && <Button size='mini' onClick={onShowLessButtonClick}>{serviceContext.localeManager.getString('TOON_MINDER')}</Button>}
            </div>
            <Header>{serviceContext.localeManager.getString('PROMOTOR')}s</Header>
            <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '10px', alignItems: 'center'}}>
                
                {adaPromotors.map((adaUser, index) => {
                    
                    return <AdaUserCard deleteable={editable} key={index}
                                        adaUser={adaUser}
                                        label={serviceContext.localeManager.getString(adaUser.rol)}/>
                })}

                {hasMore && !showAll && <Button size='mini' onClick={onShowMoreButtonClick}>{serviceContext.localeManager.getString('TOON_N_MEER').replace('[[n]]', moreNum)}</Button>}
                {hasMore && showAll && <Button size='mini' onClick={onShowLessButtonClick}>{serviceContext.localeManager.getString('TOON_MINDER')}</Button>}
            </div>
             */}
            {/*
            <Header>{serviceContext.localeManager.getString('PROMOTOR')}s</Header>
            <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '10px', alignItems: 'center'}}>
                
                {adaPromotors.map((adaUser, index) => {
                    
                    return <AdaUserCard deleteable={editable} key={index}
                                        adaUser={adaUser}
                                        label={serviceContext.localeManager.getString(adaUser.rol)}/>
                })}

                {hasMore && !showAll && <Button size='mini' onClick={onShowMoreButtonClick}>{serviceContext.localeManager.getString('TOON_N_MEER').replace('[[n]]', moreNum)}</Button>}
                {hasMore && showAll && <Button size='mini' onClick={onShowLessButtonClick}>{serviceContext.localeManager.getString('TOON_MINDER')}</Button>}
            </div>
            */}

            {editable && <Input/>}
        </div>
    )
}

export default AdaUserDisplay

AdaUserDisplay.propTypes = {
    adaUserLinks: PropTypes.array.isRequired,
    editable: PropTypes.bool,
}

AdaUserDisplay.defaultProps = {
    editable: false
}
