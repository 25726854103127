import React, {useState, useEffect, useContext, Fragment} from 'react'
import ServiceContext from '../../context/ServiceContext'
import {Form, Divider} from "semantic-ui-react"
import {TrefwoordenViewer} from '@ahsmediatheek/med-component-trefwoorden'

//import '@ahsmediatheek/med-component-locale-content/build/index.css'
import {SaveBar, InfoViewer} from "@ahsmediatheek/ada-lib"
import "@ahsmediatheek/ada-lib/build/index.css"
import AcademiejaarKiezer from "../../component/spinbutton/AcademiejaarKiezer";
import OpleidingKiezer from "../../component/dropdown/OpleidingKiezer";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const BundelSettingsMetadata = (props) => {

    const [isDirty, setIsDirty] = useState(false)

    const [tempBundel, setTempBundel] = useState()
    const [localeContent, setLocaleContent] = useState()
    const [changedLocales, setChangedLocales] = useState([])
    const [opleidingen, setOpleidingen] = useState()

    const serviceContext = useContext(ServiceContext)

    //TEMP BUNDEL
    useEffect(() => {
        setTempBundel({...serviceContext.bundel})
    }, [serviceContext.bundel])

    //LOCALE DATA
    useEffect(() => {
        if (!tempBundel) return

        setLocaleContent(serviceContext.localeContent.map(localeData => {
            const ldArr = []
            if (localeData.field === 'titel' && localeData.locale === 'nl' && localeData.content === '') {
                localeData.content = tempBundel.titel_nl
                ldArr.push(localeData)
            }
            if (localeData.field === 'titel' && localeData.locale === 'en' && localeData.content === '') {
                localeData.content = tempBundel.titel_en
                ldArr.push(localeData)
            }

            //serviceContext.localeDataService.saveLocaleData(ldArr)

            return {...localeData}
        }))
    }, [serviceContext.localeContent, tempBundel])

    //OPLEIDINGEN
    useEffect(() => {
        if (!tempBundel || !tempBundel.academiejaar) return
        serviceContext.arteveldeApiService.getOpleidingen(tempBundel.academiejaar).then(res => {
            setOpleidingen(res)
        })
    }, [tempBundel, serviceContext.arteveldeApiService])

    //METADATA OPTIONS
    useEffect(() => {
        /*serviceContext.metadataService.getRootMetadataTypes().then(res => {
            console.log('MDT', res)
        })*/
        const cwOptions = serviceContext.metadataService.getMetadataTypeOptions('CreativeWork')
        console.log('CW OPTIONS', cwOptions)
    }, [tempBundel, serviceContext.metadataService])

    //METADATA
    /*useEffect(() => {
        if (!tempBundel) return

        const metadata = serviceContext.metadataService.getMetadata(tempBundel).then(res => {
            console.log('METADATA', res)
        })
    }, [tempBundel])*/

    //TREFWOORDEN LIJSTEN
    /*useEffect(() => {
        if (!serviceContext.bundelCollectieCollectie) return
        const settings = serviceContext.collectieService.getCollectieSettings(serviceContext.bundelCollectieCollectie)

        if (settings[CollectieSettings.GEBRUIK_TREFWOORDENLIJST] && settings[CollectieSettings.TREFWOORDENLIJST]) {
            serviceContext.collectieService.getTrefwoordenlijst(settings[CollectieSettings.TREFWOORDENLIJST]).then(res => {
                const trefwoorden = res.split('\n').map((line, index) => {
                    return {key: index, value: line, text: line}
                })
                setTrefwoordenOptions(trefwoorden)
            })
        }
    }, [serviceContext.bundelCollectieCollectie, serviceContext.collectieService])*/

    /*const onTrefwoordChange = (event, {value}) => {
        changeDirty(true)
        setSelectedTrefwoorden(value)
    }*/

    const changeDirty = (value) => {
        setIsDirty(value)
        serviceContext.setIsDirty(value)
    }

    /*const onLocaleContentChange = (localeContentObject, data, plainText) => {
        localeContentObject.content = data
        changeDirty(true)

        if (localeContentObject.field === 'titel' && localeContentObject.locale === 'nl') tempBundel.titel_nl = localeContentObject.content
        if (localeContentObject.field === 'titel' && localeContentObject.locale === 'en') tempBundel.titel_en = localeContentObject.content
        if (localeContentObject.field === 'subtitel' && localeContentObject.locale === 'nl') tempBundel.subtitel_nl = localeContentObject.content
        if (localeContentObject.field === 'subtitel' && localeContentObject.locale === 'en') tempBundel.subtitel_en = localeContentObject.content
        if (localeContentObject.field === 'abstract' && localeContentObject.locale === 'nl') tempBundel.abstract_nl = localeContentObject.content
        if (localeContentObject.field === 'abstract' && localeContentObject.locale === 'en') tempBundel.abstract_en = localeContentObject.content

        setTempBundel({...tempBundel})

        if (!changedLocales.includes(localeContentObject.id))
            changedLocales.push(localeContentObject.id)
    }*/

    const onChangeBundelProp = (prop, value) => {
        changeDirty(true)
        setTempBundel({...tempBundel, [prop]: value})
    }

    const onChangeAcademiejaar = (aj) => {
        changeDirty(true)

        tempBundel.opleiding = null
        tempBundel.academiejaar = aj
        serviceContext.arteveldeApiService.getOpleidingen(tempBundel.academiejaar).then(res => {
            setOpleidingen(res)
        })

        setTempBundel({...tempBundel})
    }

    const kopieerMetadataVanCollectie = () => {
        changeDirty(true)
        tempBundel.academiejaar = serviceContext.bundelCollectieCollectie.academiejaar
        tempBundel.opleiding = serviceContext.bundelCollectieCollectie.opleiding
        setTempBundel({...tempBundel})
    }

    const onCancel = () => {
        changeDirty(false)
        setTempBundel({...serviceContext.bundel})
        setLocaleContent(serviceContext.localeContent.map(localeData => ({...localeData})))
    }

    const onSubmit = (...args) => {
        changeDirty(false)
        let cLocales = localeContent.filter(item => changedLocales.includes(item.id))

        serviceContext.onSaveBundel && serviceContext.onSaveBundel(tempBundel, cLocales)
        setChangedLocales([])
    }

    const onChangeTrefwoorden = (trefwoorden) => {
        changeDirty(true)
        setTempBundel({...tempBundel, trefwoorden: trefwoorden.join('|')})
    }


    return (
        <Form>
            <ComponentIdentifier displayName='BundelSettingsMetadata'/>

            <InfoViewer editable={serviceContext.infoEditable}
                        refId={'INFO_METADATA'}
                        localeManager={serviceContext.localeManager}
                        localeDataService={serviceContext.infoLocaleDataService}/>

            {tempBundel &&
            <Fragment>
                <Form.Field>
                    <label>{serviceContext.localeManager.getString('ACADEMIEJAAR')}</label>
                    <AcademiejaarKiezer academiejaren={serviceContext.arteveldeApiService.academiejaren}
                                        selectedAcademiejaar={tempBundel.academiejaar}
                                        onSelectAcademiejaar={onChangeAcademiejaar}/>
                </Form.Field>
                {opleidingen && <Form.Field style={{width: '100%'}}>
                    <label>{serviceContext.localeManager.getString('OPLEIDING')}</label>
                    <OpleidingKiezer opleidingen={opleidingen}
                                     localeManager={serviceContext.localeManager}
                                     selectedOpleiding={tempBundel.opleiding}
                                     onSelectOpleiding={(opl) => onChangeBundelProp('opleiding', opl)}/>
                </Form.Field>}

                {serviceContext.bundelCollectieCollectie
                && (serviceContext.bundelCollectieCollectie.academiejaar !== tempBundel.academiejaar || serviceContext.bundelCollectieCollectie.opleiding !== tempBundel.opleiding) &&
                <Fragment>
                    <InfoViewer editable={serviceContext.infoEditable}
                                refId={'INFO_KOPIEER_VAN_COLLECTIE'}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>
                    <Form.Button content={serviceContext.localeManager.getString('KOPIEER_VAN_COLLECTIE')} onClick={kopieerMetadataVanCollectie}/>
                </Fragment>}

                {tempBundel && <Form.Field>
                    <label>{serviceContext.localeManager.getString('TREFWOORDEN')}</label>

                    {serviceContext.bundelCollectieCollectie && serviceContext.bundelCollectieCollectie.trefwoorden !== '' &&
                    <Fragment>
                        <p>{serviceContext.localeManager.getString('TREFWOORDEN')} {serviceContext.localeManager.getString('COLLECTIE')}</p>
                        <TrefwoordenViewer consumerKey={serviceContext.TREFWOORDEN_CONSUMER_KEY}
                                           readonly
                                           allowCandidates={false}
                                           onChangeTrefwoorden={onChangeTrefwoorden}
                                           trefwoordenString={serviceContext.bundelCollectieCollectie.trefwoorden}
                                           locale={serviceContext.localeManager.locale}
                                           labelFunction={serviceContext.localeManager.getString}/>
                        <Divider/>
                    </Fragment>}

                    <TrefwoordenViewer consumerKey={serviceContext.TREFWOORDEN_CONSUMER_KEY}
                                       allowCandidates={serviceContext.privilegeManager.canAddBundelTrefwoordKandidaat(serviceContext.bundelCollectieCollectie, tempBundel, serviceContext.collectieAdaUserLinks, serviceContext.bundelAdaUserLinks)}
                                       onChangeTrefwoorden={onChangeTrefwoorden}
                                       trefwoordenString={tempBundel.trefwoorden}
                                       locale={serviceContext.localeManager.locale}
                                       labelFunction={serviceContext.localeManager.getString}/>
                </Form.Field>}
            </Fragment>
            }

            <Divider/>

            <Form.Field>
                <label>{serviceContext.localeManager.getString('PUBLICATIETYPE')}</label>

            </Form.Field>

            {/*<Form.Field>
                <label>{serviceContext.localeManager.getString('TAGS')}</label>
                <Dropdown
                    placeholder='Tags'
                    fluid
                    multiple
                    search
                    selection
                    value={selectedTrefwoorden}
                    options={trefwoordenOptions}
                    onChange={onTrefwoordChange}
                />

            </Form.Field>*/}

            {isDirty && <SaveBar cancelButtonLabel={serviceContext.localeManager.getString('ANNULEER')}
                                 saveButtonLabel={serviceContext.localeManager.getString('BEWAAR')}
                                 onCancelClick={onCancel} onSaveClick={onSubmit}/>}
        </Form>
    )
}

export default BundelSettingsMetadata
