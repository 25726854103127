import React, { Fragment, useContext, useState } from 'react';
import { Segment, Input } from "semantic-ui-react";

import { Query } from "@ahsmediatheek/ada-lib";

import ServiceContext from '../context/ServiceContext';
import InhoudZoekresultaten from './InhoudZoekresultaten';


export default function CompilatieInhoudZoeker({ onSelect }) {
    const serviceContext = useContext(ServiceContext);

    const [searching, setSearching] = useState(false);
    const [query, setQuery] = useState('');
    const [zoekresultaten, setZoekresultaten] = useState();

    function handleChange(event, { value }) {
        setQuery(value);
    }

    function handleKeyPress(event) {
        if (event.key !== 'Enter') return;

        if (query === '') {
            setZoekresultaten();
            setQuery();

            return;
        }

        setSearching(true);

        serviceContext.zoekService.getZoekresultaten(new Query(query)).then(result => {
            setSearching(false);
            setZoekresultaten(result);
        });
    }

    return (
        <Fragment>
            <h2>{serviceContext.localeManager.getString('INHOUD_TOEVOEGEN_AAN_COMPILATIE')}:</h2>
            <Segment>
                <Input
                    value={query}
                    action={{ icon: 'search' }}
                    placeholder='Search...'
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                />
            </Segment>
            {query && zoekresultaten && (
                <InhoudZoekresultaten
                    zoekresultaten={zoekresultaten}
                    onSelect={onSelect}
                />
            )}
        </Fragment>
    );
};

