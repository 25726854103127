import React from 'react'
import PropTypes from 'prop-types'

const Spacer = ({distance, vertical, fluid}) => {

    const style = {height: vertical ? distance : 0, width: vertical ? 0 : fluid ? '100%' : distance}

    return (
        <div style={style}/>
    )
}

export {Spacer}

Spacer.propTypes = {
    fluid: PropTypes.bool,
    distance: PropTypes.any,
    vertical: PropTypes.bool
}

Spacer.defaultProps = {
    fluid: false,
    distance: 10
}
