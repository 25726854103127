import React, {useContext, useState, useEffect} from 'react'
import {Form, Divider, Header, Button} from "semantic-ui-react";
import ServiceContext from '../../../../context/ServiceContext'
import AbstractWizardPage from "./AbstractWizardPage";
import {CollectieCard} from "../../../../component/CollectieCard";
import {ComponentIdentifier} from "../../../../component/ComponentIdentifier";


const CwNaam = () => {

    const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        setNextButtonDisabled(serviceContext.wizardCollectie.titel_nl === '' && serviceContext.wizardCollectie.titel_en === '')
    }, [serviceContext.wizardCollectie])

    const onChangeProp = (event, {name, value}) => {
        serviceContext.onChangeProp(event, {name, value})
    }

    return (
        <AbstractWizardPage nextButtonEnabled={!nextButtonDisabled}>
            <ComponentIdentifier displayName='CwNaam'/>

            <Header>{serviceContext.localeManager.getString('VOORBEELD')}</Header>
            <CollectieCard collectie={serviceContext.wizardCollectie} locale={serviceContext.localeManager.locale}/>

            <Form>
                <Form.Input name='titel_nl' label={serviceContext.localeManager.getString('TITEL_NL')}
                            onChange={onChangeProp}
                            value={serviceContext.wizardCollectie.titel_nl}/>
                <Form.Input name='titel_en' label={serviceContext.localeManager.getString('TITEL_EN')}
                            onChange={onChangeProp}
                            value={serviceContext.wizardCollectie.titel_en}/>

                <Divider/>

                <Form.Input name='subtitel_nl' label={serviceContext.localeManager.getString('SUBTITEL_NL')}
                            onChange={serviceContext.onChangeProp}
                            value={serviceContext.wizardCollectie.subtitel_nl}/>
                <Form.Input name='subtitel_en' label={serviceContext.localeManager.getString('SUBTITEL_EN')}
                            onChange={serviceContext.onChangeProp}
                            value={serviceContext.wizardCollectie.subtitel_en}/>

                <Divider/>

                <Form.TextArea name='beschrijving_nl' label={serviceContext.localeManager.getString('BESCHRIJVING_NL')}
                               onChange={serviceContext.onChangeProp}
                               value={serviceContext.wizardCollectie.beschrijving_nl}/>
                <Form.TextArea name='beschrijving_en' label={serviceContext.localeManager.getString('BESCHRIJVING_EN')}
                               onChange={serviceContext.onChangeProp}
                               value={serviceContext.wizardCollectie.beschrijving_en}/>


            </Form>
        </AbstractWizardPage>
    )
}

export default CwNaam
