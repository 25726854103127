import React, {useState, useContext} from 'react'
import {Button} from "semantic-ui-react";
import ServiceContext from '../../../../context/ServiceContext'
import AbstractWizardPage from "./AbstractWizardPage";
import AdaUserSearch from "../../../../component/AdaUserSearch";
import {AdaUserCard, AdaUserRol} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "../../../../component/ComponentIdentifier";


const CwInzenders = () => {

    const [adaUserSearchModalOpen, setAdaUserSearchModalOpen] = useState(false)

    const serviceContext = useContext(ServiceContext)

    const onInzendersSelect = (users) => {
        setAdaUserSearchModalOpen(false)
        serviceContext.onInzendersSelect(users)
    }

    return (
        <AbstractWizardPage>
            <ComponentIdentifier displayName='CwInzenders'/>

            <div style={{display: 'flex', flexWrap: 'wrap'}}>{serviceContext.inzenders.map((inzender, index) => {
                //const adaUser = serviceContext.collectieService.adaUserService.adaUserLookup[adaUserLink.adaUser]
                return <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
                    <AdaUserCard deleteable={true}
                                 onDelete={serviceContext.onInzenderDelete}
                                 style={{marginBottom: '20px'}}
                                 key={index}
                                 adaUser={inzender}/>
                </div>
            })}</div>

            <Button onClick={() => setAdaUserSearchModalOpen(true)} color='green'>
                {serviceContext.localeManager.getString('VOEG_ITEM_TOE').replace('[[item]]', serviceContext.localeManager.getString('INZENDERS'))}
            </Button>

            {adaUserSearchModalOpen &&
            <AdaUserSearch modalOpen={adaUserSearchModalOpen} rol={AdaUserRol.INZENDER}
                           onUsersSelect={onInzendersSelect}
                           academiejaar={serviceContext.wizardCollectie.academiejaar}
                           opleiding={serviceContext.wizardCollectie.opleiding}/>}
        </AbstractWizardPage>
    )
}

export default CwInzenders
