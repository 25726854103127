import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import { formatDistance, formatRelative, format } from 'date-fns'
import {useParams, useHistory, useLocation} from "react-router-dom"

import {Button, Icon, Segment, Header, Input, Divider} from "semantic-ui-react";
import ServiceContext from "../../context/ServiceContext";
import {DateUtil, ToegangsticketHistoriekActie, ToegangsticketStatus} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "../ComponentIdentifier";

const INITIAL_POGINGEN = 3

const ToegangsticketValidator = ({toegangsticket, onToegangGeblokkeerd, onToegangAanvaard}) => {

    const [owner, setOwner] = useState()
    const [aangemaaktString, setAangemaaktString] = useState('')
    const [begunstigde, setBegunstigde] = useState('')
    const [pogingen, setPogingen] = useState(INITIAL_POGINGEN)

    const serviceContext = useContext(ServiceContext)
    const [geldig_van_string, setGeldig_van_string] = useState('')
    const [geldig_tot_string, setGeldig_tot_string] = useState('')

    const history = useHistory()

    useEffect(() => {
        serviceContext.adaUserService.getAdaUser(toegangsticket.aangemaakt_door).then(user => {
            setOwner(user)
        })
    }, [toegangsticket])

    useEffect(() => {
        setAangemaaktString(formatRelative(
            DateUtil.dateFromMysql(toegangsticket.datum_aanmaak),
            new Date(),
            { addSuffix: true, locale: serviceContext.localeManager.dateFnsLocaleLookup[serviceContext.localeManager.locale] }))

        setGeldig_tot_string(format(DateUtil.dateFromMysql(toegangsticket.geldig_tot), 'PPPPp', { locale: serviceContext.localeManager.dateFnsLocaleLookup[serviceContext.localeManager.locale] }))
        setGeldig_van_string(format(DateUtil.dateFromMysql(toegangsticket.geldig_van), 'PPPPp', { locale: serviceContext.localeManager.dateFnsLocaleLookup[serviceContext.localeManager.locale] }))

    }, [toegangsticket, serviceContext.localeManager.locale])

    useEffect(() => {
        if (pogingen >= INITIAL_POGINGEN) return

        const initiator = serviceContext.privilegeManager.adaUser ? serviceContext.privilegeManager.adaUser.email : 'anoniem'

        if (pogingen <= 0){
            serviceContext.toegangsticketService.createQuickToegangsticketHistoriek(toegangsticket, ToegangsticketHistoriekActie.TICKET_GEBLOKKEERD, initiator)
            toegangsticket.status = ToegangsticketStatus.GEBLOKKEERD
            serviceContext.toegangsticketService.updateToegangsticket(toegangsticket)
            serviceContext.toegangsticketService.stuurMailBijBlokkering(toegangsticket, serviceContext.localeManager.locale)

            alert(serviceContext.localeManager.getString('TOEGANGSTICKET_GEBLOKKEERD'))

            history.push('/home')
        } else {

            serviceContext.toegangsticketService.createQuickToegangsticketHistoriek(toegangsticket,
                ToegangsticketHistoriekActie.TICKET_VALIDATIE,
                initiator, `Invoer: ${begunstigde}. Mislukt (nog ${pogingen} pogingen)`)

            alert(serviceContext.localeManager.getString('FOUTE_BEGUNSTIGDE_NOG_X_POGINGEN').replace('[[POGINGEN]]', pogingen))
        }
        setBegunstigde('')

    }, [pogingen])

    const onChange = (event, {value}) => {
        setBegunstigde(value)
    }

    const onKeydown = (event) => {
        if (event.keyCode === 13) validateButton_clickHandler()
    }

    const validateButton_clickHandler = () => {

        if (begunstigde === toegangsticket.begunstigde){
            onToegangAanvaard()
        } else {
            const p = pogingen-1

            setPogingen(p)

        }
    }


    if (!owner) return null

    return (
        <Segment style={{display: 'flex'}} secondary>
            <ComponentIdentifier displayName='ToegangsticketValidator'/>

            <Icon name='ticket' size='huge' color='brown'/>
            <div style={{marginLeft: '20px'}}>

                <Header>{serviceContext.localeManager.getString('TOEGANGSTICKET')}</Header>
                {serviceContext.localeManager.getString('AANGEMAAKT_DOOR')} <strong>{owner.voornaam} {owner.familienaam}</strong> - <strong>{aangemaaktString}</strong>


                {/*<p>{serviceContext.localeManager.getString('BEGUNSTIGDE')}: <strong>{toegangsticket.begunstigde}</strong></p>*/}
                <p>{serviceContext.localeManager.getString('GELDIG_VAN')} <strong>{geldig_van_string}</strong> {serviceContext.localeManager.getString('TOT')} <strong>{geldig_tot_string}</strong></p>

                <Input onChange={onChange} onKeyDown={onKeydown} fluid focus value={begunstigde} placeholder={serviceContext.localeManager.getString('EMAIL_ADRES_BEGUNSTIGDE_INVOEREN')}/>

                <Divider/>

                <Button color='green' disabled={begunstigde === ''} onClick={validateButton_clickHandler}>{serviceContext.localeManager.getString('VALIDEER')}...</Button>



            </div>
        </Segment>
    )
}

export default ToegangsticketValidator

ToegangsticketValidator.propTypes = {
    toegangsticket: PropTypes.object.isRequired,
    onToegangGeblokkeerd: PropTypes.func.isRequired,
    onToegangAanvaard: PropTypes.func.isRequired,
}

ToegangsticketValidator.defaultProps = {
}
