(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("semantic-ui-react"), require("html-react-parser"), require("@ckeditor/ckeditor5-react"), require("@ckeditor/ckeditor5-build-inline"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "semantic-ui-react", "html-react-parser", "@ckeditor/ckeditor5-react", "@ckeditor/ckeditor5-build-inline"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("semantic-ui-react"), require("html-react-parser"), require("@ckeditor/ckeditor5-react"), require("@ckeditor/ckeditor5-build-inline")) : factory(root["react"], root["semantic-ui-react"], root["html-react-parser"], root["@ckeditor/ckeditor5-react"], root["@ckeditor/ckeditor5-build-inline"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__) {
return 