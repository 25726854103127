import React, {useState, useEffect, useContext, useRef, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Grid, List, Image, Button, Dimmer, Loader, Modal, Form, Icon} from "semantic-ui-react";
import {InfoViewer} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../context/ServiceContext'
import AcademiejaarKiezer from "./spinbutton/AcademiejaarKiezer";
import OpleidingKiezer from "./dropdown/OpleidingKiezer";
import {ComponentIdentifier} from "./ComponentIdentifier";

const RADIO_ZOEK = 'radioZoek'
const RADIO_SELECT_STUDENTEN = 'radioSelectStudenten'
const RADIO_SELECT_DOCENTEN = 'radioSelectDocenten'

const AdaUserSearch = ({onUsersSelect, academiejaar, opleiding, modalOpen, rol, adaUsers}) => {

    const [radioMode, setRadioMode] = useState(RADIO_ZOEK)
    const [zoekString, setZoekString] = useState('')
    const [results, setResults] = useState()
    const [loading, setLoading] = useState(false)
    const [selectedAdaUsers, setSelectedAdaUsers] = useState([])
    const [localAcademiejaar, setLocalAcademiejaar] = useState()
    const [opleidingen, setOpleidingen] = useState()
    const [selectedOpleiding, setSelectedOpleiding] = useState()


    const serviceContext = useContext(ServiceContext)

    const inputRef = useRef()

    useEffect(() => {
        if (inputRef.current) inputRef.current.focus()
    }, [inputRef, radioMode])

    useEffect(() => {

        const executeSearch = () => {
            if (zoekString === '') return
            serviceContext.adaUserService.searchAdaUsers(zoekString).then(res => {
                setResults(res)
            })
        }

        const timeout = setTimeout(executeSearch, 800)

        return () => {
            clearTimeout(timeout)
        }
    }, [zoekString, serviceContext.adaUserService])

    useEffect(() => {
        setZoekString('')
        inputRef.current && inputRef.current.focus()
    }, [selectedAdaUsers])

    useEffect(() => {
        if (!academiejaar) {
            setLocalAcademiejaar(serviceContext.arteveldeApiService.getCurrentAcademiejaar())
        } else {
            setLocalAcademiejaar(academiejaar)
        }
    }, [academiejaar, serviceContext.arteveldeApiService])

    useEffect(() => {
        if (!localAcademiejaar) return

        serviceContext.arteveldeApiService.getOpleidingen(localAcademiejaar).then(opl => {
            setOpleidingen(opl)
        })

    }, [localAcademiejaar, serviceContext.arteveldeApiService])

    useEffect(() => {
        if (!selectedOpleiding) return

        setLoading(true)

        if (radioMode === RADIO_SELECT_DOCENTEN) {
            serviceContext.arteveldeApiService.getOpleidingDocenten(selectedOpleiding, localAcademiejaar).then(res => {
                setLoading(false)
                setResults(res)
            })
        } else if (radioMode === RADIO_SELECT_STUDENTEN) {
            serviceContext.arteveldeApiService.getOpleidingStudenten(selectedOpleiding, localAcademiejaar).then(res => {
                setLoading(false)
                setResults(res)
            })
        }
    }, [selectedOpleiding, localAcademiejaar, radioMode, serviceContext.arteveldeApiService])

    const onZoekStringChange = (event) => {
        setZoekString(event.target.value)
    }


    const selectUser = (event, {value, adauser}) => {
        if(adauser.id === 0) {
            console.log(adauser)
            adauser = serviceContext.adaUserService.addIncompleteAdaUser(adauser)
        }

        if (selectedAdaUsers.includes(adauser)) return

        console.log('USER', adauser)
        let alreadySelected = adaUsers.filter(au => au.id === adauser.id)
        if (alreadySelected.length > 0) return

        //reeds aan lijst toegevoegd
        alreadySelected = selectedAdaUsers.filter(au => au.id === adauser.id)
        if (alreadySelected.length > 0) return

        setSelectedAdaUsers([adauser, ...selectedAdaUsers])
    }

    const selectAhsUser = (event, {ahsuser}) => {
        serviceContext.adaUserService.getAdaUserFromAhsUser(ahsuser).then(adauser => {
            //hetzelfde objecty
            if (selectedAdaUsers.includes(adauser)) return

            //reeds in lijst van geselecteerde adaUser
            let alreadySelected = adaUsers.filter(au => au.id === adauser.id)
            if (alreadySelected.length > 0) return

            //reeds aan lijst toegevoegd
            alreadySelected = selectedAdaUsers.filter(au => au.id === adauser.id)
            if (alreadySelected.length > 0) return

            setResults(results.filter(ahsu => ahsu !== ahsuser))
            setSelectedAdaUsers([adauser, ...selectedAdaUsers])
        })
    }

    const onSubmit = () => {
        onUsersSelect(selectedAdaUsers)
    }

    const onChangeAcademiejaar = (academiejaar) => {
        setLocalAcademiejaar(academiejaar)
    }

    const onModalClose = () => {
        onUsersSelect([])
    }

    const onChangeRadioMode = (event, {name, value}) => {
        setRadioMode(value)
        setSelectedOpleiding(null)
        setResults(null)
        setZoekString('')
    }

    const onSelectOpleiding = (opleiding) => {
        console.log('sel opl', opleiding)
        setSelectedOpleiding(opleiding)
    }

    return (


        <Modal open={modalOpen} onClose={onModalClose} dimmer='inverted'>
            <ComponentIdentifier displayName='AdaUserSearch'/>

            <Modal.Header>{serviceContext.localeManager.getString('VOEG_ITEM_TOE').replace('[[item]]', rol.toLowerCase())}</Modal.Header>
            <Modal.Content>

                <div>

                    <InfoViewer refId={`INFO_ADD_ADAUSER_LINK`}
                                editable={serviceContext.infoEditable}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>

                    <Form>
                        <Form.Group inline>
                            <Form.Radio
                                label={serviceContext.localeManager.getString('ZOEK_OP_NAAM')}
                                name='radioGroup'
                                value={RADIO_ZOEK}
                                checked={radioMode === RADIO_ZOEK}
                                onChange={onChangeRadioMode}
                            />
                            <Form.Radio
                                label={serviceContext.localeManager.getString('SELECTEER_STUDENTEN')}
                                name='radioGroup'
                                value={RADIO_SELECT_STUDENTEN}
                                checked={radioMode === RADIO_SELECT_STUDENTEN}
                                onChange={onChangeRadioMode}
                            />
                            <Form.Radio
                                label={serviceContext.localeManager.getString('SELECTEER_DOCENTEN')}
                                name='radioGroup'
                                value={RADIO_SELECT_DOCENTEN}
                                checked={radioMode === RADIO_SELECT_DOCENTEN}
                                onChange={onChangeRadioMode}
                            />
                        </Form.Group>

                        {radioMode !== RADIO_ZOEK &&
                        <Fragment>
                            <div style={{display: "flex", alignItems: "center", marginBottom: '10px'}}>
                                <div style={{width: '200px'}}><strong>{serviceContext.localeManager.getString('SELECTEER_ACADEMIEJAAR')}</strong></div>
                                <AcademiejaarKiezer style={{marginLeft: '5px', width: '300px'}} academiejaren={serviceContext.arteveldeApiService.academiejaren}
                                                    selectedAcademiejaar={localAcademiejaar}
                                                    onSelectAcademiejaar={onChangeAcademiejaar}/>
                            </div>

                            {opleidingen &&
                            <div style={{display: "flex", alignItems: "center", marginBottom: '10px'}}>
                                <div style={{width: '200px', marginRight: '10px'}}><strong>{serviceContext.localeManager.getString('SELECTEER_OPLEIDING')}</strong></div>
                                <OpleidingKiezer localeManager={serviceContext.localeManager}
                                                 opleidingen={opleidingen}
                                                 selectedOpleiding={selectedOpleiding}
                                                 onSelectOpleiding={onSelectOpleiding}
                                                 multiple={false}/>
                            </div>}

                        </Fragment>}
                    </Form>


                    {radioMode === RADIO_ZOEK &&
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <form autoComplete="new-password" style={{width: '100%'}}>
                            <input className='input-field' ref={inputRef} value={zoekString} autoComplete='off' type='text' name='searchInput' onChange={onZoekStringChange}/>
                        </form>

                    </div>
                    }

                    <Grid style={{marginTop: '20px'}}>
                        <Grid.Column width={8} style={{overflowY: 'scroll', height: '40vh'}}>
                            {radioMode === RADIO_ZOEK && results &&
                            <List selection divided>
                                {results.map((adaUser, index) => {
                                    return <List.Item className='clickable-list-item' key={index} onClick={selectUser} adauser={adaUser}>
                                        {adaUser.profielfoto !== null && 
                                            <Image avatar src={adaUser.profielfoto}/>
                                        }
                                        {adaUser.profielfoto == null && 
                                            <List.Icon name="user" size="large" />
                                        }
                                        <List.Content>
                                            {adaUser.voornaam !== null && adaUser.familienaam !== null &&
                                                <List.Header>{`${adaUser.familienaam} ${adaUser.voornaam}`}</List.Header>
                                            }
                                            {(adaUser.voornaam == "" || adaUser.familienaam == "") &&
                                                <List.Header>NAAM_ONBEKEND</List.Header>
                                            }
                                            <List.Description>
                                                {adaUser.email}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                })}

                            </List>}

                            {radioMode !== RADIO_ZOEK &&
                            <div>
                                <Dimmer active={loading} inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer>

                                <List selection divided>
                                    {results && results.map((ahsUser, index) => {
                                        return <List.Item className='clickable-list-item' key={index} onClick={selectAhsUser} ahsuser={ahsUser}>
                                            <Image avatar src={`https://www.arteveldehogeschool.be/mediatheken/dev/foto/foto.php?size=XS&user=${ahsUser.Upn}`}/>
                                            <List.Content>
                                                <List.Header>{`${ahsUser.Naam} ${ahsUser.Voornaam}`}</List.Header>
                                                <List.Description>
                                                    {ahsUser.Upn}
                                                </List.Description>
                                            </List.Content>
                                        </List.Item>
                                    })}
                                </List>
                            </div>}

                        </Grid.Column>


                        <Grid.Column width={8} style={{overflowY: 'scroll', height: '40vh'}}>
                            <List selection divided>

                                {adaUsers.map((adaUser, index) => {
                                    return <List.Item style={{opacity: '0.5'}} className='clickable-list-item' key={index} adauser={adaUser}>
                                        <Image avatar src={adaUser.profielfoto}/>
                                        <List.Content>
                                            <List.Header>{`${adaUser.familienaam} ${adaUser.voornaam}`}</List.Header>
                                            <List.Description>
                                                {adaUser.email}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                })}

                                {selectedAdaUsers.map((adaUser, index) => {
                                    return <List.Item className='clickable-list-item' key={index} onClick={selectUser} adauser={adaUser}>
                                        <Image avatar src={adaUser.profielfoto}/>
                                        <List.Content>
                                            <List.Header>{`${adaUser.familienaam} ${adaUser.voornaam}`}</List.Header>
                                            <List.Description>
                                                {adaUser.email}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                })}

                            </List>
                        </Grid.Column>
                    </Grid>
                </div>


            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onSubmit} disabled={selectedAdaUsers.length === 0}>
                    {serviceContext.localeManager.getString('SELECTEER')}
                </Button>
            </Modal.Actions>

        </Modal>

    )
}

export default AdaUserSearch

AdaUserSearch.propTypes = {
    rol: PropTypes.string,
    opleiding: PropTypes.string,
    academiejaar: PropTypes.string,
    onUsersSelect: PropTypes.func,
    adaUsers: PropTypes.array
}

AdaUserSearch.defaultProps = {
    adaUsers: []
}
