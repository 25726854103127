//import moment from 'moment'
import axios from "axios";
import {ApplicationPath} from "../class/general/ApplicationPath";
import {AuthenticationService} from "./AuthenticationService";
import orderBy from 'lodash/orderBy'

const moment = require('moment')


export class ArteveldeApiService {

    readonly API_URL: string = "https://api.arteveldehs.be/v1/"

    applicationPath: ApplicationPath
    authenticationInstance: AuthenticationService
    academiejaren: string[]
    opleidingenLookup: object = {} // -> academiejaar
    ENWsLookup: object = {}; // -> academiejaar
    dienstenLookup: object = {}; // -> academiejaar
    opleidingenCodeLookup: object = {} // -> academiejaar.code

    adaUserOpleidingenLookup: object = {} // -> adaUser
    adaUserOlodsLookup: object = {} // -> adaUser
    opleidingDocenten: object = {}
    opleidingOlods: object = {}
    opleidingStudenten: object = {}

    constructor(authenticationInstance, applicationPath) {
        this.authenticationInstance = authenticationInstance
        this.applicationPath = applicationPath
        this.getAcademiejaren()
    }

    getAcademiejaren = () => {
        if (!this.academiejaren) {
            this.academiejaren = []
            let now: number = parseInt(moment().format('YYYY'))
            let month: number = parseInt(moment().format('MM'))
            if (month > 8) now++

            for (let i = 0; i < 10; i++) {
                this.academiejaren.push(`${now}-${now + 1}`)
                now--
            }
        }

        return this.academiejaren
    }

    getCurrentAcademiejaar = () => {
        return this.academiejaren[1]
    }

    getShortAcademiejaar = (academiejaar): string => {
        const ja = academiejaar.split('-')
        ja[1] = ja[1].substr(2)
        return ja.join('-')
    }

    //==============================================================
    //==============================================================
    //                      OPLEIDINGEN
    //==============================================================
    //==============================================================

    getOpleidingen = (academiejaar: string) => {
        return new Promise((resolve, reject) => {

            if (!academiejaar || academiejaar === '') {
                resolve(null)
                return
            }

            if (this.opleidingenLookup[academiejaar]) {
                resolve(this.opleidingenLookup[academiejaar])
                return
            }

            const url = `${this.applicationPath.ahs_api()}/opleidingen/?academiejaar=${this.getShortAcademiejaar(academiejaar)}`
            axios.get(url)
                .then(response => {
                    this.opleidingenLookup[academiejaar] = response.data
                    this.opleidingenCodeLookup[academiejaar] = {}
                    for (const opleiding of this.opleidingenLookup[academiejaar]) {
                        this.opleidingenCodeLookup[academiejaar][opleiding.OplCode] = opleiding
                    }
                    resolve(this.opleidingenLookup[academiejaar])
                })
                .catch(error => {
                    console.error('GET OPLEIDINGEN ERROR', error)
                    reject(error)
                })


        })
    }

    getENWs = (academiejaar: string) => {
        return new Promise((resolve, reject) => {

            if (!academiejaar || academiejaar === '') {
                resolve(null);
                return;
            }

            if (this.ENWsLookup[academiejaar]) {
                resolve(this.ENWsLookup[academiejaar]);
                return;
            }

            const refDatum = academiejaar.replace(/^\d{4}-(\d{4})$/, '$1-09-01');
            const url = `${this.applicationPath.ahs_api()}/directies?refDatum=${refDatum}`;

            axios.get(url)
                .then(response => {
                    const ENWs = response.data.filter(item => (item.Id.substring(0, 3) === 'ENW'));

                    this.ENWsLookup[academiejaar] = ENWs;
                    resolve(this.ENWsLookup[academiejaar]);
                })
                .catch(error => {
                    console.error('GET ENWS ERROR', error);
                    reject(error);
                });

        });
    }

    getDiensten = (academiejaar: string) => {
        return new Promise((resolve, reject) => {

            if (!academiejaar || academiejaar === '') {
                resolve(null);
                return;
            }

            if (this.dienstenLookup[academiejaar]) {
                resolve(this.dienstenLookup[academiejaar]);
                return;
            }

            const refDatum = this.getShortAcademiejaar(academiejaar).replace(/^(\d{4})-\d{2}$/, '$1-09-01');
            const url = `${this.applicationPath.ahs_api()}/diensten?refDatum=${refDatum}`;

            axios.get(url)
                .then(response => {
                    const diensten = response.data.filter(item => ((item.DirectieId === null) || (item.Id.substring(0, 3) === 'ADI')));

                    this.dienstenLookup[academiejaar] = diensten;
                    resolve(this.dienstenLookup[academiejaar]);
                })
                .catch(error => {
                    console.error('GET DIENSTEN ERROR', error);
                    reject(error);
                });

        });
    }

    getMedewerkers = () => {
        //https://api.arteveldehs.be/v1/diensten/medewerkers[?refDatum]
        return new Promise((resolve, reject) => {

            const url = `${this.applicationPath.ahs_api()}/diensten/medewerkers?${moment().format('YYYY-MM-DD')}`
            axios.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.error('GET OPLEIDINGEN ERROR', error)
                    reject(error)
                })


        })
    }

    getAdaUserOpleidingen = (adaUser) => {
        return new Promise((resolve, reject) => {

            if (this.adaUserOpleidingenLookup[adaUser]) {
                resolve(this.adaUserOpleidingenLookup[adaUser])
                return
            }
//https://api.arteveldehs.be/v1/users/{id}/opleidingen[?actief]
            const url = `${this.applicationPath.ahs_api()}/users/${adaUser.email}/opleidingen?1` //enkel actieve opleidingen
            axios.get(url)
                .then(response => {
                    this.adaUserOpleidingenLookup[adaUser] = response.data
                    resolve(this.adaUserOpleidingenLookup[adaUser])
                })
                .catch(error => {
                    console.error('GET OPLEIDINGEN ERROR', error)
                    reject(error)
                })


        })
    }

    getOpleidingString = (academiejaar, opleidingen, locale) => {
        if (!academiejaar || academiejaar === '' || !opleidingen || opleidingen === '') return ''
        const str = opleidingen.split(',').map(code => {
            if (this.opleidingenCodeLookup[academiejaar][code] === undefined) {

                return `[${code}]`;
            }
            return this.opleidingenCodeLookup[academiejaar][code]['Opleiding' + locale.toUpperCase()]
        })
        return str.join(', ')
    }

    //==============================================================
    //==============================================================
    //                      OLODS
    //==============================================================
    //==============================================================


    getOpleidingOlods = (opleiding, academiejaar?) => {
        //https://api.arteveldehs.be/v1/opleidingen/{opleidingID}/olods[?academiejaar][&goedgekeurd][&docenten][&studenten][&mobiliteit]

        return new Promise((resolve, reject) => {

            if (this.opleidingOlods[opleiding]) {
                resolve(this.opleidingOlods[opleiding])
                return
            }

            let url = `${this.applicationPath.ahs_api()}/opleidingen/${opleiding}/olods`
            if (academiejaar) url += `?academiejaar=${this.getShortAcademiejaar(academiejaar)}`

            axios.get(url)
                .then(response => {
                    this.opleidingOlods[opleiding] = response.data
                    resolve(this.opleidingOlods[opleiding])
                })
                .catch(error => {
                    console.error('GET OPLEIDINGEN ERROR', error)
                    reject(error)
                })


        })
    }


    //==============================================================
    //==============================================================
    //                      DOCENTEN
    //==============================================================
    //==============================================================

    getOpleidingDocenten = (opleiding, academiejaar?) => {
        //https://api.arteveldehs.be/v1/opleidingen/{opleidingID}/docenten[?academiejaar]

        return new Promise((resolve, reject) => {

            if (this.opleidingDocenten[opleiding]) {
                resolve(this.opleidingDocenten[opleiding])
                return
            }
//https://api.arteveldehs.be/v1/users/{id}/opleidingen[?actief]
            let url = `${this.applicationPath.ahs_api()}/opleidingen/${opleiding}/docenten`
            if (academiejaar) url += `?academiejaar=${this.getShortAcademiejaar(academiejaar)}`

            axios.get(url)
                .then(response => {
                    this.opleidingDocenten[opleiding] = orderBy(response.data, (user) => {
                        if (!user.Naam) return '' //soms zit er een object tussen met enkel een mail adres
                        return user.Naam.toLowerCase()
                    })
                    resolve(this.opleidingDocenten[opleiding])
                })
                .catch(error => {
                    console.error('GET OPLEIDINGEN ERROR', error)
                    reject(error)
                })


        })

    }

    /*getOpleidingenDocenten = (opleidingen, academiejaar) => {
        return new Promise((resolve, reject) => {
            if (!opleidingen || opleidingen === ''){
                resolve([])
                return
            }
            const opleidingArr = opleidingen.split(',')
            const promises = opleidingArr.map(opleiding => {
                return new Promise((res, rej) => {
                    this.getOpleidingDocenten(opleiding, academiejaar).then(resp => {
                        res(resp)
                    })
                })
            })
            Promise.all(promises).then(responses => {
                resolve(responses)
            })
        })
    }*/

    //==============================================================
    //==============================================================
    //                      STUDENTEN
    //==============================================================
    //==============================================================

    getOpleidingStudenten = (opleiding, academiejaar) => {
        //https://api.arteveldehs.be/v1/opleidingen/{opleidingID}/studenten[?academiejaar]

        return new Promise((resolve, reject) => {

            if (!this.opleidingStudenten[opleiding]) this.opleidingStudenten[opleiding] = {}
            if (this.opleidingStudenten[opleiding][academiejaar]) {
                resolve(this.opleidingStudenten[opleiding][academiejaar])
                return
            }

            const url = `${this.applicationPath.ahs_api()}/opleidingen/${opleiding}/studenten?academiejaar=${this.getShortAcademiejaar(academiejaar)}`
            console.log('URL', url)
            axios.get(url)
                .then(response => {
                    console.log('RESP', response)
                    this.opleidingStudenten[opleiding][academiejaar] = orderBy(response.data, (user) => user.Naam.toLowerCase())
                    resolve(this.opleidingStudenten[opleiding][academiejaar])
                })
                .catch(error => {
                    console.error('GET OPLEIDINGEN ERROR', error)
                    reject(error)
                })


        })
    }

    //==============================================================
    //==============================================================
    //                      GEBRUIKERS
    //==============================================================
    //==============================================================

    getGebruiker = (idOrUpn: number | string) => {

        return new Promise((resolve, reject) => {
//https://www.arteveldehogeschool.be/ada/ada-server/ada-ahs/users/danydh@arteveldehs.be/details
            const url = `${this.applicationPath.ahs_api()}/users/${idOrUpn}/details`
            axios.get(url)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('GEBRUIKER ERROR', error)
                    reject(error)
                })
        })
    }

    //==============================================================
    //==============================================================
    //                      DEELTRAJECTEN
    //==============================================================
    //==============================================================

    getDeeltrajecten = (opleidingId: string) => {

        return new Promise((resolve, reject) => {
//https://api.arteveldehs.be/v1/opleidingen/{opleidingID}/deeltrajecten
            const url = `${this.applicationPath.ahs_api()}/opleidingen/${opleidingId}/deeltrajecten`
            axios.get(url)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('DEELTRAJECTEN ERROR', error)
                    reject(error)
                })
        })
    }
}
