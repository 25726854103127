import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {v4 as uuidv4} from 'uuid'
import '../styles/trefwoordenStyles.css'

import {Modal, Dropdown, Form, Header, Divider, Label} from "semantic-ui-react";
import {Trefwoord, Tag, TrefwoordBron, TrefwoordStatus, TrefwoordType} from "../class/Trefwoord";
import {TrefwoordenService} from "../service/TrefwoordenService";

const TrefwoordenViewer = ({consumerKey, trefwoordenString, readonly, onChangeTrefwoorden, locale, candidateInfoContent, locales, labelFunction, allowCandidates, multiple, infoComponentHook}) => {

    //console.log('UUID', uuidv4())

    const [consumer, setConsumer] = useState()
    const [licenseString, setLicenseString] = useState('')
    const [trefwoordenService] = useState(new TrefwoordenService())
    const [isFetching, setIsFetching] = useState(false)
    const [selectedValues, setSelectedValues] = useState(multiple ? [] : null)
    const [defaultOptions, setDefaultOptions] = useState()
    const [trefwoordenOptions, setTrefwoordenOptions] = useState()
    const [delayTimer, setDelayTimer] = useState()
    const [searchQuery, setSearchQuery] = useState()

    const [candidate, setCandidate] = useState()
    const [candidateModalOpen, setCandidateModalOpen] = useState(false)

    const [candidateFormContent, setCandidateFormContent] = useState({})
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)

    useEffect(() => {
        trefwoordenService.getConsumer(consumerKey).then(consumer => {
            setConsumer(consumer)
            if (!consumer) return
            setLicenseString(<Fragment>Licensed by <strong>Arteveldehogeschool</strong> to <strong>{consumer.consumer}</strong></Fragment>)
        })
    }, [consumerKey, trefwoordenService])

    useEffect(() => {
        setCandidateFormContent(locales.reduce((content, locale) => {
            content['tag_' + locale] = ''
            content['context_' + locale] = ''
            return content
        }, {}))
    }, [locales])

    useEffect(() => {

        if (trefwoordenString === null) return

        trefwoordenService.getTrefwoorden(trefwoordenString).then(trefwoorden => {

            const defaultOptions = trefwoorden.map(trefwoord => ({
                text: !multiple ? trefwoord.tags[locale] ? trefwoord.tags[locale].tag : trefwoord.tags.nl.tag : null,
                key: trefwoord.refKey,
                value: trefwoord.refKey, trefwoord,
                description: trefwoord.status
            }))
            setDefaultOptions(defaultOptions)
            setTrefwoordenOptions(defaultOptions)
            setSelectedValues(multiple ? trefwoorden.map(trefwoord => trefwoord.refKey) : trefwoorden.length > 0 ? trefwoorden[0].refKey : '')
        })
    }, [trefwoordenString, trefwoordenService, locale, multiple])

    useEffect(() => {
        if (!searchQuery) return

        const fetchAutoComplete = () => {
            trefwoordenService.getTrefwoordSuggestions(searchQuery, 100).then(res => {
                const trefwoorden = res.trefwoorden

                setIsFetching(false)

                if (!trefwoorden) {
                    setTrefwoordenOptions(defaultOptions)
                    return
                }


                let uniqueRefKeys = []

                const options = defaultOptions.concat(trefwoorden.map(trefwoord => {

                    return {
                        text: trefwoord.tags[locale] ? trefwoord.tags[locale].tag : trefwoord.tags.nl.tag,
                        key: trefwoord.refKey,
                        value: trefwoord.refKey,
                        trefwoord,
                        description: trefwoord.status
                    }
                })).reduce((uniques, option) => {
                    if (uniqueRefKeys.indexOf(option.value) < 0) {
                        uniques.push(option)
                        uniqueRefKeys.push(option.value)
                    }
                    return uniques
                }, []).reduce((options, option) => {

                    //synoniemen
                    options.push(option) //basis optie mag er altijd bij!


                    if (option.trefwoord.tags[locale] && option.trefwoord.tags[locale].synoniemen) {
                        for (const synoniem of option.trefwoord.tags[locale].synoniemen) {
                            const synOption = {
                                text: synoniem.tag + ' >>> ' + option.text,
                                key: synoniem.tag,
                                value: option.value,
                                trefwoord: option.trefwoord,
                                description: 'synoniem'
                            }
                            if (synoniem.tag.toLowerCase().indexOf(searchQuery) > -1) options.push(synOption)
                        }
                    }
                    return options
                }, [])

                setTrefwoordenOptions(options)
            })
        }

        setDelayTimer(setTimeout(fetchAutoComplete, 1000))

    }, [searchQuery, defaultOptions, locale, trefwoordenService])


    const renderTrefwoord = (value) => {

        const trefwoord = value.trefwoord
        const tag = trefwoord.tags[locale] || trefwoord.tags.nl
        let label = tag.tag,
            color = TrefwoordStatus.getTrefwoordStatusColor(trefwoord.status),
            textColor = TrefwoordStatus.getTrefwoordStatusTextColor(trefwoord.status),
            icon = trefwoord.status === TrefwoordStatus.VALIDATED ? 'check' : null

        if (tag.context && tag.context !== '') label += ` [${tag.context}]`
        if (trefwoord.status === 'candidate') label += ` (${trefwoord.status})`

        if (readonly) return label

        return ({
            color,
            textColor,
            content: label,
            icon,
        })
    }

    /*const renderColor = (option) => {
        const trefwoord = option.trefwoord
        switch (trefwoord.status) {
            case TrefwoordStatus.VALIDATED:
                return 'blue'
            case TrefwoordStatus.DEPRECATED:
                return 'red'
            default:
                return null
        }
    }*/

    const onSearchChange = (event, {searchQuery}) => {
        clearTimeout(delayTimer)
        setSearchQuery(searchQuery.toLowerCase())
        setIsFetching(searchQuery !== '')
    }

    const onSelectTrefwoord = (event, {value}) => {

        //we sturen een array van refKeys terug

        //eerst checken of het niet om een candidate gaat!!

        //wanneer niet alle trefwoorden terug te vinden zijn in de options, dan gaat het om een candidate, dus dan niet bewaren!!
        const isCandidate = value.reduce((isCand, trefwoord) => {
            const optionArr = trefwoordenOptions.filter(option => option.value === trefwoord)
            if (optionArr.length === 0) return true
            return isCand
        }, false)

        if (isCandidate) return

        setSelectedValues(value)
        onChangeTrefwoorden && onChangeTrefwoorden(value)
    }

    const onAddCandidate = (event, {value}) => {
        setCandidate(value)
        setCandidateFormContent({['tag_' + locale]: value})
        setCandidateModalOpen(true)
    }

    const onModalClose = () => {
        setCandidateModalOpen(false)
    }

    const onChangeCandidateContent = (event, {name, value}) => {
        const newCContent = {...candidateFormContent, [name]: value}
        setCandidateFormContent(newCContent)
        setSubmitButtonDisabled(locales.reduce((disabled, locale) => {
            if (newCContent['tag_' + locale] === '') return true
            return disabled
        }, false))
    }

    const onSubmitCandidateLocal = () => {

        const refKey = candidateFormContent.tag_nl + '_' + uuidv4()
        const promises = []

        const trefwoord = new Trefwoord()
        trefwoord.bron = TrefwoordBron.CUSTOM
        trefwoord.refKey = refKey
        trefwoord.status = TrefwoordStatus.CANDIDATE
        promises.push(trefwoordenService.createTrefwoord(trefwoord))

        for (const locale of locales) {
            const tag = new Tag()
            tag.context = candidateFormContent['context_' + locale]
            tag.tag = candidateFormContent['tag_' + locale]
            tag.locale = locale
            tag.refKey = refKey
            tag.type = TrefwoordType.TREFWOORD
            promises.push(trefwoordenService.createTag(tag))

        }

        //candidates worden volledig beheerd in deze component!!
        Promise.all(promises).then(results => {
            const values = [...selectedValues, refKey]
            setSelectedValues(values)
            onChangeTrefwoorden && onChangeTrefwoorden(values)

            setCandidateModalOpen(false)
        })

    }

    if (!trefwoordenOptions) return null

    if (!consumer) return <div>No license</div>

    return (
        <div>

            {infoComponentHook && infoComponentHook()}

            {!readonly && <div className="license">{licenseString}</div>}

            {!readonly && <Dropdown options={trefwoordenOptions}
                                    renderLabel={renderTrefwoord}
                                    loading={isFetching}
                                    disabled={isFetching}
                                    fluid
                                    multiple={multiple}
                                    labeled
                                    search
                                    allowAdditions={consumer.allowCandidateCreation && allowCandidates}
                                    additionLabel={<strong>{labelFunction('VOEG_TOE_ALS_TREFWOORD_KANDIDAAT')} </strong>}
                                    onAddItem={onAddCandidate}
                                    onChange={onSelectTrefwoord}
                                    onSearchChange={onSearchChange}
                                    value={selectedValues}
                                    selection/>}

            {readonly && <div>
                {defaultOptions.map(option => <Label style={{marginRight: '10px', backgroundColor: TrefwoordStatus.getTrefwoordStatusColor(option.trefwoord.status), color: TrefwoordStatus.getTrefwoordStatusTextColor(option.trefwoord.status)}} key={option.value} tag>
                    {renderTrefwoord(option)}
                </Label>)}
            </div>}

            <Modal open={candidateModalOpen} onClose={onModalClose} dimmer='inverted'>
                <Modal.Header>{labelFunction('TOEVOEGEN_VAN_TREFWOORD_KANDIDAAT')} <Label>{candidate}</Label></Modal.Header>
                <Modal.Content>

                    {candidateInfoContent && candidateInfoContent(candidate)}

                    <Divider/>

                    <Form>
                        {locales.map(locale => {
                            return <div key={locale}>
                                <Header>{labelFunction('TREFWOORD_') + locale}</Header>
                                <Form.Input name={'tag_' + locale} label={labelFunction('TREFWOORD_' + locale)}
                                            value={candidateFormContent['tag_' + locale]} onChange={onChangeCandidateContent}/>
                                <Form.Input name={'context_' + locale} label={labelFunction('TREFWOORD_CONTEXT_' + locale)}
                                            value={candidateFormContent['context_' + locale]} onChange={onChangeCandidateContent}/>
                                <Divider/>
                            </div>
                        })}

                        <Form.Button disabled={submitButtonDisabled} content={labelFunction('VOEG_TREFWOORD_KANDIDAAT_TOE')}
                                     onClick={onSubmitCandidateLocal}/>
                    </Form>
                </Modal.Content>
                <Modal.Actions></Modal.Actions>
            </Modal>
        </div>
    )
}

export default TrefwoordenViewer

TrefwoordenViewer.propTypes = {
    consumerKey: PropTypes.string.isRequired,
    trefwoordenString: PropTypes.string.isRequired,
    locale: PropTypes.string,
    labelFunction: PropTypes.func,
    onChangeTrefwoorden: PropTypes.func,
    allowCandidates: PropTypes.bool,
    candidateInfoContent: PropTypes.func,
    readonly: PropTypes.bool,
    locales: PropTypes.array.isRequired,
    multiple: PropTypes.bool,
    infoComponentHook: PropTypes.func,
}

TrefwoordenViewer.defaultProps = {
    readonly: false,
    allowCandidates: false,
    locale: 'nl',
    labelFunction: (key) => key,
    locales: ['nl', 'en'],
    multiple: true
}
