import React, {useContext} from 'react'
import {Button} from "semantic-ui-react";
import ServiceContext from '../../../../context/ServiceContext'
import AbstractWizardPage from "./AbstractWizardPage";
import {ComponentIdentifier} from "../../../../component/ComponentIdentifier";


const BwAfronding = () => {
    const serviceContext = useContext(ServiceContext)

    return (
        <AbstractWizardPage nextButtonVisible={false} additionalButtons={[
            <Button color='green' content={serviceContext.localeManager.getString('MAAK_BUNDEL')} onClick={serviceContext.onMaakBundelButtonClick} />
        ]}>
            <ComponentIdentifier displayName='BwAfronding'/>

        </AbstractWizardPage>
    )
}

export default BwAfronding
