import React, {useState, useEffect, useContext} from 'react'
import {Form, Divider, Checkbox, Header} from 'semantic-ui-react'
import ServiceContext from '../../context/ServiceContext'
import {CollectieSettings, InfoViewer} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const CollectieSettingsNotificatie = () => {

const [NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING, SET_NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING] = useState()



    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        SET_NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING(serviceContext.collectieService.getCollectieSetting(serviceContext.collectie, CollectieSettings.NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING) === 1)

    }, [serviceContext.collectieSettings, serviceContext.collectie, serviceContext.collectieService])

    const onCheckboxChange = (event, {name, checked}) => {
        console.log('CBC', name, checked)
        const newSettings = serviceContext.collectieService.setCollectieSetting(serviceContext.collectie, name, checked ? 1 : 0)
        serviceContext.setCollectieSettings({...newSettings})

        //SAVE
        serviceContext.collectieService.updateCollectie(serviceContext.collectie).then(res => {
            console.log('COLLECTIE UPDATED', serviceContext.collectie)

        })
    }

    return (
        <Form>
            <ComponentIdentifier displayName='CollectieSettingsNotificatie'/>

            <Header dividing size='huge'>{serviceContext.localeManager.getString('NOTIFICATIE')}</Header>

            <Form.Field>
                <label>{serviceContext.localeManager.getString(CollectieSettings.NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING)}</label>

                <div><InfoViewer editable={serviceContext.infoEditable}
                                 refId='INFO_NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING'
                                 localeDataService={serviceContext.infoLocaleDataService}
                                 localeManager={serviceContext.localeManager}/></div>

                <Checkbox label={serviceContext.localeManager.getString(CollectieSettings.NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING)}
                          name={CollectieSettings.NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING}
                          checked={NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING}
                          onChange={onCheckboxChange}/>
            </Form.Field>


        </Form>
    )
}

export default CollectieSettingsNotificatie
