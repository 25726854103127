
export class AuthenticationService {

    appName: string
    bearer: string
    validUntil: string
    axiosOptions: any = {}
    token: string = 'LocalHostToken'

    constructor(appName: string) {
        this.appName = appName
    }

    authenticate = () => {
        return new Promise((resolve, reject) => {

            resolve('ok')

           /* const postObject = {} as any
            postObject.appName = this.appName
            postObject.appConsumer = 'danydh@arteveldehs.be'
            postObject.userSecret = ApplicationConstant.USER_SECRET

            console.group()
            console.info('CALL auth', postObject, ApplicationPath.AUTHENTICATION_API)
            axios.post(ApplicationPath.AUTHENTICATION_API, postObject)
                .then(response => {
                    console.info('SERVER RESPONSE', response)


                    if (response.data.error) {
                        console.warn(response.data.error)
                    } else {
                        this.bearer = response.data.bearer
                        this.validUntil = response.data.valid_until
                        this.axiosOptions = {
                            headers: {'Authorization': `Bearer ${this.bearer}`}
                        }
                    }
                    resolve(response.data)

                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });*/
        })
    }

    setToken = (token) => {

        this.token = token
        this.axiosOptions.headers = { "Authorization" : "Bearer " + token}
    }
}