import React, {useState, useContext, Fragment} from 'react'
import {Divider, Grid, Menu} from "semantic-ui-react";
import ServiceContext from "../../context/ServiceContext";
import {SettingsIcon} from "@ahsmediatheek/ada-lib";
import AdaUserProfiel from './settings/AdaUserProfiel'
import {ComponentIdentifier} from "../../component/ComponentIdentifier";


const VIEW_PROFIEL_FOTO = 'profielFoto'

const MENU_GROUPS = [
    {label: 'profiel', items: [VIEW_PROFIEL_FOTO]},
]

const AdaUserSettingsMain = (props) => {

    const [view, setView] = useState(VIEW_PROFIEL_FOTO)
    const [isDirty, setIsDirty] = useState(false)

    const serviceContext = useContext(ServiceContext)

    const handleMenuClick = (event, {name}) => {

        if (isDirty) {
            const ok = window.confirm(serviceContext.localeManager.getString('GEGEVENS_NOG_NIET_BEWAARD'))
            if (ok) {
                setView(name)
                setIsDirty(false)
            }
        } else setView(name)

    }

    const getMenuIcon = (item) => {
        switch (item) {
            /*case VIEW_EMBARGOS:
                return serviceContext.embargo.length > 0 && <EmbargoIcon/>*/
            default:
                return null
        }

    }

    return (
        <Fragment>
            <ComponentIdentifier displayName='AdaUserSettingsMain'/>

            <div>
                <div style={{fontSize: '1.5em', fontWeight: 'bold'}}><SettingsIcon/> {serviceContext.localeManager.getString('INSTELLINGEN')}</div>
                <Divider/>
            </div>


                <Grid columns={2}>
                    <Grid.Column width={4}>

                        <div>
                            <Menu fluid vertical pointing>
                                {MENU_GROUPS.map((group, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <Menu.Item>
                                                <Menu.Header style={{padding: '5px', backgroundColor: '#eff6fa'}}>
                                                    {serviceContext.localeManager.getString(group.label)}
                                                </Menu.Header>
                                                <Menu.Menu>
                                                    {group.items.map((item, index) => {
                                                        return <Menu.Item key={index} name={item} active={view === item} onClick={handleMenuClick}>
                                                            {serviceContext.localeManager.getString(item)}
                                                            {getMenuIcon(item)}

                                                        </Menu.Item>
                                                    })}
                                                </Menu.Menu>
                                            </Menu.Item>
                                        </Fragment>
                                    )
                                })}
                            </Menu>
                        </div>
                    </Grid.Column>

                    <ServiceContext.Provider value={{...serviceContext, setIsDirty}}>
                        <Grid.Column style={{backgroundColor: isDirty ? 'linen' : ''}} width={12}>
                            {view === VIEW_PROFIEL_FOTO && <AdaUserProfiel/>}

                        </Grid.Column>
                    </ServiceContext.Provider>
                </Grid>

            {/*{adaUserSearchModalOpen && adaUserLink &&
            <Modal open={adaUserSearchModalOpen} onClose={onModalClose} dimmer='inverted'>
                <Modal.Header>{serviceContext.localeManager.getString('VOEG_ITEM_TOE').replace('[[item]]', adaUserLink.rol.toLowerCase())}</Modal.Header>
                <Modal.Content scrolling>
                    <AdaUserSearch adaUserLink={adaUserLink} onUserSelect={onAdaUserSelect}/>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onModalClose}>
                        {serviceContext.localeManager.getString('SLUIT')}
                    </Button>
                </Modal.Actions>

            </Modal>}*/}
        </Fragment>
    )
}

export default  AdaUserSettingsMain

AdaUserSettingsMain.propTypes = {

}

AdaUserSettingsMain.defaultProps = {

}
