import React, { useState, useEffect, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from "semantic-ui-react";
import Parser from 'html-react-parser';
import ServiceContext from '../context/ServiceContext';
import { ComponentIdentifier } from "./ComponentIdentifier";
import ENWOpleidingLabels from './ENWOpleidingLabels';

const MetadataContainer = ({ provider, locale, beschrijvingField }) => {

    const [ENWs, setENWs] = useState();
    const [opleidingen, setOpleidingen] = useState();
    const [diensten, setDiensten] = useState();
    const serviceContext = useContext(ServiceContext);

    useEffect(() => {
        if (!provider || !provider.academiejaar) return;

        Promise.all([
            serviceContext.arteveldeApiService.getENWs(provider.academiejaar),
            serviceContext.arteveldeApiService.getOpleidingen(provider.academiejaar),
            serviceContext.arteveldeApiService.getDiensten(provider.academiejaar)
        ]).then(([ENWs, opleidingen, diensten]) => {

            console.log(ENWs, opleidingen, diensten);

            setENWs(ENWs);
            setOpleidingen(opleidingen);
            setDiensten(diensten);
        });
    }, [provider, serviceContext.arteveldeApiService]);

    return (
        <Fragment>
            <ComponentIdentifier displayName='MetadataContainer' />

            <Grid stackable columns={2}>
                <Grid.Column width={12}>
                    <div style={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: '5px' }}>{provider[`subtitel_${locale}`] || provider.subtitel_nl}</div>

                    <Divider />

                    <div style={{whiteSpace: 'pre-line'}}>{Parser(provider[`${beschrijvingField}_${locale}`] || provider[`${beschrijvingField}_nl`])}</div>
                </Grid.Column>
                <Grid.Column width={4} className='aside-metadata'>

                    {(provider.publicatietype && provider.publicatietype !== '') &&
                        <p><strong>{serviceContext.localeManager.getString('publicatietype')}:</strong> {provider['publicatietype']}</p>
                    }

                    {(provider.academiejaar && provider.academiejaar !== '') &&
                        <p><strong>{serviceContext.localeManager.getString('academiejaar')}:</strong> {provider['academiejaar']}</p>
                    }

                    {(ENWs && opleidingen && diensten && provider.opleiding && provider.opleiding !== '') && (
                        <p>
                            <strong>ENW/{serviceContext.localeManager.getString('opleiding')}/{serviceContext.localeManager.getString('dienst')}:</strong><br />
                            {<ENWOpleidingLabels
                                localeManager={serviceContext.localeManager}
                                ENWs={ENWs}
                                opleidingen={opleidingen}
                                diensten={diensten}
                                selectie={provider.opleiding}
                            />}
                        </p>
                    )}

                    {/*<MetadataInset provider={provider}/>*/}
                </Grid.Column>
            </Grid></Fragment>
    );
};

export default MetadataContainer;

MetadataContainer.propTypes = {
    provider: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    beschrijvingField: PropTypes.string
};

MetadataContainer.defaultProps = {
    beschrijvingField: 'beschrijving'
};
