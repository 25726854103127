import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Input, Image, Pagination, Segment, Button} from "semantic-ui-react";
import {InfoViewer} from '../InfoViewer'
import axios from 'axios'
import {ResultContainer} from "../layout/ResultContainer";
import {ApplicationPath} from "../../class/general/ApplicationPath";
import {Spacer} from "../Spacer";

const PIXABAY_SEARCH_URL = 'https://pixabay.com/api/'
const API_KEY = '19378600-ecee2c620a4bee24f0a399eaa'
//const PIXABAY_LICENSE_URL = 'https://pixabay.com/nl/service/terms/#license'
const QUERY_LIMIT = 100
const IMAGES_PER_PAGE = 20

const VIEW_LIST = 'list'
const VIEW_IMAGE = 'image'

const PixabayBrowser = ({infoEditable, localeManager, infoLocaleDataService, defaultSearchString, onSelectImage, applicationPath}) => {

    const [searchString, setSearchString] = useState(defaultSearchString)
    const [images, setImages] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [maxPages] = useState(Math.ceil(500/IMAGES_PER_PAGE))
    const [view, setView] = useState(VIEW_LIST)
    const [currentImage, setCurrentImage] = useState()




    useEffect(() => {

        const getImages = () => {

            const query = searchString.replace(/ /g, '+')
            const requestUrl = PIXABAY_SEARCH_URL + `?key=${API_KEY}&q=${query}&lang=${localeManager.locale}&per_page=${IMAGES_PER_PAGE}&page=${currentPage}`

            axios.get(requestUrl)
                .then(res => {
                    setImages(res.data.hits)
                })
        }

        const to = setTimeout(getImages, 600)

        return () => clearTimeout(to)
    }, [searchString, currentPage, localeManager.locale])

    const onSearchStringChange = (event, {value}) => {
        if (value.length > QUERY_LIMIT) return
        setSearchString(value)
    }

    const onPageChange = (event, {activePage}) => {
        setCurrentPage(activePage)
    }

    const onImageClick = (hit) => {
        setView(VIEW_IMAGE)
        setCurrentImage(hit)
    }

    const onAnnuleer = () => {
        setView(VIEW_LIST)
        setCurrentImage(null)
    }

    const onSelecteer = () => {

        axios
            .get(currentImage.largeImageURL, {
                responseType: 'blob'
            })
            .then(response => {
                const file = new File([response.data], "File name",{ type: response.data.type })
                onSelectImage && onSelectImage(file, currentImage)
                setView(VIEW_LIST)
            })
    }

    return (
        <div>
            <InfoViewer editable={infoEditable}
                        refId={'INFO_PIXABAY'}
                        localeManager={localeManager}
                        localeDataService={infoLocaleDataService}/>

            {view === VIEW_LIST && <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                <Input style={{width: '100%'}} fluid placeholder={localeManager.getString('TYP_HIER_UW_ZOEKTERMEN')} value={searchString} onChange={onSearchStringChange}/>

                <Spacer vertical/>

                <a href="https://pixabay.com/" target='_blank' rel='noopener noreferrer'>
                    <Image size='small' src={applicationPath.logo_pixabay()}/>
                </a>

                <Spacer vertical/>
                <Pagination activePage={currentPage} totalPages={maxPages} onPageChange={onPageChange}/>

                <Spacer vertical/>

                <ResultContainer
                    containerWidth={1400}
                    children={images.map(hit => <Image size='medium' src={hit.previewURL} onClick={() => onImageClick(hit)}/>)}/>

                <Spacer vertical/>

                <Pagination activePage={currentPage} totalPages={maxPages} onPageChange={onPageChange}/>
            </div>}

            {view === VIEW_IMAGE && currentImage &&
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {/*<img width='80%' src={currentImage.webformatURL}/>*/}
                <Image size='massive' src={currentImage.webformatURL}/>

                <Segment>
                    <Button onClick={onAnnuleer}>{localeManager.getString('ANNULEER')}</Button>
                    <Button onClick={onSelecteer} color='green'>{localeManager.getString('SELECTEER')}</Button>
                </Segment>
            </div>}
        </div>
    )
}

export {PixabayBrowser}

PixabayBrowser.propTypes = {
    infoLocaleDataService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    infoEditable: PropTypes.bool,
    defaultSearchString: PropTypes.string,
    onSelectImage: PropTypes.func
}

PixabayBrowser.defaultProps = {
    infoEditable: false
}
