import React, {useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Link, useLocation} from "react-router-dom"
import ServiceContext from '../context/ServiceContext'

import {Dropdown, Image, Menu, Button, Grid, Label} from 'semantic-ui-react'
import {Spacer, TaalKiezer, ApplicationPath} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "./ComponentIdentifier";


const VIEW_HOME = '/home'
const VIEW_ADMIN = '/admin'
const VIEW_MIJN_STARTPAGINA = '/mijnStartpagina'
const METADATA_MANAGER = '/metadata'

const ApplicationHeader = (props) => {

    const location = useLocation()
    const serviceContext = useContext(ServiceContext)

    const headerText = props.localeManager.getString('ARTEVELDE_DIGITAAL_ARCHIEF')

    /*useEffect(() => {
        if (!localeManager || !privilegeManager) return
        const mainMenuOptions = [
            {key: 'home', value: 'home', text: localeManager.getString('HOME'), active: view === VIEW_HOME, name: localeManager.getString('HOME')},
            {key: '2', value: 'o2', text: 'menu item 2', active: false, name: 'menu item 2'}
        ]

        if (privilegeManager.canEditInfo()) {
            userMenuOptions.push(
            )
        }

        setMainMenuOptions(mainMenuOptions)
        setUserMenuOptions(userMenuOptions)
    }, [localeManager, view, privilegeManager])*/

    const onLogoClickHandler = () => {
        props.onLogoClick && props.onLogoClick()
    }

    const userDropdown = <Dropdown
        trigger={props.privilegeManager.adaUser && <Image avatar src={props.privilegeManager.adaUser.profielfoto}/>}
        pointing='top right'
        /*onClick={props.onUserMenuClick}*/
        icon={null}>

        <Dropdown.Menu>
            {/*<Dropdown.Item text={props.localeManager.getString('NIEUWE_COLLECTIE')} onClick={() => props.onUserMenuClick('nieuweCollectie')}/>*/}
            <Dropdown.Item text={props.localeManager.getString('UITLOGGEN')} onClick={props.onLogoutClick}/>

            {props.privilegeManager.canEditInfo() &&
            <Dropdown.Item text='Info edit active' onClick={() => props.onUserMenuClick('canEditInfo')}
                           icon={props.infoEditable ? 'check' : null}/>
            }
            {props.privilegeManager.isSuperAdmin() &&
            <Dropdown.Item text='Show component names' onClick={() => props.onUserMenuClick('componentIdentifierActive')}
                           icon={props.componentIdentifierActive ? 'check' : null}/>
            }
        </Dropdown.Menu>
    </Dropdown>


    /*const menuDropdown = () => {
        if (!props.showMainMenu) return null
        return (<Dropdown
            trigger={<Icon size='big' name='sidebar' link/>}
            pointing='top right'
            icon={null}>
            <Dropdown.Menu>
                <Link to='/home'>
                    <Dropdown.Item text={props.localeManager.getString('HOME')}
                                   active={location.pathname === VIEW_HOME}
                                   onClick={() => props.onMainMenuClick(VIEW_HOME)}/>
                </Link>

                {props.privilegeManager && props.privilegeManager.loggedIn() &&
                <Link to='/mijnStartpagina'>
                    <Dropdown.Item text={props.localeManager.getString('MIJN_STARTPAGINA')}
                                   active={location.pathname === VIEW_MIJN_STARTPAGINA}
                                   onClick={() => props.onMainMenuClick(VIEW_MIJN_STARTPAGINA)}/>
                </Link>}

                {props.privilegeManager && props.privilegeManager.isSuperAdmin() &&
                <Link to='/admin'>
                    <Dropdown.Item text={props.localeManager.getString('ADMIN')}
                                   active={location.pathname === VIEW_ADMIN}
                                   onClick={() => props.onMainMenuClick(VIEW_ADMIN)}/>
                </Link>}
            </Dropdown.Menu>
        </Dropdown>)
    }*/

    const menu = () => {
        if (!props.showMainMenu) return null

        return <Menu secondary pointing>
            <Link to='/home'>
                <Menu.Item name={VIEW_HOME} as='div'
                           active={location.pathname === VIEW_HOME}
                           onClick={() => props.onMainMenuClick(VIEW_HOME)}>{props.localeManager.getString('HOME')}</Menu.Item>
            </Link>

            {props.privilegeManager && props.privilegeManager.loggedIn() &&
            <Link to='/mijnStartpagina'>
                <Menu.Item name={VIEW_MIJN_STARTPAGINA} as='div'
                           active={location.pathname === VIEW_MIJN_STARTPAGINA}
                           onClick={() => props.onMainMenuClick(VIEW_MIJN_STARTPAGINA)}>{props.localeManager.getString('MIJN_STARTPAGINA')}</Menu.Item>
            </Link>}

            {props.privilegeManager && props.privilegeManager.isSuperAdmin() &&
            <Link to='/admin'>
                <Menu.Item name={VIEW_ADMIN} as='div'
                           active={location.pathname === VIEW_ADMIN}
                           onClick={() => props.onMainMenuClick(VIEW_ADMIN)}>{props.localeManager.getString('ADMIN')}</Menu.Item>
            </Link>}
            {console.log(props.privilegeManager.isAdmin())}
            {/**props.privilegeManager && props.privilegeManager.isAdmin() &&
            <Link to='/metadata'>
                <Menu.Item name={METADATA_MANAGER} as='div'
                           active={location.pathname === METADATA_MANAGER}
                           onClick={() => props.onMainMenuClick(METADATA_MANAGER)}>{props.localeManager.getString('METADATA_MANAGER')}</Menu.Item>
            </Link>**/}
        </Menu>
    }

    //console.log('RENDER', props.privilegeManager, props.privilegeManager.adminEnabled, props.privilegeManager.adminEnabled === true)

    return (
        <Fragment>
            <ComponentIdentifier displayName='ApplicationHeader'/>

            <Grid columns='equal' style={{padding: '10px'}}>

                <Grid.Column>
                    <Image as='a' src={serviceContext.applicationPath.logo_ahs()} onClick={onLogoClickHandler}/>
                </Grid.Column>

                <Grid.Column width={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h2>{headerText}</h2>
                        {serviceContext.privilegeManager.isAdmin(null, false) && <div>
                            <Label>{serviceContext.privilegeManager.adminRole()}</Label>
                            <Label as='a'
                                   color={serviceContext.adminEnabled ? 'blue' : null}
                                   onClick={() => props.onUserMenuClick('toggleAdminRole')}>
                                {serviceContext.adminEnabled ? 'Admin enabled' : 'Admin disabled'}
                            </Label>
                        </div>}
                    </div>
                </Grid.Column>

                <Grid.Column style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>

                        <TaalKiezer locale={props.locale} onChangeLocale={props.onChangeLocale}/>

                        <div style={{display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end'}}>
                            {menu()}

                            <Spacer distance={20}/>

                            {props.privilegeManager.adaUser && userDropdown}

                            {!props.privilegeManager.adaUser && <Button size='tiny' onClick={props.onLoginClick}>Login</Button>}
                        </div>
                    </div>
                </Grid.Column>

            </Grid>

            {/* <Responsive {...{minWidth: Responsive.onlyMobile.minWidth, maxWidth: Responsive.onlyTablet.maxWidth}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div className='application-header'>
                        <Image as='a' src={serviceContext.applicationPath.logo_ahs()} size='small' onClick={onLogoClickHandler}/>

                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>


                            <TaalKiezer locale={props.locale} onChangeLocale={props.onChangeLocale}/>

                            <div style={{display: 'flex', alignItems: 'center'}}>

                                {menuDropdown()}

                                <Spacer distance={10}/>

                                {props.privilegeManager.adaUser && userDropdown}

                                {!props.privilegeManager.adaUser && <Button size='tiny' onClick={props.onLoginClick}>Login</Button>}
                            </div>
                        </div>

                    </div>

                    <h3 style={{alignSelf: 'center', margin: 0}}>{headerText}</h3>

                </div>
            </Responsive>*/}

        </Fragment>
    )
}

export default ApplicationHeader

ApplicationHeader.propTypes = {
    view: PropTypes.string,
    localeManager: PropTypes.object,
    onMainMenuClick: PropTypes.func,
    onUserMenuClick: PropTypes.func,
    onLogoClick: PropTypes.func,
    onLoginClick: PropTypes.func,
    onLogoutClick: PropTypes.func,
    privilegeManager: PropTypes.object,
    locale: PropTypes.string,
    onChangeLocale: PropTypes.func,
    showMainMenu: PropTypes.bool,
    infoEditable: PropTypes.bool,
}

ApplicationHeader.defaultProps = {
    headerText: '',
    showMainMenu: false
}
