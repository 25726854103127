export class CollectieSettings {

    static readonly TREFRWOORDEN_LIJSTEN: string = 'trefwoordenLijsten'
    static readonly GROEPSBUNDELS_TOEGESTAAN: string = 'GROEPSBUNDELS_TOEGESTAAN'
    static readonly INZENDERS_MOGEN_BUNDELS_AANMAKEN: string = 'INZENDERS_MOGEN_BUNDELS_AANMAKEN'
    static readonly INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN: string = 'INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN'
    static readonly INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN: string = 'INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN'
    static readonly INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN: string = 'INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN'
    static readonly INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN: string = 'INZENDERS_KUNNEN_TREFWOORD_KANDIDATEN_MAKEN'
    static readonly BUNDEL_AUTOMATISCH_PUBLICEREN: string = 'BUNDEL_AUTOMATISCH_PUBLICEREN'
    static readonly COLLECTIE_VERPLICHTE_METADATA: string = 'COLLECTIE_VERPLICHTE_METADATA'
    static readonly COLLECTIE_AUTOMATISCH_SLUITEN: string = 'COLLECTIE_AUTOMATISCH_SLUITEN'
    static readonly COLLECTIE_AFSLUITEN_NA: string = 'COLLECTIE_AFSLUITEN_NA'
    static readonly COLLECTIE_AFSLUITEN_OP: string = 'COLLECTIE_AFSLUITEN_OP'
    static readonly GEBRUIK_TREFWOORDENLIJST: string = 'GEBRUIK_TREFWOORDENLIJST'
    static readonly TREFWOORDENLIJST: string = 'TREFWOORDENLIJST'
    static readonly VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN: string = 'VERPLICHT_MINIMUUM_AANTAL_TREFWOORDEN'
    static readonly MINIMUUM_AANTAL_TREFWOORDEN: string = 'MINIMUUM_AANTAL_TREFWOORDEN'
    static readonly BUNDEL_VALIDATIE_ACTIEF: string = 'BUNDEL_VALIDATIE_ACTIEF'

    static readonly NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING: string = 'NOTIFICEER_COLLECTIE_EIGENAAR_BIJ_STATUS_WIJZIGING'

   /* static readonly createSetting = () => {
        return {
            trefwoordenLijsten: [],
            GROEPSBUNDELS_TOEGESTAAN: 0,
            INZENDERS_MOGEN_BUNDELS_AANMAKEN: 0,
            INZENDERS_KUNNEN_EIGEN_VALIDATOR_KIEZEN: 0,
            INZENDERS_KUNNEN_BUNDEL_EMBARGO_MAKEN: 0,
            INZENDERS_KUNNEN_ITEM_EMBARGO_MAKEN: 0,
            BUNDEL_AUTOMATISCH_PUBLICEREN: 0,
            COLLECTIE_VERPLICHTE_METADATA: 0,
            COLLECTIE_AUTOMATISCH_SLUITEN: 0,
            COLLECTIE_AFSLUITEN_NA: 0,
            COLLECTIE_AFSLUITEN_OP: 0,
        }
    }*/


    /*get settings(){
        const sett = this.collectie.settings
        if (!sett) return new
    }*/
}
