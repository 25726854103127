import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'

import {Step, Segment} from 'semantic-ui-react'
import ServiceContext from '../../../context/ServiceContext'
import { Bundel } from "@ahsmediatheek/ada-lib";
import BwWelkom from "./pages/BwWelkom";
import BwNaam from "./pages/BwNaam";
import BwAfronding from "./pages/BwAfronding";
import {ComponentIdentifier} from "../../../component/ComponentIdentifier";

const STEP_WELKOM = 'welkom'
const STEP_NAAM = 'naam'
const STEP_AFRONDING = 'afronding'

const STEPS = [
    {name: STEP_WELKOM, title: 'WELKOM', infoKey: 'NB_WIZARD_CONTENT_WELKOM'},
    {name: STEP_NAAM, title: 'TITEL_EN_BESCHRIJVING', infoKey: 'NB_WIZARD_CONTENT_NAAM'},
    {name: STEP_AFRONDING, title: 'AFRONDING', infoKey: 'NB_WIZARD_CONTENT_AFRONDING'},
]

const NieuweBundelWizard = ({onAddBundel}) => {

    const serviceContext = useContext(ServiceContext)

    const [activeStep, setActiveStep] = useState(STEPS[0])
    const [wizardBundel, setWizardBundel] = useState()
    const [opleidingen, setOpleidingen] = useState()

    useEffect(() => {
        if (!wizardBundel) {
            const bundel = new Bundel()
            bundel.aangemaakt_door = serviceContext.privilegeManager.adaUser.email
            bundel.academiejaar = serviceContext.arteveldeApiService.getCurrentAcademiejaar()
            bundel.defaultReferentiebeeld = serviceContext.applicationPath.geen_referentiebeeld()
            
            setWizardBundel(bundel)
        }
    }, [serviceContext.arteveldeApiService, wizardBundel])

    useEffect(() => {
        if (!wizardBundel) return

        serviceContext.arteveldeApiService.getOpleidingen(wizardBundel.academiejaar).then(res => {
            setOpleidingen(res)
        })

    }, [wizardBundel, serviceContext.arteveldeApiService])

    const onStepClick = (event, {name}) => {
        const as = STEPS.reduce((acc, step) => step.name === name ? step : acc)
        setActiveStep(as)
    }

    const onNavigationClick = (direction) => {
        const currIndex = STEPS.indexOf(activeStep)
        setActiveStep(STEPS[currIndex + direction])
    }

    const onChangeProp = (event, {name, value}) => {
        //console.log('CHANGE', name, value)
        
        setWizardBundel({...wizardBundel, [name]: value})
    }

    const onMaakBundelButtonClick = () => {
        onAddBundel(wizardBundel)
    }

    return (
        <ServiceContext.Provider value={{
            ...serviceContext,
            wizardBundel,
            opleidingen,
            STEPS,
            activeStep,
            onNavigationClick,
            onChangeProp,
            onMaakBundelButtonClick,
        }}>
            <Fragment>
                <ComponentIdentifier displayName='NieuweBundelWizard'/>

                <div className='main-content'>
                    <Step.Group size='mini' attached='top'>

                        {STEPS.map((step, index) => {
                            return <Fragment key={index}><Step name={step.name} active={activeStep.name === step.name} onClick={onStepClick}>
                                <Step.Content>
                                    <Step.Title>{serviceContext.localeManager.getString(step.title)}</Step.Title>
                                    {/*{step.titleKey && <Step.Description>
                                <InfoViewer localeManager={serviceContext.localeManager} localeDataService={serviceContext.infoLocaleDataService}
                                            editable={serviceContext.infoEditable} refId={'NB_WIZARD_COLLECTIE_NAAM'}/>
                            </Step.Description>}*/}
                                </Step.Content>
                            </Step></Fragment>
                        })}


                    </Step.Group>

                    <Segment attached>
                        {activeStep.name === STEP_WELKOM && <BwWelkom/>}
                        {activeStep.name === STEP_NAAM && <BwNaam/>}
                        {activeStep.name === STEP_AFRONDING && <BwAfronding/>}
                    </Segment>
                </div>
            </Fragment>
        </ServiceContext.Provider>
    )
}

export default NieuweBundelWizard

NieuweBundelWizard.propTypes = {
    onAddBundel: PropTypes.func
}

NieuweBundelWizard.defaultProps = {}
