import React, {useState, useEffect, useCallback, useContext} from 'react'
import PropTypes from 'prop-types'
import {v4 as uuidv4} from 'uuid'
import {Progress} from "semantic-ui-react";
import Dropzone from "react-dropzone";
import {ActiviteitType, ApplicationPath, InfoViewer, Mimetype} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../context/ServiceContext'
import {ComponentIdentifier} from "./ComponentIdentifier";

const AdaUserBannerDropzone = ({adaUser}) => {

    const [imageUrl, setImageUrl] = useState()
    const [uploading, setUploading] = useState(false)
    const [progressPercentage, setProgressPercentage] = useState(0)

    const serviceContext = useContext(ServiceContext)

    const updateBanner = useCallback(() => {
        setImageUrl(`${serviceContext.applicationPath.ada_user_banners()}${adaUser.adaUserBanner}.jpg?d=${new Date().getTime()}`)
    }, [adaUser.adaUserBanner])

    useEffect(() => {
        if (!adaUser) return
        if (!adaUser.adaUserBanner || adaUser.adaUserBanner === ''){
            setImageUrl(serviceContext.applicationPath.ada_user_banner_default())
            return
        }

       updateBanner()

    }, [adaUser, updateBanner])

    const onDrop = (addedFiles, rFiles) => {

        if(addedFiles.length > 1){
            window.alert(serviceContext.localeManager.getString('GEEN_MEERDERE_FILES_TOEGESTAAN'))
            return
        }
        const file = addedFiles[0]

        if ([Mimetype.IMAGE_JPEG, Mimetype.IMAGE_PNG].indexOf(file.type) === -1){
            window.alert(serviceContext.localeManager.getString('ENKEL_JPG_PNG_TOEGESTAAN'))
            return
        }

        setProgressPercentage(0)
        setUploading(true)

        const uuid = adaUser.adaUserBanner && adaUser.adaUserBanner !== '' ? adaUser.adaUserBanner : uuidv4()
        adaUser.adaUserBanner = uuid

        serviceContext.adaUserService.uploadAdaUserBanner(file, uuid, progressCallback).then(res => {
            setUploading(false)
            serviceContext.activiteitService.createActiviteit(serviceContext.privilegeManager.adaUser, ActiviteitType.ADA_USER_BANNER_VERVANGEN, file.path)
            serviceContext.onAdaUserBannerUploaded && serviceContext.onAdaUserBannerUploaded(adaUser, updateBanner)
        })
    }

    const progressCallback = (progressEvent) => {
        setProgressPercentage(Math.round(progressEvent.loaded / progressEvent.total * 1000)/10)
    }

    return (
        <div>
            <ComponentIdentifier displayName='AdaUserBannerDropzone'/>

            <div><InfoViewer refId='INFO_REFERENTIEBEELD'
                             editable={serviceContext.infoEditable}
                             localeManager={serviceContext.localeManager}
                             localeDataService={serviceContext.infoLocaleDataService}/></div>


            <Dropzone onDrop={onDrop}>
                {({getRootProps, getInputProps}) => (
                    <div className='dropzone' style={{maxWidth: '400px'}} {...getRootProps()}>
                        <input {...getInputProps()} accept="image/png, image/jpeg"/>

                        {uploading && <Progress style={{width: '100%'}} percent={progressPercentage} autoSuccess progress size='small'/>}

                        <img src={imageUrl} alt='Ada user banner' width='100%'/>
                    </div>
                )}
            </Dropzone>


        </div>
    )
}

export default AdaUserBannerDropzone

AdaUserBannerDropzone.propTypes = {
    adaUser: PropTypes.object.isRequired
}

AdaUserBannerDropzone.defaultProps = {}
