import React, {useState, useContext, useEffect, Fragment} from 'react'
import {Grid, Label, Menu} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'
import {AdaUserLink, AdaUserLinkRef, EmbargoIcon, DangerIcon, AdminIcon, ToegangsticketTargetType, ToegangsticketStatus} from '@ahsmediatheek/ada-lib'
import BundelSettingsGegevens from "./settingsBundle/BundelSettingsGegevens"
import BundelSettingsGebruikers from "./settingsBundle/BundelSettingsGebruikers";
import AdaUserSearch from "../component/AdaUserSearch";
import BundelSettingsEmbargo from "./settingsBundle/BundelSettingsEmbargo";
import BundelSettingsAdmin from "./settingsBundle/BundelSettingsAdmin";
import BundelSettingsReferentiebeeld from "./settingsBundle/BundelSettingsReferentiebeeld";
import BundelSettingsMetadata from "./settingsBundle/BundelSettingsMetadata";
import BundelActies from "../component/BundelActies";
import BundelSettingsToegangstickets from "./settingsBundle/BundelSettingsToegangstickets";
import CollectieSettingsToegangstickets from "./settingsCollectie/CollectieSettingsToegangstickets";
import BundelSettingsInstellingen from "./settingsBundle/BundelSettingsInstellingen";
import {ComponentIdentifier} from "../component/ComponentIdentifier";

const VIEW_GEGEVENS = 'titel_en_beschrijving'
const VIEW_METADATA = 'metadata'
const VIEW_INSTELLINGEN = 'instellingen'
const VIEW_GEBRUIKERS = 'gebruikers'
//const VIEW_VALIDATIEMODULES = 'validatiemodules'
//const VIEW_BUNDELCONTROLE = 'bundelcontrole'
const VIEW_EMBARGO = 'embargo'
//const VIEW_OPEN_ACCESS = 'openAccess'
const VIEW_TOEGANGSTICKETS = 'toegangstickets'
const VIEW_ADMIN = 'admin'
const VIEW_REFERENTIEBEELD = 'referentiebeeld'


const MENU_GROUPS = [
    {label: 'algemeen', items: [VIEW_GEGEVENS, VIEW_METADATA, VIEW_INSTELLINGEN, VIEW_REFERENTIEBEELD]},
    {label: 'gebruikers', items: [VIEW_GEBRUIKERS]},
    /*{label: 'validatie', items: [VIEW_VALIDATIEMODULES, VIEW_BUNDELCONTROLE]},*/
    {label: 'toegang', items: [VIEW_EMBARGO, VIEW_TOEGANGSTICKETS/*, VIEW_OPEN_ACCESS, */]},
    {label: 'Admin', items: [VIEW_ADMIN]}
]

const BundelDetailSettingsView = (props) => {

    const [view, setView] = useState(VIEW_GEGEVENS)
    const [isDirty, setIsDirty] = useState(false)
    const [adaUserSearchModalOpen, setAdaUserSearchModalOpen] = useState(false)
    //const [adaUserLink, setAdaUserLink] = useState()
    const [bundelSettings, setBundelSettings] = useState()

    const [adaUserSearchSelectedUsers, setAdaUserSearchSelectedUsers] = useState([])
    const [rol, setRol] = useState()
    const [toegangstickets, setToegangstickets] = useState([])
    const [toegangsticketGeblokkeerd, setToegangsticketGeblokkeerd] = useState(false)


    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        const settings = serviceContext.bundelService.getBundelSettings(serviceContext.bundel)
        setBundelSettings(settings)
    }, [serviceContext.bundel, serviceContext.bundelService])

    useEffect(() => {
        serviceContext.toegangsticketService.getToegangstickets(serviceContext.bundel.uuid).then(res => {
            setToegangstickets(res)
        })
    }, [serviceContext.bundel])

    useEffect(() => {
        setToegangsticketGeblokkeerd(toegangstickets.reduce((geblokkeerd, tt) => {
            if (tt.status === ToegangsticketStatus.GEBLOKKEERD) return true
            return geblokkeerd
        }, false))
    }, [toegangstickets])

    const handleMenuClick = (event, {name}) => {

        if (isDirty) {
            const ok = window.confirm(serviceContext.localeManager.getString('GEGEVENS_NOG_NIET_BEWAARD'))
            if (ok) {
                setView(name)
                setIsDirty(false)
            }
        } else setView(name)

    }


    const onAddAdaUsers = (rol) => {
        setAdaUserSearchSelectedUsers(serviceContext.bundelAdaUserLinks
            .filter(adaUserLink => adaUserLink.rol === rol)
            .map(adaUserLink => serviceContext.collectieService.adaUserService.adaUserLookup[adaUserLink.adaUser]))

        setRol(rol)
        setAdaUserSearchModalOpen(true)
    }

    /*const onAddAdaUsers = (rol) => {
        console.log('ADDA ADA USER', rol)
        const adaUserLink = new AdaUserLink()
        adaUserLink.rol = rol
        adaUserLink.ref = AdaUserLinkRef.BUNDEL
        adaUserLink.refId = serviceContext.bundel.id
        setAdaUserLink(adaUserLink)
        setAdaUserSearchModalOpen(true)
    }*/

    const onAdaUsersSelect = (adaUsers) => {
        console.log('SELECTED', adaUsers)
        setAdaUserSearchModalOpen(false)

        const promises = []
        for (const adaUser of adaUsers) {
            const adaUserLink = new AdaUserLink()
            adaUserLink.rol = rol
            adaUserLink.ref = AdaUserLinkRef.BUNDEL
            adaUserLink.refId = serviceContext.bundel.id
            adaUserLink.adaUser = adaUser.id

            promises.push(serviceContext.adaUserService.createAdaUserLink(adaUserLink))
        }

        Promise.all(promises).then(() => {
            serviceContext.updateAdaUserLinks()
        })

    }

    const getMenuIcon = (item) => {
        switch (item) {
            case VIEW_EMBARGO:
                return serviceContext.bundel.embargo && serviceContext.bundel.embargo !== '' && <EmbargoIcon/>
            case VIEW_ADMIN:
                return <AdminIcon/>
            case VIEW_TOEGANGSTICKETS:
                return toegangstickets && toegangstickets.length > 0 &&
                    <Label circular size='tiny'>{toegangstickets.length}{toegangsticketGeblokkeerd && <Fragment>&nbsp;&nbsp;<DangerIcon/></Fragment>}</Label>
            default:
                return null
        }

    }

    const deleteTicket = (tt) => {
        serviceContext.toegangsticketService.deleteToegangsticket(tt).then(res => {
            setToegangstickets(toegangstickets.filter(toeti => toeti.id !== tt.id))
        })
    }

    const maakTicket = (tt) => {
        tt.consumer_type = ToegangsticketTargetType.BUNDEL
        tt.consumer_uuid = serviceContext.bundel.uuid

        serviceContext.toegangsticketService.createToegangsticket(tt).then(res => {
            setToegangstickets([res, ...toegangstickets])
        })
    }

    const heropenTicket = (ticket) => {
        ticket.status = ToegangsticketStatus.OPEN

        serviceContext.toegangsticketService.updateToegangsticket(ticket).then(res => {
            setToegangstickets([...toegangstickets])
        })
    }

    return (
        <Fragment>
            <ComponentIdentifier displayName='BundelDetailSettingsView'/>

            <BundelActies bundel={serviceContext.bundel}/>

            <Grid columns={2}>
                <Grid.Column width={4}>

                    <div>
                        <Menu fluid vertical pointing>
                            {MENU_GROUPS.map((group, index) => {

                                if (group.access && group.access === 'admin' && !serviceContext.privilegeManager.isAdmin()) return null

                                return (
                                    <Fragment key={index}>
                                        <Menu.Item>
                                            <Menu.Header style={{padding: '5px', backgroundColor: '#eff6fa'}}>
                                                {serviceContext.localeManager.getString(group.label)}
                                            </Menu.Header>
                                            <Menu.Menu>
                                                {group.items.map((item, index) => {
                                                    return <Menu.Item key={index} name={item} active={view === item} onClick={handleMenuClick}>
                                                        {serviceContext.localeManager.getString(item)}
                                                        {getMenuIcon(item)}

                                                    </Menu.Item>
                                                })}
                                            </Menu.Menu>
                                        </Menu.Item>
                                    </Fragment>
                                )
                            })}
                        </Menu>
                    </div>
                </Grid.Column>

                <ServiceContext.Provider value={{...serviceContext, setIsDirty, bundelSettings, setBundelSettings}}>
                    <Grid.Column style={{backgroundColor: isDirty ? 'linen' : ''}} width={12}>

                        {view === VIEW_GEGEVENS && <BundelSettingsGegevens/>}
                        {view === VIEW_INSTELLINGEN && <BundelSettingsInstellingen/>}
                        {view === VIEW_METADATA && <BundelSettingsMetadata/>}
                        {view === VIEW_REFERENTIEBEELD && <BundelSettingsReferentiebeeld/>}
                        {view === VIEW_GEBRUIKERS && <BundelSettingsGebruikers onAddAdaUsers={onAddAdaUsers}/>}
                        {view === VIEW_EMBARGO && <BundelSettingsEmbargo/>}
                        {view === VIEW_TOEGANGSTICKETS && <CollectieSettingsToegangstickets
                            toegangstickets={toegangstickets}
                            deleteTicket={deleteTicket}
                            heropenTicket={heropenTicket}
                            maakTicket={maakTicket}/>}


                        {view === VIEW_ADMIN && <BundelSettingsAdmin/>}

                    </Grid.Column>
                </ServiceContext.Provider>
            </Grid>

            {adaUserSearchModalOpen &&
            <AdaUserSearch modalOpen={adaUserSearchModalOpen} rol={rol}
                           adaUsers={adaUserSearchSelectedUsers}
                           onUsersSelect={onAdaUsersSelect}
                           academiejaar={serviceContext.bundel.academiejaar}
                           opleiding={serviceContext.bundel.opleiding}/>}
        </Fragment>
    )
}

export default BundelDetailSettingsView
