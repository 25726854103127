import React, {useState, useEffect, useContext, useCallback, Fragment} from 'react'
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types'
import axios from "axios";
import {Progress, Icon} from "semantic-ui-react";
import ServiceContext from '../../context/ServiceContext'

import DropzoneComponent from 'react-dropzone-component';
import 'react-dropzone-js/styles/filepicker.css'
import './uploadStyles.css'
import {ComponentIdentifier} from "../ComponentIdentifier";
import {ContentViewer, DownloadIcon, ReferentieitemIcon, TeKoopItemIcon, TrashIcon, ViewIcon} from "@ahsmediatheek/ada-lib";

const DropzoneBundel = ({uploadConfig, bundel, children, root, onUploadComplete, uploadEnabled}) => {

    const [uploading, setUploading] = useState(false)
    const [progressPercentage, setProgressPercentage] = useState(0)
    const serviceContext = useContext(ServiceContext)


    const componentConfig = {
        showFiletypeIcon: true,
        removeFile: true,
        postUrl: `${uploadConfig.url}${bundel.uuid}/`
    }

    const djsConfig = {

        previewTemplate: ReactDOMServer.renderToStaticMarkup(
            <div className="dz-preview dz-file-preview" style={{margin: 0}}>

                <div className='dropzone-item selected'>
                    <div className="dz-image"><img data-dz-thumbnail/></div>

                    <div className="dz-details">
                        <div className="dz-size"><span data-dz-size></span></div>
                        <div className="dz-filename"><span data-dz-name></span></div>
                    </div>
                    <div className="dz-progress">
                        <span className="dz-upload" data-dz-uploadprogress></span>
                    </div>
                </div>
            </div>
        ),
        renameFile: (file) => {
            let newName
            if (file.fullPath) {
                newName = file.fullPath.replace(/[\.,\/,\s]/g, '_')
            } else {
                newName = file.name.replace(/[\.,\/,\s]/g, '_')
            }
            //console.log('RENAMING', file.name, newName)
            return newName
        },
        clickable: uploadEnabled,
        accept: (file, done) => {
            if (!uploadEnabled) done('Geen upload rechten')
            done()
        },
        params(files, xhr, chunk) {
            //console.log('PARAMS', chunk)
            if (chunk) {
                return {
                    dzuuid: chunk.file.upload.uuid,
                    dzchunkindex: chunk.index,
                    dztotalfilesize: chunk.file.size,
                    dzchunksize: this.options.chunkSize,
                    dztotalchunkcount: chunk.file.upload.totalChunkCount,
                    dzchunkbyteoffset: chunk.index * this.options.chunkSize,
                };
            }
        },
        method: 'post',
        maxFilesize: eval(uploadConfig.maxFileSize),
        autoProcessQueue: true,
        chunking: true,
        forceChunking: false,
        chunkSize: eval(uploadConfig.chunkSize),
        timeout: eval(uploadConfig.timeout),
        parallelChunkUploads: true,
        parallelUploads: uploadConfig.parallelUploads,
        retryChunks: uploadConfig.retryChunks,
        retryChunksLimit: uploadConfig.retryChunksLimit,
    }

    const eventHandlers = {
        init: (dropzone) => {
            //console.log('INIT', dropzone, uploadEnabled)
        },
        totaluploadprogress: (percent, totalBytes, bytesLoaded) => {
            percent && setProgressPercentage(Math.round(percent))
        },
        queuecomplete: (props) => {
            setUploading(false)
            onUploadComplete()
        },
        sending: (file, xhr, data) => {
            if (!uploading) setUploading(true)

            let dataObject = {}
            let fileUuid = (file.name).replace(/[\.,\/,\s]/g, '_')
            if (file.fullPath) {
                fileUuid = (file.fullPath).replace(/[\.,\/,\s]/g, '_')
                dataObject[fileUuid] = {}
                dataObject[fileUuid].fullPath = file.fullPath
            } else {
                dataObject[fileUuid] = {}
                dataObject[fileUuid].fullPath = ""
                data.append("fullPath", "");
            }
            dataObject[fileUuid].filename = file.name
            dataObject[fileUuid].filetype = file.type
            //data.append("isPartOfMultiBundelUpload",1)
            data.append('root', root)
            data.append([fileUuid], JSON.stringify(dataObject[fileUuid]))

            console.log('SENDING', file, xhr, data, fileUuid, root, djsConfig)
            console.log('fullPath', data.get('fullPath'))
            console.log('fileUuid', data.get(fileUuid))
        },
        chunksUploaded: (file, done) => {
            console.log('CUNKS UPLOADED', file)
            done()
        },
        success: (file, response) => {
            console.log('SUCCESS!', file, response)
            if (file.upload.chunked) {
                console.log('Removing temp files', uploadConfig.deleteTempFileUrl)
                axios.delete(uploadConfig.deleteTempFileUrl + file.upload.uuid + '/')
            }
        },
        error: (file, msg) => {
            console.log('ERROR', msg)
            window.alert(`Er is iets misgegaan (${msg})`)
            if (file && file.upload && file.upload.chunked) {
                console.log('Removing temp files', uploadConfig.deleteTempFileUrl)
                axios.delete(uploadConfig.deleteTempFileUrl + file.upload.uuid + '/')
            }
            s
            setUploading(false)
        }
    }

    return (
        <Fragment>
            <div>
                <ComponentIdentifier displayName='DropzoneBundel'/>

                <DropzoneComponent config={componentConfig}
                                   eventHandlers={eventHandlers}
                                   djsConfig={djsConfig}>

                    {uploading && <Progress style={{width: '100%'}} autoSuccess progress size='small' percent={progressPercentage}>

                    </Progress>}

                    {children}

                </DropzoneComponent>

            </div>
        </Fragment>
    )
}

export default DropzoneBundel

DropzoneBundel.propTypes = {
    bundelService: PropTypes.object.isRequired,
    uploadEnabled: PropTypes.bool,
}

DropzoneBundel.defaultProps = {}
