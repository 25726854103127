import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import ServiceContext from '../context/ServiceContext'

import { Card, Image, Label } from "semantic-ui-react";
import { CollectieIcon, EmbargoIcon, ApplicationPath, StatusManager, MetadataUtil } from "@ahsmediatheek/ada-lib";
import { ComponentIdentifier } from "./ComponentIdentifier";
import ENWOpleidingLabels from './ENWOpleidingLabels';


const DISPLAY_LIST = "list"
const DISPLAY_TILE = "tile"

const CollectieCard = ({ collectie, locale, showStatus, display, onClick }) => {
    const [referentieBeeld, setReferentieBeeld] = useState()
    const [hasEmbargo, setHasEmbargo] = useState(false)
    const [ENWs, setENWs] = useState();
    const [opleidingen, setOpleidingen] = useState();
    const [diensten, setDiensten] = useState();
    const [labelColor, setLabelColor] = useState()

    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        setLabelColor(StatusManager.getStatusColor(collectie.status))
    }, [collectie])

    useEffect(() => {
        if (!collectie) return

        const resolveReferentiebeeld = () => {
            return collectie.defaultReferentiebeeld !== ''
                ? collectie.defaultReferentiebeeld
                : serviceContext.applicationPath.referentiebeelden() + collectie.uuid + '_thumb.jpg?d=' + new Date().getTime()
        }

        setReferentieBeeld(resolveReferentiebeeld())
        setHasEmbargo(collectie.embargo && collectie.embargo !== '')

    }, [collectie])

    useEffect(() => {
        if (!collectie || !collectie.academiejaar) return;

        Promise.all([
            serviceContext.arteveldeApiService.getENWs(collectie.academiejaar),
            serviceContext.arteveldeApiService.getOpleidingen(collectie.academiejaar),
            serviceContext.arteveldeApiService.getDiensten(collectie.academiejaar)
        ]).then(([ENWs, opleidingen, diensten]) => {
            setENWs(ENWs);
            setOpleidingen(opleidingen);
            setDiensten(diensten);
        });
    }, [collectie, serviceContext.arteveldeApiService]);

    if (!collectie || !locale) return null

    const card = (
        display === DISPLAY_TILE ? (
            <Card color='orange' className='result-card'>
                <Image src={referentieBeeld} wrapped ui={true} loading="lazy" />

                <div className='icon-group-top-left'>
                    <CollectieIcon />
                    {hasEmbargo && <EmbargoIcon />}
                </div>

                {showStatus && <div className='icon-group-top-right'>
                    <Label size='tiny' color={labelColor}>{serviceContext.localeManager.getString(collectie.status)}</Label>
                </div>}

                <Card.Content>
                    <p className='academiejaar'>{collectie.academiejaar}</p>

                    <Card.Header>{MetadataUtil.resolveTitel(collectie, locale)}</Card.Header>
                    <Card.Meta>
                        {opleidingen && (
                            <ENWOpleidingLabels
                                ENWs={ENWs}
                                localeManager={serviceContext.localeManager}
                                opleidingen={opleidingen}
                                diensten={diensten}
                                selectie={collectie.opleiding}
                            />
                        )}
                    </Card.Meta>
                    <Card.Description>
                        {collectie[`beschrijving_${locale}`] || collectie.beschrijving_nl}
                    </Card.Description>
                </Card.Content>
            </Card>
        ) : display === DISPLAY_LIST && (
            <Card fluid color='orange' className='result-card-list'>
                <div className='icon-group-top-right'>
                        <CollectieIcon />
                        {hasEmbargo && <EmbargoIcon />}
                </div>

                {showStatus && <div className='icon-group-bottom-right'>
                    <Label size='tiny' color={labelColor}>{serviceContext.localeManager.getString(collectie.status)}</Label>
                </div>}

                <Card.Content>
                        <Image src={referentieBeeld} size='small' wrapped ui={true} floated='left' />
                    <p className='academiejaar'>{collectie.academiejaar}</p>

                    <Card.Header>{MetadataUtil.resolveTitel(collectie, locale)}</Card.Header>
                    <Card.Meta>
                            {opleidingen && (
                                <ENWOpleidingLabels
                                    localeManager={serviceContext.localeManager}
                                    ENWs={ENWs}
                                    opleidingen={opleidingen}
                                    diensten={diensten}
                                    selectie={collectie.opleiding}
                                />
                            )}
                    </Card.Meta>
                    <Card.Description>
                        {collectie[`beschrijving_${locale}`] || collectie.beschrijving_nl}
                    </Card.Description>
                </Card.Content>
            </Card>
        )
    );

    return (
        <Fragment>
            <ComponentIdentifier displayName='CollectieCard' />

            {onClick ? (
                <div onClick={event => onClick(event, collectie)}>
                    {card}
                </div>
            ) : card
            }
        </Fragment>
    )
}

export {CollectieCard}

CollectieCard.propTypes = {
    collectie: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    showStatus: PropTypes.bool,
    display: PropTypes.string,
}

CollectieCard.defaultProps = {
    showCollectieUuid: false,
    showStatus: false,
    display: 'tile'
}
