//import moment from 'moment'
const moment = require('moment')

export class Embargo {

    static readonly NIEMAND: string = 'embargo_niemand'
    static readonly EMBARGO_ARTEVELDE_MEDEWERKERS_AAN_GERELATEERDE_OPLEIDINGEN: string = 'embargo_artevelde_medewerkers_aan_gerelateerde_opleidingen'
    static readonly EMBARGO_ARTEVELDE_MEDEWERKERS: string = 'embargo_artevelde_medewerkers'
    static readonly EMBARGO_ARTEVELDE: string = 'embargo_artevelde'
    static readonly EMBARGO_ADA_GEBRUIKERS: string = 'embargo_ada_gebruikers'
    static readonly EMBARGO_IEDEREEN: string = 'embargo_iedereen'

    static readonly VALUES: string[] = [
        Embargo.NIEMAND,
        Embargo.EMBARGO_ARTEVELDE_MEDEWERKERS_AAN_GERELATEERDE_OPLEIDINGEN,
        Embargo.EMBARGO_ARTEVELDE_MEDEWERKERS,
        Embargo.EMBARGO_ARTEVELDE,
        Embargo.EMBARGO_ADA_GEBRUIKERS,
        Embargo.EMBARGO_IEDEREEN
    ]

    static fromString = function (baseString){
        if (!baseString || baseString === '') return null

        return JSON.parse(baseString)
    }

    static toJsonString = function (embargo){
        return JSON.stringify({metadata: embargo.metadata, bundelInhoud: embargo.bundelInhoud, uitzonderingen: embargo.uitzonderingen})
    }

    static allowUitzonderingen = function (embargo){
        return Embargo.VALUES.indexOf(embargo.bundelInhoud) < Embargo.VALUES.length - 1
    }

    metadata: string = Embargo.EMBARGO_IEDEREEN
    bundelInhoud: string = Embargo.EMBARGO_ARTEVELDE_MEDEWERKERS_AAN_GERELATEERDE_OPLEIDINGEN

    /*toJsonString = () => {
        return JSON.stringify({metadata: this.metadata, bundelInhoud: this.bundelInhoud})
    }*/
}

export class EmbargoUitzondering {

    constructor(){
        const now = moment()
        this.van = now.format('YYYY-MM-DD')
        this.tot = now.add(5, 'weeks').format('YYYY-MM-DD')
        this.toegang = EmbargoUitzondering.REFERENTIE_ITEMS
    }

    static readonly REFERENTIE_ITEMS: string = 'referentie_items'
    static readonly ALLE_ITEMS: string = 'alle_items'
    static readonly VALUES: string[] = [EmbargoUitzondering.REFERENTIE_ITEMS, EmbargoUitzondering.ALLE_ITEMS]

    gebruiker: number = 0
    toegang: string
    van: string
    tot: string
}

export class EmbargoContext {
    static readonly ADA: string = 'ada'
    static readonly COLLECTIE: string = 'collectie'
    static readonly BUNDEL: string = 'bundel'
    static readonly ITEM: string = 'item'

    static readonly VALUES: string[] = [
        EmbargoContext.ADA,
        EmbargoContext.COLLECTIE,
        EmbargoContext.BUNDEL,
        EmbargoContext.ITEM]
}
