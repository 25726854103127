import React, {useState} from 'react'
import PropTypes from 'prop-types'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'


const ImageUploader = (props) => {

    const [crop, setCrop] = useState({ aspect: 16 / 9 });
    //const [mode, setMode] = useState('view')

    //const slimRef = useRef()

    // called when upload button is pressed or automatically if push is enabled
    /*const upload = (formdata, progress, success, failure, slim) => {
        const {onSuccess, onError} = this.props

        let config = {
            onUploadProgress: (progressEvent) => {
                progress(progressEvent.loaded, progressEvent.total)
            }
        }
        console.log('UPLOADING', formdata, config)

        console.log('POSTING', this.props.serviceUrl)

        axios.post(this.props.serviceUrl, formdata, config).then(response => {

            console.log('POST POST', response)

            success()
            if (onSuccess) onSuccess(response.data)

            setTimeout(this.onCancel, 4000)

        }).catch(err => {
            failure(err)
            if (onError) onError()
        })
    }*/


    /*const willSave = (data, ready) => {
        const {filenameFunction, uploadMode} = this.props

        if (filenameFunction) {
            data.meta.filename = filenameFunction(data)
        }

        data.meta.uploadMode = uploadMode

        ready(data)
    }*/

    /*const didLoad = (...props) => {
        console.log('DID LOAD', ...props)
    }*/

    /*const onEditButtonClick = () => {
        this.setState({mode: 'edit'})
    }*/

    /*const onCancel = (...props) => {
        console.log('CANCEL', props)
        this.setState({mode: 'view'})
    }*/

    return (
        <div>
            <ReactCrop src={props.imageUrl} crop={crop} onChange={newCrop => setCrop(newCrop)} />
        </div>
    )
}

export {ImageUploader}

ImageUploader.propTypes = {
    imageUrl: PropTypes.string,
    serviceUrl: PropTypes.string.isRequired,
    uploadMode: PropTypes.string.isRequired,
    ratio: PropTypes.string,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    forceSize: PropTypes.string,
    size: PropTypes.string,
    onSave: PropTypes.func,
    filenameFunction: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    previewSize: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
}

ImageUploader.defaultProps = {
    imageUrl: '',
    ratio: 'free',
    minWidth: 100,
    minHeight: 100,
    size: null,
    forceSize: '',
    previewSize: 'large'
}