
const csv = require('@fast-csv/parse')

const HEADERS = [
    {label: 'titel_nl', required: true},
    {label: 'titel_en', required: false},
    {label: 'subtitel_nl', required: false},
    {label: 'subtitel_en', required: false},
    {label: 'abstract_nl', required: false},
    {label: 'abstract_en', required: false},
    {label: 'inzenders', required: true},
    {label: 'promotors', required: false},
    //{label: 'validators', required: false},
    {label: 'trefwoorden', required: false},
    //{label: 'publicatietype', required: false},
    //{label: 'academiejaar', required: false},
    //{label: 'opleiding', required: false},
    {label: 'compilaties', required: false},
]

const getAllowedHeaders = () => {
    let arr = []
    HEADERS.map(headerData => {
        arr.push(headerData.label)
    })
    return arr
}

export const allowedHeaders = getAllowedHeaders()
 
export const parseBundelCsv = (rawData) => {


    return new Promise((resolve, reject) => {
        const data = []
        let headers, error
        const stream = csv.parseString(rawData, { headers: true, delimiter: ';', ignoreEmpty: true , trim : true })
            .on('error', err => {
                reject(err)
                return
            })
            .on('headers', hdrs => { 
                headers = hdrs
            })
            .on('data', row => {
                data.push(row)
                //console.log(row)
            })
            .on('end', rowCount => {
                const validation = validate(headers, data)
                if (validation.length > 0){
                    reject(validation)
                } else{

                resolve({headers, data})
                }
            });
    })
}

const validate = (headers, data) => {
    let ra = []

    //const invalidHeaders = validateHeaders(headers)
    //if (invalidHeaders) ra = ra.concat(invalidHeaders)

    const requiredHeaders = validateRequired(headers)
    if (requiredHeaders) ra = ra.concat(requiredHeaders)

    return ra
}
/*
const validateHeaders = (headers) => {
    const flatHeaders = HEADERS.map(header => header.label)

    const errors = []
    for (const header of headers) {
        if (!flatHeaders.includes(header)) errors.push({error: 'ONGELDIGE_HEADER', subst: header})
    }
    if (errors.length > 0) return errors
    return
}
*/

const validateRequired = (headers) => {

    const errors = []
    for (const header of HEADERS) {
        if (!headers.includes(header.label) && header.required) 
            errors.push({error: `HEADER_IS_VERPLICHT : ${header.label}`, subst: header.label}) 
    }
    if (errors.length > 0) return errors
    return
}
