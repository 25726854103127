import React from 'react'
import {Icon} from "semantic-ui-react";
import AbstractIcon from "./AbstractIcon";

import {SiAdobeillustrator, SiAdobephotoshop, SiSvg} from 'react-icons/si'
import {RiFileExcel2Fill, RiNumbersFill} from 'react-icons/ri'
import {AiFillFolder, AiOutlineFileJpg, AiOutlineFileText} from 'react-icons/ai'
import {FaFileAlt, FaFilePdf, FaFilePowerpoint, FaFileWord} from "react-icons/fa";
import {GrDocumentCsv, GrDocumentRtf, GrDocumentZip} from "react-icons/gr";

const AdaUserIcon = props => <AbstractIcon {...props} name='user' color='teal'/>
const AdminIcon = props => <AbstractIcon {...props} name='user md' color='red'/>
const BundelIcon = props => <AbstractIcon {...props} name='archive' color='teal'/>
const CheckIcon = props => <AbstractIcon {...props} name='check'/>
const CloseIcon = props => <AbstractIcon {...props} name='close' color='red'/>
const CollectieIcon = props => <AbstractIcon {...props} name='folder' color='orange'/>
const CompilatieIcon = props => <AbstractIcon {...props} name='folder' color='purple'/>
const DownloadIcon = props => <AbstractIcon {...props} name='cloud download' color='blue'/>
const EditIcon = props => <AbstractIcon {...props} name='pencil'/>
const EmbargoIcon = props => <AbstractIcon {...props} name='unlock' color='red'/>
const FolderIcon = props => <AbstractIcon {...props} name='folder'/>
const InfoIcon = props => <AbstractIcon {...props} name='info' color='blue'/>
const ItemIcon = props => <AbstractIcon {...props} name='file' color='purple'/>
const PreviousIcon = props => <AbstractIcon {...props} name='arrow left'/>
/*const ReferentieitemIcon = props => <AbstractIcon {...props} name='registered' color='green'/>*/
const SettingsIcon = props => <AbstractIcon {...props} name='cog' color='grey'/>
const ShareIcon = props => <AbstractIcon {...props} name='share square' color='blue'/>
const TrashIcon = props => <AbstractIcon {...props} name='trash' color='red'/>
const ViewIcon = props => <AbstractIcon {...props} name='eye'/>
const DangerIcon = props => <AbstractIcon {...props} name='warning' color='red'/>



const ReferentiebeeldIcon = props => <Icon.Group {...props} size='big'>
    <Icon name='image' color='blue'/>
    <Icon corner name='registered' color='blue'/>
</Icon.Group>
const ReferentieitemIcon = props => <Icon.Group size='big' {...props}>
    <Icon name='file' color='green'/>
    <Icon corner name='registered' color='green'/>
</Icon.Group>
const TeKoopItemIcon = props => <Icon.Group size='big' {...props}>
    <Icon name='shop' color='teal'/>
</Icon.Group>


const MimetypeIcon_Illustrator = props => <SiAdobeillustrator {...props}/>
const MimetypeIcon_Directory = props => <AiFillFolder {...props}/>
const MimetypeIcon_Zip = props => <GrDocumentZip {...props}/>
const MimetypeIcon_TextPlain = props => <AiOutlineFileText {...props}/>
const MimetypeIcon_TextRtf = props => <GrDocumentRtf {...props}/>
const MimetypeIcon_TextCsv = props => <GrDocumentCsv {...props}/>
const MimetypeIcon_Powerpoint = props => <FaFilePowerpoint {...props}/>
const MimetypeIcon_Word = props => <FaFileWord {...props}/>
const MimetypeIcon_Numbers = props => <RiNumbersFill {...props}/>
const MimetypeIcon_Excel = props => <RiFileExcel2Fill {...props}/>
const MimetypeIcon_Photoshop = props => <SiAdobephotoshop {...props}/>
const MimetypeIcon_Pdf = props => <FaFilePdf {...props}/>
const MimetypeIcon_Jpeg = props => <AiOutlineFileJpg {...props}/>
const MimetypeIcon_Svg = props => <SiSvg {...props}/>
const MimetypeIcon_File = props => <FaFileAlt {...props}/>


export {CollectieIcon, CompilatieIcon, SettingsIcon, BundelIcon, PreviousIcon, InfoIcon, EditIcon, TrashIcon, ItemIcon, ViewIcon, DownloadIcon,
    ReferentieitemIcon, FolderIcon, ReferentiebeeldIcon, EmbargoIcon, AdaUserIcon, CheckIcon, AdminIcon, CloseIcon, ShareIcon, DangerIcon, TeKoopItemIcon,

    MimetypeIcon_Illustrator, MimetypeIcon_Directory, MimetypeIcon_Zip, MimetypeIcon_TextPlain, MimetypeIcon_TextRtf, MimetypeIcon_TextCsv, MimetypeIcon_Powerpoint, MimetypeIcon_Word, MimetypeIcon_Numbers, MimetypeIcon_Excel, MimetypeIcon_Photoshop, MimetypeIcon_Pdf, MimetypeIcon_Jpeg, MimetypeIcon_Svg, MimetypeIcon_File}
