import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import MetadataContainer from "../component/MetadataContainer";
import {AdaUserRol, ItemCard, Spacer, ItemIcon, EmbargoIcon, ResultContainer} from "@ahsmediatheek/ada-lib";
import {Button, Divider, Label, Segment} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'
import AdaUserDisplay from "../component/AdaUserDisplay";
import {TrefwoordenViewer} from '@ahsmediatheek/med-component-trefwoorden'
import {ComponentIdentifier} from "../component/ComponentIdentifier";
import CompilatieZoeker from './CompilatieZoeker';


const VIEW_ENKEL_METADATA = 'viewEnkelMetadata'
const VIEW_INHOUD = 'viewInhoud'
const VOEG_TOE_AAN_COMPILATIE = 'voegToeAanCompilatie'

const BundelDetailMainView = ({bundel, onShowBundleInhoud, resolvedCombinedEmbargo, onDeleteBundel}) => {
    console.log(bundel)
    const [view, setView] = useState(VIEW_ENKEL_METADATA)
    const [trefwoorden, setTrefwoorden] = useState()
    const [trefwoordenArray , setTrefwoordenArray] = useState([])


    const serviceContext = useContext(ServiceContext)

    const isValidUuid = (string) => {
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        return regexExp.test(string);
    }

    const convertTrefwoord = (string) => {
        let twSplit = string.split('_')
        let parts = []
        twSplit.map((part) => {
            if(!isValidUuid(part)) parts.push(part)
        })

        return parts.join(' ')
    }

    useEffect(() => {
        const trefwoorden = bundel.trefwoorden
            .split('|')
            //.concat(serviceContext.bundelCollectieCollectie.trefwoorden
            //    .split('|')).filter(tw => tw !== '')
        setTrefwoorden(trefwoorden.join('|'))
        setTrefwoordenArray(trefwoorden)
    }, [bundel, serviceContext.bundelCollectieCollectie])

    useEffect(() => {
        if (!resolvedCombinedEmbargo) return
        setView(resolvedCombinedEmbargo.allow ? VIEW_INHOUD : VIEW_ENKEL_METADATA)

    }, [resolvedCombinedEmbargo])

    const onItemClick = (bundel, referentiebestand, referentiebeeld) => {
        if (referentiebestand.mimetype.indexOf('image') === -1 && referentiebestand.mimetype.indexOf('pdf') === -1) return
        window.open(referentiebeeld, '_blank');
    }

    function onSelectCompilatie(compilatie) {
        serviceContext.compilatieService.addToCompilatie(compilatie, 'bundel', serviceContext.bundel.uuid);

        alert(serviceContext.localeManager.getString('BUNDEL_TOEGEVOEGD_AAN_COMPILATIE'));

        setView(VIEW_INHOUD);
    }

    return (
        <div>

            <ComponentIdentifier displayName='BundelDetailMainView'/>

            <MetadataContainer provider={serviceContext.bundel} locale={serviceContext.localeManager.locale} beschrijvingField='abstract'/>

            <Spacer vertical distance={15}/>

            <Segment>

                {serviceContext.bundelAdaUserLinks &&
                <AdaUserDisplay adaUserLinks={serviceContext.bundelAdaUserLinks}
                                rollen={AdaUserRol.BUNDEL_ROLLEN}/>
                }

                {trefwoorden !== '' &&
                <div>
                    {
                        console.log(trefwoorden)
                    }
                    {
                        console.log(trefwoordenArray)
                    }
                    <Spacer vertical distance={20}/>
                        
                        <div>
                           { 
                           trefwoordenArray.map((trefwoord) => {
                                {
                                return <Label tag>
                                        {convertTrefwoord(trefwoord)}
                                    </Label>
                                }
                            })
                            }
                        </div>
                      

                    {/*<TrefwoordenViewer consumerKey={serviceContext.TREFWOORDEN_CONSUMER_KEY}
                                       infoComponentHook={() => <ComponentIdentifier displayName='TrefwoordenViewer'/>}
                                       readonly
                                       trefwoordenString={trefwoorden}
                                       locale={serviceContext.localeManager.locale}/>
                    */}
                </div>}
            </Segment>

            <Segment clearing>
            {serviceContext.privilegeManager.canViewBundelItems(bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks) &&
                <Button onClick={onShowBundleInhoud}>{serviceContext.localeManager.getString('TOON_BUNDEL_ITEMS')}</Button>
                }

                {serviceContext.privilegeManager.canAddCompilatie() && (
                    <Button onClick={() => setView(VOEG_TOE_AAN_COMPILATIE)}>{serviceContext.localeManager.getString('VOEG_BUNDEL_TOE_AAN_COMPILATIE')}</Button>
                )}
            </Segment>


            {view === VIEW_ENKEL_METADATA &&
            <Segment>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.2em'}}>
                    <EmbargoIcon/>
                    <Spacer vertical distance={20}/>
                    <p>{serviceContext.localeManager.getString(resolvedCombinedEmbargo.reason)}</p>
                </div>
            </Segment>}


            {view === VIEW_INHOUD && serviceContext.referentiebestanden &&
            <ResultContainer containerWidth={serviceContext.resultContainerAvailableWidth}
                             headerContent={() => {
                                 return <div>
                                     <div style={{fontSize: '1.5em', fontWeight: 'bold'}}>
                                         <ItemIcon/> {serviceContext.localeManager.getString('REFERENTIEBESTANDEN')}</div>
                                     <Divider/>
                                 </div>
                             }}>
                {serviceContext.referentiebestanden.map((referentiebestand, index) => {
                    return <ItemCard key={index} bundel={bundel}
                                     browser={serviceContext.browser}
                                     onClick={onItemClick}
                                     applicationPath={serviceContext.applicationPath}
                                     referentiebestand={referentiebestand}
                                     locale={serviceContext.localeManager.locale}>
                        <ComponentIdentifier displayName='ItemCard'/>

                    </ItemCard>
                })}

            </ResultContainer>}
            
            {view === VOEG_TOE_AAN_COMPILATIE && <CompilatieZoeker onSelect={onSelectCompilatie} />}

        </div>
    )
}

export default BundelDetailMainView

BundelDetailMainView.propTypes = {
    bundel: PropTypes.object.isRequired,
    onShowBundleInhoud: PropTypes.func,
    onDeleteBundel: PropTypes.func,
    resolvedCombinedEmbargo: PropTypes.object
}

BundelDetailMainView.defaultProps = {
    contentViewable: false
}
