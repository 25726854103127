import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Image, Icon} from "semantic-ui-react";
import {ContentViewerType} from "../../class/item/ContentViewerType";
import {Mimetype} from "../../class/bundel/Mimetype";

const ContentViewer = ({streamingUrl, streamingState, path, mimetype, browser, children, filesize}) => {

    const [type, setType] = useState()
    const [filesizeMB, setFilesizeMB] = useState(0)

    useEffect(() => {
        if (filesize){
            setFilesizeMB(filesize / 1024 / 1024)
        }
    }, [filesize])

    useEffect(() => {
        switch (mimetype) {
            case Mimetype.VIDEO_MP4:
            case Mimetype.VIDEO_OGG:
            case Mimetype.VIDEO_QUICKTIME:
            case Mimetype.APPLICATION_OCTET_STREAM:
                setType(ContentViewerType.VIDEO)
                break
            case Mimetype.AUDIO_MPEG:
                setType(ContentViewerType.AUDIO)
                break
            case Mimetype.APPLICATION_PDF:
                setType(browser && browser.name === 'safari' ? ContentViewerType.IMAGE : ContentViewerType.PDF)
                break
            default:
                setType(ContentViewerType.IMAGE)
                break
        }
    }, [mimetype, browser])

    if (!type) return null

    return (
        <Fragment>

            {children}

            {type === ContentViewerType.IMAGE && <Image style={{display: 'flex', justifyContent: 'center'}} src={path}/>}

            {type === ContentViewerType.VIDEO &&

            <Fragment>
                {streamingUrl &&
                <video width="100%" controls>
                    <source src={streamingUrl} type="video/mp4"/>
                </video>}

                {!streamingUrl && <Fragment>

                    {(streamingState === 'waiting' || streamingState === "0") && <Fragment>
                        <div style={{padding: '20px'}}><p>Deze video wordt momenteel omgezet voor streaming. Dit kan een tiental minuten duren.</p></div>
                    </Fragment>}
                    {streamingState === 'format_not_supported_yet' && <Fragment>
                        <div style={{padding: '20px'}}><p>Browserplayback not supported yet. Download the video to watch it.</p></div>
                    </Fragment>}
                    {streamingState === 'error' && <Fragment color="red">
                        <div style={{padding: '20px'}}><p>Error converting this video. Contact a system administrator.</p></div>
                    </Fragment>}
                    {/*{filesizeMB <= 20 &&
                    <video width="100%" controls>
                        <source src={path} type="video/mp4"/>
                    </video>
                    }*/}
                    {/*{filesizeMB > 20 &&
                    <div>
                        <Icon style={{margin: '10px'}} name='file video' size='massive' color='grey'/>
                        <em>Deze video is te groot om inline af te spelen. U kan hem enkel downloaden</em>
                    </div>
                    }*/}
                </Fragment>}

            </Fragment>

            }



            {type === ContentViewerType.AUDIO &&
            <audio style={{margin: '10px', width: '200px', alignSelf: 'center'}} controls>
                <source src={path} type="audio/mpeg"/>
            </audio>
            }

            {type === ContentViewerType.PDF &&
                <Icon style={{margin: '10px'}} name='file pdf' size='massive' color='red'/>
            }

            {type === ContentViewerType.VIDEO && streamingUrl == null &&
                <Icon style={{margin: '10px'}} name='file video' size='massive' color='orange'/>
            }
        </Fragment>
    )
}

export {ContentViewer}

ContentViewer.propTypes = {
    path: PropTypes.string.isRequired,
    mimetype: PropTypes.string.isRequired,
    browser: PropTypes.object,
    filesize: PropTypes.number,
    streamingUrl: PropTypes.string
}

ContentViewer.defaultProps = {
}
