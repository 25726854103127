import React, {useContext} from 'react'
import { Form } from "semantic-ui-react";
import ServiceContext from '../../../../context/ServiceContext'
import AbstractWizardPage from "./AbstractWizardPage";
import AcademiejaarKiezer from "../../../../component/spinbutton/AcademiejaarKiezer";
import ENWOpleidingKiezer from "../../../../component/dropdown/ENWOpleidingKiezer";
import { ComponentIdentifier } from "../../../../component/ComponentIdentifier";


const CwMetadata = () => {

    const serviceContext = useContext(ServiceContext)

    return (
        <AbstractWizardPage>
            <ComponentIdentifier displayName='CwMetadata' />

            <Form>

                <Form.Field>
                    <label>{serviceContext.localeManager.getString('ACADEMIEJAAR')}</label>
                    <AcademiejaarKiezer academiejaren={serviceContext.arteveldeApiService.academiejaren}
                        selectedAcademiejaar={serviceContext.wizardCollectie.academiejaar}
                        onSelectAcademiejaar={serviceContext.onChangeAcademiejaar}
                                        style={{ width: '200px' }}/>
                </Form.Field>

                {serviceContext.opleidingen && <Form.Field>
                    <label>{serviceContext.localeManager.getString('OPLEIDINGEN')}</label>
                    <ENWOpleidingKiezer
                        opleidingen={serviceContext.opleidingen}
                        diensten={serviceContext.diensten}
                        localeManager={serviceContext.localeManager}
                        selectedOpleiding={serviceContext.wizardCollectie.opleiding}
                        onSelectOpleiding={(opl) => serviceContext.onChangeProp(null, { name: 'opleiding', value: opl })}
                    />
                </Form.Field>}


            </Form>
        </AbstractWizardPage>
    )
}

export default CwMetadata
