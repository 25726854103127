import React, {useState, useEffect, useContext} from 'react'
import {Form, Divider, Checkbox, Header} from 'semantic-ui-react'
import ServiceContext from '../../context/ServiceContext'
import {BundelSettings, InfoViewer} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const BundelSettingsInstellingen = () => {


    const [TK_IS_TE_KOOP, SET_TK_IS_TE_KOOP] = useState()
    const [TK_ENKEL_REFERENTIE_ITEMS, SET_TK_ENKEL_REFERENTIE_ITEMS] = useState()
    
    const serviceContext = useContext(ServiceContext)
    console.log(serviceContext.bundel)
    console.log(Boolean(serviceContext.bundel.zichtbaarInCarrousel))
    const [zichtbaarInCarrousel,setZichtbaarInCarrousel] = useState(Boolean(serviceContext.bundel.zichtbaarInCarrousel))


    useEffect(() => {
        SET_TK_IS_TE_KOOP(serviceContext.bundelService.getBundelSetting(serviceContext.bundel, BundelSettings.TK_IS_TE_KOOP) === 1)
        SET_TK_ENKEL_REFERENTIE_ITEMS(serviceContext.bundelService.getBundelSetting(serviceContext.bundel, BundelSettings.TK_ENKEL_REFERENTIE_ITEMS) === 1)
    }, [serviceContext.bundelSettings, serviceContext.bundel, serviceContext.bundelService])

    const onCheckboxChange = (event, {name, checked}) => {
        console.log('CBC', name, checked)
        const newSettings = serviceContext.bundelService.setBundelSetting(serviceContext.bundel, name, checked ? 1 : 0)
        serviceContext.setBundelSettings({...newSettings})
        //SAVE
        serviceContext.bundelService.updateBundel(serviceContext.bundel).then(res => {
            console.log('BUNDEL UPDATED', serviceContext.bundel)
        })
    }

    const onZichtbaarChange = (event, {name, checked}) => {
        console.log(name,checked)
        serviceContext.bundel.zichtbaarInCarrousel = +checked
        //SAVE
        serviceContext.bundelService.updateBundel(serviceContext.bundel).then(res => {
            console.log('ZichtbaarInCarrousel changed', serviceContext.bundel)
            setZichtbaarInCarrousel(checked)
        })
    }

    return (
        <Form>
            <ComponentIdentifier displayName='BundelSettingsInstellingen'/>

            <Header dividing size='huge'>{serviceContext.localeManager.getString('SETTINGS')}</Header>
            <Form.Field>
                <label>{serviceContext.localeManager.getString('CARROUSEL')}</label>
                <Checkbox
                    toggle
                    name='zichtbaarInCarrousel'
                    checked={zichtbaarInCarrousel} 
                    label={<label>{serviceContext.localeManager.getString('ZICHTBAAR_IN_CARROUSEL')}</label>} 
                    onChange={onZichtbaarChange}
                    />
            </Form.Field>
            <Form.Field>
                <label>{serviceContext.localeManager.getString('WEBSHOP')}</label>

                <div><InfoViewer editable={serviceContext.infoEditable}
                                 refId='INFO_BUNDEL_TE_KOOP'
                                 localeDataService={serviceContext.infoLocaleDataService}
                                 localeManager={serviceContext.localeManager}/></div>

                <div>
                    <Checkbox label={serviceContext.localeManager.getString('TK_BUNDEL_IS_TE_KOOP')}
                              name={BundelSettings.TK_IS_TE_KOOP}
                              checked={TK_IS_TE_KOOP}
                              disabled={!serviceContext.privilegeManager.canSetBundelTeKoop(serviceContext.collectieAdaUserLinks)}
                              onChange={onCheckboxChange}/>
                </div>
                {TK_IS_TE_KOOP && <div>
                    <Checkbox label={serviceContext.localeManager.getString(BundelSettings.TK_ENKEL_REFERENTIE_ITEMS)}
                              name={BundelSettings.TK_ENKEL_REFERENTIE_ITEMS}
                              checked={TK_ENKEL_REFERENTIE_ITEMS}
                              disabled={!serviceContext.privilegeManager.canSetBundelTeKoop(serviceContext.collectieAdaUserLinks)}
                              onChange={onCheckboxChange}/>
                </div>}

            </Form.Field>

        </Form>
    )
}

export default BundelSettingsInstellingen
