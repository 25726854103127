import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {Confirm} from "semantic-ui-react";

const AdaConfirm = ({localeManager, cancelButtonLabel, confirmButtonLabel, callback}) => {

    const [cancelButton, setCancelButton] = useState()
    const [confirmButton, setConfirmButton] = useState()
    const [confirmOpen, setConfirmOpen] = useState(true)

    useEffect(() => {
        setCancelButton(localeManager.getString(cancelButtonLabel) || localeManager.getString('ANNULEER'))
        setConfirmButton(localeManager.getString(confirmButtonLabel) || localeManager.getString('OK'))
    }, [])

    const handleCancel = () => {

    }

    const handleConfirm = () => {

    }


    return (
        <Confirm
            open={confirmOpen}
            cancelButton={cancelButton}
            confirmButton={confirmButton}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
        />
    )
}

export {AdaConfirm}

AdaConfirm.propTypes = {
    localeManager: PropTypes.object.isRequired,
    cancelButtonLabel: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
    callback: PropTypes.func,
}

AdaConfirm.defaultProps = {

}
