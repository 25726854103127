import axios from 'axios'

import {ApplicationPath} from "../class/general/ApplicationPath"
import {AuthenticationService} from "./AuthenticationService";
import {AdaUserService} from "./AdaUserService";
import {DateUtil} from "../util/DateUtil";
import {ToegangsticketHistoriek, ToegangsticketTargetType} from "../class/toegangsticket/Toegangsticket";
import {CollectieService} from "./CollectieService";
import {BundelService} from "./BundelService";


const TOEGANGSTICKET_TABLE: string = 'toegangsticket'
const TOEGANGSTICKET_HISTORIEK_TABLE: string = 'toegangsticket_historiek'

export class ToegangsticketService {

    applicationPath: ApplicationPath
    authenticationInstance: AuthenticationService
    adaUserService: AdaUserService
    collectieService: CollectieService
    bundelService: BundelService


    constructor(authenticationInstance, adaUserService, collectieService, bundelService, applicationPath) {
        this.authenticationInstance = authenticationInstance
        this.adaUserService = adaUserService
        this.collectieService = collectieService
        this.bundelService = bundelService
        this.applicationPath = applicationPath

        this.deleteVervallenTickets()
    }

    //==============================================================
    //==============================================================
    //                      TOEGANGSTICKET
    //==============================================================
    //==============================================================

    deleteVervallenTickets = () => {
        const now = DateUtil.getNowMysql()

        const url = `${this.applicationPath.crud_api()}${TOEGANGSTICKET_TABLE}/?filter=geldig_tot,lt,${now}&transform=1`
        axios.get(url, this.authenticationInstance.axiosOptions)
            .then(response => {
                const toDelete = response.data[TOEGANGSTICKET_TABLE]
                const ids = toDelete.map(tt => tt.id).join(',')

                axios.delete(`${this.applicationPath.crud_api()}${TOEGANGSTICKET_TABLE}/${ids}?transform=1`)

            })
            .catch(error => {
                console.error('DELETE VERVALLEN TOEGANGSTICKETS ERROR', error)
            })
    }

    getToegangstickets = (consumer_uuid: string) => {
        return new Promise((resolve, reject) => {

            const now = DateUtil.getNowMysql()

            const url = `${this.applicationPath.crud_api()}${TOEGANGSTICKET_TABLE}/?filter[]=consumer_uuid,eq,${consumer_uuid}&filter[]=geldig_tot,gt,${now}&order=datum_aanmaak,desc&transform=1`

            //console.log('URL', url)
            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {

                    //console.log('RESP', response)
                    resolve(response.data[TOEGANGSTICKET_TABLE])
                })
                .catch(error => {
                    console.error('GET TOEGANGSTICKET ERROR', error)
                    reject(error)
                })
        })
    }

    getToegangsticket = (toegagnsticket_uuid: string) => {
        return new Promise((resolve, reject) => {

            const url = `${this.applicationPath.crud_api()}${TOEGANGSTICKET_TABLE}/?filter[]=uuid,eq,${toegagnsticket_uuid}&transform=1`
            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {
                    const ttArray = response.data[TOEGANGSTICKET_TABLE]
                    if (ttArray.length > 1) throw(new Error('Meerdere toegangstickets met hetzelfde uuid!'))
                    if (ttArray.length === 1) {
                        resolve(ttArray[0])
                    } else {
                        resolve(null)

                    }
                })
                .catch(error => {
                    console.error('GET TOEGANGSTICKET ERROR', error)
                    reject(error)
                })
        })
    }

    updateToegangsticket = (toegangsticket) => {

        const url = `${this.applicationPath.crud_api()}${TOEGANGSTICKET_TABLE}/${toegangsticket.id}?transform=1`

        return new Promise((resolve, reject) => {
                axios.put(url, toegangsticket)
                    .then((result) => {
                        resolve(result)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    createToegangsticket = (toegangsticket) => {

        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api()}${TOEGANGSTICKET_TABLE}/?transform=1`

            axios.post(url, toegangsticket)
                .then((result) => {
                    toegangsticket.id = result.data
                    resolve(toegangsticket)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    deleteToegangsticket = (toegangsticket) => {

        return new Promise((resolve, reject) => {

            this.getToegangsticketHistoriek(toegangsticket).then(historiek => {

                const ids = (historiek as any[]).map(h => h.id)
                const idsString = ids.join(',')

                const deleteUrl = `${this.applicationPath.crud_api()}${TOEGANGSTICKET_HISTORIEK_TABLE}/${idsString}?transform=1`

                axios.delete(deleteUrl)
                    .then(() => {
                        const url = `${this.applicationPath.crud_api()}${TOEGANGSTICKET_TABLE}/${toegangsticket.id}?transform=1`

                        axios.delete(url)
                            .then(() => {
                                resolve('Toegangsticket deleted')
                            })
                            .catch((error) => {
                                console.log(error)
                                reject(error)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })


            })

        })
    }

    //==============================================================
    //==============================================================
    //                      HISTORIEK
    //==============================================================
    //==============================================================

    createQuickToegangsticketHistoriek = (toegangsticket, actie, actie_initiator: string = 'anoniem', toelichting: string = '') => {
        const tth = new ToegangsticketHistoriek()
        tth.toegangsticket = toegangsticket.uuid
        tth.actie = actie
        tth.actie_initiator = actie_initiator
        tth.toelichting = toelichting
        return this.createToegangsticketHistoriek(tth)
    }

    getToegangsticketHistoriek = (toegangsticket) => {
        return new Promise((resolve, reject) => {

            const url = `${this.applicationPath.crud_api()}${TOEGANGSTICKET_HISTORIEK_TABLE}/?filter[]=toegangsticket,eq,${toegangsticket.uuid}&order=datum,desc&transform=1`
            axios.get(url, this.authenticationInstance.axiosOptions)
                .then(response => {

                    resolve(response.data[TOEGANGSTICKET_HISTORIEK_TABLE])
                })
                .catch(error => {
                    console.error('GET TOEGANGSTICKET_HISTORIEK ERROR', error)
                    reject(error)
                })
        })
    }

    createToegangsticketHistoriek = (toegangsticketHistoriek) => {

        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.crud_api()}${TOEGANGSTICKET_HISTORIEK_TABLE}/?transform=1`

            axios.post(url, toegangsticketHistoriek)
                .then((result) => {
                    toegangsticketHistoriek.id = result.data
                    resolve(toegangsticketHistoriek)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    //==============================================================
    //==============================================================
    //                      MAILS
    //==============================================================
    //==============================================================

    stuurToegangsTicketNaarBegunstigde = (toegangsticket, locale) => {
        return new Promise((resolve, reject) => {

            let consumerPromise

            const formData = {
                template: 'template_4',
                subject: 'Je hebt een toegangsticket ontvangen van het Arteveldehogeschool Digitaal Archief',
                ontvangers: [toegangsticket.begunstigde],
                /*verzender: toegangsticket.aangemaakt_door,*/
                substitute: {
                    '[TOEGANGSTICKET_UUID]': toegangsticket.uuid,
                    '[CONSUMER_TYPE]': toegangsticket.consumer_type
                }
            }


            if (toegangsticket.consumer_type === ToegangsticketTargetType.COLLECTIE) {
                consumerPromise = this.collectieService.getCollectie
            } else if (toegangsticket.consumer_type === ToegangsticketTargetType.BUNDEL) {
                consumerPromise = this.bundelService.getBundel
            } else {
                throw(new Error('ERROR: Geen consumer type'))
            }

            consumerPromise(toegangsticket.consumer_uuid).then(consumer => {

                formData.substitute['[CONSUMER_TITEL]'] = consumer['titel_' + locale]
                formData.substitute['[CONSUMER_UUID]'] = consumer.uuid
                formData.substitute['[CONSUMER_METADATA]'] = consumer.academiejaar + ' | ' + consumer.opleiding
                formData.substitute['[CONSUMER_BESCHRIJVING]'] = consumer['beschrijving_' + locale]
                formData.substitute['[CONSUMER_SUBTITEL]'] = consumer['subtitel_' + locale]

                const url = this.applicationPath.email_api() + 'verstuurHtmlEmail/'

                console.log('EMAIL', url, formData)
                axios.post(url, formData)
                    .then((result) => {
                        resolve(result)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            })


        })
    }

    stuurMailBijBlokkering = (toegangsticket, locale) => {
        return new Promise((resolve, reject) => {

            let consumerPromise

            const formData = {
                template: 'template_12',
                subject: 'Een toegangsticket werd geblokkeerd',
                ontvangers: [],
                /*verzender: toegangsticket.aangemaakt_door,*/
                substitute: {
                    '[TOEGANGSTICKET_UUID]': toegangsticket.uuid,
                    '[CONSUMER_TYPE]': toegangsticket.consumer_type.toLowerCase()
                }
            }


            if (toegangsticket.consumer_type === ToegangsticketTargetType.COLLECTIE) {
                consumerPromise = this.collectieService.getCollectie
            } else if (toegangsticket.consumer_type === ToegangsticketTargetType.BUNDEL) {
                consumerPromise = this.bundelService.getBundel
            } else {
                throw(new Error('ERROR: Geen consumer type'))
            }

            Promise.all([consumerPromise(toegangsticket.consumer_uuid), this.adaUserService.getAdaUser(toegangsticket.aangemaakt_door)])
                .then(([consumer, eigenaar]) => {
                console.log('RES', consumer, eigenaar)

                formData.ontvangers = [(eigenaar as any).email]
                formData.substitute['[ADA_USER_VOORNAAM]'] = (eigenaar as any).voornaam + ' ' + (eigenaar as any).familienaam
                formData.substitute['[CONSUMER_TITEL]'] = consumer['titel_' + locale]
                formData.substitute['[CONSUMER_UUID]'] = consumer.uuid
                formData.substitute['[CONSUMER_METADATA]'] = consumer.academiejaar + ' | ' + consumer.opleiding
                formData.substitute['[CONSUMER_BESCHRIJVING]'] = consumer['beschrijving_' + locale]
                formData.substitute['[CONSUMER_SUBTITEL]'] = consumer['subtitel_' + locale]
                formData.substitute['[CONSUMER_LINK]'] = `${toegangsticket.consumer_type.toLowerCase()}/${consumer.uuid}`

                const url = this.applicationPath.email_api() + 'verstuurHtmlEmail/'

                console.log('EMAIL', url, formData)
                axios.post(url, formData)
                    .then((result) => {
                        resolve(result)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })


                resolve(null)
            })
            /*consumerPromise(toegangsticket.consumer_uuid).then(consumer => {


            })*/


        })
    }
}
