import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Progress, Segment, Radio} from "semantic-ui-react";
import Dropzone from "react-dropzone";
import {ActiviteitType, ApplicationPath, InfoViewer, Mimetype, PixabayBrowser, Spacer} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../../context/ServiceContext'
import {ComponentIdentifier} from "../ComponentIdentifier";

const MODE_DROPZONE = 'dropzone'
const MODE_PIXABAY = 'pixabay'

const ReferentiebeeldDropzone = ({provider}) => {

    const [mode, setMode] = useState(MODE_DROPZONE)
    const [imageUrl, setImageUrl] = useState()
    const [uploading, setUploading] = useState(false)
    const [progressPercentage, setProgressPercentage] = useState(0)

    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        if (!provider) return
        if (provider.defaultReferentiebeeld){
            setImageUrl(provider.defaultReferentiebeeld)
            return
        }

        if (provider.uuid) setImageUrl(`${serviceContext.applicationPath.referentiebeelden()}${provider.uuid}.jpg?d=${new Date().getTime()}`)
    }, [provider])

    const onDrop = (addedFiles, rFiles) => {

        if(addedFiles.length > 1){
            window.alert(serviceContext.localeManager.getString('GEEN_MEERDERE_FILES_TOEGESTAAN'))
            return
        }
        const file = addedFiles[0]

        if ([Mimetype.IMAGE_JPEG, Mimetype.IMAGE_PNG].indexOf(file.type) === -1){
            window.alert(serviceContext.localeManager.getString('ENKEL_JPG_PNG_TOEGESTAAN'))
            return
        }

        setProgressPercentage(0)
        setUploading(true)

        serviceContext.collectieService.uploadReferentiebeeld(file, provider.uuid, progressCallback).then(res => {
            setUploading(false)
            serviceContext.activiteitService.createActiviteit(serviceContext.privilegeManager.adaUser, ActiviteitType.COLLECTIE_REFERENTIEBEELD_VERVANGEN, file.path)
            serviceContext.onReferentiebeeldUploaded && serviceContext.onReferentiebeeldUploaded(provider)
        })
    }

    const progressCallback = (progressEvent) => {
        setProgressPercentage(Math.round(progressEvent.loaded / progressEvent.total * 1000)/10)
    }

    const onModeChange = (event, {value}) => {
        setMode(value)
    }

    const onSelectPixabayImage = (file, pixabayHit) => {
        console.log('SELECT', file, pixabayHit)

        setProgressPercentage(0)
        setUploading(true)

        serviceContext.collectieService.uploadReferentiebeeld(file, provider.uuid, progressCallback).then(res => {
            setUploading(false)
            serviceContext.activiteitService.createActiviteit(serviceContext.privilegeManager.adaUser, ActiviteitType.COLLECTIE_REFERENTIEBEELD_VERVANGEN, file.path)
            serviceContext.onReferentiebeeldUploaded && serviceContext.onReferentiebeeldUploaded(provider)
        })
    }

    return (
        <div>
            <ComponentIdentifier displayName='ReferentiebeeldDropzone'/>

            <div><InfoViewer refId='INFO_REFERENTIEBEELD'
                             editable={serviceContext.infoEditable}
                             localeManager={serviceContext.localeManager}
                             localeDataService={serviceContext.infoLocaleDataService}/></div>


            <Segment inverted>
                <div style={{display: 'flex'}}><Radio
                    label={serviceContext.localeManager.getString('KIES_BEELD_VAN_COMPUTER')}
                    name='modeGroup'
                    value={MODE_DROPZONE}
                    checked={mode === MODE_DROPZONE}
                    onChange={onModeChange}
                />
                    <Spacer/>
                    <Radio
                        label={serviceContext.localeManager.getString('KIES_BEELD_VAN_PIXABAY')}
                        name='modeGroup'
                        value={MODE_PIXABAY}
                        checked={mode === MODE_PIXABAY}
                        onChange={onModeChange}
                    /></div>
            </Segment>

            {mode === MODE_DROPZONE &&
            <Dropzone onDrop={onDrop}>
                {({getRootProps, getInputProps}) => (
                    <div className='dropzone' {...getRootProps()}>
                        <input {...getInputProps()} accept="image/png, image/jpeg"/>

                        {uploading && <Progress style={{width: '100%'}} percent={progressPercentage} autoSuccess progress size='small'/>}

                        <img src={imageUrl} alt='Referentiebeeld' width='100%'/>
                    </div>
                )}
            </Dropzone>
            }

            {mode === MODE_PIXABAY && <Fragment>
                {uploading && <Progress style={{width: '100%'}} percent={progressPercentage} autoSuccess progress size='small'/>}

                <PixabayBrowser infoLocaleDataService={serviceContext.infoLocaleDataService}
                                applicationPath={serviceContext.applicationPath}
                                                         localeManager={serviceContext.localeManager}
                                                         infoEditable={serviceContext.infoEditable}
                                                         defaultSearchString=''
                                                         onSelectImage={onSelectPixabayImage}
            />
            </Fragment>}

        </div>
    )
}

export default ReferentiebeeldDropzone

ReferentiebeeldDropzone.propTypes = {
    provider: PropTypes.object.isRequired
}

ReferentiebeeldDropzone.defaultProps = {}
