import React from 'react'
import PropTypes from 'prop-types'
import {
    EmailShareButton, EmailIcon,
    FacebookShareButton, FacebookIcon,
    LinkedinShareButton, LinkedinIcon,
    TwitterShareButton, TwitterIcon,
} from "react-share"
import {ComponentIdentifier} from "../ComponentIdentifier";

const ICON_SIZE = 32

const Sharing = ({url}) => {

    return (
        <div>
            <ComponentIdentifier displayName='Sharing'/>

            <EmailShareButton className='sharing' url={url}><EmailIcon round size={ICON_SIZE}/></EmailShareButton>
            <FacebookShareButton className='sharing' url={url}><FacebookIcon round size={ICON_SIZE}/></FacebookShareButton>
            <LinkedinShareButton className='sharing' url={url}><LinkedinIcon round size={ICON_SIZE}/></LinkedinShareButton>
            <TwitterShareButton className='sharing' url={url}><TwitterIcon round size={ICON_SIZE}/></TwitterShareButton>
           {/* <WhatsappShareButton className='sharing' url={url}><WhatsappIcon round size={ICON_SIZE}/></WhatsappShareButton>*/}
        </div>
    )
}

export default  Sharing

Sharing.propTypes = {
    url: PropTypes.string.isRequired
}

Sharing.defaultProps = {

}
