import React, {useContext} from 'react'
import ServiceContext from '../../context/ServiceContext'
import {Form, Header} from "semantic-ui-react"
import {BundelCard} from "../../component/BundelCard"
import ReferentiebeeldDropzone from "../../component/referentiebeeld/ReferentiebeeldDropzone";
import {ComponentIdentifier} from "../../component/ComponentIdentifier";

const BundelSettingsReferentiebeeld = () => {

    const serviceContext = useContext(ServiceContext)

    return (
        <Form>
            <ComponentIdentifier displayName='BundelSettingsReferentiebeeld'/>

            <Header dividing size='huge'>{serviceContext.localeManager.getString('REFERENTIEBEELD')}</Header>

            <Form.Field>
                <label>{serviceContext.localeManager.getString('VOORVERTONING')}</label>
                <BundelCard locale={serviceContext.localeManager.locale} bundel={serviceContext.bundel}/>
            </Form.Field>

            <Form.Field>
                <label>{serviceContext.localeManager.getString('REFERENTIEBEELD')}</label>
                <ReferentiebeeldDropzone provider={serviceContext.bundel}/>
            </Form.Field>
        </Form>
    )
}

export default BundelSettingsReferentiebeeld
