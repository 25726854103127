import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {Button} from "semantic-ui-react";
import ServiceContext from '../context/ServiceContext'
import {EmbargoUitzondering} from "@ahsmediatheek/ada-lib";
import AdaUserSearch from "../component/AdaUserSearch";
import EmbargoUitzonderingRenderer from "./EmbargoUitzonderingRenderer";
import {ComponentIdentifier} from "../component/ComponentIdentifier";


const EmbargoUitzonderingen = ({embargo, onUpdate}) => {

    const [uitzonderingen, setUitzonderingen] = useState()
    const [adaUserSearchModalOpen, setAdaUserSearchModalOpen] = useState(false)

    const serviceContext = useContext(ServiceContext)

    useEffect(() => {
        if (!embargo) return

        if (!embargo.uitzonderingen) {
            setUitzonderingen([])
        } else {
            setUitzonderingen(embargo.uitzonderingen)
        }
    }, [embargo])

    const onVoegUitzonderingToe = () => {
        setAdaUserSearchModalOpen(true)
    }

    const onAdaUsersSelect = (users) => {
        setAdaUserSearchModalOpen(false)

        const nieuweUitzonderingen = users
            .filter(user => {
                //bestaat al?
                for (const uitzondering of uitzonderingen) {
                    if (!uitzondering) return false
                    if (uitzondering.gebruiker === user.id) return false
                }
                return true
            })
            .map(user => {
                const uitzondering = new EmbargoUitzondering()
                uitzondering.gebruiker = user.id
                return uitzondering
            })

        onUpdate(uitzonderingen.concat(nieuweUitzonderingen))
    }

    const onUitzonderingUpdate = (uitzondering) => {
        onUpdate(uitzonderingen)
    }

    const onUitzonderingDelete = (uitzondering) => {
        const nieuweUitzonderingen = uitzonderingen.filter(uitz => uitz !== uitzondering)
        setUitzonderingen(nieuweUitzonderingen)
        onUpdate(nieuweUitzonderingen)
    }

    if (!uitzonderingen) return null

    return (
        <div>
            <ComponentIdentifier displayName='EmbargoUitzonderingen'/>

            {uitzonderingen.map((uitzondering, index) => {
                return <EmbargoUitzonderingRenderer key={index} uitzondering={uitzondering} onUpdate={() => onUitzonderingUpdate(uitzondering)} onDelete={onUitzonderingDelete}/>
            })}

            <Button content={serviceContext.localeManager.getString('VOEG_ITEM_TOE').replace('[[item]]', serviceContext.localeManager.getString('UITZONDERING').toLowerCase())}
                    size='mini'
                    onClick={onVoegUitzonderingToe}/>

            {adaUserSearchModalOpen &&
            <AdaUserSearch modalOpen={adaUserSearchModalOpen} rol='uitzondering'
                           onUsersSelect={onAdaUsersSelect}/>}
        </div>
    )
}

export default EmbargoUitzonderingen

EmbargoUitzonderingen.propTypes = {
    embargo: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,

}

EmbargoUitzonderingen.defaultProps = {}
