import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {add, format, formatISO9075, roundToNearestMinutes} from 'date-fns'
import {Button, Segment, Form, Input, Modal} from "semantic-ui-react";
import ServiceContext from "../../context/ServiceContext";
import {Toegangsticket, DateUtil, InfoViewer, DatePickerInput} from "@ahsmediatheek/ada-lib";
import ToegangsticketRenderer from "./ToegangsticketRenderer";
import {ComponentIdentifier} from "../ComponentIdentifier";

const AANMAAK_STATUS_IDLE = 'idle'
const AANMAAK_STATUS_CREATING = 'creating'

const ToegangsticketCreator = ({onMaakTicket}) => {

    const [aanmaakStatus, setAanmaakStatus] = useState(AANMAAK_STATUS_IDLE)
    const [emailValid, setEmailValid] = useState(false)
    const serviceContext = useContext(ServiceContext)
    const [toegangsticket, setToegangsticket] = useState()
    const [previewModalOpen, setPreviewModalOpen] = useState(false)

    const createToegangsticketButtonClickHandler = () => {
        setAanmaakStatus(AANMAAK_STATUS_CREATING)
        const tt = new Toegangsticket()
        tt.aangemaakt_door = serviceContext.privilegeManager.adaUser.id
        tt.begunstigde = ''
        tt.datum_aanmaak = DateUtil.getNowMysql()
        const vd = add(roundToNearestMinutes(new Date(), {nearestTo: 30}), {
            weeks: 2,
        })
        tt.geldig_van = tt.datum_aanmaak
        tt.geldig_tot = formatISO9075(vd)
        setToegangsticket(tt)
    }

    const onChange = (event, {name, value}) => {
        setToegangsticket({...toegangsticket, [name]: value})
        setEmailValid(emailIsValid(value))
    }

    const onDateChange = (name, value) => {
        setToegangsticket({...toegangsticket, [name]: value})
    }

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const onCancelButton_clickHandler = () => {
        setEmailValid(false)
        setAanmaakStatus(AANMAAK_STATUS_IDLE)
    }

    const onMaakToegangsticketButton_clickHandler = () => {
        setPreviewModalOpen(true)
    }


    const onMaakToegangsticketConfirmButton_clickHandler = () => {
        setPreviewModalOpen(false)
        setEmailValid(false)
        setAanmaakStatus(AANMAAK_STATUS_IDLE)
        onMaakTicket && onMaakTicket(toegangsticket)
    }

    return (
        <Segment>
            <ComponentIdentifier displayName='ToegangsticketCreator'/>

            {aanmaakStatus === AANMAAK_STATUS_IDLE && <Button color='green' onClick={createToegangsticketButtonClickHandler}>{serviceContext.localeManager.getString('MAAK_TOEGANGSTICKET')}</Button>}

            {aanmaakStatus === AANMAAK_STATUS_CREATING && toegangsticket &&
            <Form>

                <Form.Field required error={!emailValid}>
                    <label>{serviceContext.localeManager.getString('BEGUNSTIGDE')}</label>
                    <InfoViewer editable={serviceContext.infoEditable}
                                refId={'INFO_TOEGANGSTICCKET_BEGUNSTIGDE'}
                                localeManager={serviceContext.localeManager}
                                localeDataService={serviceContext.infoLocaleDataService}/>
                    <Input name='begunstigde' value={toegangsticket.begunstigde} placeholder={serviceContext.localeManager.getString('VUL_MAIL_ADRES_IN')} onChange={onChange}/>
                </Form.Field>

                <Form.Field>
                    <label>{serviceContext.localeManager.getString('GELDIGHEID')}</label>

                    <div style={{display: 'flex'}}>
                        <DatePickerInput label={serviceContext.localeManager.getString('VAN')} useTime datestring={toegangsticket.geldig_van}
                                         onChange={(ds) => onDateChange('geldig_van', ds)}/>
                        <DatePickerInput label={serviceContext.localeManager.getString('TOT')} useTime datestring={toegangsticket.geldig_tot}
                                         onChange={(ds) => onDateChange('geldig_tot', ds)}/>
                    </div>

                </Form.Field>

                <div>
                    <Button onClick={onCancelButton_clickHandler}>{serviceContext.localeManager.getString('ANNULEER')}</Button>
                    <Button disabled={!emailValid} onClick={onMaakToegangsticketButton_clickHandler}>Maak toegangsticket</Button>

                </div>
            </Form>}

            {toegangsticket && <Modal open={previewModalOpen} onClose={() => setPreviewModalOpen(false)} dimmer='inverted'>
                <Modal.Header>{serviceContext.localeManager.getString('PREVIEW_NIEUW_TOEGANGSTICKET_HEADER')}</Modal.Header>

                <Modal.Content>
                    <ToegangsticketRenderer toegangsticket={toegangsticket} showButtons={false}/>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={() => setPreviewModalOpen(false)}>{serviceContext.localeManager.getString('ANNULEER')}</Button>
                    <Button onClick={onMaakToegangsticketConfirmButton_clickHandler}>
                        {serviceContext.localeManager.getString('MAAK_TOEGANGSTICKET')}
                    </Button>
                </Modal.Actions>
            </Modal>}

        </Segment>
    )
}

export default ToegangsticketCreator

ToegangsticketCreator.propTypes = {
    onMaakTicket: PropTypes.func
}

ToegangsticketCreator.defaultProps = {}
