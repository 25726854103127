import React, {useState, useEffect, useRef, useCallback} from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'
import {Flag, Label, Icon, TextArea, Input, Form, Segment, Divider} from "semantic-ui-react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext';
import '../index.css'

const LocaleContentViewer = ({
                                 placeholder, onChange, onLocaleChange, noLocaleWarning, editMode, singleLine, localeContent, referenceId, dataField,
                                 maxChars, maxCharsLabel, optimalChars, optimalCharsLabel, textAreaHeight, previewFunction
                             }) => {

    const [data, setData] = useState()
    const [previewData, setPreviewData] = useState('')
    const [localeContentObject, setLocaleContentObject] = useState()
    const [locale, setLocale] = useState('nl')
    const [simpleText, setSimpleText] = useState()
    const [optimalDivStyle, setOptimalDivStyle] = useState({})

    const maxCharsRef = useRef()
    const optimalCharsRef = useRef()

    const getLocaleObject = useCallback(() => {
        const lo = localeContent.reduce((a, o) => {
            if (o.locale === locale && o.field === dataField) return o
            return a
        })
        return lo
    }, [localeContent, dataField, locale])

    const handleMaxChars = useCallback((input) => {
        if (!input) input = ''
        if (maxChars && input.length > maxChars) {
            input = input.substr(0, maxChars)
            if (maxCharsRef.current) {
                maxCharsRef.current.classList.add('warn')
                const timeout = setTimeout(() => {
                    clearTimeout(timeout)
                    maxCharsRef.current.classList.remove('warn')
                }, 1000)
            }
        }
        return input
    }, [maxChars])

    const handleOptimalChars = useCallback((input) => {
        if (!input) input = ''

        if (!optimalChars) return

        const offset = Math.abs(input.length - optimalChars)
        const offsetPercentage = offset / optimalChars * 100

        const opacity = 1 - offsetPercentage / 100
        //const color = offsetPercentage < 30 ? 'white' : 'black'
        setOptimalDivStyle({backgroundColor: `rgba(70, 222, 96,${opacity})`})
    }, [optimalChars])

    useEffect(() => {
        if (!localeContent || !referenceId) return
        const lco = getLocaleObject()
        if (lco.refId.toString() !== referenceId.toString()) return

        let content = handleMaxChars(lco.content)
        handleOptimalChars(content)

        if (editMode === 'html') {
            setData(content)
            previewFunction && setPreviewData(previewFunction(content))
        } else {
            setSimpleText(content)
        }
        setLocaleContentObject(lco)
    }, [localeContent, locale, dataField, editMode, getLocaleObject, handleMaxChars, handleOptimalChars, previewFunction, referenceId])

    const changeLocale = (loc) => {
        setLocale(loc)
        const lco = getLocaleObject()
        setLocaleContentObject(lco)
        //console.log('CONTENT', lco)
        if (!lco.content) lco.content = ''
        let content = handleMaxChars(lco.content)
        handleOptimalChars(content)
        setData(content)
        onLocaleChange && onLocaleChange(loc)
    }



    /*const handleSubstitution = (input) => {
        let regex
        regex = /\[\[ICON ([a-z ]*)\]\]/gm
        input = input.replace(regex, '<i aria-hidden="true" class="$1 icon"></i>')

        return input
    }*/

    const onEdit = (data, editor) => {
        data = handleMaxChars(data)
        handleOptimalChars(data)

        //data = handleSubstitution(data)

        if (referenceId !== localeContentObject.refId) return
        if (data === localeContentObject.content) return
        if (localeContentObject.locale !== locale) return

        const viewItem = editor.editing.view.document.getRoot()
        const plainText = viewToPlainText(viewItem)
        onChange && onChange(localeContentObject, data, plainText)

        previewFunction && setPreviewData(previewFunction(data))
    }

    const onChangePlainText = (event, {value}) => {
        value = handleMaxChars(value)
        handleOptimalChars(value)

        if (localeContentObject.locale !== locale) return
        setSimpleText(value)
        onChange && onChange(localeContentObject, value)
    }



    const getWarningLabels = () => {
        return (localeContent.map((lco, i) => {
            const hasContent = lco.content !== '' && lco.content !== '<p></p>'
            if (lco.field === dataField && !hasContent)
                return <Label key={i} style={{fontSize: '0.5rem'}}><Icon name='warning sign' color='red'/>{`${noLocaleWarning} ${lco.locale}`}</Label>

            return null
        }))
    }

    const onInitCKEditor = (props, editor) => {
        //Array.from( editor.ui.componentFactory.names() );
        //console.log('INIT', editor)
    }

    if (!localeContent || !localeContentObject) return null

    return (
        <div style={{padding: '5px'}}>

            <div style={{display: 'flex', justifyContent: 'space-between', zoom: 1.5, cursor: 'pointer', borderBottom: '1px solid lightgrey', paddingBottom: '2px'}}>
                {/*<div>{localeContentObject.id}</div>*/}
                <div>
                    <Flag name='be' size='huge'
                          onClick={() => changeLocale('nl')}
                          style={{filter: locale !== 'nl' && 'grayscale()', opacity: locale === 'nl' ? 1 : 0.5}}/>
                    <Flag name='gb' size='huge'
                          onClick={() => changeLocale('en')}
                          style={{filter: locale !== 'en' && 'grayscale()', opacity: locale === 'en' ? 1 : 0.5}}/>
                </div>
                <div>
                    {getWarningLabels()}
                </div>
            </div>

            {(maxChars || optimalChars) && <div style={{margin: '5px 0 5px 0', display: 'flex', fontSize: '0.8em'}}>
                {optimalChars &&
                <div ref={optimalCharsRef} className='info-div' style={optimalDivStyle}>{optimalCharsLabel}: <strong>{optimalChars}</strong> ({data ? data.length : simpleText ? simpleText.length : 0}}
                </div>}
                {maxChars && <div ref={maxCharsRef} className='info-div'>{maxCharsLabel}: <strong>{maxChars}</strong> ({data ? data.length : simpleText ? simpleText.length : 0}}</div>}
            </div>}

            {editMode === 'html' && <CKEditor
                config={{

                    /*removePlugins: ['ImageUpload', 'Table'],*/
                    placeholder: placeholder,
                    language: 'nl',
                    link: {
                        addTargetToExternalLinks: true
                    }
                }}
                onReady={onInitCKEditor}
                editor={InlineEditor}
                data={data}
                onChange={(event, editor) => onEdit(editor.getData(), editor)}

            />}

            {editMode === 'plain' && <Form.Field>
                {console.log("BUILD 21:20 - whiteSpace removed Test")}
                {singleLine && <Input fluid name='simpleText' value={simpleText} onChange={onChangePlainText} placeholder={placeholder}/>}
                {!singleLine && <TextArea name='simpleText' style={{height: textAreaHeight ? textAreaHeight + 'px' : ''}} value={simpleText} onChange={onChangePlainText} placeholder={placeholder}/>
                }
                
            </Form.Field>}

            {previewData && <Segment>
                <label>Preview</label>
                <Divider/>
                <div>{Parser(previewData)}</div>
            </Segment>}
        </div>
    )
}

export default LocaleContentViewer

LocaleContentViewer.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    localeContent: PropTypes.array.isRequired,
    referenceId: PropTypes.number.isRequired,
    dataField: PropTypes.string.isRequired,
    onLocaleChange: PropTypes.func,
    editMode: PropTypes.oneOf(['plain', 'html']),
    noLocaleWarning: PropTypes.string,
    singleLine: PropTypes.bool,
    maxChars: PropTypes.number,
    maxCharsLabel: PropTypes.string,
    optimalChars: PropTypes.number,
    optimalCharsLabel: PropTypes.string,
    textAreaHeight: PropTypes.number,
    previewFunction: PropTypes.func
}

LocaleContentViewer.defaultProps = {
    placeholder: 'Start met typen...',
    editMode: 'html',
    noLocaleWarning: 'Let op: geen inhoud voor locale',
    singleLine: false,
    maxCharsLabel: 'Maximum aantal karakters',
    optimalCharsLabel: 'Optimaal aantal karakters',
}
