import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {useLocation} from "react-router-dom"
import MetadataContainer from "../component/MetadataContainer";
import {AdaUserRol, BundelIcon, EmbargoIcon, Spacer, ApplicationPath, ResultContainer, BundelStatus, Embargo} from "@ahsmediatheek/ada-lib";
import {Button, Divider, Input, Segment, Dropdown, Icon , Label} from "semantic-ui-react";
import {TrefwoordenViewer} from '@ahsmediatheek/med-component-trefwoorden'

import ServiceContext from '../context/ServiceContext'

import AdaUserDisplay from "../component/AdaUserDisplay";
import {BundelCard} from "../component/BundelCard";
import DropzoneBundels from "../component/dropzone/DropzoneBundels";
import Sharing from "../component/sharing/Sharing";
import DropzoneBundelsCsv from "../component/dropzone/DropzoneBundelsCsv";
import ExportXls from "../component/export/ExportXls";
import {ComponentIdentifier} from "../component/ComponentIdentifier";
import NieuweBundelWizard from './bundel/nieuweBundelWizard/NieuweBundelWizard';
import CompilatieZoeker from './CompilatieZoeker';

const VIEW_BUNDELS = 'viewBundels'
const NIEUWE_BUNDEL_WIZARD = 'nieuweBundelWizzard'
const UPLOAD_BUNDELS = 'uploadBundels'
const IMPORTEER_CSV = 'importeerCsv'
const EXPORTEER_XLS = 'exporteerXls'
const VOEG_TOE_AAN_COMPILATIE = 'voegToeAanCompilatie'

const CollectieDetailMainView = ({onAddBundel}) => {

    const [view, setView] = useState(VIEW_BUNDELS)
    const [filterText, setFilterText] = useState('')
    const [filteredBundels, setFilteredBundels] = useState()
    const [groepeerOptions, setGroepeerOptions] = useState()
    const [selectedGroepeerOption, setSelectedGroepeerOption] = useState('geen')
    const [trefwoordenArray , setTrefwoordenArray] = useState([])

    const serviceContext = useContext(ServiceContext)

    const location = useLocation()

    useEffect(() => {
        if (serviceContext.cachedFilterData && serviceContext.cachedFilterData.filterText)
            setFilterText(serviceContext.cachedFilterData.filterText)
        if (serviceContext.cachedFilterData && serviceContext.cachedFilterData.groepeerOption)
            setSelectedGroepeerOption(serviceContext.cachedFilterData.groepeerOption)
        //filterBundels()
    }, [serviceContext.bundels, serviceContext.cachedFilterData, serviceContext.filterGemarkeerd])

    useEffect(() => {
        setGroepeerOptions(
            [
                {key: 'geen', text: serviceContext.localeManager.getString('GEEN'), value: 'geen'},
                {key: 'status', text: serviceContext.localeManager.getString('STATUS'), value: 'status'},
                {key: 'embargo_metadata', text: 'Embargo ' + serviceContext.localeManager.getString('METADATA'), value: 'embargo_metadata'},
                {key: 'embargo_inhoud', text: 'Embargo ' + serviceContext.localeManager.getString('BUNDEL_INHOUD'), value: 'embargo_inhoud'},
            ]
        )
    }, [serviceContext.localeManager])

    useEffect(() => {
        setFilteredBundels(serviceContext.bundels.filter(b => {
            const searchString = (b.titel_nl + b.titel_en + b.subtitel_nl + b.subtitel_nl + b.trefwoorden + b.gebruikersSearchString).toLowerCase()
            return searchString.indexOf(filterText.toLowerCase()) > -1
        }))
    }, [filterText, serviceContext.bundels])

    const onFilterTextChange = (event, {value}) => {
        setFilterText(value)
        serviceContext.setCachedFilterData({...serviceContext.setCachedFilterData, filterText: value})
    }

    useEffect(() => {
        //let trefwoordenString = serviceContext.collectie.trefwoorden
        const trefwoorden = serviceContext.collectie.trefwoorden
            .split('|')
            //.concat(serviceContext.bundelCollectieCollectie.trefwoorden
            //    .split('|')).filter(tw => tw !== '')
        //setTrefwoorden(trefwoorden.join('|'))
        setTrefwoordenArray(trefwoorden)
    } , [onAddBundel])

    const onGroepeerOptionChange = (event, {name, value}) => {
        setSelectedGroepeerOption(value)
        serviceContext.setCachedFilterData({...serviceContext.setCachedFilterData, groepeerOption: value})

    }

    const onUploadsComplete = () => {
        serviceContext.bundelService.getCollectieBundels(serviceContext.collectie, true).then(bundels => {
            setFilteredBundels(bundels)
            setView(VIEW_BUNDELS)
        })
    }

    function onSelectCompilatie(compilatie) {
        serviceContext.compilatieService.addToCompilatie(compilatie, 'collectie', serviceContext.collectie.uuid);

        alert(serviceContext.localeManager.getString('COLLECTIE_TOEGEVOEGD_AAN_COMPILATIE'));

        setView(VIEW_BUNDELS);
    }

    if (!filteredBundels) return null

    const isValidUuid = (string) => {
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        return regexExp.test(string);
    }

    const convertTrefwoord = (string) => {
        let twSplit = string.split('_')
        let parts = []
        twSplit.map((part) => {
            if(!isValidUuid(part)) parts.push(part)
        })

        return parts.join(' ')
    }

    return (
        <div>
            <ComponentIdentifier displayName='CollectieDetailMainView'/>

            <MetadataContainer provider={serviceContext.collectie} locale={serviceContext.localeManager.locale}/>

            <Spacer vertical distance={15}/>

            <Segment>

                {serviceContext.collectieAdaUserLinks &&
                <AdaUserDisplay adaUserLinks={serviceContext.collectieAdaUserLinks}
                                rollen={AdaUserRol.COLLECTIE_ROLLEN}/>
                }

                {serviceContext.collectie.trefwoorden !== '' && <div>

                    <Spacer vertical distance={20}/>
                    { 
                        trefwoordenArray.map((trefwoord, idx) => {
                                {
                                return <Label tag key={idx}>
                                        {convertTrefwoord(trefwoord)}
                                    </Label>
                                }
                            })
                            }
                    {/*
                    <TrefwoordenViewer consumerKey={serviceContext.TREFWOORDEN_CONSUMER_KEY}
                                       readonly
                                       trefwoordenString={serviceContext.collectie.trefwoorden}
                                       locale={serviceContext.localeManager.locale}/>
                    */}
                </div>}
            </Segment>

            <Sharing url={ApplicationPath.ADA_HOST + location.pathname}/>


            <Segment clearing>
                {serviceContext.privilegeManager.canAddBundel(serviceContext.collectie, serviceContext.collectieAdaUserLinks) && <Fragment>
                    <Button onClick={() => setView(VIEW_BUNDELS)}>{serviceContext.localeManager.getString('TOON_INHOUD')}</Button>
                    <Button onClick={() => setView(NIEUWE_BUNDEL_WIZARD)}>{serviceContext.localeManager.getString('VOEG_ITEM_TOE').replace('[[item]]', serviceContext.localeManager.getString('BUNDEL').toLowerCase())}</Button>
                    <Button onClick={() => setView(UPLOAD_BUNDELS)}>{serviceContext.localeManager.getString('UPLOAD_BUNDELS')}</Button>
                    <Button onClick={() => setView(IMPORTEER_CSV)}>{serviceContext.localeManager.getString('IMPORTEER_CSV')}</Button>
                </Fragment>}
                
                {serviceContext.privilegeManager.canAddCompilatie() && (
                    <Button onClick={() => setView(VOEG_TOE_AAN_COMPILATIE)}>{serviceContext.localeManager.getString('VOEG_COLLECTIE_TOE_AAN_COMPILATIE')}</Button>
                )}

                {serviceContext.privilegeManager.canExportExcel(serviceContext.collectie, serviceContext.collectieAdaUserLinks) &&
                <ExportXls collectie={serviceContext.collectie} bundels={filteredBundels} collectieAdaUserLinks={serviceContext.collectieAdaUserLinks}/>}
            </Segment>

            {view === VIEW_BUNDELS &&

            <Fragment>

                <Divider/>

                <div style={{display: 'flex', alignItems: "center"}}>

                    <Input style={{marginLeft: 'auto', width: '80%', marginRight: '10px'}}
                           icon={{name: 'delete', color: 'grey', link: true, onClick: () => setFilterText('')}}
                           placeholder='Filter'
                           value={filterText}
                           onChange={onFilterTextChange}/>

                    <span style={{flexShrink: 0, marginRight: '5px'}}>{serviceContext.localeManager.getString('GROEPEER_OP')}: </span>

                    {groepeerOptions && <Dropdown selection options={groepeerOptions} value={selectedGroepeerOption}
                                                  onChange={onGroepeerOptionChange}/>}
                </div>

                <Divider/>

                {selectedGroepeerOption === 'geen' &&

                <ResultContainer containerWidth={serviceContext.resultContainerAvailableWidth}
                                 header={serviceContext.localeManager.getString('BUNDEL') + 's'}
                                 headerIcon={<BundelIcon size='large' style={{marginRight: '10px'}}/>}
                                 display={serviceContext.resultDisplay} setDisplay={serviceContext.setResultDisplay}>

                    {filteredBundels && filteredBundels.map((bundel, index) => {
                        return <BundelCard key={index} toonGemarkeerdIcon={true}
                                           display={serviceContext.resultDisplay}
                                           showStatus={serviceContext.privilegeManager.canViewBundelStatusInCard(bundel, serviceContext.bundelAdaUserLinks, serviceContext.collectieAdaUserLinks)}
                                           showBundelUuid={serviceContext.privilegeManager.isAdmin()}
                                           bundel={bundel}
                                           locale={serviceContext.localeManager.locale}/>

                    })}
                </ResultContainer>}

                {selectedGroepeerOption === 'status' && filteredBundels && BundelStatus.STATUS.map((status, index) => {
                        const statusBundels = filteredBundels.filter(bundel => bundel.status === status)
                        if (statusBundels.length === 0) return null
                        return <ResultContainer key={index} containerWidth={serviceContext.resultContainerAvailableWidth}
                                                header={serviceContext.localeManager.getString(status)}
                                                headerIcon={<BundelIcon size='normal' style={{marginRight: '10px'}}/>}
                                                display={serviceContext.resultDisplay}>

                            {statusBundels.map((bundel, index) => {
                                return <BundelCard key={index} toonGemarkeerdIcon={true}
                                                   display={serviceContext.resultDisplay}
                                                   showBundelUuid={serviceContext.privilegeManager.isAdmin()}
                                                   bundel={bundel}
                                                   locale={serviceContext.localeManager.locale}/>

                            })}
                        </ResultContainer>
                    }
                )}

                {selectedGroepeerOption === 'embargo_metadata' && filteredBundels && Embargo.VALUES.map(embargo => {
                        const embargoBundels = filteredBundels.filter(bundel => {
                            const eo = bundel.embargo && JSON.parse(bundel.embargo)
                            if (!eo) return embargo === Embargo.EMBARGO_IEDEREEN
                            return eo.metadata === embargo
                        })
                        if (embargoBundels.length === 0) return null
                        return <ResultContainer containerWidth={serviceContext.resultContainerAvailableWidth}
                                                header={serviceContext.localeManager.getString(embargo)}
                                                headerIcon={<BundelIcon size='normal' style={{marginRight: '10px'}}/>}
                                                display={serviceContext.resultDisplay}>
                            {embargoBundels.map((bundel, index) => {
                                return <BundelCard key={index} toonGemarkeerdIcon={true}
                                                   display={serviceContext.resultDisplay}
                                                   showBundelUuid={serviceContext.privilegeManager.isAdmin()}
                                                   bundel={bundel}
                                                   locale={serviceContext.localeManager.locale}/>

                            })}
                        </ResultContainer>
                    }               )}

                {selectedGroepeerOption === 'embargo_inhoud' && filteredBundels && Embargo.VALUES.map((embargo, index) => {
                        const embargoBundels = filteredBundels.filter(bundel => {
                            const eo = bundel.embargo && JSON.parse(bundel.embargo)
                            if (!eo) return embargo === Embargo.EMBARGO_IEDEREEN
                            return eo.bundelInhoud === embargo
                        })
                        if (embargoBundels.length === 0) return null
                        return <ResultContainer key={index} containerWidth={serviceContext.resultContainerAvailableWidth}
                                                header={serviceContext.localeManager.getString(embargo)}
                                                headerIcon={<EmbargoIcon size='large' style={{marginRight: '10px'}}/>}
                                                display={serviceContext.resultDisplay}>
                            {embargoBundels.map((bundel, index) => {
                                return <BundelCard key={index} toonGemarkeerdIcon={true} fromCollectie={true}
                                                   display={serviceContext.resultDisplay}
                                                   showBundelUuid={serviceContext.privilegeManager.isAdmin()}
                                                   bundel={bundel}
                                                   locale={serviceContext.localeManager.locale}/>

                            })}
                        </ResultContainer>
                    }
                )}

            </Fragment>
            }

            {view === NIEUWE_BUNDEL_WIZARD && <NieuweBundelWizard onAddBundel={onAddBundel}/>}

            {view === UPLOAD_BUNDELS && <DropzoneBundels collectie={serviceContext.collectie}
                                                         onUploadsComplete={onUploadsComplete}
                                                         onCancel={() => setView(VIEW_BUNDELS)} 
                                                         uploadConfig={serviceContext.applicationService.config.upload}
                                                         />}

            {view === IMPORTEER_CSV && <DropzoneBundelsCsv collectie={serviceContext.collectie}
                                                           onUploadsComplete={onUploadsComplete}
                                                           onCancel={() => setView(VIEW_BUNDELS)}/>}

            {view === VOEG_TOE_AAN_COMPILATIE && <CompilatieZoeker onSelect={onSelectCompilatie} />}

            {view === EXPORTEER_XLS && <ExportXls/>}
        </div>
    )
}

export default CollectieDetailMainView

CollectieDetailMainView.propTypes =
    {
        onAddBundel: PropTypes.func
    }

CollectieDetailMainView.defaultProps =
    {}
