import React, {useState, useEffect, useContext, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Header, Segment, Icon, Form, Message , Label} from "semantic-ui-react";
import ServiceContext from '../../context/ServiceContext'
import {bundelCsvErrors} from "./BundelCsvErrors";
import {AdaUserCard, MetadataUtil} from "@ahsmediatheek/ada-lib";
import {ComponentIdentifier} from "../ComponentIdentifier";

const VirtualBundelRenderer = ({bundel, keyNumber, invalidateUploadButton}) => {

    const [inzenders, setInzenders] = useState()
    const [invalidInzenders, setInvalidInzenders] = useState()
    const [createdInzenders, setCreatedInzenders] = useState()

    const [trefwoorden , setTrefwoorden] = useState()

    const [promotors, setPromotors] = useState()
    const [invalidPromotors, setInvalidPromotors] = useState()
    const [createdPromotors, setCreatedPromotors] = useState()
    const [compilaties,setCompilaties] = useState()
    const [unusedFields , setUnusedFields] = useState([])

    const serviceContext = useContext(ServiceContext)

    //inzenders
    useEffect(() => {
        if (!bundel || !bundel.bundelData) return
        if (bundel.bundelData.inzenders === '' || bundel.bundelData.inzenders === undefined ) {
            setInzenders([])
            return
        }
        /*
        bundel.bundelData.inzenders = (bundel.bundelData.inzenders).trim().toLowerCase()
        */
        let checkInzenders = (bundel.bundelData.inzenders).split(',')
        let inzenderArray = []
        checkInzenders.map(inzender => {
            if(inzender !== '') {
                inzender = inzender.trim()
                console.log(inzender)
                inzenderArray.push(inzender)
            }
        })
        bundel.bundelData.inzenders = inzenderArray.join(',')
        
        serviceContext.adaUserService.searchAdaUsers(bundel.bundelData.inzenders).then(res => {
            setInzenders(res)
            bundel.inzenders = res
            const invalidInzenders = []
            const createdInzenders = []
            const validInzenderMails = res.map(inz => inz.email)
            const validInzenderFullMails = res.map(inz => inz.fullMail)
            for (const mail of bundel.bundelData.inzenders.split(',')) {
                //if (!validInzenderMails.includes(mail)) invalidInzenders.push(mail)
               
                    if (!validInzenderMails.includes(mail) && !validInzenderFullMails.includes(mail)) bundel.inzenders.push({ email : mail , id : 0 , complete : 0  })
                
                
            }

            if (invalidInzenders.length > 0) invalidateUploadButton()
            setInvalidInzenders(invalidInzenders)
            setCreatedInzenders(createdInzenders)
        })
    }, [bundel])
    
    //promotors
    useEffect(() => {
        if (!bundel || !bundel.bundelData) return
        if (bundel.bundelData.promotors === '' || bundel.bundelData.promotors === undefined) {
            setPromotors([])
            return
        }
        /*
        bundel.bundelData.promotors = (bundel.bundelData.promotors).trim().toLowerCase()
        */
        let checkPromotors = (bundel.bundelData.promotors).split(',')
        let promotorArray = []
        checkPromotors.map(promotor => {
            if(promotor !== '') {
                promotor = promotor.trim()
                console.log(promotor)
                promotorArray.push(promotor)
            }
        })
        bundel.bundelData.promotors = promotorArray.join(',')
        
        serviceContext.adaUserService.searchAdaUsers(bundel.bundelData.promotors).then(res => {
            setPromotors(res)
            bundel.promotors = res
            const invalidPromotors = []
            const createdPromotors = []
            const validPromotorMails = res.map(pro => pro.email)
            const validPromotorFullMails = res.map(pro => pro.fullMail)
            for (const mail of bundel.bundelData.promotors.split(',')) {
                console.log(mail)
                //if (!validPromotorMails.includes(mail)) invalidPromotors.push(mail)
                //if (!validPromotorMails.includes(mail)) createdPromotors.push(mail)
                
                    if (!validPromotorMails.includes(mail) && !validPromotorFullMails.includes(mail)) bundel.promotors.push({ email : mail , id : 0  , complete : 0})                
            }
            if (invalidPromotors.length > 0) invalidateUploadButton()

            setInvalidPromotors(invalidPromotors)
            setCreatedPromotors(createdPromotors)
        })
    }, [bundel])

    //compilaties
    useEffect(() => {
        if (!bundel || !bundel.bundelData) return
        if (bundel.bundelData.compilaties === '' || bundel.bundelData.compilaties === undefined) {
            setCompilaties([])
            return
        }
        let compList = bundel.bundelData.compilaties.split(',')
        console.log(compList)
        let compilatieTitels = []
        let compilatieValidUuids = []
        compList.map( (thisUuid) => {
            console.log(thisUuid)
            serviceContext.compilatieService.getCompilatie(thisUuid).then(res => {
                console.log(res)
                if(res != null) {
                    //res.map((getuuid) => {
                        compilatieTitels.push(res.titel_nl)
                        compilatieValidUuids.push(thisUuid)
                        
                    //})
                    
                }
            })
        })
        bundel.compilaties = compilatieValidUuids
        setCompilaties(compilatieTitels)
    } , [bundel])

    // trefwoorden
    useEffect(() => {
        if (!bundel || !bundel.bundelData) return
        if (bundel.bundelData.trefwoorden=== '' || bundel.bundelData.trefwoorden === undefined ) {
            setTrefwoorden([])
            return
        }
            let trefwoordenArray = []
            for (const tw of bundel.bundelData.trefwoorden.split(',')) {
                trefwoordenArray.push(tw)
            }
            setTrefwoorden(trefwoordenArray)
    }, [bundel])

    //unusedheaders
    useEffect(() => {
        setUnusedFields(bundel.bundelData.unused_headers)
     },[bundel])

    return (
        <Fragment>
            <ComponentIdentifier displayName='VirtualBundelRenderer'/>

            <div style={{marginBottom: '-10px'}}>{serviceContext.localeManager.getString('BUNDEL')} #{keyNumber}</div>

            <Segment color='teal'>
                <Form error warning>
                    <Form.Field>
                        {!bundel.titel_nl && <Message
                            error
                            icon='warning'
                            header={bundelCsvErrors.geen_titel[`header_${serviceContext.localeManager.locale}`]}
                            content={bundelCsvErrors.geen_titel[`content_${serviceContext.localeManager.locale}`]}
                        />}
                        {bundel.titel_nl && <div>
                            <Header>{MetadataUtil.resolveTitel(bundel, serviceContext.localeManager.locale)}</Header>
                            {bundel.titel_nl.length > 80 && <Message warning icon='warning'
                                                                     header={bundelCsvErrors.titel_te_lang[`header_${serviceContext.localeManager.locale}`]}
                                                                     content={bundelCsvErrors.titel_te_lang[`content_${serviceContext.localeManager.locale}`]}
                            />}
                        </div>}

                    </Form.Field>

                    {bundel[`subtitel_${serviceContext.localeManager.locale}`] !== '' &&
                    <Form.Field>
                        <label>{serviceContext.localeManager.getString('SUBTITEL')}</label>
                        <p>{bundel[`subtitel_${serviceContext.localeManager.locale}`] || bundel.subtitel_nl}</p>
                    </Form.Field>}
                    {bundel[`abstract_${serviceContext.localeManager.locale}`] && bundel[`abstract_${serviceContext.localeManager.locale}`] !== '' &&
                    <Form.Field>
                        <label>{serviceContext.localeManager.getString('ABSTRACT')}</label>
                        { bundel[`abstract_${serviceContext.localeManager.locale}`].length > 255 &&
                            <p>{bundel[`abstract_${serviceContext.localeManager.locale}`].substring(0,255)}...</p>
                        }
                        { bundel[`abstract_${serviceContext.localeManager.locale}`].length <= 255 &&
                            <p>{bundel[`abstract_${serviceContext.localeManager.locale}`]}</p>
                        }
                        
                    </Form.Field>
                    }
                    <Form.Field>
                        <label>{serviceContext.localeManager.getString('INZENDERS')}</label>
                        {inzenders && inzenders.length === 0 && <Message error icon='warning'
                                                                         header={bundelCsvErrors.geen_inzenders[`header_${serviceContext.localeManager.locale}`]}
                                                                         content={bundelCsvErrors.geen_inzenders[`content_${serviceContext.localeManager.locale}`]}
                        />
                        
                        }

                        {inzenders && inzenders.map(inzender => {
                            inzender.complete = parseInt(inzender.complete)
                            inzender.id = parseInt(inzender.id)
                            switch(inzender.complete) {
                            case 0:
                                switch(inzender.id) {
                                    case 0:
                                        return <div key={inzender.id} style={{display: 'flex', alignItems: 'center', margin: '5px'}}>
                                   <Icon name='check' color='green'/><Icon name='info' color='blue'/>
                                   <span><i>{inzender.email} : {serviceContext.localeManager.getString('NIEUW_PROFIEL_ZAL_WORDEN_AANGEMAAKT')}</i></span>
                                </div> 

                                    default:
                                        return <div key={inzender.id} style={{display: 'flex', alignItems: 'center', margin: '5px'}}>
                                        <Icon name='check' color='green'/><Icon name='info' color='blue'/>
                                        <span><i>{inzender.email} : {serviceContext.localeManager.getString('PROFIEL_BESTAAT_MAAR_ONVOLLEDIG')}</i></span>
                                     </div> 

                                }

                            case 1:
                                return <div key={inzender.id} style={{display: 'flex', alignItems: 'center', margin: '5px'}}>
                                {inzender && inzender.complete == 1 }
                                    <Icon name='check' color='green'/>
                                    <AdaUserCard adaUser={inzender}/>
                                </div>
                            }
                            
                        })}

                        {invalidInzenders && invalidInzenders.map(inzender => {
                            return <Fragment key={inzender}>
                                <Message error icon>
                                    <Icon name='warning'/>
                                    <Message.Content>
                                        <Message.Header>{bundelCsvErrors.fout_mail_adres[`header_${serviceContext.localeManager.locale}`]}: <strong>{inzender}</strong></Message.Header>
                                        <p>{bundelCsvErrors.fout_mail_adres[`content_${serviceContext.localeManager.locale}`].replace('[[mail]]', inzender)}</p>
                                    </Message.Content>
                                </Message>
                            </Fragment>
                        })}
                    
                    </Form.Field>

                    {promotors && invalidPromotors && (promotors.length > 0 || invalidPromotors.length > 0 || createdPromotors.length > 0) && <Form.Field>
                        <label>{serviceContext.localeManager.getString('PROMOTOR')}s</label>
                        {console.log(promotors)}
                        {promotors.map(promotor => {
                            promotor.complete = parseInt(promotor.complete)
                            promotor.id = parseInt(promotor.id)
                            switch(promotor.complete) {
                               case 0:
                                    switch(promotor.id) {
                                        case 0:
                                            return <div key={promotor.id} style={{display: 'flex', alignItems: 'center', margin: '5px'}}>
                                            <Icon name='check' color='green'/><Icon name='info' color='blue'/>
                                            <span><i>{promotor.email}  : {serviceContext.localeManager.getString('NIEUW_PROFIEL_ZAL_WORDEN_AANGEMAAKT')}</i></span>
                                            </div>
                                        default:
                                            return <div key={promotor.id} style={{display: 'flex', alignItems: 'center', margin: '5px'}}>
                                            <Icon name='check' color='green'/><Icon name='info' color='blue'/>
                                            <span><i>{promotor.email}  : {serviceContext.localeManager.getString('PROFIEL_BESTAAT_MAAR_ONVOLLEDIG')}</i></span>
                                            </div>
                                    }
                                case 1 :
                                    return <div key={promotor.id} style={{display: 'flex', alignItems: 'center', margin: '5px'}}>
                                    <Icon name='check' color='green'/>
                                    <AdaUserCard adaUser={promotor}/>
                                </div>
                                break
                                
                            }
                        })}

                        {invalidPromotors && invalidPromotors.map(promotor => {
                            return <Fragment key={promotor}>
                                <Message error icon>
                                    <Icon name='warning'/>
                                    <Message.Content>
                                        <Message.Header>{bundelCsvErrors.fout_mail_adres[`header_${serviceContext.localeManager.locale}`]}: <strong>{promotor}</strong></Message.Header>
                                        <p>{bundelCsvErrors.fout_mail_adres[`content_${serviceContext.localeManager.locale}`].replace('[[mail]]', promotor)}</p>
                                    </Message.Content>
                                </Message>
                            </Fragment>
                        })}

                        
                    </Form.Field>}
                    {compilaties && compilaties.length > 0 &&
                        <Form.Field>
                        {console.log(compilaties)}
                        <label>{serviceContext.localeManager.getString('COMPILATIES')}</label>
                        {compilaties && compilaties.map(compilatie => {
                            console.log(compilatie)
                            return <div>
                                    <Icon name='folder circular inverted' color='purple'/>{compilatie}
                                </div>
                            })
                        }
                        </Form.Field>
                    }

                    {trefwoorden && trefwoorden.length > 0 &&
                        <Form.Field>
                        <label>{serviceContext.localeManager.getString('TREFWOORDEN')}</label>
                        {trefwoorden && trefwoorden.map(trefwoord => {
                            return <Label color='green' tag>
                                    {trefwoord}
                                </Label>
                            })
                        }
                        </Form.Field>
                    }

                    {unusedFields && unusedFields.length > 0 &&
                        <Form.Field>
                        <label>{serviceContext.localeManager.getString('UNUSED_FIELDS')}</label>
                        {unusedFields && unusedFields.map(unused_field => {
                            return <Label color='red'>
                                <Icon name="exclamation circle" />
                                    {(unused_field != "") ? unused_field : serviceContext.localeManager.getString('EMPTY_HEADER')}
                                </Label>
                            })
                        }
                        </Form.Field>
                    }

                </Form>

            </Segment></Fragment>
    )
}

export default VirtualBundelRenderer

VirtualBundelRenderer.propTypes = {
    bundel: PropTypes.object.isRequired,
    invalidateUploadButton: PropTypes.func.isRequired,
}
