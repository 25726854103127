import React, {useState, useEffect, useContext, useRef , useCallback, Component } from 'react'
import ReactDOMServer from 'react-dom/server';

import PropTypes from 'prop-types'
import {Segment, Button } from "semantic-ui-react";
import Dropzone from "react-dropzone";
import {InfoViewer, Bundel, BundelStatus, ResultContainer} from "@ahsmediatheek/ada-lib";
import ServiceContext from '../../context/ServiceContext'
//import {Dropzone} from 'dropzone'
import {ComponentIdentifier} from "../ComponentIdentifier";
//import './DropzoneRender'

import DropzoneComponent from 'react-dropzone-component';
//import 'react-dropzone-js/styles/filepicker.css'
import './uploadStyles.css'
import axios from 'axios';
import DropzoneBundelsMetadata from './DropzoneBundelsMetadata'
//import {ContentViewer, DownloadIcon, ReferentieitemIcon, TeKoopItemIcon, TrashIcon, ViewIcon} from "@ahsmediatheek/ada-lib";

const VIEW_IDLE = 'idle'
const VIEW_UPLOADING = 'uploading'
const VIEW_COMPLETE = 'complete'

const DropzoneBundels = ({collectie, onCancel, onUploadsComplete,uploadConfig}) => {

    const [uploading, setUploading] = useState(true)
    const [progressPercentage, setProgressPercentage] = useState(0)
    const serviceContext = useContext(ServiceContext)

    const [view, setView] = useState(VIEW_IDLE)
    //const [, setUploading] = useState(false)
    //const [progressPercentage, setProgressPercentage] = useState(0)
    const [bundels, setBundels] = useState([])
    const [rootFolders, setRootFolders] = useState([])
    const [files, setFiles] = useState([])
    const [paths, setPaths] = useState([])
    const [completedUploads, setCompletedUploads] = useState([])
    const [uploadEnabled , setUploadEnabled] = useState(true)
    const [disableCreate, setDisableCreate] = React.useState(true);
    const [disableUpload, setDisableUpload] = React.useState(true);
    const [autoProcessDropzone, setAutoProcessDropzone] = React.useState(false);
    const [filesInQueue,setFilesInQueue] = React.useState(0)
    const [showBundelsInList,setShowBundelsInList] = React.useState(0)
    const [compilaties,setCompilaties] = useState([])

    const compilatieRef = React.useRef(compilaties)

    const onAnnuleer = () => {
        setBundels([])
        setPaths([])
        setFiles([])
        setRootFolders([])
        setCompletedUploads([])
        setView(VIEW_IDLE)
        setCompilaties([])
        onCancel()
    }

    var BundelFolders = {}
    var UuidList = {}

    //const serviceContext = useContext(ServiceContext)
    const componentConfig = {
        //showFiletypeIcon: false,
        removeFile: true,
        postUrl: `${uploadConfig.multibundelUploadUrl}`,
    }

    var addToCompilaties 

    const djsConfig = {
        renameFile: (file) => {
            let newName
            if (file.fullPath) {
                newName = file.fullPath.replace(/[\.,\/,\s]/g, '_')
            } else {
                newName = file.name.replace(/[\.,\/,\s]/g, '_')
            }
            console.log('RENAMING', file.name, newName)
            return newName
        },
        clickable: false,
        params(files, xhr, chunk) {
            //console.log('PARAMS', chunk)
            if (chunk) {
                return {
                    dzuuid: chunk.file.upload.uuid,
                    dzchunkindex: chunk.index,
                    dztotalfilesize: chunk.file.size,
                    dzchunksize: this.options.chunkSize,
                    dztotalchunkcount: chunk.file.upload.totalChunkCount,
                    dzchunkbyteoffset: chunk.index * this.options.chunkSize,
                };
            }
        },
        //accept: (file, done) => {
        //    done()
        //},
        method: 'post',
        maxFilesize: eval(uploadConfig.maxFileSize),
        autoProcessQueue: autoProcessDropzone,
        chunking: true,
        forceChunking: false,
        chunkSize: eval(uploadConfig.chunkSize),
        timeout: eval(uploadConfig.timeout),
        parallelChunkUploads: true,
        parallelUploads: uploadConfig.parallelUploads,
        retryChunks: uploadConfig.retryChunks,
        retryChunksLimit: uploadConfig.retryChunksLimit,
        createImageThumbnails: false,
        disablePreviews: true,      
    }


    // TODO : rewrite dropzone handling - useRef + func, minder "klassiek" JS
    var NumOfBundels = 0
    const eventHandlers = {
        init: (dropzone) => {
                dropzone.on("addedfile", file => {             
                    setAutoProcessDropzone(false)    
                    setDisableCreate(false)
                    console.log(file)
                    if(!file.fullPath) {
                        console.warn("No Single FILES ALLOWED")
                        dropzone.removeFile(file)
                    } else {
                        let rootFolder = (file.fullPath).split('/')[0]
                        if(!BundelFolders[rootFolder]) {
                            BundelFolders[rootFolder] = 1
                        } else {
                            BundelFolders[rootFolder] = BundelFolders[rootFolder] + 1
                        }
                    }
                    NumOfBundels = Object.keys(BundelFolders).length - Object.keys(UuidList).length
                    setShowBundelsInList(NumOfBundels)
                    //document.getElementById('ShowBundelNums').innerHTML = NumOfBundels
                    setFilesInQueue(dropzone.getQueuedFiles().length)
                    let previews = document.getElementsByClassName('dz-file-preview')
                    while(previews.length > 0){
                        previews[0].parentNode.removeChild(previews[0]);
                    }
                    let DropzoneHTML = document.getElementsByClassName('dropzone')
                    for(var dzh = 0 ; dzh < Object.keys(BundelFolders).length ; dzh++) {
                        let key = Object.keys(BundelFolders)[dzh]
                        let folderKey = Object.keys(BundelFolders)[dzh].replace(/[\.,\/,\s]/g, '_')
                        let foo = document.createElement('div')
                        foo.setAttribute('id',folderKey)
                        foo.setAttribute('class','ui teal card result-card folder-preview')
                        let academiejaar = (collectie.academiejaar != null) ? collectie.academiejaar : ''
                        let opleiding = (collectie.opleiding != null) ? collectie.opleiding : ''
                        foo.innerHTML = '<div class="image"><img src="https://www.arteveldehogeschool.be/digitaalarchief-dev/referentiebeelden/_default/geen_referentiebeeld.png"></div>'
                        foo.innerHTML += '<div class="icon-group-top-left"><i aria-hidden="true" class="olive archive small circular inverted icon result-card-icon" style="cursor: default;" id="icon_files_'+folderKey+'"></i></div>'
                        foo.innerHTML += '<div class="icon-group-top-right"><div class="ui olive small circular label" id="label_files_'+folderKey+'"><span id="num_files_'+folderKey+'">'+ BundelFolders[key] +'</span></div></div>'
                        //foo.innerHTML += '<div class="content"><p class="academiejaar">'+academiejaar+'</p><div class="header">'+key+'</div><div class="meta">'+opleiding+'</div><div class="description"></div></div>'
                        foo.innerHTML += '<div class="content"><div class="header">'+key+'</div><div class="meta">'+ academiejaar + ' | ' + opleiding +'</div><div class="description"></div></div>'
                        foo.innerHTML += '<div class="ui inactive dimmer"id="dimmer_'+folderKey+'"><div class="ui text loader">Uploading</div></div>'
                        
                        console.log(document.getElementById(folderKey))
                        //for(let f = 0 ; f < DropzoneHTML.length ; f++) {
                        console.log(folderKey)
                        
                        if(document.getElementById(folderKey) === null) {
                            DropzoneHTML[0].appendChild(foo)
                        } else {
                            document.getElementById('label_files_'+folderKey).classList.add('olive');
                            document.getElementById('icon_files_'+folderKey).classList.add('olive');
                            document.getElementById("num_files_"+folderKey).innerHTML = BundelFolders[key]
                        } 
                    }             
            })
            document.getElementById('AnnuleerBundelCreate').addEventListener("click", function(e) {
                dropzone.removeAllFiles(true)
                BundelFolders = {}
                UuidList = {}
                setShowBundelsInList(0)
                let previews = document.getElementsByClassName('folder-preview')
                    while(previews.length > 0){
                        previews[0].parentNode.removeChild(previews[0]);
                    }
                setDisableCreate(true)
                console.log(dropzone.getQueuedFiles())
            })
            document.getElementById('CreateBundelButton').addEventListener("click", function(e) {
                setDisableCreate(true)
                console.log(BundelFolders)
                let BundelNames = Object.keys(BundelFolders)
                let BundelsWithUuid = Object.keys(UuidList)
                let BundelSend = {}
                for(let BundelList = 0 ; BundelList < BundelNames.length ; BundelList++) {
                    if(!BundelsWithUuid.includes(BundelNames[BundelList])) {
                        BundelSend[BundelNames[BundelList]] = BundelFolders[BundelNames[BundelList]]
                    }
                }
                let BundelProcessing = Object.keys(BundelSend)
                axios.post(uploadConfig.multibundelUploadUrl + collectie.uuid + '/', { collectie : collectie , isBundelCreateAndCheck : true , folders : BundelSend })
                    .then( response => { 
                        console.log("Received UUIDs")
                        console.log(response) 
                        let compilatieList = compilatieRef.current
                        console.log(compilatieList)
                        
                        if(compilatieList.length > 0 ) {
                            compilatieList.map((compilatie) => {
                                Object.keys(response.data.uuidList).map((fold) => {
                                    console.log(`Adding ${fold} to comilations`)
                                    console.log({uuid:compilatie},'bundel',response.data.uuidList[fold])
                                    serviceContext.compilatieService.addToCompilatie({uuid:compilatie},'bundel',response.data.uuidList[fold])
                                })
                            })
                        } 
                        
                        for(let BundelList = 0 ; BundelList < BundelProcessing.length ; BundelList++) {
                            let folderId = BundelProcessing[BundelList].replace(/[\.,\/,\s]/g, '_')
                            console.log(folderId)
                            //console.log(document.getElementById('num_files_'+folderId).classList)
                            document.getElementById('label_files_'+folderId).classList.remove('olive');
                            document.getElementById('icon_files_'+folderId).classList.remove('olive');
                            if(response.data.uuidList[BundelProcessing[BundelList]] != false) {
                                UuidList[BundelProcessing[BundelList]] = response.data.uuidList[BundelProcessing[BundelList]]
                                document.getElementById('icon_files_'+folderId).classList.add('teal');
                                document.getElementById('label_files_'+folderId).classList.add('teal');
                            } else {
                                document.getElementById('icon_files_'+folderId).classList.add('red');
                                document.getElementById('label_files_'+folderId).classList.add('red');
                            }
                        }
                        let queue = dropzone.getQueuedFiles()
                        let countAllFilesToCheck = dropzone.getQueuedFiles().length
                        for(let fList = 0 ; fList < countAllFilesToCheck ; fList++) {
                            let rootFolder = (queue[fList].fullPath).split('/')[0]
                            let rootFolderKey = rootFolder.replace(/[\.,\/,\s]/g, '_')
                            if(response.data.uuidList[rootFolder] !== false && BundelProcessing.includes(rootFolder)) {
                                queue[fList].bundelUuid = response.data.uuidList[rootFolder]
                            } else if(response.data.uuidList[rootFolder] === false && BundelProcessing.includes(rootFolder)) {
                                dropzone.removeFile(queue[fList])
                                BundelFolders[rootFolder] = BundelFolders[rootFolder] - 1;
                                document.getElementById('num_files_'+rootFolderKey).innerHTML = BundelFolders[rootFolder]
                            }
                        }

                        
                            setDisableCreate(true)                            
                            console.log(dropzone.getQueuedFiles())
                            setUploading(true)
                            setAutoProcessDropzone(true)
                            dropzone.processQueue()
                    });        
            });
        },
        
        totaluploadprogress: (percent, totalBytes, bytesLoaded) => {
            percent && setProgressPercentage(Math.round(percent))
        },
        queuecomplete: (props) => {
            setDisableCreate(true)
            setAutoProcessDropzone(false)
            setShowBundelsInList(0)
            //onUploadComplete()
            console.log("UPLOAD COMPLETE TRIGGER",collectie.uuid)
            processCompletion()

            //alert('Je bundels zijn nu opgeladen')
        },
        sending: (file, xhr, data) => {
            setDisableCreate(true)
            let dataObject = {}
            let fileUuid = (file.name).replace(/[\.,\/,\s]/g, '_')
            console.log(fileUuid)
            if (file.fullPath) {
                fileUuid = (file.fullPath).replace(/[\.,\/,\s]/g, '_')
                dataObject[fileUuid] = {}
                dataObject[fileUuid].fullPath = file.fullPath
            } else {
                dataObject[fileUuid] = {}
                dataObject[fileUuid].fullPath = ""
                data.append("fullPath", "");
            }
            let folderId = (file.fullPath).split('/')[0]
            let folderIdKey = folderId.replace(/[\.,\/,\s]/g, '_')
            document.getElementById('dimmer_'+folderIdKey).classList.remove('inactive');
            document.getElementById('dimmer_'+folderIdKey).classList.add('active');
            dataObject[fileUuid].filename = file.name
            dataObject[fileUuid].filetype = file.type
            data.append("isPartOfMultiBundelUpload",1)
            data.append( "isBundelCreateAndCheck" , 0)
            data.append( "bundelUuid" , file.bundelUuid)
            //data.append('root', root)
            data.append([fileUuid], JSON.stringify(dataObject[fileUuid]))
            console.log('SENDING', file, xhr, data, fileUuid, root, djsConfig)
        },
        chunksUploaded: (file, done) => {
            console.log('CHUNKS UPLOADED', file)
            done()
        },
        success: (file, response) => {
            console.log('SUCCESS!', file, response)
            let folderId = (file.fullPath).split('/')[0]
            let folderIdKey = folderId.replace(/[\.,\/,\s]/g, '_')
            BundelFolders[folderId] = BundelFolders[folderId] - 1
            console.log(BundelFolders)
            if(BundelFolders[folderId] == 0) {
                document.getElementById('dimmer_'+folderIdKey).classList.remove('active');
                document.getElementById('dimmer_'+folderIdKey).classList.add('inactive');
            }
            if (file.upload.chunked) {
                console.log('Removing temp files', uploadConfig.deleteTempFileUrl)
                axios.delete(uploadConfig.deleteTempFileUrl + file.upload.uuid + '/')
            }
        },
        error: (file, msg) => {
            console.log('ERROR', msg)
            window.alert(`Er is iets misgegaan (${msg})`)
            let folderId = (file.fullPath).split('/')[0]
            let folderIdKey = folderId.replace(/[\.,\/,\s]/g, '_')
            BundelFolders[folderId] = BundelFolders[folderId] - 1
            if(BundelFolders[folderId] == 0) {
                document.getElementById('dimmer_'+folderIdKey).classList.remove('active');
                document.getElementById('dimmer_'+folderIdKey).classList.add('inactive');
            }
            if (file && file.upload && file.upload.chunked) {
                console.log('Removing temp files', uploadConfig.deleteTempFileUrl)
                axios.delete(uploadConfig.deleteTempFileUrl + file.upload.uuid + '/')
            }
            setUploading(false)
        }
    }

    const processCompletion = () => {
        onUploadsComplete()
    }

    const addCompilaties = (arr) => {
        compilatieRef.current = arr
        setCompilaties(arr)
    }

    useEffect(() => {
        console.log(compilaties)
    },[compilaties])

    return(
        <div>
            <InfoViewer refId='INFO_UPLOAD_BUNDELS'
                             editable={serviceContext.infoEditable}
                             localeManager={serviceContext.localeManager}
                             localeDataService={serviceContext.infoLocaleDataService}/>
        <div>
        <DropzoneBundelsMetadata 
            setupCompilaties={addCompilaties}
        />
        <ComponentIdentifier displayName='DropzoneBundel'/>
        <DropzoneComponent 
                    config={componentConfig}
                    eventHandlers={eventHandlers}
                    djsConfig={djsConfig}
        >
        </DropzoneComponent>    
        <Segment>
                <InfoViewer refId='INFO_UPLOAD_BUNDELS_PROCESS'
                            editable={serviceContext.infoEditable}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button id="AnnuleerBundelCreate" disabled={disableCreate}>{serviceContext.localeManager.getString('ANNULEER')}</Button>
                    <Button id="CreateBundelButton" color='green' disabled={disableCreate}>Upload {serviceContext.localeManager.getString('BUNDELS')} ({showBundelsInList})</Button>
                </div>
            </Segment> 
        </div>
        </div>
    )
    
}

/**
({collectie, onCancel, onUploadsComplete}) => {

    const [view, setView] = useState(VIEW_IDLE)
    const [, setUploading] = useState(false)
    //const [progressPercentage, setProgressPercentage] = useState(0)
    const [bundels, setBundels] = useState([])
    const [rootFolders, setRootFolders] = useState([])
    const [files, setFiles] = useState([])
    const [paths, setPaths] = useState([])
    const [completedUploads, setCompletedUploads] = useState([])

    const serviceContext = useContext(ServiceContext)


    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        const newFiles = acceptedFiles.filter(file => paths.indexOf(file.path.replace(/^\//, "")) < 0)
        const allFiles = files.concat(newFiles)
        setPaths(allFiles.map(file => file.path.replace(/^\//, "")))
        const newRootFolders = getRootFolders(allFiles)
        const rootFiles = getRootFiles(allFiles)

        if (rootFiles.length > 0) {
            alert(serviceContext.localeManager.getString('ENKEL_MAPPEN_TOEGELATEN') + '\n\n' + rootFiles.join(', '))
        } else {
            setFiles(allFiles)
            setRootFolders(newRootFolders)
        }

    }, [files, paths, serviceContext.localeManager])

    useEffect(() => {
        setBundels(rootFolders.map(folder => {
            const bundel = new Bundel()
            bundel.titel_nl = folder
            bundel.academiejaar = collectie.academiejaar
            bundel.opleiding = collectie.opleiding
            bundel.status = BundelStatus.DRAFT
            return bundel
        }))
    }, [rootFolders, collectie.academiejaar, collectie.opleiding])

    const getRootFolders = (files) => {

        //wanneer files gedragged worden, dan begint het path met / maar als ze geselecteerd worden, dan niet!!!
        const rootFolders = files.reduce((folders, file) => {
            const pArr = file.path.split('/').filter(part => part !== '')
            if (pArr.length > 1 && !folders.includes(pArr[0])) {
                folders.push(pArr[0])
            }
            return folders
        }, [])

        return rootFolders
    }

    const getRootFiles = (files) => {
        const rootFiles = files.reduce((files, file) => {
            const pArr = file.path.split('/')
            if (pArr.length === 1 && !files.includes(pArr[0])) {
                files.push(pArr[0])
            }
            return files
        }, [])

        return rootFiles
    }

    const onRemoveBundel = (event, bundel) => {
        event.stopPropagation()
        setBundels(bundels.filter(b => b !== bundel))
    }

    const onAnnuleer = () => {
        setBundels([])
        setPaths([])
        setFiles([])
        setRootFolders([])
        setCompletedUploads([])
        setView(VIEW_IDLE)
        onCancel()
    }

    const onUpload = () => {
        setUploading(true)
        setView(VIEW_UPLOADING)
    }

    const onUploadComplete = (bundel) => {
        completedUploads.push(bundel)

        const allBundelsUploaded = bundels.reduce((completed, bundel) => {
            if (!completedUploads.includes(bundel)) return false
            return completed
        }, true)

        if (allBundelsUploaded) setView(VIEW_COMPLETE)
    }

    return (
        <div>
            <ComponentIdentifier displayName='DropzoneBundels'/>

            {view !== VIEW_COMPLETE && <div>
                <InfoViewer refId='INFO_UPLOAD_BUNDELS'
                             editable={serviceContext.infoEditable}
                             localeManager={serviceContext.localeManager}
                             localeDataService={serviceContext.infoLocaleDataService}/>
            </div>}


           {view === VIEW_IDLE && <Dropzone onDrop={onDrop}>
                {({getRootProps, getInputProps}) => (
                    <div className='dropzone' {...getRootProps()}>
                        <input {...getInputProps()} directory="" webkitdirectory=""/>

                        {bundels && <ResultContainer containerWidth={serviceContext.resultContainerAvailableWidth}>
                            {
                                bundels.map(bundel => {
                                    return <DropzoneBundelUpload key={bundel.uuid}
                                                                 files={files}
                                                                 bundel={bundel}
                                                                 onRemove={onRemoveBundel}
                                                                 onUploadComplete={onUploadComplete}/>
                                })
                            }

                        </ResultContainer>
                        }

                        {bundels && bundels.length === 0 && <div>{serviceContext.localeManager.getString('SLEEP_BUNDELMAPPEN_HIERNAARTOE')}</div>}
                    </div>
                )}
            </Dropzone>}

            {bundels && view === VIEW_UPLOADING && <ResultContainer containerWidth={serviceContext.resultContainerAvailableWidth}>
                {
                    bundels.map(bundel => {
                        return <DropzoneBundelUpload key={bundel.uuid}
                                                     uploading={true}
                                                     files={files}
                                                     bundel={bundel}
                                                     onRemove={onRemoveBundel}
                                                     onUploadComplete={onUploadComplete}/>
                    })
                }

            </ResultContainer>
            }


            {view !== VIEW_COMPLETE && <Segment>
                <InfoViewer refId='INFO_UPLOAD_BUNDELS_PROCESS'
                            editable={serviceContext.infoEditable}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={onAnnuleer}>{serviceContext.localeManager.getString('ANNULEER')}</Button>
                    <Button onClick={onUpload} color='green' disabled={bundels.length === 0}>Upload {serviceContext.localeManager.getString('BUNDELS')}</Button>
                </div>
            </Segment>}

            {view === VIEW_COMPLETE && <Segment>
                <InfoViewer refId='INFO_UPLOAD_BUNDELS_COMPLETE'
                            editable={serviceContext.infoEditable}
                            localeManager={serviceContext.localeManager}
                            localeDataService={serviceContext.infoLocaleDataService}/>

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button color='green' onClick={onUploadsComplete}>{serviceContext.localeManager.getString('SLUIT')}</Button>
                </div>
            </Segment>}
        </div>
    )
}
**/
export default DropzoneBundels

DropzoneBundels.propTypes = {
    collectie: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onUploadsComplete: PropTypes.func,
}

DropzoneBundels.defaultProps = {}
