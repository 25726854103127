import axios from 'axios'
import {ApplicationPath} from "../class/general/ApplicationPath"
import {AuthenticationService} from "./AuthenticationService"
import {ApplicationService} from "./ApplicationService";

export class MetadataService {

    applicationPath: ApplicationPath
    authenticationInstance: AuthenticationService
    applicationService: ApplicationService

    constructor(authenticationInstance, applicationService, applicationPath) {
        this.authenticationInstance = authenticationInstance
        this.applicationService = applicationService
        this.applicationPath = applicationPath
    }

    getMetadata = (provider) => {
        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.ada_api()}/getMetadata/${provider.uuid}/`

            axios.get(url)
                .then(response => {
                    console.log('IMPL CACHE', response.data)
                    resolve(response.data)
                })
                .catch(error => {
                    console.error('GET METADATA ERROR', error)
                    reject(error)
                })
        })
    }

    getMetadataTypeOptions = (type) => {
        return this.applicationService.config.metadata.allowed[type]
    }

    getRootMetadataTypes = () => {
        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.ada_api()}/getRootMetadataTypes/`

            axios.get(url)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.error('METADATA ERROR', error)
                    reject(error)
                })
        })
    }

    getMetadataType = (type) => {
        return new Promise((resolve, reject) => {
            let url = `${this.applicationPath.ada_api()}/getMetadataType/${type}/`
            //if (type) url += type + '/'

            axios.get(url)
                .then(response => {
                    console.log('GET METADATA TYPE', url, response)
                    resolve(response.data)
                })
                .catch(error => {
                    console.error('METADATA ERROR', error)
                    reject(error)
                })
        })
    }


}
