import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-react-spinbutton/dist/index.css';
import 'semantic-ui-react-treeview/dist/index.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import {ApplicationMode} from "@ahsmediatheek/ada-lib";

const isLocalHost = window.location.hostname === "localhost"

let serverMode = isLocalHost ? ApplicationMode.DEV : ApplicationMode.LIVE
let basename = '/digitaalarchief'

if (isLocalHost){
    serverMode = ApplicationMode.DEV
    //basename += '-dev'
} else {
    if (window.location.href.includes('digitaalarchief-dev')) {
        //basename += '-dev'
        serverMode = ApplicationMode.DEV
    } else if (window.location.href.includes('digitaalarchief-staging')) {
        //basename += '-staging'
        serverMode = ApplicationMode.STAGING
    }
}

ReactDOM.render(
    <BrowserRouter basename={basename}><App serverMode={serverMode}/></BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
